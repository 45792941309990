import React, { Component } from "react";

import { getUserRoles } from "../../utils/restUtils";
import { UserEdit } from "./edit";

class UserEditPage extends Component {
  state = {
    clubAdminRoleId: null
  };
  componentDidMount() {
    getUserRoles().then(options => {
      options.map(item => {
        if (item.name === "ROLE_CLUB_ADMIN") {
          this.setState({ clubAdminRoleId: item.id });
        }
      });
    });
  }

  render = () => (
    <UserEdit clubAdminRoleId={this.state.clubAdminRoleId} {...this.props} />
  );
}

export default UserEditPage;
