import React from "react";
import { Route } from "react-router-dom";
import Configuration from "./pages/configuration/Configuration";
import CompetitionListPage from "./pages/competition/CompetitionListPage";
import OrderListPage from "./pages/order/OrderListPage";
import OrderItemListPage from "./pages/order/OrderItemListPage";
import ClubShowPublicPage from "./pages/club/ClubShowPublicPage";
import AdShowPublicPage from "./pages/advertising/AdShowPublicPage";
import ClubListPage from "./pages/club/ClubListPage";
import CompetitionShowPage from "./pages/competition/CompetitionShowPage";
import OrderShowPage from "./pages/order/OrderShowPage";
import CheckoutFormPage from "./pages/competition/CheckoutFormPage";
import CompetitionCopyPage from "./pages/competition/CompetitionCopyPage";
import EventCopyPage from "./pages/competition/EventCopyPage";
import ConfirmRegistrationPage from "./pages/user/ConfirmRegistrationPage";
import ConfirmPasswordResetPage from "./pages/user/ConfirmPasswordResetPage";
import UserRegisterFormPage from "./pages/user/UserRegisterFormPage";
import ResetPasswordPage from "./pages/user/ResetPasswordPage";
import Dashboard from "./pages/dashboard/Dashboard";
import ReservationCodeFormPage from "./pages/competition/ReservationCodeFormPage";
import ReservationCodeListPage from "./pages/competition/ReservationCodeListPage";
import ShooterCompetitionListPage from "./pages/personalPages/ShooterCompetitionListPage";
import CompetitionResultListPage from "./pages/competition/CompetitionResultListPage";
import conditionsPage from "./pages/contentPages/conditionsPage";
import dataProtectionPage from "./pages/contentPages/dataProtectionPage";
import impressumPage from "./pages/contentPages/impressumPage";
import changelogPage from "./pages/contentPages/changelogPage";
import ExportPage from "./pages/export";
import orderCompletePage from "./pages/contentPages/orderCompletePage";
import userRegisterSuccessPage from "./pages/contentPages/userRegisterSuccessPage";
import paymentCompletePage from "./pages/contentPages/PaymentCompletePage";
import orderFailPage from "./pages/contentPages/orderFailPage";
import PublicOrderItemEditPage from "./pages/order/PublicOrderItemEditPage";
import OrderItemPayPage from "./pages/order/OrderItemPayPage";

export default [
  <Route exact path="/configuration" component={Configuration} />,
  <Route path="/competitionList" component={CompetitionListPage} />,
  <Route path="/orderList" component={OrderListPage} />,
  <Route path="/orderItemList" component={OrderItemListPage} />,
  <Route
    path="/publicOrderItems/:id/:token"
    component={PublicOrderItemEditPage}
  />,
  <Route path="/competitiondetails/:id" component={CompetitionShowPage} />,
  <Route path="/orderDetails/:id" component={OrderShowPage} />,
  <Route path="/competitions/copy/:id" component={CompetitionCopyPage} />,
  <Route path="/events/copy/:id" component={EventCopyPage} />,
  <Route path="/reservationCodes" component={ReservationCodeFormPage} />,
  <Route path="/reservationCodesList" component={ReservationCodeListPage} />,
  <Route path="/orderItem/pay/:id" component={OrderItemPayPage} />,

  <Route path="/clubList" component={ClubListPage} />,

  <Route path="/userRegister" component={UserRegisterFormPage} />,
  <Route path="/dashboard" component={Dashboard} />,
  <Route path="/exports" component={ExportPage} />,
  <Route
    path="/confirmRegistration/:token"
    component={ConfirmRegistrationPage}
  />,
  <Route
    path="/confirmPasswordReset/:token"
    component={ConfirmPasswordResetPage}
  />,
  <Route path="/resetPassword" component={ResetPasswordPage} />,
  <Route path="/vereine/:id" component={ClubShowPublicPage} />,
  <Route path="/werbung/:id" component={AdShowPublicPage} />,
  // <Route path="/cart" component={CheckoutFormPage} />,
  <Route path="/myCompetitions" component={ShooterCompetitionListPage} />,
  <Route path="/results" component={CompetitionResultListPage} />,
  <Route path="/conditions" component={conditionsPage} />,
  <Route path="/datenschutz" component={dataProtectionPage} />,
  <Route path="/impressum" component={impressumPage} />,
  <Route path="/about" render={() => window.location.replace("https://compsign.at/ueber-compsign/")} />,
  <Route path="/costs" render={() => window.location.replace("https://compsign.at/was-compsign-kostet/")} />,
  <Route path="/premiumdienste" render={() => window.location.replace("https://compsign.at/meine-vorteile-als-registrierter-schuetze/")} />,
  <Route path="/orderComplete" component={orderCompletePage} />,
  <Route path="/paymentComplete" component={paymentCompletePage} />,
  <Route path="/paymentFailed" component={orderFailPage} />,
  <Route path="/changelog" component={changelogPage} />,
  <Route path="/userRegisterSuccess" component={userRegisterSuccessPage} />
];
