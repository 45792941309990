import { ListItemIcon, Menu, MenuItem, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ButtonMenuDownload from "./ButtonMenuDownload";

require("moment/locale/de.js");
require("moment/locale/de-at.js");
const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 3
  }
});

class ButtonMenu extends Component {
  state = {
    anchorEl: null,
    openMenu: null
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
      openMenu: event.currentTarget.getAttribute("aria-controls")
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, openMenu: null });
  };

  render() {
    const { options, buttonIcon, anchorOrigin, transformOrigin } = this.props;

    if (!options || options.length <= 0) {
      return null;
    }

    return [
      <IconButton
        key={"buttonMenuButton"}
        aria-label="more"
        aria-controls={"simpleListMenu"}
        aria-haspopup="true"
        onClick={this.handleClick}
      >
        {buttonIcon}
      </IconButton>,
      <Menu
        key={"buttonMenuMenu"}
        anchorEl={this.state.anchorEl}
        open={this.state.openMenu}
        onClose={this.handleClose}
        PaperProps={{}}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {options.map((option, oKey) => [
          option.download ? (
            <ButtonMenuDownload menuItem={option} />
          ) : (
            <MenuItem
              key={oKey}
              onClick={() => {
                this.handleClose();
                option.callBack();
              }}
            >
              {typeof option.icon !== "undefined" && option.icon !== null && (
                <ListItemIcon>{option.icon}</ListItemIcon>
              )}
              <Typography variant="inherit" noWrap>
                {option.text}
              </Typography>
            </MenuItem>
          )
        ])}
      </Menu>
    ];
  }
}

ButtonMenu.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  anchorOrigin: PropTypes.object,
  transformOrigin: PropTypes.object
};

ButtonMenu.defaultProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right"
  }
};

export default withStyles(styles)(withWidth()(ButtonMenu));
