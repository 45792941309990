// React imports
import React from "react";

// React Admin imports
import { translate } from "react-admin";

// Redux imports
import { connect } from "react-redux";

// Material imports
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// Icon imports
import CopyIcon from "@material-ui/icons/Add";

// Other imports
import PropTypes from "prop-types";

const styles = {
  copyBtn: {
    justifyContent: "center"
  },
  copyColor: {
    color: "#73B163"
  }
};

const CopyButton = ({ clickHandler, translate, itemId, ...rest }) => (
  <Tooltip title={translate("copyButton.tooltip")}>
    <IconButton
      onClick={() => clickHandler(itemId)}
      style={styles.copyBtn}
      {...rest}
    >
      <CopyIcon style={styles.copyColor} />
    </IconButton>
  </Tooltip>
);

CopyButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired
};
export default connect()(translate(CopyButton));
