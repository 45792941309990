// React imports
import React from "react";

// React Redux imports
import { connect } from "react-redux";
import { Form, Field, reduxForm, blur, touch } from "redux-form";

// Material imports
import Grid from "@material-ui/core/Grid";
import { translate } from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

// Other imports
import ErrorMessage from "../ErrorMessage";
import SuccessMessage from "../SuccessMessage";
import PropTypes from "prop-types";
import {
  renderSelectField,
  renderTextField
} from "../inputs/renderMaterialFields";
import PageTitle from "../../components/PageTitle";
import CustomAutocompleteInput from "../CustomAutocompleteInput";

const styles = {
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: "center"
  },
  spacer: {
    height: "20px"
  },
  cardTitleBgGreen: {
    backgroundColor: "#7E896E"
  },
  cardTitleBgPurple: {
    backgroundColor: "#7C757B"
  },
  cardTitle: {
    color: "#ffffff",
    display: "flex",
    minHeight: "40px",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  signupBtn: {
    backgroundColor: "#7E896E",
    color: "#ffffff"
  },
  notification: {
    backgroundColor: "rgba(67, 160, 71, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#43A047",
    padding: "8px 10px"
  },
  error: {
    backgroundColor: "rgba(211, 46, 47, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#D32E2F",
    padding: "8px 10px"
  },
  info: {
    backgroundColor: "rgba(26, 118, 210, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#43A047",
    padding: "8px 10px"
  },
  notificationText: {
    color: "#ffffff"
  }
};

const formatDate = dateString => {
  let date = new Date(dateString);
  var d = date.getDate();
  var m = date.getMonth() + 1; //Month from 0 to 11
  var y = date.getFullYear();
  return (d <= 9 ? "0" + d : d) + "." + (m <= 9 ? "0" + m : m) + "." + y;
};

const renderCustomLabel = label => {
  var labelStyle = {
    color: "gray",
    fontSize: "16px"
  };
  return <label style={labelStyle}>{label}</label>;
};

const renderCustomAutocompleteInput = ({
  input,
  label,
  type,
  options,
  source,
  translate,
  validate,
  defaultValue,
  sort,
  meta: { touched, error, warning, invalid }
}) => (
  <>
    <CustomAutocompleteInput
      validate={validate}
      {...input}
      invalid={invalid}
      placeholder={label}
      source={source}
      type={type}
      options={options}
      translate={translate}
      defaultValue={defaultValue}
      label={renderCustomLabel(label)}
      touched={touched}
      error={error}
      warning={warning}
      sort={sort}
    />
    {touched &&
      ((error && (
        <Typography>
          <span style={{ color: "red", fontSize: "12px" }}>
            {translate(error)}
          </span>
        </Typography>
      )) ||
        (warning && <p>{warning}</p>))}
  </>
);

const required = value => (value && value !== "" ? undefined : "Required");

let ReservationCodeForm = props => {
  return (
    <Grid container spacing={16}>
      <PageTitle title={props.translate("resources.reservationCodes.create")} />
      <Grid item xs={12}>
        <Card>
          <CardContent style={{ height: "430px" }}>
            <Form
              initalValues={props.initialValues}
              onSubmit={props.handleSubmit}
            >
              <Field
                name="amount"
                id="amount"
                component={renderTextField}
                validate={required}
                type="text"
                label={props.translate("resources.reservationCodes.amount")}
              />
              <Field
                name="selectedCompetition"
                component={renderCustomAutocompleteInput}
                options={props.csCompetitions}
                translate={props.translate}
                validate={[required]}
                label={props.translate(
                  "resources.reservationCodes.competition"
                )}
              />
              <div style={styles.spacer}></div>
              {props.submitButton ? (
                <Button
                  variant="contained"
                  style={styles.signupBtn}
                  onClick={() => {
                    Object.keys(props.myForm.registeredFields).forEach(key => {
                      props.dispatch(touch("reservationCodeForm", key));
                      props.dispatch(blur("reservationCodeForm", key));
                    });
                    if (props.valid) {
                      props.handleSubmit();
                    }
                  }}
                >
                  {props.translate("resources.reservationCodes.submit")}
                </Button>
              ) : (
                <div style={styles.info}>
                  <Typography variant="body1" style={styles.notificationText}>
                    {props.translate("resources.reservationCodes.generating")}
                  </Typography>
                </div>
              )}
            </Form>
          </CardContent>
        </Card>
      </Grid>
      <SuccessMessage
        successMessage={props.successMessage}
        open={props.successMessage ? true : false}
        onClose={props.hideSuccess}
        translate={props.translate}
      />
      <ErrorMessage
        errorMessage={props.errorMessage}
        open={props.errorMessage ? true : false}
        onClose={props.hideError}
        translate={props.translate}
      />
    </Grid>
  );
};

ReservationCodeForm = reduxForm({
  // a unique name for the form
  form: "reservationCodeForm"
})(ReservationCodeForm);

ReservationCodeForm.propTypes = {
  translate: PropTypes.func.isRequired,
  errorMessage: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired,
  successMessage: PropTypes.object,
  submitButton: PropTypes.object,
  hideSuccess: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    myForm: state.form.reservationCodeForm,
    popupWaitingListAlert: state.flags.popupOpen.popupWaitingListAlert
  };
}

export default translate(
  connect(mapStateToProps)(withStyles(styles)(ReservationCodeForm))
);
