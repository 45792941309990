import React, { Component, Fragment } from "react";
import {
  Edit,
  TextInput,
  SelectInput,
  BooleanInput,
  translate,
  FormDataConsumer,
  Toolbar,
  SaveButton,
  TabbedForm,
  FormTab,
  WithPermissions,
  Responsive,
  SimpleForm,
  SelectArrayInput
} from "react-admin";
import { setError } from "../../reducer/errorActions";
import moment from "moment";
import {
  required,
  passwordEdit,
  passwordEquals
} from "../../validators/validators";
import { connect } from "react-redux";
import { updateUserData } from "../../reducer/userActions";
import {
  getCompetitionClasses,
  getCompetitionDevisions,
  getCompetitionFactors,
  getClubs,
  getReservationsByUser
} from "../../utils/restUtils";
import {
  isPortalAdmin,
  isClubAdmin,
  isShooter,
  isGuest
} from "../../utils/utils";

import CheckIcon from "@material-ui/icons/Check";

import PageTitle from "../../components/PageTitle";
import {
  Typography,
  Table,
  TableCell,
  TableRow,
  TableHead,
  Grid,
  Button,
  TextField
} from "@material-ui/core";

import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepButton from "@material-ui/core/StepButton";
import StepContent from "@material-ui/core/StepContent";

import { austrianStates, germanStates } from "../../utils/states";
import { REDUX_FORM_NAME } from "ra-core";
import { change, untouch } from "redux-form";
import countries from "../../components/pages/country-codes-case-lower.json";
const getCountryOptions = () => {
  return countries.map(country => {
    return {
      id: country.countryCode,
      name: country.countryName
    };
  });
};

const styles = {
  greyButton: {
    marginRight: "10px"
  },
  colorButton: {
    backgroundColor: "#7e886e",
    marginRight: "20px"
  },
  actionsContainer: {
    marginTop: "30px"
  }
};

const CustomToolbar = props => (
  <Toolbar style={{ justifyContent: "space-between" }} {...props}>
    <SaveButton />
    <Button
      variant="contained"
      onClick={() => props.history.push("/dashboard")}
    >
      Abbrechen
    </Button>
  </Toolbar>
);

class ProfileEdit extends Component {
  state = {
    clubs: [],
    competitionClasses: [],
    competitionDivisions: [],
    competitionFactors: [],
    reservations: [],
    activeStep: 0,
    errorSteps: {}
  };

  keyToStep = {
    email: 0,
    password: 0,
    passwordValidate: 0,
    selectedLanguage: 0,
    salutation: 1,
    title: 1,
    firstName: 1,
    lastName: 1,
    address: 1,
    zip: 1,
    city: 1,
    country: 1,
    state: 1,
    telephone: 1,
    competitionClass: 2,
    division: 2,
    factor: 2,
    club: 2,
    alias: 2,
    cowboyName: 2,
    sassNumber: 2,
    ipscAlias: 2,
    ipscNumber: 2,
    ipscRegion: 2
  };

  getStepContent = step => {
    const { translate } = this.props;
    return (
      <>
        <div style={{ display: step === 0 ? "block" : "none" }}>
          <div>
            <TextInput
              label={translate("resources.users.registerForm.fields.username")}
              source="username"
              validate={[required()]}
              disabled
              fullWidth
            />

            <FormDataConsumer>
              {(formData, ...rest) => {
                return (
                  formData &&
                  formData.formData &&
                  formData.formData.userRoles &&
                  formData.formData.userRoles.map(role => [
                    <TextInput
                      label={this.props.translate("resources.userRoles.name")}
                      source="rolename"
                      defaultValue={this.props.translate(role.name)}
                      disabled
                      fullWidth
                    />
                  ])
                );
              }}
            </FormDataConsumer>

            <TextInput
              label={this.props.translate(
                "resources.users.registerForm.fields.email"
              )}
              source="email"
              validate={[required()]}
              fullWidth
            />

            <TextInput
              label={this.props.translate(
                "resources.users.registerForm.fields.password"
              )}
              source="password"
              type="password"
              validate={[passwordEdit()]}
              fullWidth
            />

            <TextInput
              label={this.props.translate(
                "resources.users.registerForm.fields.passwordValidate"
              )}
              source="password2"
              type="password"
              validate={[passwordEquals()]}
              fullWidth
            />
          </div>
        </div>
        <div style={{ display: step === 1 ? "block" : "none" }}>
          <div>
            <TextInput
              label={this.props.translate(
                "resources.users.registerForm.fields.firstName"
              )}
              source="firstName"
              validate={[required()]}
              fullWidth
            />

            <TextInput
              label={this.props.translate(
                "resources.users.registerForm.fields.lastName"
              )}
              source="lastName"
              validate={[required()]}
              fullWidth
            />

            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                return (
                  formData &&
                  formData.userRoles &&
                  formData.userRoles.some(e => e.name === "ROLE_USER") && [
                    <SelectInput
                      source="salutation"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.salutation"
                      )}
                      validate={[required()]}
                      choices={[
                        { id: "", name: "" },
                        {
                          id: "mr",
                          name: this.props.translate(
                            "resources.users.registerForm.fields.salutations.mr"
                          )
                        },
                        {
                          id: "mrs",
                          name: this.props.translate(
                            "resources.users.registerForm.fields.salutations.mrs"
                          )
                        }
                      ]}
                      fullWidth
                    />,
                    <TextInput
                      label={this.props.translate(
                        "resources.users.registerForm.fields.degree"
                      )}
                      source="degree"
                      fullWidth
                    />,
                    <SelectInput
                      source="selectedLanguage"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.selectedLanguage"
                      )}
                      validate={[required()]}
                      choices={[
                        { id: "", name: "" },
                        {
                          id: "de",
                          name: this.props.translate(
                            "resources.users.registerForm.fields.languages.de"
                          )
                        },
                        {
                          id: "en",
                          name: this.props.translate(
                            "resources.users.registerForm.fields.languages.en"
                          )
                        }
                      ]}
                      fullWidth
                    />,
                    <TextInput
                      source="address"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.address"
                      )}
                      validate={[required()]}
                      fullWidth
                    />,
                    <TextInput
                      source="zip"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.zip"
                      )}
                      validate={[required()]}
                      fullWidth
                    />,
                    <TextInput
                      source="city"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.city"
                      )}
                      validate={[required()]}
                      fullWidth
                    />,
                    <SelectInput
                      source="state"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.state"
                      )}
                      validate={[required()]}
                      choices={
                        formData && formData.country === "de"
                          ? germanStates
                          : austrianStates
                      }
                      fullWidth
                    />,
                    <SelectInput
                      source="country"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.country"
                      )}
                      onChange={() => {
                        dispatch(untouch(REDUX_FORM_NAME, "state"));
                        dispatch(change(REDUX_FORM_NAME, "state", null));
                      }}
                      validate={[required()]}
                      choices={[
                        { id: "", name: "" },
                        {
                          id: "at",
                          name: this.props.translate(
                            "resources.users.registerForm.fields.countries.austria"
                          )
                        },
                        {
                          id: "de",
                          name: this.props.translate(
                            "resources.users.registerForm.fields.countries.germany"
                          )
                        }
                      ]}
                      fullWidth
                    />,
                    <TextInput
                      source="telephone"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.telephone"
                      )}
                      validate={[]}
                      fullWidth
                    />,
                    <BooleanInput
                      source="allowInformations"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.allowInformations"
                      )}
                    />
                  ]
                );
              }}
            </FormDataConsumer>
          </div>
        </div>
        <div style={{ display: step === 2 ? "block" : "none" }}>
          <div>
            <FormDataConsumer>
              {({ formData, dispatch, ...rest }) => {
                return (
                  formData &&
                  formData.userRoles &&
                  formData.userRoles.some(e => e.name === "ROLE_USER") && [
                    <SelectInput
                      label={this.props.translate(
                        "resources.users.registerForm.fields.competitionClass"
                      )}
                      validate={[required()]}
                      source="competitionClass.id"
                      choices={this.state.competitionClasses}
                      optionText="name"
                      optionValue="id"
                      fullWidth
                    />,
                    <SelectInput
                      label={this.props.translate(
                        "resources.users.registerForm.fields.competitionDivision"
                      )}
                      validate={[required()]}
                      source="division.id"
                      choices={this.state.competitionDivisions}
                      optionText="name"
                      optionValue="id"
                      fullWidth
                    />,
                    <SelectInput
                      label={this.props.translate(
                        "resources.users.registerForm.fields.competitionFactor"
                      )}
                      validate={[required()]}
                      source="factor.id"
                      choices={this.state.competitionFactors}
                      optionText="name"
                      optionValue="id"
                      fullWidth
                    />,
                    <SelectInput
                      label={this.props.translate(
                        "resources.users.registerForm.fields.club"
                      )}
                      validate={[required()]}
                      source="club.id"
                      choices={this.state.clubs}
                      optionText="name"
                      optionValue="id"
                      fullWidth
                    />,
                    <TextInput
                      source="alias"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.alias"
                      )}
                      validate={[]}
                      fullWidth
                    />,
                    <TextInput
                      source="cowboyName"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.cowboyName"
                      )}
                      validate={[]}
                      fullWidth
                    />,
                    <TextInput
                      source="sassNumber"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.sassNumber"
                      )}
                      validate={[]}
                      fullWidth
                    />,
                    <TextInput
                      source="ipscNumber"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.ipscNumber"
                      )}
                      validate={[]}
                      fullWidth
                    />,
                    <TextInput
                      source="ipscRegion"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.ipscRegion"
                      )}
                      validate={[]}
                      fullWidth
                    />,
                    <TextInput
                      source="ipscAlias"
                      label={this.props.translate(
                        "resources.users.registerForm.fields.ipscAlias"
                      )}
                      validate={[]}
                      fullWidth
                    />
                  ]
                );
              }}
            </FormDataConsumer>
          </div>
        </div>
        <div style={{ display: step === 3 ? "block" : "none" }}>
          <div>
            <WithPermissions
              render={({ permissions }) => {
                return [
                  isPortalAdmin(permissions) && (
                    <BooleanInput
                      source="notificationSelection.newShooterRegistrations"
                      label="resources.profile.fields.notificationSelection.newShooterRegistrations"
                      options={{}}
                    />
                  ),
                  isClubAdmin(permissions) && (
                    <BooleanInput
                      source="notificationSelection.newRegistrationsToCompetitions"
                      label="resources.profile.fields.notificationSelection.newRegistrationsToCompetitions"
                      options={{}}
                    />
                  ),
                  isShooter(permissions) && (
                    <BooleanInput
                      source="notificationSelection.registrationChangeRegistrationSuccess"
                      label="resources.profile.fields.notificationSelection.registrationChangeRegistrationSuccess"
                      options={{}}
                    />
                  ),
                  isShooter(permissions) && (
                    <BooleanInput
                      source="notificationSelection.registrationChangeRegistrationCancelledByAdministrator"
                      label="resources.profile.fields.notificationSelection.registrationChangeRegistrationCancelledByAdministrator"
                      options={{}}
                    />
                  ),
                  isShooter(permissions) && (
                    <BooleanInput
                      source="notificationSelection.registrationChangeRegistrationCancelledByShooter"
                      label="resources.profile.fields.notificationSelection.registrationChangeRegistrationCancelledByShooter"
                      options={{}}
                    />
                  ),
                  isShooter(permissions) && (
                    <BooleanInput
                      source="notificationSelection.registrationChangeRegistrationCancelledNoPayment"
                      label="resources.profile.fields.notificationSelection.registrationChangeRegistrationCancelledNoPayment"
                      options={{}}
                    />
                  ),
                  isShooter(permissions) && (
                    <BooleanInput
                      source="notificationSelection.gettingStartingPlace"
                      label="resources.profile.fields.notificationSelection.gettingStartingPlace"
                      options={{}}
                    />
                  ),
                  isShooter(permissions) && (
                    <BooleanInput
                      source="notificationSelection.signupPhaseEndsReminder"
                      label="resources.profile.fields.notificationSelection.signupPhaseEndsReminder"
                      options={{}}
                    />
                  ),
                  isShooter(permissions) && (
                    <BooleanInput
                      source="notificationSelection.squadPlaceFree"
                      label="resources.profile.fields.notificationSelection.squadPlaceFree"
                      options={{}}
                    />
                  ),
                  isShooter(permissions) && (
                    <BooleanInput
                      source="notificationSelection.registrationChangePaymentReceived"
                      label="resources.profile.fields.notificationSelection.registrationChangePaymentReceived"
                      options={{}}
                    />
                  ),
                  isShooter(permissions) && (
                    <BooleanInput
                      source="notificationSelection.competitionStartReminder"
                      label="resources.profile.fields.notificationSelection.competitionStartReminder"
                      options={{}}
                    />
                  ),
                  isShooter(permissions) && (
                    <BooleanInput
                      source="notificationSelection.competitionPaymentReminder"
                      label="resources.profile.fields.notificationSelection.competitionPaymentReminder"
                      options={{}}
                    />
                  ),
                  isClubAdmin(permissions) && (
                    <BooleanInput
                      source="notificationSelection.memberCancelNoPaymentReminder"
                      label="resources.profile.fields.notificationSelection.memberCancelNoPaymentReminder"
                      options={{}}
                    />
                  ),
                  isClubAdmin(permissions) && (
                    <BooleanInput
                      source="notificationSelection.memberCancelNoPayment"
                      label="resources.profile.fields.notificationSelection.memberCancelNoPayment"
                      options={{}}
                    />
                  ),
                  isShooter(permissions) && (
                    <BooleanInput
                      source="notificationSelection.signupPhaseOfFavourizedCompetitionStarted"
                      label="resources.profile.fields.notificationSelection.signupPhaseOfFavourizedCompetitionStarted"
                      options={{}}
                    />
                  ),
                  <SelectArrayInput
                    fullWidth
                    label={this.props.translate(
                      "resources.users.registerForm.fields.competitionDivisionNotification"
                    )}
                    source="division_ids"
                    validate={required()}
                    choices={this.state.competitionDivisions}
                    optionText="name"
                  />
                ];
              }}
            />
          </div>
        </div>
        <div style={{ display: step === 4 ? "block" : "none" }}>
          <div>
            {this.state.reservations !== []
              ? this.state.reservations.map((reservation, key) => (
                  <Grid>
                    <Table style={{ marginTop: "30px" }}>
                      <TableHead>
                        <Typography variant="title">
                          {this.props.translate(
                            "resources.profile.reservations.reservation"
                          ) + " "}
                          #{key + 1}
                        </Typography>
                      </TableHead>
                      <TableRow>
                        <TableCell>
                          {this.props.translate(
                            "resources.profile.reservations.competitions"
                          ) + ": "}
                        </TableCell>
                        <TableCell>
                          {reservation.competitions.map(
                            competition => competition.name + " "
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {this.props.translate(
                            "resources.profile.reservations.divisions"
                          ) + ": "}
                        </TableCell>
                        <TableCell>
                          {reservation.divisions.map(
                            division => division.name + " "
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {this.props.translate(
                            "resources.profile.reservations.factors"
                          ) + ": "}
                        </TableCell>
                        <TableCell>
                          {reservation.factors.map(factor => factor.name + " ")}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {this.props.translate(
                            "resources.profile.reservations.compClasses"
                          ) + ": "}
                        </TableCell>
                        <TableCell>
                          {reservation.competitionClasses.map(
                            competitionClasse => competitionClasse.name + " "
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {this.props.translate(
                            "resources.profile.reservations.validUntil"
                          ) + ":"}
                        </TableCell>
                        <TableCell>
                          {moment(reservation.validUntil)
                            .format("L")
                            .toString()}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {this.props.translate(
                            "resources.profile.reservations.qualification"
                          ) + ":"}
                        </TableCell>
                        <TableCell>
                          {reservation.qualification ? <CheckIcon /> : null}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          {this.props.translate(
                            "resources.profile.reservations.maxReservations"
                          ) + ":"}
                        </TableCell>
                        <TableCell>{reservation.maxReservations}</TableCell>
                      </TableRow>
                    </Table>
                  </Grid>
                ))
              : null}
          </div>
        </div>
      </>
    );
  };

  getSteps = () => {
    const { translate } = this.props;
    return [
      this.props.translate("resources.profile.tabs.userInformation"),
      this.props.translate("resources.profile.tabs.userContactData"),
      this.props.translate("resources.profile.tabs.shooterData"),
      this.props.translate("resources.profile.tabs.notificationSelection"),
      this.props.translate("resources.profile.tabs.reservations")
    ];
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleStep = step => () => {
    this.setState({
      activeStep: step
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  componentDidMount = () => {
    getCompetitionClasses().then(data => {
      this.setState({ competitionClasses: data ? data : [] }, () => {});
    });
    getCompetitionDevisions().then(data => {
      this.setState({ competitionDivisions: data ? data : [] }, () => {});
    });
    getCompetitionFactors().then(data => {
      this.setState({ competitionFactors: data ? data : [] }, () => {});
    });
    getClubs().then(data => {
      this.setState({ clubs: data ? data : [] }, () => {});
    });

    getReservationsByUser(0, 10, "", "", {}, 1).then(
      data => {
        this.setState({ reservations: data ? this.decorateData(data) : [] });
      },
      e => this.props.dispatch(setError(e))
    );
  };

  decorateData = data => {
    return data;
  };

  redirect = (basePath, id, data) => {
    this.props.dispatch(
      updateUserData({
        userFirstName: data.firstName,
        userLastName: data.lastName,
        userFullName: data.firstName + " " + data.lastName,
        competitionViewType: data.competitionViewType
      })
    );
    return "/dashboard/";
  };

  render() {
    moment.locale("de-at");
    const { activeStep } = this.state;
    const steps = this.getSteps();
    return (
      <div>
        <PageTitle
          title={this.props.translate("resources.profile.editProfile")}
        />
        <Responsive
          small={
            <Edit {...this.props}>
              <SimpleForm
                toolbar={<CustomToolbar {...this.props} />}
                redirect={this.redirect}
                style={{ width: "100%" }}
              >
                <Stepper
                  activeStep={activeStep}
                  orientation="vertical"
                  style={{ width: "90%" }}
                >
                  {steps.map((label, index) => {
                    const labelProps = {};
                    return (
                      <Step key={label}>
                        <StepButton onClick={this.handleStep(index)}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </StepButton>
                        <StepContent>
                          <Typography>{this.getStepContent(index)}</Typography>
                          <div style={styles.actionsContainer}>
                            <div>
                              <Button
                                disabled={activeStep === 0}
                                onClick={this.handleBack}
                                style={styles.greyButton}
                              >
                                {this.props.translate(
                                  "resources.users.registerForm.back"
                                )}
                              </Button>
                              {activeStep === steps.length - 1 ? (
                                ""
                              ) : (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={this.handleNext}
                                  style={styles.colorButton}
                                >
                                  {this.props.translate(
                                    "resources.users.registerForm.next"
                                  )}
                                </Button>
                              )}
                            </div>
                          </div>
                        </StepContent>
                      </Step>
                    );
                  })}
                </Stepper>
              </SimpleForm>
            </Edit>
          }
          medium={
            <Edit {...this.props}>
              <TabbedForm
                //asyncValidate={email}
                //asyncBlurFields={["email"]}
                redirect={this.redirect}
                bulkActionButtons={false}
                toolbar={<CustomToolbar {...this.props} />}
              >
                <FormTab label="resources.profile.tabs.userInformation">
                  <TextInput
                    label={this.props.translate(
                      "resources.users.registerForm.fields.username"
                    )}
                    source="username"
                    validate={[required()]}
                    disabled
                    fullWidth
                  />

                  <FormDataConsumer>
                    {(formData, ...rest) => {
                      return (
                        formData &&
                        formData.formData &&
                        formData.formData.userRoles &&
                        formData.formData.userRoles.map(role => [
                          //<span>-{this.props.translate(role.name)}</span>,
                          <TextInput
                            label={this.props.translate(
                              "resources.userRoles.name"
                            )}
                            source="rolename"
                            defaultValue={this.props.translate(role.name)}
                            disabled
                            fullWidth
                          />
                        ])
                      );
                    }}
                  </FormDataConsumer>

                  <TextInput
                    label={this.props.translate(
                      "resources.users.registerForm.fields.email"
                    )}
                    source="email"
                    validate={[required()]}
                    fullWidth
                  />

                  <TextInput
                    label={this.props.translate(
                      "resources.users.registerForm.fields.password"
                    )}
                    source="password"
                    type="password"
                    validate={[passwordEdit()]}
                    fullWidth
                  />

                  <TextInput
                    label={this.props.translate(
                      "resources.users.registerForm.fields.passwordValidate"
                    )}
                    source="password2"
                    type="password"
                    validate={[passwordEquals()]}
                    fullWidth
                  />
                  {isGuest(this.props.identity.userData.permissions) ||
                  (!isGuest(this.props.identity.userData.permissions) &&
                    (this.props.identity.userData.paymentValid === false ||
                      this.props.identity.userData.paymentValid ===
                        "false")) ? null : (
                    <BooleanInput
                      source="competitionViewType"
                      disabled={
                        isGuest(this.props.identity.userData.permissions) ||
                        (!isGuest(this.props.identity.userData.permissions) &&
                          (this.props.identity.userData.paymentValid ===
                            false ||
                            this.props.identity.userData.paymentValid ===
                              "false"))
                      }
                      label={this.props.translate(
                        "resources.users.registerForm.fields.competitionViewType"
                      )}
                    />
                  )}
                  {isGuest(this.props.identity.userData.permissions) ||
                  (!isGuest(this.props.identity.userData.permissions) &&
                    (this.props.identity.userData.paymentValid === false ||
                      this.props.identity.userData.paymentValid ===
                        "false")) ? null : (
                    <BooleanInput
                      source="shooterCompetitionViewType"
                      disabled={
                        isGuest(this.props.identity.userData.permissions) ||
                        (!isGuest(this.props.identity.userData.permissions) &&
                          (this.props.identity.userData.paymentValid ===
                            false ||
                            this.props.identity.userData.paymentValid ===
                              "false"))
                      }
                      label={this.props.translate(
                        "resources.users.registerForm.fields.shooterCompetitionViewType"
                      )}
                    />
                  )}
                </FormTab>
                <FormTab label="resources.profile.tabs.userContactData">
                  <TextInput
                    label={this.props.translate(
                      "resources.users.registerForm.fields.firstName"
                    )}
                    source="firstName"
                    validate={[required()]}
                    fullWidth
                  />
                  <TextInput
                    label={this.props.translate(
                      "resources.users.registerForm.fields.lastName"
                    )}
                    source="lastName"
                    validate={[required()]}
                    fullWidth
                  />
                  <SelectInput
                    source="selectedLanguage"
                    label={this.props.translate(
                      "resources.users.registerForm.fields.selectedLanguage"
                    )}
                    validate={[required()]}
                    choices={[
                      { id: "", name: "" },
                      {
                        id: "de",
                        name: this.props.translate(
                          "resources.users.registerForm.fields.languages.de"
                        )
                      },
                      {
                        id: "en",
                        name: this.props.translate(
                          "resources.users.registerForm.fields.languages.en"
                        )
                      }
                    ]}
                    fullWidth
                  />

                  <FormDataConsumer>
                    {({ formData, dispatch, ...rest }) => {
                      return (
                        formData &&
                        formData.userRoles &&
                        formData.userRoles.some(
                          e => e.name === "ROLE_USER"
                        ) && [
                          <SelectInput
                            source="salutation"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.salutation"
                            )}
                            validate={[required()]}
                            choices={[
                              { id: "", name: "" },
                              {
                                id: "mr",
                                name: this.props.translate(
                                  "resources.users.registerForm.fields.salutations.mr"
                                )
                              },
                              {
                                id: "mrs",
                                name: this.props.translate(
                                  "resources.users.registerForm.fields.salutations.mrs"
                                )
                              }
                            ]}
                            fullWidth
                          />,
                          <TextInput
                            label={this.props.translate(
                              "resources.users.registerForm.fields.degree"
                            )}
                            source="degree"
                            fullWidth
                          />,
                          <TextInput
                            source="address"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.address"
                            )}
                            validate={[required()]}
                            fullWidth
                          />,
                          <TextInput
                            source="zip"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.zip"
                            )}
                            validate={[required()]}
                            fullWidth
                          />,
                          <TextInput
                            source="city"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.city"
                            )}
                            validate={[required()]}
                            fullWidth
                          />,
                          <SelectInput
                            source="country"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.country"
                            )}
                            onChange={() => {
                              dispatch(untouch(REDUX_FORM_NAME, "state"));
                              dispatch(change(REDUX_FORM_NAME, "state", null));
                            }}
                            validate={[required()]}
                            choices={[
                              { id: "", name: "" },
                              ...getCountryOptions()
                            ]}
                            fullWidth
                          />,
                          <>
                            {formData &&
                            (formData.country === "de" ||
                              (formData && formData.country === "at")) ? (
                              <SelectInput
                                source="state"
                                label={this.props.translate(
                                  "resources.users.registerForm.fields.state"
                                )}
                                validate={[required()]}
                                choices={
                                  formData && formData.country === "de"
                                    ? germanStates
                                    : austrianStates
                                }
                                fullWidth
                              />
                            ) : null}
                          </>,
                          <TextInput
                            source="telephone"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.telephone"
                            )}
                            validate={[]}
                            fullWidth
                          />,
                          <BooleanInput
                            source="allowInformations"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.allowInformations"
                            )}
                          />
                        ]
                      );
                    }}
                  </FormDataConsumer>
                </FormTab>
                <FormTab label="resources.profile.tabs.shooterData">
                  <FormDataConsumer>
                    {({ formData, dispatch, ...rest }) => {
                      return (
                        formData &&
                        formData.userRoles &&
                        formData.userRoles.some(
                          e => e.name === "ROLE_USER"
                        ) && [
                          <SelectInput
                            label={this.props.translate(
                              "resources.users.registerForm.fields.competitionClass"
                            )}
                            validate={[required()]}
                            source="competitionClass.id"
                            choices={this.state.competitionClasses}
                            optionText="name"
                            optionValue="id"
                            fullWidth
                          />,
                          <SelectInput
                            label={this.props.translate(
                              "resources.users.registerForm.fields.competitionDivision"
                            )}
                            validate={[required()]}
                            source="division.id"
                            choices={this.state.competitionDivisions}
                            optionText="name"
                            optionValue="id"
                            fullWidth
                          />,
                          <SelectInput
                            label={this.props.translate(
                              "resources.users.registerForm.fields.competitionFactor"
                            )}
                            validate={[required()]}
                            source="factor.id"
                            choices={this.state.competitionFactors}
                            optionText="name"
                            optionValue="id"
                            fullWidth
                          />,
                          <SelectInput
                            label={this.props.translate(
                              "resources.users.registerForm.fields.club"
                            )}
                            validate={[required()]}
                            source="club.id"
                            choices={this.state.clubs}
                            optionText="name"
                            optionValue="id"
                            fullWidth
                          />,
                          <TextInput
                            source="alias"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.alias"
                            )}
                            validate={[]}
                            fullWidth
                          />,
                          <TextInput
                            source="cowboyName"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.cowboyName"
                            )}
                            validate={[]}
                            fullWidth
                          />,
                          <TextInput
                            source="sassNumber"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.sassNumber"
                            )}
                            validate={[]}
                            fullWidth
                          />,
                          <TextInput
                            source="ipscNumber"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.ipscNumber"
                            )}
                            validate={[]}
                            fullWidth
                          />,
                          <TextInput
                            source="ipscRegion"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.ipscRegion"
                            )}
                            validate={[]}
                            fullWidth
                          />,
                          <TextInput
                            source="ipscAlias"
                            label={this.props.translate(
                              "resources.users.registerForm.fields.ipscAlias"
                            )}
                            validate={[]}
                            fullWidth
                          />
                        ]
                      );
                    }}
                  </FormDataConsumer>
                </FormTab>
                <FormTab label="resources.profile.tabs.notificationSelection">
                  <WithPermissions
                    render={({ permissions }) => {
                      return [
                        isPortalAdmin(permissions) && (
                          <BooleanInput
                            source="notificationSelection.newShooterRegistrations"
                            label="resources.profile.fields.notificationSelection.newShooterRegistrations"
                            options={{}}
                          />
                        ),
                        isClubAdmin(permissions) && (
                          <BooleanInput
                            source="notificationSelection.newRegistrationsToCompetitions"
                            label="resources.profile.fields.notificationSelection.newRegistrationsToCompetitions"
                            options={{}}
                          />
                        ),
                        isClubAdmin(permissions) && (
                          <BooleanInput
                            source="notificationSelection.memberCancelNoPayment"
                            label="resources.profile.fields.notificationSelection.memberCancelNoPayment"
                            options={{}}
                          />
                        ),
                        isShooter(permissions) && (
                          <BooleanInput
                            source="notificationSelection.registrationChangeRegistrationSuccess"
                            label="resources.profile.fields.notificationSelection.registrationChangeRegistrationSuccess"
                            options={{}}
                          />
                        ),
                        isShooter(permissions) && (
                          <BooleanInput
                            source="notificationSelection.registrationChangeRegistrationCancelledByAdministrator"
                            label="resources.profile.fields.notificationSelection.registrationChangeRegistrationCancelledByAdministrator"
                            options={{}}
                          />
                        ),
                        isShooter(permissions) && (
                          <BooleanInput
                            source="notificationSelection.registrationChangeRegistrationCancelledByShooter"
                            label="resources.profile.fields.notificationSelection.registrationChangeRegistrationCancelledByShooter"
                            options={{}}
                          />
                        ),
                        isShooter(permissions) && (
                          <BooleanInput
                            source="notificationSelection.registrationChangeRegistrationCancelledNoPayment"
                            label="resources.profile.fields.notificationSelection.registrationChangeRegistrationCancelledNoPayment"
                            options={{}}
                          />
                        ),
                        isShooter(permissions) && (
                          <BooleanInput
                            source="notificationSelection.gettingStartingPlace"
                            label="resources.profile.fields.notificationSelection.gettingStartingPlace"
                            options={{}}
                          />
                        ),
                        isShooter(permissions) && (
                          <BooleanInput
                            source="notificationSelection.signupPhaseEndsReminder"
                            label="resources.profile.fields.notificationSelection.signupPhaseEndsReminder"
                            options={{}}
                          />
                        ),
                        isShooter(permissions) && (
                          <BooleanInput
                            source="notificationSelection.squadPlaceFree"
                            label="resources.profile.fields.notificationSelection.squadPlaceFree"
                            options={{}}
                          />
                        ),
                        isShooter(permissions) && (
                          <BooleanInput
                            source="notificationSelection.registrationChangePaymentReceived"
                            label="resources.profile.fields.notificationSelection.registrationChangePaymentReceived"
                            options={{}}
                          />
                        ),
                        isShooter(permissions) && (
                          <BooleanInput
                            source="notificationSelection.competitionStartReminder"
                            label="resources.profile.fields.notificationSelection.competitionStartReminder"
                            options={{}}
                          />
                        ),
                        isShooter(permissions) && (
                          <BooleanInput
                            source="notificationSelection.competitionPaymentReminder"
                            label="resources.profile.fields.notificationSelection.competitionPaymentReminder"
                            options={{}}
                          />
                        ),
                        isClubAdmin(permissions) && (
                          <BooleanInput
                            source="notificationSelection.memberCancelNoPaymentReminder"
                            label="resources.profile.fields.notificationSelection.memberCancelNoPaymentReminder"
                            options={{}}
                          />
                        ),
                        isShooter(permissions) && (
                          <BooleanInput
                            source="notificationSelection.signupPhaseOfFavourizedCompetitionStarted"
                            label="resources.profile.fields.notificationSelection.signupPhaseOfFavourizedCompetitionStarted"
                            options={{}}
                          />
                        ),
                        <SelectArrayInput
                          style={{ width: 500 }}
                          label={this.props.translate(
                            "resources.users.registerForm.fields.competitionDivisionNotification"
                          )}
                          source="division_ids"
                          validate={required()}
                          choices={this.state.competitionDivisions}
                          optionText="name"
                        />
                      ];
                    }}
                  />
                </FormTab>
                <FormTab label="resources.profile.tabs.reservations">
                  {this.state.reservations !== []
                    ? this.state.reservations.map((reservation, key) => (
                        <Grid>
                          <Table style={{ marginTop: "30px" }}>
                            <TableHead>
                              <Typography variant="title">
                                {this.props.translate(
                                  "resources.profile.reservations.reservation"
                                ) + " "}
                                #{key + 1}
                              </Typography>
                            </TableHead>
                            <TableRow>
                              <TableCell>
                                {this.props.translate(
                                  "resources.profile.reservations.competitions"
                                ) + ": "}
                              </TableCell>
                              <TableCell>
                                {reservation.competitions.map(
                                  competition => competition.name + " "
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {this.props.translate(
                                  "resources.profile.reservations.divisions"
                                ) + ": "}
                              </TableCell>
                              <TableCell>
                                {reservation.divisions.map(
                                  division => division.name + " "
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {this.props.translate(
                                  "resources.profile.reservations.factors"
                                ) + ": "}
                              </TableCell>
                              <TableCell>
                                {reservation.factors.map(
                                  factor => factor.name + " "
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {this.props.translate(
                                  "resources.profile.reservations.compClasses"
                                ) + ": "}
                              </TableCell>
                              <TableCell>
                                {reservation.competitionClasses.map(
                                  competitionClasse =>
                                    competitionClasse.name + " "
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {this.props.translate(
                                  "resources.profile.reservations.validUntil"
                                ) + ":"}
                              </TableCell>
                              <TableCell>
                                {moment(reservation.validUntil)
                                  .format("L")
                                  .toString()}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {this.props.translate(
                                  "resources.profile.reservations.qualification"
                                ) + ":"}
                              </TableCell>
                              <TableCell>
                                {reservation.qualification ? (
                                  <CheckIcon />
                                ) : null}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {this.props.translate(
                                  "resources.profile.reservations.maxReservations"
                                ) + ":"}
                              </TableCell>
                              <TableCell>
                                {reservation.maxReservations}
                              </TableCell>
                            </TableRow>
                          </Table>
                        </Grid>
                      ))
                    : null}
                </FormTab>
              </TabbedForm>
            </Edit>
          }
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    myForm: state.form.userRegisterForm,
    httpErrorMsg: state.error.httpError.message,
    identity: state.identity
  };
}

export default translate(connect(mapStateToProps)(ProfileEdit));
