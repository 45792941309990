// React imports
// Material imports
import Grid from "@material-ui/core/Grid";
// Other imports
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
// React Admin imports
import { translate } from "react-admin";
import { connect } from "react-redux";
import DynamicList from "../DynamicList";
import ErrorMessage from "../ErrorMessage";
import GenericPopup from "../GenericPopup";

const ShooterCompetitionList = props => {
  moment.locale(props.locale);

  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <GenericPopup
          open={props.cancelOrderItemPopup}
          handleConfirm={props.handleCancel}
          handleClose={props.handleCancelClose}
          message={props.translate(
            "resources.orderList.shooterCancelOrderItemPopup.warning"
          )}
          title={props.translate(
            "resources.orderList.shooterCancelOrderItemPopup.title"
          )}
          confirmText={props.translate(
            "resources.orderList.shooterCancelOrderItemPopup.confirm"
          )}
          cancelText={props.translate(
            "resources.orderList.shooterCancelOrderItemPopup.cancel"
          )}
        />
        <DynamicList
          data={props.competitions}
          page={props.page}
          rowsPerPage={props.rowsPerPage}
          total={props.total}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangePage}
          translate={props.translate}
          sortCallback={props.sortCallback}
          disable_keys={props.disable_keys}
          translationPrefix={props.translationPrefix}
          dateParseString={props.dateParseString}
          momentFormat={props.momentFormat}
          transpose={false}
          locale={props.locale}
        />
      </Grid>
      <ErrorMessage
        errorMessage={props.errorMessage}
        open={props.errorMessage ? true : false}
        onClose={props.hideError}
        translate={props.translate}
      />
    </Grid>
  );
};
ShooterCompetitionList.propTypes = {
  competitions: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  sortCallback: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  errorMessage: PropTypes.object,
  hideError: PropTypes.func.isRequired
};
function mapStateToProps(state) {
  return {
    payOrderItemPopup: state.flags.popupOpen.payOrderItemPopup,
    cancelOrderItemPopup: state.flags.popupOpen.cancelOrderItemPopup
  };
}
export default translate(connect(mapStateToProps)(ShooterCompetitionList));
