// React imports
import React from "react";

// React Admin imports
import { translate } from "react-admin";

// Redux imports
import { connect } from "react-redux";

// Material imports
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// Icon imports
import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";

// Other imports
import PropTypes from "prop-types";

const styles = {
  btn: {
    justifyContent: "center"
  },
  doColor: {
    color: "#CB5D58"
  },
  undoColor: {
    color: "#73B163"
  }
};

const VerifyButton = ({
  clickHandler,
  isVerified,
  translate,
  itemId,
  ...rest
}) => (
  <Tooltip
    title={
      isVerified
        ? translate("resources.users.verifyButton.unVerify")
        : translate("resources.users.verifyButton.verify")
    }
  >
    <IconButton
      onClick={() => clickHandler(itemId)}
      style={styles.btn}
      {...rest}
    >
      {isVerified ? (
        <CheckCircle style={styles.undoColor} />
      ) : (
        <CancelIcon style={styles.doColor} />
      )}
    </IconButton>
  </Tooltip>
);

VerifyButton.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
  isVerified: PropTypes.bool.isRequired
};
export default connect()(translate(VerifyButton));
