import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";
import ErrorMessage from "../ErrorMessage";
import BackButton from "../BackButton";
import { getAdImageSrc } from "../../utils/imageUtils";

const styles = {
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: "center"
  },
  spacer: {
    height: "20px"
  },
  error: {
    backgroundColor: "rgba(211, 46, 47, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#D32E2F",
    padding: "8px 10px"
  },
  info: {
    backgroundColor: "rgba(26, 118, 210, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#43A047",
    padding: "8px 10px"
  },
  notificationText: {
    color: "#ffffff"
  },
  simpleTitle: {
    marginBottom: "20px"
  }
};

const AdShowPublic = props => {
  const { ad, translate } = props;

  return (
    <Grid container spacing={16}>
      {ad != null
        ? [
            <Grid item xs={12}>
              <BackButton
                history={props.history}
                tooltip={translate("backButton.tooltip")}
              />
              <Card style={styles.marginBottom}>
                {ad.detailImage ? (
                  <img
                    src={getAdImageSrc(ad.detailImage)}
                    style={{ width: "100%" }}
                  />
                ) : null}
                <CardContent style={styles.clubInfoContent}>
                  <Typography variant="display2" style={styles.simpleTitle}>
                    {ad.name}
                  </Typography>
                  <Typography variant="display1">{ad.shortName}</Typography>
                  <Typography variant="body1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: ad.text
                      }}
                    />
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ]
        : null}

      <ErrorMessage
        errorMessage={props.errorMessage}
        open={props.errorMessage ? true : false}
        onClose={props.hideError}
        translate={props.translate}
      />
    </Grid>
  );
};
AdShowPublic.propTypes = {
  ad: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired
};
export default AdShowPublic;
