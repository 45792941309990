import React from "react";
import { translate } from "react-admin";
import PageTitle from "../../components/PageTitle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const impressumPage = translate => (
  <div>
    <PageTitle title={"Impressum"} />
    <Card>
      <CardContent style={{ maxWidth: "1200px" }}>
        <p>comp|sign</p>
        <p>
          <b>Firma Thomas Tuschl</b>
        </p>
        <p>
          <b>Anschrift</b> Zwerbach 35a, 3244 Ruprechtshofen
        </p>
        <p>
          <b>Telefon</b> +43 664 3968212
        </p>
        <p>
          <b>Email</b> office@compsign.net
        </p>
        <p>
          <b>Datenschutz</b> datenschutz@compsign.net
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>GISA-ZAHL</b> 31836616
        </p>
        <p>
          <b>Behörde</b> Bezirkshauptmannschaft Melk
        </p>
        <p>
          <b>Spartenname</b> Information und Consulting
        </p>
        <p>
          <b>Berufszweig</b> IT-Dienstleistungen
        </p>
        <p>
          <b>UID</b> ATU58138848
        </p>
        <p>
          <br />
        </p>
        <p>
          <b>Bankverbindung</b> Raiffeisenbank Mittleres Mostviertel
        </p>
        <p>
          <b>IBAN:</b> AT47 3293 9000 0381 1783, BIC RLNWATWW939
        </p>
      </CardContent>
    </Card>
  </div>
);

export default translate(impressumPage);
