import React, { Component } from "react";
import { FileField } from "react-admin";

class ImageFieldInput extends Component {
  render() {
    return (
      <div>
        <FileField {...this.props} target="_blank" />
      </div>
    );
  }
}

export default ImageFieldInput;
