// React imports
import React from "react";

// React Admin imports
import { translate } from "react-admin";

// Material imports
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

// Other imports
import ErrorMessage from "../ErrorMessage";
import PropTypes from "prop-types";
import DynamicList from "../DynamicList";
import { rowsPerPageOptions } from "../../utils/paging";

const styles = {
  spacer: {
    height: "20px"
  },
  cardTitleBgGreen: {
    backgroundColor: "#7E896E"
  },
  cardTitleBgPurple: {
    backgroundColor: "#7C757B"
  },
  cardTitle: {
    color: "#ffffff",
    display: "flex",
    minHeight: "40px",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  simpleTitle: {
    marginBottom: "20px"
  },
  signupBtn: {
    backgroundColor: "#7E896E",
    color: "#ffffff"
  }
};

const ReservationCodesList = props => {
  const { translate } = props;

  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Button
              variant="contained"
              style={styles.signupBtn}
              onClick={props.generateCodeLinkAction}
            >
              {props.translate("resources.reservationCodesList.generate")}
            </Button>
            <Button
              variant="contained"
              color="secoundarx"
              style={{ float: "right" }}
              onClick={props.export}
            >
              Export
            </Button>
          </CardContent>
          <CardContent>
            <DynamicList
              data={props.reservationCodes}
              page={props.page}
              rowsPerPage={props.rowsPerPage}
              total={props.total}
              handleChangePage={props.handleChangePage}
              handleChangeRowsPerPage={props.handleChangePage}
              translate={props.translate}
              sortCallback={props.sortCallback}
              translationPrefix={props.translationPrefix}
              dateParseString={props.dateParseString}
              options={{ filterMenu: true }}
              handleFilter={props.handleFilter}
              transpose={false}
              filterList={[
                { name: "competitionName", type: String },
                { name: "code", type: String }
              ]}
              momentFormat={props.momentFormat}
              locale={props.locale}
              disable_keys={props.disable_keys}
              rowsPerPageOptions={rowsPerPageOptions}
            />
          </CardContent>
        </Card>
      </Grid>
      <ErrorMessage
        errorMessage={props.errorMessage}
        open={props.errorMessage ? true : false}
        onClose={props.hideError}
        translate={props.translate}
      />
    </Grid>
  );
};
ReservationCodesList.propTypes = {
  reservationCodes: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  sortCallback: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  errorMessage: PropTypes.object,
  hideError: PropTypes.func.isRequired,
  handleFilter: PropTypes.func.isRequired
};
export default translate(ReservationCodesList);
