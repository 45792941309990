import React, { Component } from "react";
import { translate } from "react-admin";
import PageTitle from "../../components/PageTitle";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ListSubheader } from "@material-ui/core";

class changelogPage extends Component {
  versions = () => [
    {
      title: "1.0.3",
      date: "06.12.2019",
      fixes: [
        {
          text: "#446 Freie Plätze",
          description: "Die Freien Plätze werden nun richtig berechnet.",
          lines: 1
        },
        {
          text: "#454 Vereins informationen",
          description:
            "Der Vereinsname wird nun ber der Bewerbdetail Ansicht angezeigt.",
          lines: 1
        },
        {
          text: "#457 Uploadtypen",
          description: "Die Uploadtypen kann man nun löschen.",
          lines: 1
        },
        {
          text: "#449 Bewerbsbild",
          description:
            "Bewerbsbild wird nun in der Bewerbsdetail Ansicht kleiner angezeigt",
          lines: 1
        },
        {
          text: "#456 - Über comp|sign ",
          description: "Diverse Textänderungen",
          lines: 1
        },
        {
          text: "#462 - Squad Auswahl gesperrt ",
          description:
            "Squad Auswahl ist nun bei der Anmledung nicht mehr gesperrt",
          lines: 1
        },
        {
          text: "#462 - Anmeldung ",
          description: "Die Benutzer Daten werden nun übernommen",
          lines: 1
        },
        {
          text: "#466 - Shütze - Neuanmeldung ",
          description:
            "Ein Schütze kann eine abgesagte anmeldung nicht rückgängig machen",
          lines: 1
        }
      ],
      features: []
    },
    {
      title: "1.0.2",
      date: "05.12.2019",
      fixes: [
        {
          text: "#460 Berechtigungen",
          description:
            "Portal-Admin kann nun die Faktoren, Klasssen, Levels, Bewerbstypen und Divisionen verwalten ",
          lines: 1
        },
        {
          text: "447 Edge - Browser nicht unterstützt",
          description: "Edge wird nun unterstützt",
          lines: 1
        },
        {
          text: "421 Stornierung - Alert",
          description: "Textänderung",
          lines: 1
        },
        {
          text: "309 Fehlende Übersetzung für CODE_USED",
          description: "",
          lines: 1
        },
        {
          text: "444 Bundeslandauswahl",
          description:
            "Das Bundesland kann man nun im einem Dropdown auswählen",
          lines: 1
        },
        {
          text: "448 Vereins-Admin Profilseite",
          description: "Die Profilseite für den Vereins-Admin funktioniert nun",
          lines: 1
        }
      ],
      features: []
    },
    {
      title: "1.0.1",
      date: "04.12.2019",
      fixes: [
        {
          text: "#202 Duplizierte Squads",
          description:
            "Die Squads werden nun nicht mehr dupliziert aufgelistet",
          lines: 1
        },
        {
          text: "#282 Club Admin - Vereins-Admin",
          description: "Textänderung",
          lines: 1
        },
        {
          text: "#346 Einkaufswagen Icon ",
          description:
            "Die Einkaufswagen Icon wird jetzt auch im IE11 ganz angezeigt",
          lines: 1
        },
        {
          text: "#423 Anzeige der Bankdaten",
          description:
            "Anzeige der Bankdaten wenn per Überweisung bezahlt werden kann",
          lines: 1
        },
        {
          text: "#424 Uhrzeit fehlt beim Datum",
          description: "",
          lines: 1
        },
        {
          text: "#425 Anmeldung zum Bewerb mit einem Reservierungscode",
          description: "",
          lines: 1
        }
      ],
      features: [
        {
          text: "#202 Automatisierte email versenden",
          description:
            "Der Schütze wird per email benachrichtigt wenn ein Platz in einer anderen Squad Frei wird oder er ein Platz in seiner bekommen hat.",
          lines: 1
        },
        {
          text: "#302 Exports und Auswertungen",
          description: "",
          lines: 1
        }
      ]
    },
    {
      title: "0.1.11",
      date: "29.11.2019",
      fixes: [
        {
          text: "#341 Startseite Textänderungen",
          description: "Diverse Textänderungen auf der Startseite.",
          lines: 1
        },
        {
          text: "#413 Bildgrößen",
          description: "Default-Bilder für Webansicht optimiert.",
          lines: 1
        },
        {
          text: "#410 Bewerbsansicht",
          description: "Fehler wenn kein Bild gefunden wurde",
          lines: 1
        },
        {
          text: "#338 Anpassung Vereinsliste",
          description:
            "Liste ist nun alphabetisch und hat 25 Einträge voreingestellt",
          lines: 1
        },
        {
          text: "#356 Anpassung der aktuellen Bewerbe",
          description: "Diverse Layout und Textanpassungen",
          lines: 1
        },
        {
          text: "#350 Bewerbanlage",
          description: "Kontaktperson des Vereins wurde nicht übernommen.",
          lines: 1
        },
        {
          text: "#404 Automatisches Logout",
          description:
            "Benutzer wird im Fehlerfall automatisch abgemeldet und auf das Dashboard geleitet",
          lines: 1
        },
        {
          text: "#342 Farbänderung",
          description:
            "Farbänderung des Textes im Headerbild für mobile Geräte bzw kleine Ansichten",
          lines: 1
        },
        {
          text: "#358 Formatänderung",
          description: "Formatänderung bei Bewerbsdetails",
          lines: 1
        },
        {
          text: "#332 Textänderung",
          description: "Textänderung bei Benachrichtigungen",
          lines: 1
        },
        {
          text: "#405 BenutzerId wurde nicht zur Bestellung gespeichert",
          description:
            "Bei einer Bestellung eines registrierten Benutzers wurde der Benutzer nicht zur Bestellung gespeichert.",
          lines: 1
        },
        {
          text: "#333 Schützendetails",
          description: "Formatierung der Schützendetail-Informationen",
          lines: 1
        },
        {
          text: "#400 Textänderung",
          description: "Textänderung",
          lines: 1
        },
        {
          text: "#401 Formatierung",
          description: "Text-Formatierung",
          lines: 1
        },
        {
          text: "#334 Textänderungen",
          description:
            "Text für Premiumdienste wurde ausgebessert und formatiert.",
          lines: 1
        },
        {
          text: "#285 Schütze löschen",
          description: "Ein Schütze konnte nicht gelöscht werden.",
          lines: 1
        },
        {
          text: "#399 Standard-logo für Vereine",
          description:
            "Vereinsübersicht konnte nicht geladen werden wenn ein Verein kein Logo zugewiesenhatte.",
          lines: 1
        },
        {
          text: "#335 Userverwaltung",
          description: "Benutzer werden nun alphabetisch angezeigt",
          lines: 1
        },
        {
          text: "#375 Bezahlt- und Stornostatus",
          description: "Die Statusinformation wird nun richtig angezeigt",
          lines: 1
        },
        {
          text: "#352 Zeitformat bei den Squads anpassen",
          description: "Zeiformat wurde auf 'ampm' angepasst",
          lines: 1
        },
        {
          text: "#344 Symbolfoto für Bewerbe",
          description: "Das Symbolfoto für Bewerbe wurde ausgetauscht",
          lines: 1
        },
        {
          text: "#339 Vereins Dropdown",
          description:
            "Die Vereine in der Vereinsauswahl bei der Bewerberstellung sind nun alphabetisch geordnet.",
          lines: 1
        }
      ],
      features: [
        {
          text: "#345 Aktuelle Bilder",
          description: "Einpflegen der aktuellen Default-Bilder",
          lines: 1
        },
        {
          text: "#402 Teilnehmerliste erweitert",
          description:
            "Teilnehmerliste um Ifnormationen um Klasse und Division erweitert",
          lines: 1
        },
        {
          text: "#317 Volltextsuche bei Tabellen",
          description: "Alle Tabellen wurden um eine Volltextsuche erweitert.",
          lines: 1
        },
        {
          text: "#357 Squadübersicht",
          description:
            "Squad-Header erweitert um Datumsinformation und Anzahl der freien Plätze",
          lines: 1
        },
        {
          text: "#280 Zurück-Button",
          description:
            "Die Detail-Seiten wurden mit einem Zurück-Knopf versehen.",
          lines: 1
        },
        {
          text: "#389 Neue Checkobx 'Ich habe die AGB gelesen und akzeptiert'",
          description:
            "Neue Checkbox wurde im Registrierungsformular hinzugefügt",
          lines: 1
        },
        {
          text: "#395 Bestelldetail-Ansicht",
          description: "Die Bestelldetails-Ansicht wurde überarbeitet",
          lines: 1
        },
        {
          text: "#397 Listenansicht erweitert",
          description:
            "Der Umbruch der Listenansichten kann nun eingestellt werden",
          lines: 1
        },
        {
          text: "#336 Bundesland Dropdown-Feld",
          description:
            "Das Bundesland kann nun aus einem Dropdown-Feld gewählt werden",
          lines: 1
        }
      ]
    },
    {
      title: "0.1.5",
      date: "26.11.2019",
      fixes: [
        {
          text: "#345 Bildvorshau Größe",
          description: "Die Bildvorshau Größe ist nun kleiner",
          lines: 1
        },
        {
          text: "#343 Meine Vorteile als registrierter Schütze ",
          description:
            "Der Punkt 'Meine Vorteile als Schütze' im Menü heißt nun 'Meine Vorteile als registrierter Schütze'",
          lines: 1
        },
        {
          text: "#340 Text entfernt",
          description: "Dummy-Text im Footer wurde entfernt",
          lines: 1
        },
        {
          text: "#331 Die Squad",
          description: "",
          lines: 1
        },
        {
          text: "#295 Öffentlicher Link vom Bewerb",
          description:
            "Den Öffentlichen Link vom Bewerb kann man nun aus der Liste vom Bewerben raus kopieren.",
          lines: 1
        },
        {
          text: "383 Shütze kann sein Namen nicht ändern",
          description:
            "Ein Shütze darf seinen Namen bei der Anmeldung nicht ändern",
          lines: 1
        },
        {
          text: "#329 Englische Übersetzungen",
          description: "",
          lines: 1
        },
        {
          text: "#337 Vereinslogo",
          description: "Vereinslogo wird nun in der Liste angezeigt",
          lines: 1
        },
        {
          text: "#359 Automatisches Scrollen zum Content",
          description: "",
          lines: 1
        }
      ],
      features: []
    },
    {
      title: "0.1.4",
      date: "25.11.2019",
      fixes: [
        {
          text: "#360 Automatisch ausgewählte Squad",
          description:
            "Wenn man sich in eine bestimmte Squad anmeldet wird die Squad automatisch vorausgewählt",
          lines: 1
        },
        {
          text: "#368 Bestätigungs-Popup bei Bestellungen/Anmeldungen",
          description:
            "Wenn der Benutzer eine Bestellungen oder Anmeldungen auf bezahlt oder stoniert setzen will, bekommt er zuerst ein Bestätigungs-Popup",
          lines: 1
        },
        {
          text: "#379 Reservierungscodes",
          description:
            "Nachdem die Reservierungscodes erfolgreich generiert wurden, wird man nun auf die Liste von Reservierungscodes umgeleitet",
          lines: 1
        },
        {
          text: "#326 Registrierung gesperrt",
          description:
            "Die Registrierung ist nun für einen schon angemeldeten Benutzer nicht möglich",
          lines: 1
        },
        {
          text: "#247 Warteliste",
          description:
            "Die angemeldeten Shützen die auf der Warteliste sind werden in der Squad-Liste entsprechend markiert",
          lines: 1
        },
        {
          text: "#247 Bezahldatum",
          description:
            "Bezahldatum wird bei der jeweiligen Anmeldung mit geschpeichert",
          lines: 1
        },
        {
          text: "#272 Vereinsprofil",
          description: "Die Vereinsprofil Seite lädt nun beim erste mal",
          lines: 1
        },
        {
          text: "#358 Changelog",
          description: "",
          lines: 1
        }
      ],
      features: []
    }
  ];

  render() {
    const { translate } = this.props;
    return (
      <div>
        <PageTitle title={"About und Changelog"} />
        {this.versions().map((version, key) => (
          <Card style={{ marginBottom: "20px" }}>
            <CardHeader
              title={version.title}
              subtitle={version.date}
              actAsExpander={true}
              showExpandableButton={true}
            />
            <List>
              {version.features.length > 0 && (
                <ListSubheader>{translate("changelog.features")}</ListSubheader>
              )}
              {version.features.map((item, iKey) => (
                <ListItem key={iKey}>
                  <ListItemText
                    primary={item.text}
                    secondary={item.description}
                  />
                </ListItem>
              ))}
              {version.fixes.length > 0 && (
                <ListSubheader>{translate("changelog.fixes")}</ListSubheader>
              )}
              {version.fixes.map((item, iKey) => (
                <ListItem key={iKey}>
                  <ListItemText
                    primary={item.text}
                    secondary={item.description}
                  />
                </ListItem>
              ))}
            </List>
          </Card>
        ))}
      </div>
    );
  }
}
export default translate(changelogPage);
