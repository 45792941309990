import React, { Component } from "react";
import { Loading } from "react-admin";

import { connect } from "react-redux";
import { translate } from "react-admin";

import { redirectWirecardOrderItem } from "../../utils/restUtils";

class OrderItemPayPage extends Component {
  componentDidMount = () => {
    if (
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.id
    )
      redirectWirecardOrderItem(this.props.match.params.id).then(data => {
        window.location.href = data["payment-redirect-url"];
      });
  };
  render() {
    return (
      <Loading
        loadingSecondary={
          "Verbindung zum Zahlungsprovider wird hergetellt. Sie werden in kürze weitergeleitet"
        }
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    successMessage: state.success.successMessage.message
  };
};

export default translate(connect(mapStateToProps)(OrderItemPayPage));
