import React, { Component } from "react";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ClubList from "../../components/pages/ClubList";
import { translate } from "react-admin";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import { getClubsPub } from "../../utils/restUtils";
import { setError } from "../../reducer/errorActions";
import { connect } from "react-redux";
import ImagePreview from "../../components/inputs/ImagePreview";
import moment from "moment";

class ClubListPage extends Component {
  state = {
    clubs: [],
    currentPage: 0,
    rowsPerPage: 1000,
    totalItemsCount: 0
  };

  getData = (page, rowsPerPage, archive) => {
    getClubsPub(page, rowsPerPage, "name", archive ? "desc" : "asc", {}).then(
      data => {
        let clubs = data.data;
        for (let i = 0; i < clubs.length; i++) {
          if (clubs[i].logo)
            clubs[i].imagePre = <ImagePreview imageId={clubs[i].logo.id} />;

          clubs[i].actions = this.OpenDetailsButton(clubs[i].publicLinkId);
          clubs[i].publicLinkAction = () =>
            this.props.history.push("/vereine/" + clubs[i].publicLinkId);
        }
        this.setState({
          clubs: clubs,
          totalItemsCount: data.total
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  handlePageChange = (page, rowsPerPage) => {
    this.getData(page, rowsPerPage, this.state.archive);
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage });
  };

  OpenDetailsButton = competitionId => {
    let path = "/vereine/" + competitionId;
    return (
      <IconButton component={Link} to={path}>
        <ZoomInIcon />
      </IconButton>
    );
  };

  handleSort = (property, order) => {
    getClubsPub(
      this.state.currentPage,
      this.state.rowsPerPage,
      property,
      order,
      {}
    ).then(
      data => {
        let clubs = data.data;
        for (let i = 0; i < clubs.length; i++) {
          clubs[i].actions = this.OpenDetailsButton(clubs[i].publicLinkId);
        }
        this.setState({ clubs: clubs, totalItemsCount: data.total });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  componentDidMount = () => {
    this.getData(
      this.state.currentPage,
      this.state.rowsPerPage,
      this.state.archive
    );
  };

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  render = () => (
    <ClubList
      clubs={this.state.clubs}
      page={this.state.currentPage}
      rowsPerPage={this.state.rowsPerPage}
      total={this.state.totalItemsCount}
      handleChangePage={this.handlePageChange}
      handleChangeRowsPerPage={this.handlePageChange}
      sortCallback={this.handleSort}
      errorMessage={this.props.httpErrorMsg}
      hideError={this.hideErrorMessage}
      translate={this.props.translate}
      translationPrefix={"clubList"}
      disable_keys={["id", "logo"]}
      dateParseString={moment.HTML5_FMT.DATETIME_LOCAL_MS}
      momentFormat={"L"}
      locale={"de-at"}
    />
  );
}
const mapStateToProps = state => {
  return {
    httpErrorMsg: state.error.httpError.message
  };
};
export default connect(mapStateToProps)(translate(ClubListPage));
