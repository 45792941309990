import React from "react";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";

const HeaderImage = props => {
  const styles = {
    headerImage: {
      height: "100%",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      overflow: "hidden"
    },
    headerImageContent: {
      height: "100%",
      paddingLeft: "40px",
      display: "flex",
      alignItems: "center"
    },
    headerImageLarge: {
      backgroundPosition: "center"
    },
    headerImageContentLarge: {},
    headerImgHeadline: {
      color: "red"
    }
  };

  const imageUrl = props.width != "xs" ? props.desktopImage : props.mobileImage;

  return (
    <div
      className="headerImage"
      style={{
        ...styles.headerImage,
        backgroundImage: `url(${imageUrl})`,
        ...(props.width != "xs" ? styles.headerImageLarge : null)
      }}
    >
      <div
        className="headerImageContent"
        style={{
          ...styles.headerImageContent,
          ...(props.width != "xs" ? styles.headerImageContentLarge : null)
        }}
      >
        {props.content}
      </div>
    </div>
  );
};

HeaderImage.propTypes = {
  content: PropTypes.object.isRequired,
  desktopImage: PropTypes.string.isRequired,
  mobileImage: PropTypes.string.isRequired
};

export default withWidth()(HeaderImage);
