// React imports
import React, { Component } from "react";

// Other imports
// Material imports
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { REDUX_FORM_NAME } from "react-admin";
import { FILE_API_PATH } from "../../utils/config";

class ImagePreview extends Component {
  state = {
    imageFile: null
  };

  componentDidUpdate = prevProps => {};

  componentDidMount = () => {};

  render() {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            {!this.props.loading &&
            this.props.record &&
            this.props.record[this.props.source] &&
            this.props.record[this.props.source]["fileName"] ? (
              <img
                src={
                  FILE_API_PATH +
                  "/" +
                  this.props.record[this.props.source]["fileName"]
                }
                alt=""
                style={{ maxWidth: "256px", maxHeight: "256px" }}
              />
            ) : null}
            {!this.props.loading &&
            this.props.record &&
            !this.props.record[this.props.source] &&
            this.props.record[this.props.previewSource] &&
            this.props.record[this.props.previewSource]["path"] ? (
              <img
                src={
                  FILE_API_PATH +
                  "/" +
                  this.props.record[this.props.previewSource]["path"]
                }
                alt=""
                style={{ maxWidth: "256px", maxHeight: "256px" }}
              />
            ) : null}
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    record:
      state && state.form && state.form[REDUX_FORM_NAME]
        ? state.form[REDUX_FORM_NAME].values
        : null
  };
};

export default connect(mapStateToProps)(ImagePreview);
