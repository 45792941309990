import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, Field, reduxForm, blur, touch } from "redux-form";
import { Typography } from "@material-ui/core";
import { renderTextField } from "../inputs/renderMaterialFields";
import { required } from "../inputs/validator";
import ErrorMessage from "../ErrorMessage";

const styles = {
  alignCenter: {
    justifyContent: "center"
  },
  spacer: {
    display: "block",
    height: "20px"
  },
  notification: {
    backgroundColor: "rgba(67, 160, 71, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#43A047",
    padding: "8px 10px"
  },
  error: {
    backgroundColor: "rgba(211, 46, 47, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#D32E2F",
    padding: "8px 10px"
  },
  info: {
    backgroundColor: "rgba(26, 118, 210, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#43A047",
    padding: "8px 10px"
  },
  notificationText: {
    color: "#ffffff"
  },
  loginBtn: {
    display: "flex",
    justifyContent: "flex-end"
  }
};

let ResetPassword = props => {
  const { translate } = props;
  return (
    <Grid container spacing={16} style={styles.alignCenter}>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="title">
              {translate("resources.users.resetPasswordHeadline")}
            </Typography>
          </CardContent>
          <CardContent>
            {!props.success && (
              <Form onSubmit={props.handleSubmit}>
                {
                  <Grid item sm={6} xs={12}>
                    <div>
                      <Field
                        name="email"
                        id="email"
                        component={renderTextField}
                        validate={required}
                        type="email"
                        label={props.translate(
                          "resources.users.resetPasswordForm.email"
                        )}
                      />
                    </div>
                    <div style={styles.spacer}></div>
                  </Grid>
                }
                {
                  <div>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#4F6982",
                        color: "#ffffff"
                      }}
                      onClick={() => {
                        Object.keys(props.myForm.registeredFields).forEach(
                          key => {
                            props.dispatch(touch("resetPasswordForm", key));
                            props.dispatch(blur("resetPasswordForm", key));
                          }
                        );
                        if (props.valid) {
                          props.handleSubmit();
                        }
                      }}
                    >
                      {translate("resources.users.resetPasswordForm.reset")}
                    </Button>
                  </div>
                }
              </Form>
            )}
            {props.success && (
              <div style={styles.info}>
                <Typography variant="body1" style={styles.notificationText}>
                  {translate("resources.users.resetPasswordFormFinished")}
                </Typography>
              </div>
            )}
          </CardContent>
          <CardContent>
            <ErrorMessage
              errorMessage={props.errorMessage}
              open={props.errorMessage ? true : false}
              onClose={props.hideError}
              translate={props.translate}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

ResetPassword = reduxForm({
  form: "resetPasswordForm"
})(ResetPassword);

ResetPassword.propTypes = {
  translate: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  hideError: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
  return {
    myForm: state.form.resetPasswordForm
  };
}
export default connect(mapStateToProps)(ResetPassword);
