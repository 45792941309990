import { Grid } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputLabel from "@material-ui/core/FormLabel";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { DateInput, DateTimeInput, TimeInput } from "react-admin-date-inputs";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import deLocale from "date-fns/locale/de";

export const renderCheckbox = ({
  input,
  label,
  meta: { touched, invalid, error },
  ...custom
}) => {
  return (
    <FormControl>
      <FormControlLabel
        style={{ height: "15px", marginTop: "5px" }}
        control={
          <Checkbox
            checked={input.value ? true : false}
            onChange={input.onChange}
            value="checkedF"
          />
        }
        label={label}
      />
      <FormHelperText error={true}>
        {touched && invalid ? error : null}
      </FormHelperText>
    </FormControl>
  );
};

export const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...custom
}) => {
  return (
    <TextField
      label={label}
      id={input.id}
      placeholder={label}
      error={touched && error}
      helperText={touched && invalid ? error : null}
      fullWidth={true}
      style={{ marginBottom: "8px" }}
      {...input}
      {...custom}
    />
  );
};

export const renderFromHelper = ({ input, touched, error }) => {
  if (!(touched && error)) {
    return;
  } else {
    return <FormHelperText>{error}</FormHelperText>;
  }
};

export const renderSelectField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => {
  return (
    <FormControl error={touched && error} style={{ width: "100%" }}>
      <InputLabel
        htmlFor={input.name}
        style={{ color: "grey", fontSize: "12px" }}
      >
        {label}
      </InputLabel>
      <Select
        native
        autoWidth={true}
        style={{ marginBottom: "8px" }}
        {...input}
        {...custom}
        inputProps={{
          name: input.name,
          id: input.name
        }}
        value={input.value && input.value !== "" ? input.value : false}
      >
        {children}
      </Select>
      {renderFromHelper({ input, touched, error })}
    </FormControl>
  );
};

export const renderDateField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => {
  return [
    <Grid style={{ marginTop: "15px" }}>
      <InputLabel>{label}</InputLabel>
      <TextField
        {...input}
        floatingLabelFixed
        touched={touched}
        error={error}
        type="datetime-local"
        fullWidth
      />
    </Grid>
  ];
};

export const renderReactDateField = ({
  input,
  label,
  meta: { touched, error },
  children,
  ...custom
}) => (
  <Grid style={{ marginTop: "15px" }}>
    <InputLabel>{label}</InputLabel>
    <DateTimeInput
      fullWidth
      source={input.name}
      name={input.name}
      onChange={param => {
        input.onChange(param);
      }}
      providerOptions={{ utils: MomentUtils, locale: deLocale }}
      options={{
        fullWidth: true,
        format: "DD.MM.YYYY, HH:mm:ss",
        ampm: false,
        clearable: true
      }}
    />
  </Grid>
);
