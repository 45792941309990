import React from "react";
import {
  required,
  translate,
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  Create,
  SimpleForm,
  Edit,
  DisabledInput,
  Toolbar,
  SaveButton,
  Pagination
} from "react-admin";
import compose from "recompose/compose";
import { Grid, Button } from "@material-ui/core/";
import PageTitle from "../../components/PageTitle";
import {
  rowsPerPageOptions,
  defaultRowsPerPageOption
} from "../../utils/paging";
import PopupBulkDeleteButton from "../../components/PopupBulkDeleteButton";
import CustomToolbar from "../../components/CustomToolbar";

const FactorFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

// const CustomToolbar = props => (
//   <Toolbar style={{ justifyContent: "space-between" }} {...props}>
//     <SaveButton />
//     <Button
//       variant="contained"
//       color="primary"
//       onClick={() => props.history.goBack()}
//     >
//       Abbrechen
//     </Button>
//   </Toolbar>
// );

export const FactorList = compose(translate)(({ translate, ...props }) => (
  <Grid>
    <PageTitle title={translate("resources.factors.listPage.headline")} />
    <List
      filters={<FactorFilter />}
      exporter={false}
      pagination={
        <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
      }
      perPage={defaultRowsPerPageOption}
      bulkActionButtons={<PopupBulkDeleteButton />}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
      </Datagrid>
    </List>
  </Grid>
));

export const FactorCreate = compose(translate)(
  ({ translate, history, ...props }) => (
    <Grid>
      <PageTitle title={translate("resources.factors.createPage.headline")} />
      <Create {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar {...props} />}>
          <TextInput source="name" validate={required()} />
        </SimpleForm>
      </Create>
    </Grid>
  )
);

export const FactorEdit = compose(translate)(
  ({ translate, history, ...props }) => (
    <Grid>
      <PageTitle title={translate("resources.factors.editPage.headline")} />
      <Edit {...props}>
        <SimpleForm
          redirect="list"
          toolbar={<CustomToolbar disabledDelete {...props} />}
        >
          <DisabledInput label="Id" source="id" />
          <TextInput source="name" validate={required()} />
        </SimpleForm>
      </Edit>
    </Grid>
  )
);
