import { Typography, Button, Chip } from "@material-ui/core";
import React from "react";
import {
  translate,
  TextInput,
  SimpleForm,
  Edit,
  DisabledInput,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  ReferenceInput,
  AutocompleteInput
} from "react-admin";
import compose from "recompose/compose";
import PageTitle from "../../components/PageTitle";
import Grid from "@material-ui/core/Grid";
import { reduxForm, Form, Field, LoginForm } from "redux-form";
import { connect } from "react-redux";
import CustomAutocompleteInput from "../../components/CustomAutocompleteInput";
import Warning from "@material-ui/icons/Warning";

const required = value => {
  return value && value !== "" && !Array.isArray(value)
    ? undefined
    : "Required";
};

const renderCustomAutocompleteInput = ({
  input,
  label,
  type,
  options,
  source,
  translate,
  validate,
  defaultValue,
  isMulti,
  isDisabled,
  meta: { touched, error, warning, invalid }
}) => (
  <>
    <CustomAutocompleteInput
      isDisabled={isDisabled}
      validate={validate}
      {...input}
      isClearable={false}
      isMulti={isMulti}
      invalid={invalid}
      placeholder={label}
      source={source}
      type={type}
      options={options}
      translate={translate}
      defaultValue={defaultValue}
      label={label}
      touched={touched}
      error={error}
      warning={warning}
    />
    {touched &&
      ((error && (
        <Typography>
          <span style={{ color: "red", fontSize: "12px" }}>
            {translate(error)}
          </span>
        </Typography>
      )) ||
        (warning && <p>{warning}</p>))}
  </>
);

let PublicOrderItemEdit = props => [
  <Grid style={{ padding: "10px" }}>
    <PageTitle
      title={props.translate("resources.orderEdit.publicOrderItemEdit")}
    />
    <Grid style={{ maxWidth: "300px" }}>
      <Form>
        <Field
          component={DisabledInput}
          name="id"
          label={"resources.orderEdit.orderItemNumber"}
          fullWidth
        />
        <Field
          component={props.disabled ? DisabledInput : TextInput}
          name="firstName"
          label={"resources.orderEdit.firstName"}
          fullWidth
        />
        <Field
          component={props.disabled ? DisabledInput : TextInput}
          name="lastName"
          label={"resources.orderEdit.lastName"}
          fullWidth
        />
        <Field
          component={DisabledInput}
          name="email"
          label={"resources.orderEdit.email"}
          fullWidth
        />
        <Field
          component={DisabledInput}
          name="competition.name"
          label={"resources.orderEdit.competition"}
          fullWidth
        />
        {!props.record.competition.event
          ? [
              <Field
                name="division.id"
                label={props.translate("resources.orderEdit.division")}
                options={props.record.choices.divisions}
                defaultValue={props.record.division.id}
                translate={props.translate}
                formName={props.form}
                validate={[required]}
                component={renderCustomAutocompleteInput}
                isDisabled={
                  props.record.cancelled ||
                  props.cancelled ||
                  props.record.archive ||
                  props.record.registrationEnded
                }
              />,
              <Field
                name="competitionClass.id"
                label={props.translate("resources.orderEdit.competitionClass")}
                options={props.record.choices.competitionClasses}
                defaultValue={props.record.competitionClass.id}
                translate={props.translate}
                formName={props.form}
                validate={[required]}
                component={renderCustomAutocompleteInput}
                isDisabled={
                  props.record.cancelled ||
                  props.cancelled ||
                  props.record.archive ||
                  props.record.registrationEnded
                }
              />,
              <Field
                name="factor.id"
                label={props.translate("resources.orderEdit.factor")}
                options={props.record.choices.factors}
                defaultValue={props.record.factor.id}
                translate={props.translate}
                formName={props.form}
                validate={[required]}
                component={renderCustomAutocompleteInput}
                isDisabled={
                  props.record.cancelled ||
                  props.cancelled ||
                  props.record.archive ||
                  props.record.registrationEnded
                }
              />,
              <Field
                name="squad.id"
                label={props.translate("resources.orderEdit.squad")}
                options={props.record.choices.squads}
                defaultValue={props.record.squad.id}
                translate={props.translate}
                formName={props.form}
                validate={[required]}
                component={renderCustomAutocompleteInput}
                isDisabled={
                  props.record.cancelled ||
                  props.cancelled ||
                  props.record.archive ||
                  props.record.registrationEnded
                }
              />
            ]
          : null}

        <Chip
          style={{ margin: "15px" }}
          label={props.record.paid ? "Bezahlt" : "Nicht Bezahlt"}
        />

        {props.record.cancelled || props.cancelled ? (
          <Chip style={{ margin: "15px" }} label={"Abgemeldet"} />
        ) : null}
      </Form>
    </Grid>

    {props.record.registrationEnded &&
    !props.record.archive &&
    !props.cancelled ? (
      <div style={{ width: "90%" }}>
        <Typography
          variant="display1"
          style={{
            backgroundColor: "#ffcc00",
            padding: "10px",
            width: "100%",
            marginTop: "10px"
          }}
        >
          <Warning style={{ marginRight: "10px" }} />
          {props.translate(
            "resources.competitionshow.competitionRegistrationEnded"
          )}
        </Typography>
        <Typography
          variant="body1"
          style={{
            backgroundColor: "#ffcc00",
            padding: "10px",
            marginBottom: "10px",
            width: "100%"
          }}
        >
          {props.translate(
            "resources.competitionshow.competitionRegistrationEndedInfo"
          )}
        </Typography>
      </div>
    ) : null}
  </Grid>
];

PublicOrderItemEdit = reduxForm({
  form: "orderItemEdit"
})(PublicOrderItemEdit);

export default connect(state => ({
  initialValues: state.orderItem.data.orderItem
}))(PublicOrderItemEdit);
