import React, { Component, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  FormControlLabel,
  FormLabel,
  Typography,
  CircularProgress
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import FilterListIcon from "@material-ui/icons/FilterList";
import MenuList from "@material-ui/core/MenuList";
import TextField from "@material-ui/core/TextField";
import Switch from "@material-ui/core/Switch";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import { RefreshIconButton } from "react-admin";
import moment from "moment";

require("moment/locale/de.js");
require("moment/locale/de-at.js");
const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 3
  }
});

const FilterField = props => {
  const intialChecked =
    props.filterValues &&
    (props.filterValues[props.value] != null) &
      (props.filterValues[props.value] != "")
      ? true
      : false;

  const initialSwitchField =
    props.filterValues &&
    (props.filterValues[props.value] !== null) &
      (props.filterValues[props.value] !== "")
      ? props.filterValues[props.value] === "true"
        ? true
        : false
      : false;

  const [checked, setChecked] = useState(intialChecked);
  const [switchField, setSwitchField] = useState(initialSwitchField);
  switch (props.type) {
    case String:
      return (
        <TextField
          defaultValue={
            props.filterValues ? props.filterValues[props.value] : null
          }
          style={{ width: "190px" }}
          label={props.translate(props.translationPrefix + "." + props.value)}
          id={props.value}
          onChange={props.handleFilter}
        />
      );
    case Boolean:
      return (
        <div style={{ borderBottom: "2px solid grey" }}>
          <Checkbox
            style={{ marginLeft: "-15px" }}
            checked={checked}
            onChange={e => {
              setChecked(e.target.checked);
              if (!e.target.checked) {
                e.target.value = null;
                e.target.id = props.value;
                props.handleFilter(e);
              }
            }}
          />
          <FormLabel>
            {props.translate(props.translationPrefix + "." + props.value)}
          </FormLabel>
          <Switch
            disabled={!checked}
            id={props.value}
            label={props.translate(props.translationPrefix + "." + props.value)}
            onChange={e => {
              setSwitchField(e.target.checked);
              e.target.value = e.target.checked;
              props.handleFilter(e);
            }}
            checked={switchField}
          />
        </div>
      );
    case Date:
      return (
        <TextField
          defaultValue={
            props.filterValues ? props.filterValues[props.value] : null
          }
          style={{ width: "190px" }}
          id={props.value}
          label={props.translate(props.translationPrefix + "." + props.value)}
          type="date"
          InputLabelProps={{
            shrink: true
          }}
          onChange={props.handleFilter}
        />
      );
    default:
      return null;
  }
};

class DynamicList extends Component {
  state = {
    open: false,
    checkedList: [],
    sortingList: [],
    order: "desc",
    orderBy: null,
    filterMenu: false,
    keys: null
  };

  componentDidMount() {
    this.setCheckedList();
    this.setSortingList();
  }

  componentDidUpdate = prevProps => {
    if (!prevProps || prevProps.data !== this.props.data) {
      this.setSortingList();
      this.setCheckedList();
    }
  };

  setCheckedList = () => {
    let checkedList = new Array();
    if (this.props.data && this.props.data[0]) {
      let keys = Object.keys(this.props.data[0]);
      let disable_keys = this.props.disable_keys;
      for (let i = 0; i < keys.length; i++) {
        if (
          disable_keys != null &&
          disable_keys.length > 0 &&
          disable_keys.includes(keys[i])
        ) {
          checkedList.push(false);
        } else checkedList.push(true);
      }
    }
    this.setState({ checkedList: checkedList });
  };

  setSortingList = () => {
    let sortingList = [];
    if (this.props.data && this.props.data[0]) {
      let keys = Object.keys(this.props.data[0]);
      let disable_sorting = this.props.disable_sorting;
      for (let i = 0; i < keys.length; i++) {
        if (
          disable_sorting != null &&
          disable_sorting.length > 0 &&
          disable_sorting.includes(keys[i])
        ) {
          sortingList.push(false);
        } else sortingList.push(true);
      }
    }
    this.setState({ sortingList: sortingList });
  };

  handleChange = name => event => {
    let checkedList = this.state.checkedList;
    checkedList[name] = !checkedList[name];
    this.setState({ checkedList: checkedList });
  };

  handleClick = event => {
    this.setState({ open: !this.state.open });
  };

  handleClickAway = () => {
    this.setState({ open: false });
  };

  createSortHandler = property => event => {
    this.handleRequestSort(event, property);
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    if (this.props.sortCallback) {
      this.props.sortCallback(property, order);
      this.setState({ order, orderBy });
    }
  };

  render() {
    const {
      total,
      data,
      page,
      rowsPerPage,
      classes,
      filterList,
      transpose,
      breakpoint
    } = this.props;
    const { order, orderBy, open, filterMenu } = this.state;

    if (data && data.length > 0 && this.state.keys == null) {
      this.setState({ keys: Object.keys(data[0]) });
    }

    const rowStyle =
      this.props.rowStyle ||
      (record => {
        return { backgroundColor: "white" };
      });

    if ((!data || data.length <= 0) && this.state.keys == null) {
      return null;
    }

    let keys = null;
    if (data && data.length > 0) keys = Object.keys(data[0]);
    else keys = this.state.keys;

    if (
      transpose ||
      (transpose === undefined && !isWidthUp(breakpoint, this.props.width))
    ) {
      return (
        <Table>
          {data.map((datarow, key) => (
            <Card className={classes.root}>
              {keys.map((value, key) =>
                this.state.checkedList[key] ? (
                  <TableRow>
                    <TableCell>
                      {this.state.sortingList === [] ||
                      this.state.sortingList[key] ? (
                        <Tooltip title="Sort" enterDelay={300}>
                          <TableSortLabel
                            active={orderBy === value}
                            direction={order}
                            onClick={this.createSortHandler(value)}
                          >
                            {this.props.translate
                              ? this.props.translate(
                                  this.props.translationPrefix + ".m." + value
                                )
                              : value}
                          </TableSortLabel>
                        </Tooltip>
                      ) : (
                        <TableSortLabel hideSortIcon>
                          {this.props.translate
                            ? this.props.translate(
                                this.props.translationPrefix + ".m." + value
                              )
                            : value}
                        </TableSortLabel>
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(
                        datarow[value],
                        this.props.dateParseString,
                        true
                      ).isValid()
                        ? moment(datarow[value])
                            .locale(this.props.locale)
                            .format(this.props.momentFormat)
                        : datarow[value]}
                    </TableCell>
                  </TableRow>
                ) : null
              )}
            </Card>
          ))}
          <TablePagination
            component="div"
            count={total}
            page={page}
            rowsPerPageOptions={this.props.rowsPerpageOptions}
            rowsPerPage={rowsPerPage}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={(event, page) =>
              this.props.handleChangePage(page, rowsPerPage)
            }
            onChangeRowsPerPage={event =>
              this.props.handleChangeRowsPerPage(page, event.target.value)
            }
          />
        </Table>
      );
    }

    return (
      <Grid container spacing={24}>
        {this.props.options.filterMenu ? (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon
                onClick={() => this.setState({ filterMenu: !filterMenu })}
              />
            </IconButton>
          </Tooltip>
        ) : null}

        {filterMenu ? (
          <MenuList>
            {filterList.map((value, key) => {
              return filterList ? (
                <MenuItem>
                  <FilterField
                    filterValues={this.props.filterValues}
                    translate={this.props.translate}
                    translationPrefix={this.props.translationPrefix}
                    handleFilter={this.props.handleFilter}
                    value={value.name}
                    type={value.type}
                  />
                </MenuItem>
              ) : null;
            })}
          </MenuList>
        ) : null}
        <Grid>
          {this.props.options.cellMenu ? (
            <ClickAwayListener onClickAway={this.handleClickAway}>
              <IconButton
                aria-label="More"
                aria-owns={open ? "long-menu" : null}
                aria-haspopup="true"
                onClick={this.handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu id="long-menu" open={open}>
                {keys.map((value, key) => (
                  <FormControlLabel
                    control={
                      <MenuItem key={key}>
                        <Checkbox
                          checked={this.state.checkedList[key]}
                          label={value}
                          onChange={this.handleChange(key)}
                        />
                      </MenuItem>
                    }
                    label={value}
                  />
                ))}
              </Menu>
            </ClickAwayListener>
          ) : null}
        </Grid>
        {this.props.handleRefresh ? (
          <Grid>
            {this.props.refreshing ? (
              <RefreshIconButton
                icon={<CircularProgress size={24} />}
                onClick={() => this.props.handleRefresh()}
              />
            ) : (
              <RefreshIconButton onClick={() => this.props.handleRefresh()} />
            )}
          </Grid>
        ) : null}
        <Table>
          <TableHead>
            {keys.map((value, key) =>
              this.state.checkedList[key] ? (
                <TableCell
                  padding="none"
                  key={key}
                  sortDirection={orderBy === value}
                >
                  {this.state.sortingList === [] ||
                  this.state.sortingList[key] ? (
                    <Tooltip title="Sort" enterDelay={300}>
                      <TableSortLabel
                        active={orderBy === value}
                        direction={order}
                        onClick={this.createSortHandler(value)}
                      >
                        {this.props.translate
                          ? this.props.translate(
                              this.props.translationPrefix + "." + value
                            )
                          : value}
                      </TableSortLabel>
                    </Tooltip>
                  ) : (
                    <TableSortLabel hideSortIcon>
                      {this.props.translate
                        ? this.props.translate(
                            this.props.translationPrefix + "." + value
                          )
                        : value}
                    </TableSortLabel>
                  )}
                </TableCell>
              ) : null
            )}
          </TableHead>
          <TableBody>
            {data.map((record, key) => (
              <TableRow key={"tr" + key} style={rowStyle(record)}>
                {keys.map((value, key) =>
                  this.state.checkedList[key] ? (
                    moment(
                      record[value],
                      this.props.dateParseString,
                      true
                    ).isValid() ? (
                      <TableCell padding="none">
                        <Typography>
                          {this.props.columnDateFormat &&
                          this.props.columnDateFormat[value]
                            ? moment(record[value])
                                .locale(this.props.locale)
                                .format(this.props.columnDateFormat[value])
                            : moment(record[value])
                                .locale(this.props.locale)
                                .format(this.props.momentFormat)}
                        </Typography>
                      </TableCell>
                    ) : (
                      <TableCell padding="none">
                        <Typography>{record[value]}</Typography>
                      </TableCell>
                    )
                  ) : null
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {this.props.hidePagination ? null : (
          <TablePagination
            component="div"
            count={total}
            page={page}
            rowsPerPageOptions={this.props.rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            backIconButtonProps={{
              "aria-label": "Previous Page"
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page"
            }}
            onChangePage={(event, page) =>
              this.props.handleChangePage(page, rowsPerPage)
            }
            onChangeRowsPerPage={event =>
              this.props.handleChangeRowsPerPage(page, event.target.value)
            }
          />
        )}
      </Grid>
    );
  }
}

DynamicList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleFilter: PropTypes.func,
  breakpoint: PropTypes.string,
  transpose: PropTypes.bool,
  momentFormat: PropTypes.string,
  disable_keys: PropTypes.arrayOf(PropTypes.string),
  filterList: PropTypes.arrayOf(PropTypes.string),
  locale: PropTypes.string,
  dateParseString: PropTypes.string,
  total: PropTypes.number,
  rowStyle: PropTypes.func
};

DynamicList.defaultProps = {
  options: {
    cellMenu: false,
    filterMenu: false
  },
  breakpoint: "sm"
};

export default withStyles(styles)(withWidth()(DynamicList));
