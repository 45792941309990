import React, { Component } from "react";
import { translate } from "react-admin";
import { connect } from "react-redux";
import { FormControlLabel, Switch } from "@material-ui/core";
import { updateProfileWithShooterCompetitionView } from "../utils/restUtils";
import { change, touch } from "redux-form";
import { isGuest } from "../utils/utils";
import PropTypes from "prop-types";
import { moment } from "moment";

class ShooterCompetitionViewSwitch extends Component {
  state = {
    checked: this.props.listType,
    isGuest: isGuest(this.props.identity.userData.permissions),
    loading: false
  };

  componentDidMount = () => {};

  handleChange = event => {
    if (!this.state.loading) {
      this.setState({ loading: true });

      if (!this.state.isGuest) {
        this.updateViewTypeInIdentityAndLocalStorageAndState(
          event.target.checked
        );
        updateProfileWithShooterCompetitionView(event.target.checked)
          .then(
            data => {
              this.updateViewTypeInIdentityAndLocalStorageAndState(data);
            },
            e => {}
          )
          .then(() => this.setState({ loading: false }));
      } else {
        this.updateViewTypeInIdentityAndLocalStorageAndState(
          event.target.checked
        );
        this.setState({ loading: false });
      }
    }
  };

  updateViewTypeInIdentityAndLocalStorageAndState = viewType => {
    this.setState({ checked: viewType });
    localStorage.setItem("shooterCompetitionViewType", viewType);
    var identity = this.props.identity;
    identity.userData.shooterCompetitionViewType = viewType;
    this.props.dispatch(change("identity", identity));
    this.props.dispatch(touch("form"));
  };

  render = () => (
    <FormControlLabel
      control={
        <Switch
          checked={this.state.checked}
          onChange={this.handleChange}
          value="checked"
          disabled={
            isGuest(this.props.identity.userData.permissions) ||
            (!isGuest(this.props.identity.userData.permissions) &&
              (this.props.identity.userData.paymentValid === false ||
                this.props.identity.userData.paymentValid === "false"))
          }
        />
      }
      label={
        isGuest(this.props.identity.userData.permissions)
          ? this.props.translate(
              "resources.competitions.listPage.listViewGuest"
            )
          : this.props.translate("resources.competitions.listPage.listView")
      }
    />
  );
}

ShooterCompetitionViewSwitch.propTypes = {
  listType: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    identity: state.identity
  };
};

export default connect(mapStateToProps)(
  translate(ShooterCompetitionViewSwitch)
);
