import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-admin";
import { Typography } from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";

const styles = {
  errorMsg: {
    color: "#CB5D58",
    marginTop: "10px"
  },
  msg: {
    marginTop: "10px"
  }
};

let VerifyUserPopup = props => {
  const {
    handleClick,
    handleClose,
    open,
    errorMessage,
    resource,
    isVerified,
    translate,
    fullScreen
  } = props;
  const checkAndSubmit = () => {
    handleClick();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {isVerified
          ? translate("resources.users.verifyDialog.headerUnverify")
          : translate("resources.users.verifyDialog.header")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="p" component="p" style={styles.msg}>
            {isVerified
              ? translate("resources.users.verifyDialog.reallyUnverify")
              : translate("resources.users.verifyDialog.really")}
          </Typography>

          {errorMessage && (
            <Typography variant="p" component="p" style={styles.errorMsg}>
              {translate(errorMessage)}
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} style={{ color: "#4F6982" }}>
          {translate("resources.users.verifyDialog.cancel")}
        </Button>
        <Button
          style={{ color: "#4F6982" }}
          onClick={checkAndSubmit}
          color="primary"
        >
          {isVerified
            ? translate("resources.users.verifyDialog.doUnverify")
            : translate("resources.users.verifyDialog.doVerify")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

VerifyUserPopup.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  isVerified: PropTypes.bool.isRequired,
  resource: PropTypes.string.isRequired
};

export default withMobileDialog()(translate(connect()(VerifyUserPopup)));
