export const SET_ORDER_BY = "SET_ORDER_BY";
export const SET_ORDER = "SET_ORDER";
export const SET_PER_PAGE = "SET_PER_PAGE";
export const SET_PAGE = "SET_PAGE";
export const SET_FILTERS = "SET_FILTERS";

export const setOrderBy = orderBy => ({
  type: SET_ORDER_BY,
  orderBy: orderBy
});

export const setOrder = order => ({
  type: SET_ORDER,
  order: order
});

export const setPerPage = perPage => ({
  type: SET_PER_PAGE,
  perPage: perPage
});

export const setPage = page => ({
  type: SET_PAGE,
  page: page
});

export const setFilters = filters => ({
  type: SET_FILTERS,
  filters: filters
});
