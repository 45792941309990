import {
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT
} from "react-admin";
import qs from "qs";
import { paymintInTime } from "../utils/utils";

export const invalidateData = () => {
  localStorage.setItem("token", null);
  localStorage.removeItem("clientId");
  localStorage.removeItem("clientName");
  localStorage.removeItem("username");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("userFirstName");
  localStorage.removeItem("userLastName");
  localStorage.removeItem("userCountry");
  localStorage.removeItem("userState");
  localStorage.removeItem("userRoles");
  localStorage.removeItem("permissions");
  localStorage.removeItem("locale");
  localStorage.removeItem("userFullName");
  localStorage.removeItem("competitionViewType");
  localStorage.removeItem("competitionFilters");
  localStorage.removeItem("userId");
  localStorage.removeItem("clubId");
  localStorage.removeItem("paymentUntil");
  localStorage.removeItem("cartId");
  localStorage.removeItem("cartAmount");
  localStorage.setItem("permissions", JSON.stringify(["IS_GUEST"]));
};

export function fetchIdentity() {
  const token = localStorage.getItem("token");

  if (!token) {
    return Promise.resolve();
  }

  const identityRequest = new Request("/api/identity", {
    method: "GET",
    headers: new Headers({
      "Content-Type": "application/json",
      authorization: token
    })
  });

  return fetch(identityRequest)
    .then(response => {
      if (response.status < 200 || response.status >= 300) {
        localStorage.removeItem("token");
        throw new Error(response.statusText);
      }

      return response.json();
    })
    .then(json => {
      localStorage.setItem("userId", json.identity.id);
      localStorage.setItem("clubId", json.identity.clubId);
      localStorage.setItem(
        "competitionClassId",
        json.identity.competitionClassId
      );
      localStorage.setItem("factorId", json.identity.factorId);
      localStorage.setItem("divisionId", json.identity.divisionId);
      localStorage.setItem("clientId", json.identity.clientId);
      localStorage.setItem("clientName", json.identity.clientName);
      localStorage.setItem("username", json.identity.username);
      localStorage.setItem("userEmail", json.identity.userEmail);
      localStorage.setItem("userFirstName", json.identity.userFirstName);
      localStorage.setItem("userLastName", json.identity.userLastName);
      localStorage.setItem("userFullName", json.identity.userFullName);
      localStorage.setItem("userCountry", json.identity.userCountry);
      localStorage.setItem("userState", json.identity.userState);
      localStorage.setItem(
        "competitionViewType",
        json.identity.competitionViewType
      );
      localStorage.setItem(
        "competitionFilters",
        json.identity.competitionFilters
      );
      if (json.identity.paymentUntil) {
        localStorage.setItem("paymentUntil", json.identity.paymentUntil);
      }
      localStorage.setItem(
        "userRoles",
        JSON.stringify(json.identity.userRoles)
      );
      if (json.identity.permissions) {
        json.identity.permissions.push("IS_GUEST");
      }
      localStorage.setItem("locale", json.identity.locale);
      localStorage.setItem(
        "permissions",
        JSON.stringify(json.identity.permissions)
      );
      let paymentValid = false;

      if (
        json.identity.userRoles.indexOf("ROLE_ADMIN") > -1 ||
        json.identity.userRoles.indexOf("ROLE_PORTAL_ADMIN") > -1 ||
        json.identity.userRoles.indexOf("ROLE_CLUB_ADMIN") > -1
      ) {
        paymentValid = true;
      } else {
        paymentValid = json.identity.paymentUntil
          ? paymintInTime(json.identity.paymentUntil)
          : false;
      }
      localStorage.setItem("paymentValid", paymentValid);
    });
}

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const request = new Request("/api/login", {
      method: "POST",
      //formData: {"username": username, "password": password},
      //body: JSON.stringify({ username, password }),
      body: qs.stringify({
        username: username,
        password: password
      }),
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded"
      })
    });
    return fetch(request).then(response => {
      if (response.status === 402) throw new Error("Inactive_user");
      if (response.status === 417) throw new Error("Not_verified");
      if (response.status < 200 || response.status >= 300) {
        throw new Error("Unauthorized");
      }
      // get user data
      let token = response.headers.get("authorization");

      localStorage.setItem("token", token);

      fetchIdentity().then(() => new Promise(() => window.location.reload()));
    });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    invalidateData();
    return Promise.resolve({ redirectTo: "/dashboard" });
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401) {
      invalidateData();
      window.location.href = "/";
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem("token")
      ? Promise.resolve()
      : Promise.reject({ redirectTo: "/dashboard" });
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const permissions = JSON.parse(localStorage.getItem("permissions"));

    return Promise.resolve(permissions == null ? ["IS_GUEST"] : permissions);
  }

  return Promise.reject("Unknown method");
};
