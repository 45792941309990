// React imports
import React from "react";

// React Admin imports
import { translate } from "react-admin";

// Redux imports
import { connect } from "react-redux";

// Material imports
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// Icon imports
import CancelIcon from "@material-ui/icons/Cancel";
import UncancelIcon from "@material-ui/icons/CheckCircle";

// Other imports
import PropTypes from "prop-types";

const styles = {
  blockBtn: {
    justifyContent: "center"
  },
  blockColor: {
    color: "#E8AC61"
  },
  unBlockColor: {
    color: "#73B163"
  }
};

const CancelButton = ({
  cancelHandler,
  isCancelled,
  translate,
  itemId,
  setLabel,
  unsetLabel,
  ...rest
}) => (
  <Tooltip title={isCancelled ? translate(unsetLabel) : translate(setLabel)}>
    <IconButton
      onClick={() => cancelHandler(itemId)}
      style={styles.blockBtn}
      {...rest}
    >
      {isCancelled ? (
        <CancelIcon style={styles.blockColor} />
      ) : (
        <UncancelIcon style={styles.unBlockColor} />
      )}
    </IconButton>
  </Tooltip>
);

CancelButton.propTypes = {
  cancelHandler: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
  isCancelled: PropTypes.bool.isRequired,
  setLabel: PropTypes.string.isRequired,
  unsetLabel: PropTypes.string.isRequired
};
export default connect()(translate(CancelButton));
