// React imports
import React, { Component } from "react";

// React Admin imports
import { FormDataConsumer } from "react-admin";

// Other imports
import PdfPreview from "./PdfPreview";
import ImagePreview from "./ImagePreview";
import {
  getId,
  getNewFileId,
  getFileType,
  getNewFileType
} from "./FileUploadHelper";
import { Icon as MdiIcon } from "@mdi/react";
import { mdiFileDownloadOutline } from "@mdi/js";
import restClient from "../../providers/dataProvider/rest";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { FILE_API_PATH } from "../../utils/config";

const fetchFileFromBucket = filePath => {
  window.open(FILE_API_PATH + "/" + filePath);
};

class FileUploadsPreview extends Component {
  render = () => {
    return (
      <FormDataConsumer>
        {({ formData, ...rest }) => {
          const source = this.props.source;
          return source !== null && source.id > 0 ? (
            <div style={{ marginBottom: "50px" }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => fetchFileFromBucket(source.path)}
              >
                <MdiIcon path={mdiFileDownloadOutline} size={1} />
                Download
              </Button>
            </div>
          ) : null;
        }}
      </FormDataConsumer>
    );
  };
}

export default FileUploadsPreview;
