// React imports
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
// Material imports
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import React from "react";
// React Redux imports
import { connect } from "react-redux";
import { isAdmin } from "../../utils/utils";
// Other imports
import DynamicList from "../DynamicList";
import ErrorMessage from "../ErrorMessage";
import SuccessMessage from "../SuccessMessage";
import GenericPopup from "../GenericPopup";

const OrderList = props => {
  let disableKeys = [
    "orderItems",
    "deleted",
    "cancelled",
    "paid",
    "createDateTime",
    "updateDateTime",
    "status"
  ];
  let disableSorting = ["openDetailsButton"];
  if (!isAdmin(props.permissions)) {
    disableKeys.push("id");
  }
  return (
    <Grid>
      <GenericPopup
        open={props.cancelOrderPopup}
        handleConfirm={props.handleCancel}
        handleClose={props.handleCancelClose}
        message={props.translate(
          "resources.orderList.cancelOrderPopup.warning"
        )}
        title={props.translate("resources.orderList.cancelOrderPopup.title")}
        confirmText={props.translate(
          "resources.orderList.cancelOrderPopup.confirm"
        )}
        cancelText={props.translate(
          "resources.orderList.cancelOrderPopup.cancel"
        )}
      />
      <GenericPopup
        open={props.payOrderPopup}
        handleConfirm={props.handlePay}
        handleClose={props.handlePayClose}
        message={props.translate("resources.orderList.payOrderPopup.warning")}
        title={props.translate("resources.orderList.payOrderPopup.title")}
        confirmText={props.translate(
          "resources.orderList.payOrderPopup.confirm"
        )}
        cancelText={props.translate("resources.orderList.payOrderPopup.cancel")}
      />

      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <DynamicList
                disable_sorting={disableSorting}
                filterValues={props.filterValues}
                refreshing={props.refreshing}
                handleRefresh={props.handleRefresh}
                data={props.orders}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                total={props.total}
                handleChangePage={props.handleChangePage}
                handleChangeRowsPerPage={props.handleChangePage}
                translate={props.translate}
                sortCallback={props.sortCallback}
                disable_keys={disableKeys}
                translationPrefix={props.translationPrefix}
                dateParseString={props.dateParseString}
                momentFormat={props.momentFormat}
                locale={props.locale}
                options={{ filterMenu: true }}
                handleFilter={props.handleFilter}
                transpose={false}
                filterList={[
                  { name: "lastName", type: String },
                  { name: "email", type: String },
                  { name: "firstName", type: String }
                ]}
              />
            </CardContent>
          </Card>
        </Grid>
        <SuccessMessage
          successMessage={props.successMessage}
          open={props.successMessage ? true : false}
          onClose={props.hideSuccess}
          translate={props.translate}
        />
        <ErrorMessage
          errorMessage={props.errorMessage}
          open={props.errorMessage ? true : false}
          onClose={props.hideError}
          translate={props.translate}
        />
      </Grid>
    </Grid>
  );
};
OrderList.propTypes = {
  orders: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  sortCallback: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  errorMessage: PropTypes.object,
  hideError: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired
};
function mapStateToProps(state) {
  return {
    payOrderPopup: state.flags.popupOpen.payOrderPopup,
    cancelOrderPopup: state.flags.popupOpen.cancelOrderPopup
  };
}

export default connect(mapStateToProps)(OrderList);
