import {
  CLOSE_MENU,
  SET_POPUP,
  TOGGLE_MENU,
  TOGGLE_POPUP,
  UPDATE_DATA
} from "./flagActions";

const INITIAL_STATE = {
  popupOpen: {
    popupCompetitionRegistrationForm: false,
    popupWaitingListAlert: false,
    payOrderPopup: false,
    cancelOrderPopup: false,
    popupLogin: false,
    popupBlockUser: false,
    popupBlockUnpaidUser: false,
    popupBlockClub: false,
    popupDelete: false,
    popupCancelCompetition: false,
    popupCompetitionInvoice: false,
    popupCompetitionPay: false,
    popupVerifyUser: false,
    payOrderItemPopup: false,
    changeOrderItemPopup: false,
    cancelOrderItemPopup: false,
    popupChangeDates: false
  },
  menuOpen: {
    sidebar: false
  },
  data: {}
};

const reducer = (state = INITIAL_STATE, payload) => {
  switch (payload.type) {
    case TOGGLE_POPUP:
      var pTmp = state.popupOpen;
      pTmp[payload.id] = !pTmp[payload.id];
      var pdtmp = state.data;
      pdtmp[payload.id] = payload.data;
      return {
        ...state,
        popupOpen: pTmp,
        data: pdtmp
      };
    case SET_POPUP:
      var popups = state.popupOpen;
      popups[payload.id] = payload.data;
      return {
        ...state,
        popupOpen: popups
      };
    case TOGGLE_MENU:
      var mTmp = state.menuOpen;
      mTmp[payload.id] = !mTmp[payload.id];
      return {
        ...state,
        menuOpen: mTmp
      };
    case CLOSE_MENU:
      var cmTmp = state.menuOpen;
      cmTmp[payload.id] = false;
      return {
        ...state,
        menuOpen: cmTmp
      };
    case "@@router/LOCATION_CHANGE":
      var mcTmp = state.menuOpen;
      if (
        typeof payload.payload.state === "undefined" ||
        payload.payload.state.nextPathname !== null
      ) {
        mcTmp["sidebar"] = false;
      }
      return {
        ...state,
        menuOpen: mcTmp
      };
    case UPDATE_DATA:
      return {
        ...state,
        data: { ...payload.data }
      };
    default:
      return state;
  }
};

export default reducer;
