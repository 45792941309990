const PWREG = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,128}$/);
const PWREGEDIT = new RegExp(/(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,128}$)|(^$)/);

export const password = (
  message = "PW_MIN_6_MAX_128_1_UPPER_1_LOWER_1_NUMERIC"
) => (value, allValues, props) =>
  PWREG.test(value) ? undefined : props.translate(message);

export const passwordEdit = (
  message = "PW_MIN_6_MAX_128_1_UPPER_1_LOWER_1_NUMERIC"
) => (value, allValues, props) =>
  PWREGEDIT.test(value) || value === "" || typeof value == "undefined"
    ? undefined
    : props.translate(message);

export const passwordEquals = (message = "PW_MUST_MATCH") => (
  value,
  allValues,
  props
) => (value === allValues.password ? undefined : props.translate(message));

export const required = (message = "FIELD_IS_REQUIRED") => (
  value,
  allValues,
  props
) =>
  (value || value === 0) &&
  ((value !== "" && typeof value !== "object") || typeof value === "object")
    ? undefined
    : props.translate(message);

export const waitingListRequired = (message = "FIELD_IS_REQUIRED") => (
  value,
  allValues,
  props
) =>
  value !== null &&
  value !== undefined &&
  ((value !== "" && typeof value !== "object") || typeof value === "object")
    ? undefined
    : props.translate(message);

export const requiredIfBankNameIsFilled = (
  message = "FIELD_IS_REQUIRED_IF_BANK_NAME_IS_FILLED"
) => (value, allValues, props) =>
  allValues.bankInformation &&
  allValues.bankInformation.name &&
  allValues.bankInformation.name !== "" &&
  (!value || value === "")
    ? props.translate(message)
    : undefined;

export const requiredIfIbanIsFilled = (
  message = "FIELD_IS_REQUIRED_IF_BANK_IBAN_IS_FILLED"
) => (value, allValues, props) =>
  allValues.bankInformation &&
  allValues.bankInformation.iban &&
  allValues.bankInformation.iban !== "" &&
  (!value || value == "")
    ? props.translate(message)
    : undefined;

export const globalPaymentMethodValidator = (values, props) => {
  const errors = {};
  if (
    (!values.payOnSite === true &&
      !values.paymentViaDeposit === true &&
      !values.payWithWirecard === true) ||
    (!values.payOnSite && !values.paymentViaDeposit && !values.payWithWirecard)
  ) {
    errors.payOnSite = [props.translate("PAYMENTMETHOD_REQUIRED")];
  }

  return errors;
};

export const link = (message = "MUST_START_WITH_HTTP_OR_HTTPS") => (
  value,
  allValues,
  props
) =>
  value && !value.startsWith("http://") && !value.startsWith("https://")
    ? props.translate(message)
    : undefined;
