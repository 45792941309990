import React, { Component } from "react";
import {
  getCompetitionClasses,
  getCompetitionDevisions,
  getCompetitionFactors,
  getAllClubs,
  createPublicUser
} from "../../utils/restUtils";
import { setError } from "../../reducer/errorActions";
import UserRegisterForm from "../../components/pages/UserRegisterForm";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
class UserRegisterFormPage extends Component {
  state = {
    clubs: [],
    competitionClasses: [],
    competitionDivisions: [],
    competitionFactors: []
  };

  componentDidMount = () => {
    if (localStorage.getItem("userId") > 0) {
      this.props.history.push("/dashboard");
    }
    getCompetitionClasses().then(data => {
      this.setState({ competitionClasses: data ? data : [] }, () => {});
    });
    getCompetitionDevisions().then(data => {
      this.setState({ competitionDivisions: data ? data : [] }, () => {});
    });
    getCompetitionFactors().then(data => {
      this.setState({ competitionFactors: data ? data : [] }, () => {});
    });
    getAllClubs().then(data => {
      this.setState({ clubs: data ? data : [] }, () => {});
    });
  };

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  handleSubmit = () => {
    if (localStorage.getItem("userId") > 0) {
      this.props.history.push("/dashboard");
    } else {
      let data = { ...this.props.myForm.values };
      if (data.competitionClass) {
        data.competitionClass = { id: data.competitionClass };
      }
      if (data.division) {
        data.division = { id: data.division };
      }

      if (data.factor) {
        data.factor = { id: data.factor };
      }

      if (data.club) {
        data.club = { id: data.club };
      }
      createPublicUser(data).then(
        data => {
          this.props.dispatch(setError(null));
          this.props.history.push("/userRegisterSuccess");
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
    }
  };

  render() {
    return (
      <UserRegisterForm
        handleSubmit={this.handleSubmit}
        competitionClasses={this.state.competitionClasses}
        competitionFactors={this.state.competitionFactors}
        competitionDivisions={this.state.competitionDivisions}
        errorMessage={this.props.httpErrorMsg}
        hideError={this.hideErrorMessage}
        clubs={this.state.clubs}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    myForm: state.form.userRegisterForm,
    httpErrorMsg: state.error.httpError.message
  };
}

export default withRouter(connect(mapStateToProps)(UserRegisterFormPage));
