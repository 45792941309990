// React imports
import moment from "moment";
import React, { Component, Fragment } from "react";

// Material imports
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DownloadIcon from "@material-ui/icons/GetApp";

// Icon imports
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import CancelIcon from "@material-ui/icons/Cancel";

// React Admin imports
import { Responsive, Title, translate } from "react-admin";
import { connect } from "react-redux";
import ButtonMenu from "../../components/ButtonMenu";
import {
  EventAdTeaserCard,
  EventTeaserCard,
  MediaCard,
  SimpleCard,
  SimpleCardWithoutHeader
} from "../../components/Cards";
import CompetitionInformationIcons from "../../components/CompetitionInformationIcons";
import SimpleList from "../../components/SimpleList";
import { setError } from "../../reducer/errorActions";
import restClient from "../../providers/dataProvider/rest";

// Other imports
import {
  cancelOrderItem,
  checkAuth,
  clickAd,
  exportCompetitorList as exportCompetitorListCall,
  getAdvertisingPage,
  getCompetitionsPub,
  getUserBookedCompetitions
} from "../../utils/restUtils";
import download from "downloadjs";
import PageTitle from "../../components/PageTitle";

import { setPopup, togglePopup } from "../../reducer/flagActions";
import GenericPopup from "../../components/GenericPopup";
import {
  getAdsImageSrc,
  getCompetitionListImageSrc,
  TEASER_IMAGE_1,
  TEASER_IMAGE_2,
  TEASER_IMAGE_3
} from "../../utils/imageUtils";
import { FILE_API_PATH } from "../../utils/config";
import { setSuccess } from "../../reducer/successActions";
import SuccessMessage from "../../components/SuccessMessage";

moment.locale("de-at");

const styles = {
  spacer: {
    display: "block",
    height: "20px"
  },
  marginBottom: {
    marginBottom: "20px"
  },
  card: {
    marginBottom: "20px"
  },
  cardTitleBgGreen: {
    backgroundColor: "#7E896E",
    padding: "16px"
  },
  cardTitleBgPurple: {
    backgroundColor: "#7C757B",
    padding: "16px"
  },
  cardTitleBgBlue: {
    backgroundColor: "#4F6982",
    padding: "16px"
  },
  cardTitle: {
    color: "#ffffff",
    display: "flex",
    minHeight: "40px",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  tableRow: {
    height: "auto",
    verticalAlign: "top"
  },
  tableCell: {
    borderBottomWidth: 0,
    padding: "4px 12px 4px 0"
  },
  tableCellDate: {
    borderBottomWidth: 0,
    padding: "4px 12px 4px 0",
    fontWeight: "700"
  },
  list: {
    padding: 0
  },
  listItem: {
    padding: "3px 0 8px 0"
  },
  button: {
    backgroundColor: "#7e886e"
  },
  media: {
    height: 140
  },
  teaserButton: {
    color: "#7e886e"
  }
};

class Dashboard extends Component {
  state = {
    competitions: [],
    advertising: [],
    adPosition: Math.floor(Math.random() * 2) + 1
  };

  GuestContent = props => {
    return [
      <Typography>
        <p>{props.translate("dashboard.guestContent.introduction")}</p>
        <p>{props.translate("dashboard.guestContent.introduction2")}</p>
        <p>
          {props.translate("dashboard.guestContent.introduction3")} <br />
          {props.translate("dashboard.guestContent.introduction4")}
        </p>
      </Typography>,
      <Grid>
        <Button
          onClick={() =>
            window.location.assign(
              "https://compsign.at/meine-vorteile-als-registrierter-schuetze/"
            )
          }
          style={styles.teaserButton}
        >
          {props.translate("dashboard.guestContent.registrationAdvantages")}
        </Button>
        <Button
          onClick={() =>
            window.location.assign("https://compsign.at/ueber-compsign/")
          }
          style={styles.teaserButton}
        >
          {props.translate("dashboard.guestContent.compSignFunctions")}
        </Button>
      </Grid>
    ];
  };

  getPublicCompetitions = () => {
    var jsonString = JSON.stringify({
      year: [],
      month: [],
      country: [],
      zip: [],
      club: [],
      type: [],
      level: [],
      archive: false
    });
    var filterString = encodeURI(jsonString);

    getCompetitionsPub(0, 3, "competitionDate", "asc", filterString).then(
      data => {
        for (let i = 0; i < data.data.length; i++) {
          let item = data.data[i];
          if (item.publicLink) {
            item.publicLinkAction = () => {
              this.props.dispatch(
                setPopup("popupCompetitionRegistrationForm", false)
              );
              this.props.history.push("/competitionDetails/" + item.publicLink);
            };
          }
          item.infoIcons = <CompetitionInformationIcons record={item} />;
        }

        this.setState({
          competitions: data.data
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  exportCompetitorList = item => {
    exportCompetitorListCall(item.competitionId)
      .then(response => {
        return response.blob();
      })
      .then(
        data => {
          let filename = "teilnehmerliste.xlsx";

          download(
            data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
  };

  getUserBookedCompetitions = () => {
    getUserBookedCompetitions(0, 6, "id", "asc", {
      archive: false
    }).then(
      data => {
        let items = [];
        let mainText;
        for (let i = 0; i < data.data.length; i++) {
          let item = data.data[i];
          if (item.publicLink) {
            item.publicLinkAction = () => {
              this.props.dispatch(
                setPopup("popupCompetitionRegistrationForm", false)
              );
              this.props.history.push("/competitionDetails/" + item.publicLink);
            };
          }
          let actions = [];
          actions.push({
            callBack: () => {
              this.props.dispatch(
                setPopup("popupCompetitionRegistrationForm", false)
              );
              this.props.history.push("/competitionDetails/" + item.publicLink);
            },
            text: "Details",
            icon: <SearchIcon />
          });
          if (item.paid) {
            actions.push({
              callBack: () => this.exportCompetitorList(item),
              text: "Teilnehmerliste",
              icon: <DownloadIcon />
            });
          }
          if (item.competitionUploads.length > 0) {
            item.competitionUploads.map((competitionUpload, key) =>
              actions.push({
                callBack: () =>
                  this.fetchFileFromBucket(competitionUpload.document.path),
                text: competitionUpload.type.showName
                  ? competitionUpload.document.fileName
                  : competitionUpload.type.name,
                icon: <DownloadIcon />
              })
            );
          }

          if (!item.cancelled) {
            actions.push({
              callBack: () => {
                this.props.history.push("shooterOrderItems/" + item.id);
              },
              text: "Bearbeiten",
              icon: <EditIcon />
            });
            actions.push({
              callBack: () => {
                this.props.dispatch(togglePopup("cancelOrderItemPopup"));
                this.setState({
                  clickedButton: { id: item.id, active: item.cancelled }
                });
              },
              text: "Abmelden",
              icon: <CancelIcon />
            });
          }

          if (item.cancelled == true) {
            mainText = item.competitionName + " (Abgemeldet)";
          } else {
            mainText = item.competitionName;
          }
          items.push({
            id: i,
            mainText: mainText,
            secondaryText: <CompetitionInformationIcons record={item} />,
            link: "",
            menu: <ButtonMenu buttonIcon={<MoreVertIcon />} options={actions} />
          });
        }

        this.setState({
          userBookedCompetitions: items
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  getAdvertising = () => {
    getAdvertisingPage(0, 100, "id", "desc", {}).then(
      data => {
        this.setState({
          advertising: data.data
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  getUserParticipatedCompetitions = () => {
    getUserBookedCompetitions(0, 6, "id", "desc", {
      archive: true
    }).then(
      data => {
        let items = [];
        for (let i = 0; i < data.data.length; i++) {
          let item = data.data[i];
          if (item.publicLink) {
            item.publicLinkAction = () => {
              this.props.dispatch(
                setPopup("popupCompetitionRegistrationForm", false)
              );
              this.props.history.push("/competitionDetails/" + item.publicLink);
            };
          }
          let actions = [];
          actions.push({
            callBack: () => {
              this.props.dispatch(
                setPopup("popupCompetitionRegistrationForm", false)
              );
              this.props.history.push("/competitionDetails/" + item.publicLink);
            },
            text: "Details",
            icon: <SearchIcon />
          });
          if (item.paid) {
            actions.push({
              callBack: () => this.exportCompetitorList(item),
              text: "Teilnehmerliste",
              icon: <DownloadIcon />
            });
          }
          if (item.competitionUploads.length > 0) {
            item.competitionUploads.map((competitionUpload, key) =>
              actions.push({
                callBack: () =>
                  this.fetchFileFromBucket(competitionUpload.document.path),
                text: competitionUpload.type.name,
                icon: <DownloadIcon />
              })
            );
          }
          items.push({
            id: i,
            mainText: item.competitionName,
            secondaryText: <CompetitionInformationIcons record={item} />,
            link: "",
            menu: <ButtonMenu buttonIcon={<MoreVertIcon />} options={actions} />
          });
        }

        this.setState({
          userParticipatedCompetitions: items
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  handleCancel = () => {
    cancelOrderItem(this.state.clickedButton.id).then(
      data => {
        this.getUserBookedCompetitions();
        this.handleCancelClose();
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  handleCancelClose = () =>
    this.props.dispatch(togglePopup("cancelOrderItemPopup"));

  checkAuth = () => {
    checkAuth().then(success => {}, e => this.props.dispatch(setError(e)));
  };

  componentDidMount() {
    this.getAdvertising();
    this.getPublicCompetitions();
    /* TODO: check if user is logged in and is a competitive user!*/
    if (this.isShooter()) {
      this.getUserBookedCompetitions();
      this.getUserParticipatedCompetitions();
    }
    if (this.isClubAdmin() || this.isPortalAdmin() || this.isSystemAdmin())
      this.checkAuth();
  }

  componentDidUpdate = prevProps => {
    if (prevProps.identity !== this.props.identity) {
      if (this.isShooter()) {
        this.getUserBookedCompetitions();
        this.getUserParticipatedCompetitions();
      }
    }
  };

  getFreeSlotsText = record => {
    let totalSlots = 0;
    let bookedSlots = 0;
    for (let i = 0; i < record.squads.length; i++) {
      totalSlots += record.squads[i].squadSize;
      bookedSlots +=
        record.squads[i].squadSize >= record.squads[i].subscriptionSize
          ? record.squads[i].subscriptionSize
          : record.squads[i].squadSize;
    }
    return (
      totalSlots -
      bookedSlots +
      (" " +
        this.props.translate(
          "resources.competitionshow.detailsTable.freePlaces"
        ))
    );
  };

  isAuthenticated = () =>
    typeof this.props.identity !== "undefined" &&
    this.props.identity.userData.id;

  isSystemAdmin = () => {
    return (
      this.isAuthenticated() &&
      this.props.identity.userData.permissions.includes("IS_ADMIN")
    );
  };

  isPortalAdmin = () => {
    return (
      this.isAuthenticated() &&
      this.props.identity.userData.permissions.includes("IS_PORTAL_ADMIN")
    );
  };

  isClubAdmin = () => {
    return (
      this.isAuthenticated() &&
      this.props.identity.userData.permissions.includes("IS_CLUB_ADMIN")
    );
  };

  isShooter = () => {
    return (
      this.isAuthenticated() &&
      this.props.identity.userData.permissions.includes("IS_USER_SHOOTER")
    );
  };

  getFullName = () => {
    if (!this.isAuthenticated()) {
      return "";
    }
    return this.props.identity.userData.userFullName;
  };

  fetchFile = id => {
    restClient("DOWNLOAD", "download", {
      id: id
    }).then(response => {
      try {
        const filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        response.blob().then(blob => {
          if (typeof callback !== "undefined") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      } catch (e) {
        console.log(e);
      }
    });
  };

  fetchFileFromBucket = filePath => {
    window.open(FILE_API_PATH + "/" + filePath);
  };

  handleAdClick = (id, detailView, href) => {
    clickAd(id);
    detailView
      ? this.props.history.push("/werbung/" + id)
      : window.open(href, "_blank");
  };

  render() {
    const { classes, translate, ...rest } = this.props;

    return (
      <Fragment>
        <Title title="Compsign Admin" />
        <Responsive
          medium={
            <div style={styles.root}>
              <Grid container spacing={16}>
                <Grid item xs={12} sm={12} md={8}>
                  <Grid container spacing={16}>
                    <Grid item xs={12}>
                      <PageTitle title={translate("dashboard.headline")} />
                      {!this.isAuthenticated() && (
                        <SimpleCard
                          header={translate("dashboard.welcome.headlineGuest")}
                          content={this.GuestContent(this.props)}
                        />
                      )}
                      {this.isShooter() && (
                        <SimpleCard
                          header={translate("dashboard.welcome.headlineUser", {
                            fullName: this.getFullName()
                          })}
                          content={
                            <>
                              {translate("dashboard.welcome.textShooter")}
                              <br />
                              <br />
                              {translate("dashboard.welcome.greetingsShooter")}
                            </>
                          }
                        />
                      )}
                      {this.isPortalAdmin() && (
                        <SimpleCard
                          header={translate("dashboard.welcome.headlineUser", {
                            fullName: this.getFullName()
                          })}
                        />
                      )}
                      {this.isSystemAdmin() && (
                        <SimpleCard
                          header={translate("dashboard.welcome.headlineUser", {
                            fullName: this.getFullName()
                          })}
                        />
                      )}
                      {this.isClubAdmin() && (
                        <SimpleCard
                          header={translate("dashboard.welcome.headlineUser", {
                            fullName: this.getFullName()
                          })}
                        />
                      )}
                      {this.isShooter() && [
                        <Grid container spacing={16}>
                          <Grid item xs={12} sm={12} md={6}>
                            <GenericPopup
                              open={this.props.cancelOrderItemPopup}
                              handleConfirm={this.handleCancel}
                              handleClose={this.handleCancelClose}
                              message={this.props.translate(
                                "resources.orderList.shooterCancelOrderItemPopup.warning"
                              )}
                              title={this.props.translate(
                                "resources.orderList.shooterCancelOrderItemPopup.title"
                              )}
                              confirmText={this.props.translate(
                                "resources.orderList.shooterCancelOrderItemPopup.confirm"
                              )}
                              cancelText={this.props.translate(
                                "resources.orderList.shooterCancelOrderItemPopup.cancel"
                              )}
                            />
                            <PageTitle
                              title={translate(
                                "dashboard.myUpcomingEvents.title"
                              )}
                            />
                            <SimpleCardWithoutHeader
                              content={
                                <SimpleList
                                  data={this.state.userBookedCompetitions}
                                  momentFormat={"L"}
                                  locale={"de-at"}
                                />
                              }
                              buttonLabel={translate(
                                "dashboard.myUpcomingEvents.moreButton"
                              )}
                              btnClickHandler={() =>
                                this.props.history.push("/myCompetitions")
                              }
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6}>
                            <PageTitle
                              title={translate(
                                "dashboard.myParticipatedEvents.title"
                              )}
                            />
                            <SimpleCardWithoutHeader
                              content={
                                <SimpleList
                                  data={this.state.userParticipatedCompetitions}
                                  momentFormat={"L"}
                                  locale={"de-at"}
                                />
                              }
                              buttonLabel={translate(
                                "dashboard.myParticipatedEvents.moreButton"
                              )}
                              btnClickHandler={() =>
                                this.props.history.push("/myCompetitions")
                              }
                            />
                          </Grid>
                        </Grid>
                      ]}
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <MediaCard
                        imageUrl={TEASER_IMAGE_3}
                        imageTitle={translate(
                          "dashboard.teaser.results.imgTitle"
                        )}
                        header={translate("dashboard.teaser.results.headline")}
                        buttonLabel={translate("dashboard.teaser.btnMore")}
                        btnClickHandler={() =>
                          this.props.history.push("/results")
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <MediaCard
                        imageUrl={TEASER_IMAGE_1}
                        imageTitle={translate(
                          "dashboard.teaser.competitions.imgTitle"
                        )}
                        header={translate(
                          "dashboard.teaser.competitions.headline"
                        )}
                        buttonLabel={translate("dashboard.teaser.btnMore")}
                        btnClickHandler={() =>
                          this.props.history.push("/competitionList")
                        }
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={4}>
                      <MediaCard
                        imageUrl={TEASER_IMAGE_2}
                        imageTitle={translate(
                          "dashboard.teaser.clubs.imgTitle"
                        )}
                        header={translate("dashboard.teaser.clubs.headline")}
                        buttonLabel={translate("dashboard.teaser.btnMore")}
                        btnClickHandler={() =>
                          this.props.history.push("/clubList")
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <PageTitle
                    title={translate("dashboard.competitions.headline")}
                  />
                  {this.state.competitions.map((record, oKey) => (
                    <div>
                      {oKey == this.state.adPosition &&
                      this.state.advertising[0] ? (
                        <EventAdTeaserCard
                          handleAdClick={this.handleAdClick}
                          translate={this.props.translate}
                          key={"advert-" + oKey}
                          image={
                            this.state.advertising.length > 0
                              ? getAdsImageSrc(this.state.advertising[0])
                              : ""
                          }
                          header={
                            this.state.advertising.length > 0
                              ? this.state.advertising[0].name
                              : ""
                          }
                          desc={
                            this.state.advertising.length > 0
                              ? this.state.advertising[0].shortName
                              : ""
                          }
                          infoIcons={""}
                          buttonLabel={
                            this.state.advertising.length > 0
                              ? this.state.advertising[0].buttonText
                              : ""
                          }
                          detailView={
                            this.state.advertising.length > 0
                              ? this.state.advertising[0].detailView
                              : ""
                          }
                          detailId={
                            this.state.advertising.length > 0
                              ? this.state.advertising[0].id
                              : ""
                          }
                          href={
                            this.state.advertising.length > 0
                              ? this.state.advertising[0].buttonLink
                              : ""
                          }
                        />
                      ) : (
                        <div></div>
                      )}
                      <EventTeaserCard
                        translate={this.props.translate}
                        key={"competition-" + oKey}
                        imageUrl={getCompetitionListImageSrc(
                          record.competitionTypeImage
                        )}
                        imageTitle={record.imageTitle}
                        header={record.name}
                        isEvent={record.event}
                        date={moment(record.competitionDate)
                          .format("L")
                          .toString()}
                        infoText={this.getFreeSlotsText(record)}
                        infoIcons={record.infoIcons}
                        buttonLabel={
                          record.event
                            ? translate("dashboard.competitions.btnEvent")
                            : translate("dashboard.competitions.btnCompetition")
                        }
                        btnClickHandler={record.publicLinkAction}
                        registrationStart={moment(record.registrationStart)
                          .format("L")
                          .toString()}
                      />
                    </div>
                  ))}

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.props.history.push("/competitionList")}
                    style={styles.button}
                  >
                    {translate("dashboard.competitions.btnAllCompetitions")}
                    <KeyboardArrowRight style={styles.rightIcon} />
                  </Button>
                </Grid>
              </Grid>
            </div>
          }
        />
        <SuccessMessage
          successMessage={this.props.successMessage}
          open={this.props.successMessage ? true : false}
          onClose={() => this.props.dispatch(setSuccess(null))}
          translate={this.props.translate}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    identity: state.identity,
    httpErrorMsg: state.error.httpError.message,
    successMessage: state.success.successMessage.message,
    cancelOrderItemPopup: state.flags.popupOpen.cancelOrderItemPopup
  };
};

export default connect(mapStateToProps)(translate(Dashboard));
