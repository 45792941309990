import React, { Component } from "react";
import { translate } from "react-admin";
import ClubShowPublic from "../../components/pages/ClubShowPublic";
import {
  getClubFromPublicID,
  getFavourite,
  getRegistrations
} from "../../utils/restUtils";
import { setError } from "../../reducer/errorActions";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { setPopup } from "../../reducer/flagActions";
import { isGuest } from "../../utils/utils";
class ClubShowPublicPage extends Component {
  state = {
    club: null
  };

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  decorateData = (club, favorites, registrations) => {
    let events = [];
    let competitions = [];

    if (club.competitions && favorites !== undefined && favorites !== null) {
      club.competitions.forEach(
        item => (item.favourite = favorites.includes(item.id))
      );
    }

    if (
      club.competitions &&
      registrations !== undefined &&
      registrations !== null
    ) {
      club.competitions.forEach(item => {
        item.registered = registrations[item.id] !== undefined;
        item.paid = registrations[item.id];
      });
    }

    club.competitions &&
      club.competitions.forEach((competition, key) => {
        competition.toCompetitionButton = (
          <Button
            size="small"
            variant="outlined"
            onClick={() => {
              this.props.dispatch(
                setPopup("popupCompetitionRegistrationForm", false)
              );
              this.props.history.push(
                "/competitionDetails/" + competition.publicLink
              );
            }}
          >
            {competition.event
              ? this.props.translate("resources.clubs.publicShow.toTheEvent")
              : this.props.translate(
                  "resources.clubs.publicShow.toTheCompetition"
                )}
          </Button>
        );
        competitions.push(competition);
      });
    club.events = events;
    club.competitions = competitions;

    return club;
  };

  getArchiveCompetitions = club => {
    let now = Date.now();
    let competitions = [];
    competitions = club.competitions.filter(competition =>
      competition.competitionEndDate
        ? new Date(competition.competitionEndDate).setHours(24) <= now ||
          competition.cancelled
        : new Date(competition.competitionDate).setHours(24) <= now ||
          competition.cancelled
    );
    competitions.sort(this.shortArchive);
    return competitions;
  };

  getUpcomingCompetitions = club => {
    let now = Date.now();
    let competitions = [];
    competitions = club.competitions.filter(competition =>
      competition.competitionEndDate
        ? new Date(competition.competitionEndDate).setHours(24) > now &&
          !competition.cancelled
        : new Date(competition.competitionDate).setHours(24) > now &&
          !competition.cancelled
    );
    competitions.sort(this.shortUpcoming);
    return competitions;
  };

  shortArchive(a, b) {
    if (new Date(a.competitionDate) < new Date(b.competitionDate)) return 1;
    return -1;
  }

  shortUpcoming(a, b) {
    if (new Date(a.competitionDate) > new Date(b.competitionDate)) return 1;
    return -1;
  }

  componentDidMount = () => {
    let { id } = this.props.match.params;

    let f = (favorites, registrations) => {
      getClubFromPublicID(id).then(
        success => {
          let club = this.decorateData(success, favorites, registrations);
          club.archiveCompetitions = this.getArchiveCompetitions(club);
          club.upcomingCompetitions = this.getUpcomingCompetitions(club);
          this.setState({ club: club });
        },
        e => {
          this.setState({ club: null });
          this.props.dispatch(setError(e));
        }
      );
    };

    if (this.props.permissions && !isGuest(this.props.permissions)) {
      getFavourite()
        .then(favorites =>
          getRegistrations().then(registrations => f(favorites, registrations))
        )
        .catch(() => f());
    } else {
      f();
    }
  };
  handleArchiveChange = () => {
    let { id } = this.props.match.params;

    let f = (favorites, registrations) => {
      getClubFromPublicID(id).then(
        success => {
          let club = this.decorateData(success, favorites, registrations);
          club.archiveCompetitions = this.getArchiveCompetitions(club);
          club.upcomingCompetitions = this.getUpcomingCompetitions(club);
          this.setState({ club: club });
        },
        e => {
          this.setState({ club: null });
          this.props.dispatch(setError(e));
        }
      );
    };

    if (!isGuest(this.props.permissions)) {
      getFavourite()
        .then(favorites =>
          getRegistrations().then(registrations => f(favorites, registrations))
        )
        .catch(() => f());
    } else {
      f();
    }
  };
  render = () => {
    return (
      <ClubShowPublic
        handleArchiveChange={this.handleArchiveChange}
        club={this.state.club}
        translate={this.props.translate}
        errorMessage={this.props.httpErrorMsg}
        hideError={this.hideErrorMessage}
        history={this.props.history}
        momentFormat={"L"}
        momentFormatWithTime={"LLL"}
      />
    );
  };
}

function mapStateToProps(state) {
  return {
    myForm: state.form.userRegisterForm,
    httpErrorMsg: state.error.httpError.message,
    permissions: state.identity.userData.permissions
  };
}
export default connect(mapStateToProps)(translate(ClubShowPublicPage));
