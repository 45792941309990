import React from "react";
import { translate } from "react-admin";
import PageTitle from "../../components/PageTitle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const conditionsPage = props => (
  <div>
    <PageTitle title={"Allgemeine Geschäftsbedingungen"} />
    <Card>
      <CardContent style={{ maxWidth: "1200px" }}>
        <p>
          <h2>1. Allgemeines</h2>
        </p>

        <p>
          comp|sign ist ein Anmeldesystem für Sportveranstaltungen im Speziellen
          derzeit für Sportschützenveranstaltungen und stellt Bewerbe,
          Trainings, Sicherheitszulassungen, RO-Kurse bzw. sonstige
          Veranstaltungen für Vereine, mit denen es eine vertragliche
          Vereinbarung gibt und dem Dachverband der IPSC-Austria zur Verfügung.
        </p>

        <p>
          <h2>2. Leistungsumfang</h2>
        </p>

        <p>
          Das Anmeldesystem comp|sign dient dem Zweck diverse
          Sportveranstaltungen, derzeit speziell des Schießsports, online
          präsent zu machen und Anmeldungen von Teilnehmern entgegenzunehmen.
          <br />
          Die Anmeldedaten werden vom veranstaltenden Verein in Speziellen
          Datenformaten heruntergeladen und für deren Scoring Programme
          verwendet.
          <br />
          Der Verein stellt Ergebnislisten und Links zu Fotos auf comp|sign zum
          Download für die Teilnehmer bereit. Handelt es sich um
          Sicherheitszulassungen, Trainings oder RO-Kurse werden die
          Anmeldedaten vom Dachverband IPSC-Austria für die weitere Verwendung
          heruntergeladen.
        </p>

        <p>
          Für spezielle Leistungen im weiteren Premiumfunktionen genannt, können
          sich Teilnehmer (Schützen) in comp|sign registrieren.
          <br />
          Aufgrund dieser Registrierung ist z.B. die Anmeldung zu Bewerben durch
          die bereits hinterlegten Daten rascher möglich und bietet auch sonst
          noch viele zusätzliche Vorteile.
        </p>

        <p>
          Weitere Details der Premiumfunktionen siehe{" "}
          <a
            href="https://compsign.at/meine-vorteile-als-registrierter-schuetze/"
            target="_blank"
          >
            https://compsign.at/meine-vorteile-als-registrierter-schuetze/
          </a>
        </p>

        <p>
          Gespeichert werden die in der Datenschutzerklärung (DSGVO) angeführten
          personenbezogenen Daten.
        </p>

        <p>
          <h2>
            3. Laufzeit &amp; Kosten für die Nutzung der Premiumfunktionen für
            den Schützen (Registrierung erforderlich)
          </h2>
        </p>

        <p>
          Die Mitgliedschaft ist für ein Jahr ab Registrierung gültig und muss
          vom Schützen nicht explizit beendet werden, es reicht eine
          Nichteinzahlung der Jahresgebühr.
        </p>

        <p>
          Bei Inanspruchnahme der Abo Funktion verlängert sich dieses
          automatisch um ein Jahr. Kündigungsfrist: 2 Wochen vor Ablauf des
          Abos.
        </p>

        <p>
          Die Jahresmitgliedsgebühr beträgt € 20,- inkl. MwSt. und ist sofort
          bei Registrierung fällig.
          <br />
          <br />
          <h2>
            4. Laufzeit des Nutzungsvertrages zwischen comp|sign und dem Verein
          </h2>
        </p>

        <p>
          Der Nutzungsvertrag wird auf unbestimmte Zeit abgeschlossen. Sollte
          die Vereinbarung seitens Portalbetreiber beendet werden, wird dem
          Verein gestattet alle zu diesem Zeitpunkt im Portal gelisteten Bewerbe
          ohne Einschränkungen fertig abzuhandeln. Bei Nichtbezahlung der
          vereinbarten Kosten (lt. Punkt 4.1) behält sich der Portalbetreiber
          das Recht vor den Verein inkl. aller Bewerbe (auch laufende) im Portal
          zu sperren
        </p>

        <p>
          <h2>4.1. Kosten für den Verein</h2>
          <ul>
            <li>1 Startgeld, mind. aber € 30.- pro Bewerb</li>
            <li>FFWGK Bewerbe: 1 Startgeld, mind. aber € 15.- pro Bewerb</li>
            <li>RO-Kurse: Planung und Anmeldung über comp|sign kostenlos</li>
            <li>Helferplanung über comp|sign kostenlos</li>
            <li>Vereinspräsentation in comp|sign kostenlos</li>
            <li>
              Sonderregelungen für spezielle Bewerbs-Arten oder Cups bitte nach
              Rücksprache
            </li>
          </ul>
        </p>

        <p>
          <h2>
            4.2. Kosten für die IPSC-Austria (bzw. dem Dachverband des
            jeweiligen Landes)
          </h2>
          <ul>
            <li>
              Sicherheitszulassungen: Planung und Anmeldung über comp|sign
              kostenlos
            </li>
            <li>RO-Kurse: Planung und Anmeldung über comp|sign kostenlos</li>
          </ul>
        </p>

        <p>
          Die vereinbarten Kosten sind bis 31.12.2022 fixiert, danach können
          diese vom Portalbetreiber mit einer schriftlichen Ankündigung erhöht
          werden.
        </p>

        <p>
          <h2>5. Datenschutz &amp; Haftungsausschluss</h2>
        </p>

        <p>
          (Datenschutzerklärung)
          <br />
          Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen.
          Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der
          gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen
          Datenschutzinformationen informieren wir Sie über die wichtigsten
          Aspekte der Datenverarbeitung im Rahmen unserer Website.
          <br />
          Detaillierte Informationen ersehen Sie unter{" "}
          <a href="/#/datenschutz" target="_blank">
            www.compsign.net/#/datenschutz
          </a>
        </p>

        <p>
          <h2>6. Zustimmung zu den AGB ́s</h2>
        </p>

        <p>
          Mit der Registrierung zu den Premiumdiensten bzw. der Anmeldung zu den
          im comp|sign bereitgestellten Bewerben, Trainings,
          Sicherheitszulassungen, RO-Kursen oder sonstigen Veranstaltungen
          stimmt der Teilnehmer (Schütze) den AGB ́s des Betreibers comp|sign,
          Firma Thomas Tuschl vollinhaltlich zu.
        </p>
      </CardContent>
    </Card>
  </div>
);

export default translate(conditionsPage);
