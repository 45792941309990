// React imports
// Material imports
import Grid from "@material-ui/core/Grid";
import deLocale from "date-fns/locale/de";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import PropTypes from "prop-types";
import RichTextInput from "ra-input-rich-text";
import React from "react";
// React Admin imports
import {
  ArrayInput,
  Edit,
  FormDataConsumer,
  FormTab,
  SelectInput,
  TextField,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  translate
} from "react-admin";
import { DateInput } from "react-admin-date-inputs";
import {
  required,
  requiredIfBankNameIsFilled,
  requiredIfIbanIsFilled
} from "../../validators/validators";
import ErrorMessage from "../ErrorMessage";
import PageTitle from "../../components/PageTitle";
import CustomToolbar from "../CustomToolbar";
import S3FileUploadInput from "../inputs/S3FileUploadInput";

const ClubProfileEdit = props => {
  const { translate, permissions } = props;

  return (
    <Grid>
      <PageTitle title={translate("resources.clubs.editPage.headline")} />
      <Edit {...props}>
        <TabbedForm redirect="/dashboard" toolbar={<CustomToolbar />}>
          <FormTab label="resources.clubs.tabs.club">
            <h2>{translate("resources.clubs.fields.clubHeader")}</h2>
            <TextInput source="name" validate={required()} />
            <TextInput source="longName" validate={required()} />
            <TextInput source="address" />
            <TextInput source="city" />
            <TextInput source="zip" />

            <SelectInput
              source="country"
              choices={[
                { id: "at", name: translate("countries.at") },
                { id: "de", name: translate("countries.de") }
              ]}
            />
            <TextInput source="googleMapsLink" />
            <TextField source="publicLinkId" />
            <TextInput source="websiteLink" />
            <S3FileUploadInput
              source="logoRaw"
              previewSource="logo"
              label="resources.clubs.fields.logo"
            />

            <h2>{translate("resources.clubs.showPage.bank.information")}</h2>
            <TextInput
              label="resources.clubs.showPage.bank.name"
              source="bankInformation.name"
              validate={requiredIfIbanIsFilled()}
            />
            <TextInput
              label="resources.clubs.showPage.bank.bic"
              source="bankInformation.bic"
            />
            <TextInput
              label="resources.clubs.showPage.bank.iban"
              source="bankInformation.iban"
              validate={requiredIfBankNameIsFilled()}
            />

            <h2>{translate("resources.clubs.fields.matchHeader")}</h2>
            <TextInput source="matchAddress" />
            <TextInput source="matchCity" />
            <TextInput source="matchZip" />
            <SelectInput
              source="matchCountry"
              choices={[
                { id: "at", name: translate("countries.at") },
                { id: "de", name: translate("countries.de") }
              ]}
            />
            <h2>{translate("resources.clubs.fields.contactHeader")}</h2>
            <TextInput source="contact.firstName" />
            <TextInput source="contact.lastName" />
            <TextInput source="contact.email" />
            <TextInput source="contact.telephone" />

            {permissions &&
            (permissions.indexOf("IS_ADMIN") > -1 ||
              permissions.indexOf("IS_PORTAL_ADMIN") > -1) ? (
              <h2>{translate("resources.clubs.fields.paymentHeader")}</h2>
            ) : null}
            {permissions &&
            (permissions.indexOf("IS_ADMIN") > -1 ||
              permissions.indexOf("IS_PORTAL_ADMIN") > -1) ? (
              <DateInput
                label={translate("resources.clubs.fields.paymentUntil")}
                source="paymentUntil"
                providerOptions={{ utils: MomentUtils, locale: deLocale }}
                options={{
                  //format: "DD.MM.YYYY, HH:mm:ss",
                  ampm: false,
                  clearable: true
                }}
              />
            ) : null}
          </FormTab>
        </TabbedForm>
      </Edit>
      <ErrorMessage
        errorMessage={props.errorMessage}
        open={!!props.errorMessage}
        onClose={props.hideError}
        translate={props.translate}
      />
    </Grid>
  );
};

ClubProfileEdit.propTypes = {
  translate: PropTypes.func.isRequired,
  errorMessage: PropTypes.object,
  hideError: PropTypes.func.isRequired
};
export default translate(ClubProfileEdit);
