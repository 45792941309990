import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Divider
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import PropTypes from "prop-types";
import React, { Component } from "react";

require("moment/locale/de.js");
require("moment/locale/de-at.js");
const styles = theme => ({
  root: {
    marginTop: theme.spacing.unit * 3
  }
});

class SimpleList extends Component {
  state = {
    checkedList: [],
    order: "desc",
    orderBy: null,
    filterMenu: false,
    filters: [],
    anchorEl: null,
    openMenu: null
  };

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
      openMenu: event.currentTarget.getAttribute("aria-controls")
    });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, openMenu: null });
  };

  render() {
    const { data } = this.props;

    if (!data || data.length <= 0) {
      return null;
    }

    return (
      <List className={this.props.className}>
        {data.map((datarow, key) => [
          <ListItem key={"simpleListItem-" + key}>
            <ListItemText
              primary={datarow.mainText}
              secondary={datarow.secondaryText ? datarow.secondaryText : ""}
            />

            {typeof datarow.menu !== "undefined" && datarow.menu && (
              <ListItemSecondaryAction>{datarow.menu}</ListItemSecondaryAction>
            )}
          </ListItem>,
          <Divider />
        ])}
      </List>
    );
  }
}

SimpleList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string
};

export default withStyles(styles)(withWidth()(SimpleList));
