import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  translate,
  FormDataConsumer,
  BooleanInput,
  AutocompleteInput,
  SaveButton,
  Toolbar
} from "react-admin";
import RoleSelectInput from "../../components/inputs/RoleSelectInput";
import { compose } from "recompose";
import { asyncUsernameAndEmailValidator } from "../../components/inputs/validator";
import Grid from "@material-ui/core/Grid";
import PageTitle from "../../components/PageTitle";
import { required } from "../../validators/validators";
import { austrianStates, germanStates } from "../../utils/states";
import { REDUX_FORM_NAME } from "ra-core";
import { change, untouch } from "redux-form";
import Button from "@material-ui/core/Button";
import CustomToolbar from "../../components/CustomToolbar";
import countries from "../../components/pages/country-codes-case-lower.json";
const getCountryOptions = () => {
  return countries.map(country => {
    return {
      id: country.countryCode,
      name: country.countryName
    };
  });
};

const REG = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,128}$/);

const password = (
  props,
  message = props.translate("PW_MIN_6_MAX_128_1_UPPER_1_LOWER_1_NUMERIC")
) => value => (REG.test(value) ? undefined : message);

const passwordEquals = (props, message = props.translate("PW_MUST_MATCH")) => (
  value,
  values
) => (value === values.password ? undefined : message);

const WithProps = ({ children, ...props }) => children(props);

export const UserCreate = compose(translate)(
  ({ permissions, clubAdminRoleId, userRoleId, history, ...props }) => (
    <Grid>
      <PageTitle
        title={props.translate("resources.users.createPage.headline")}
      />
      <Create title="Create a User" {...props}>
        <SimpleForm
          redirect="list"
          asyncValidate={asyncUsernameAndEmailValidator}
          asyncBlurFields={["username", "email"]}
          toolbar={<CustomToolbar {...props} />}
        >
          <TextInput source="username" validate={[required()]} />
          <TextInput source="email" validate={[required()]} />
          <RoleSelectInput
            label="resources.users.fields.userRole"
            type="create"
            validate={[required()]}
          />
          <FormDataConsumer>
            {(formData, ...rest) => {
              return (
                formData &&
                formData.formData &&
                formData.formData.userRoleIds &&
                formData.formData.userRoleIds.indexOf(clubAdminRoleId) > -1 && (
                  <ReferenceInput
                    label="resources.users.fields.club.id"
                    source="club.id"
                    reference="clubs"
                    perPage={100}
                    sort={{ field: "name", order: "ASC" }}
                    validate={[required()]}
                  >
                    <AutocompleteInput optionText="name" optionValue="id" />
                  </ReferenceInput>
                )
              );
            }}
          </FormDataConsumer>

          <TextInput
            source="password"
            type="password"
            validate={[required(), password(props)]}
          />
          <TextInput
            source="password2"
            type="password"
            validate={[required(), passwordEquals(props)]}
          />
          <TextInput source="firstName" validate={[required()]} />
          <TextInput source="lastName" validate={[required()]} />

          <SelectInput
            source="selectedLanguage"
            label={props.translate(
              "resources.users.registerForm.fields.selectedLanguage"
            )}
            validate={[required()]}
            choices={[
              { id: "", name: "" },
              {
                id: "de",
                name: props.translate(
                  "resources.users.registerForm.fields.languages.de"
                )
              },
              {
                id: "en",
                name: props.translate(
                  "resources.users.registerForm.fields.languages.en"
                )
              }
            ]}
          />

          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              return (
                formData &&
                formData.userRoleIds &&
                formData.userRoleIds.indexOf(userRoleId) > -1 && (
                  <Grid>
                    <Grid>
                      <SelectInput
                        source="salutation"
                        label={props.translate(
                          "resources.users.registerForm.fields.salutation"
                        )}
                        validate={[required()]}
                        choices={[
                          { id: "", name: "" },
                          {
                            id: "mr",
                            name: props.translate(
                              "resources.users.registerForm.fields.salutations.mr"
                            )
                          },
                          {
                            id: "mrs",
                            name: props.translate(
                              "resources.users.registerForm.fields.salutations.mrs"
                            )
                          }
                        ]}
                      />
                    </Grid>
                    <Grid>
                      <TextInput
                        label={props.translate(
                          "resources.users.registerForm.fields.degree"
                        )}
                        source="degree"
                      />
                    </Grid>
                    <Grid>
                      <TextInput
                        source="address"
                        label={props.translate(
                          "resources.users.registerForm.fields.address"
                        )}
                        validate={[required()]}
                      />
                    </Grid>
                    <Grid>
                      <TextInput
                        source="zip"
                        label={props.translate(
                          "resources.users.registerForm.fields.zip"
                        )}
                        validate={[required()]}
                      />
                    </Grid>
                    <Grid>
                      <TextInput
                        source="city"
                        label={props.translate(
                          "resources.users.registerForm.fields.city"
                        )}
                        validate={[required()]}
                      />
                    </Grid>
                    <Grid>
                      <SelectInput
                        source="country"
                        label={props.translate(
                          "resources.users.registerForm.fields.country"
                        )}
                        validate={[required()]}
                        onChange={() => {
                          dispatch(untouch(REDUX_FORM_NAME, "state"));
                          dispatch(change(REDUX_FORM_NAME, "state", null));
                        }}
                        choices={[{ id: "", name: "" }, ...getCountryOptions()]}
                      />
                    </Grid>
                    {formData &&
                    formData.country &&
                    (formData.country === "de" || formData.country === "at") ? (
                      <Grid>
                        <SelectInput
                          source="state"
                          label={props.translate(
                            "resources.users.registerForm.fields.state"
                          )}
                          validate={[required()]}
                          choices={
                            formData && formData.country === "de"
                              ? germanStates
                              : austrianStates
                          }
                        />
                      </Grid>
                    ) : null}
                    <Grid>
                      <TextInput
                        source="telephone"
                        label={props.translate(
                          "resources.users.registerForm.fields.telephone"
                        )}
                        validate={[]}
                      />
                    </Grid>
                    <Grid>
                      <ReferenceInput
                        label={props.translate(
                          "resources.users.registerForm.fields.competitionClass"
                        )}
                        validate={[required()]}
                        source="competitionClass.id"
                        reference="competitionClasses"
                        perPage={100}
                        sort={{ field: "name", order: "ASC" }}
                      >
                        <SelectInput optionText="name" />
                      </ReferenceInput>
                    </Grid>
                    <Grid>
                      <ReferenceInput
                        label={props.translate(
                          "resources.users.registerForm.fields.competitionDivision"
                        )}
                        validate={[required()]}
                        source="division.id"
                        reference="divisions"
                        perPage={100}
                        sort={{ field: "name", order: "ASC" }}
                      >
                        <SelectInput optionText="name" />
                      </ReferenceInput>
                    </Grid>
                    <Grid>
                      <ReferenceInput
                        label={props.translate(
                          "resources.users.registerForm.fields.competitionFactor"
                        )}
                        validate={[required()]}
                        source="factor.id"
                        reference="factors"
                        perPage={100}
                        sort={{ field: "name", order: "ASC" }}
                      >
                        <SelectInput optionText="name" />
                      </ReferenceInput>
                    </Grid>
                    <Grid>
                      <ReferenceInput
                        label={props.translate(
                          "resources.users.registerForm.fields.club"
                        )}
                        validate={[required()]}
                        source="club.id"
                        reference="clubs"
                        perPage={100}
                        sort={{ field: "name", order: "ASC" }}
                      >
                        <AutocompleteInput optionText="name" />
                      </ReferenceInput>
                    </Grid>
                    <Grid>
                      <TextInput
                        source="alias"
                        label={props.translate(
                          "resources.users.registerForm.fields.alias"
                        )}
                        validate={[]}
                      />
                    </Grid>
                    <Grid>
                      <TextInput
                        source="cowboyName"
                        label={props.translate(
                          "resources.users.registerForm.fields.cowboyName"
                        )}
                        validate={[]}
                      />
                    </Grid>
                    <Grid>
                      <TextInput
                        source="sassNumber"
                        label={props.translate(
                          "resources.users.registerForm.fields.sassNumber"
                        )}
                        validate={[]}
                      />
                    </Grid>
                    <Grid>
                      <TextInput
                        source="ipscNumber"
                        label={props.translate(
                          "resources.users.registerForm.fields.ipscNumber"
                        )}
                        validate={[]}
                      />
                    </Grid>
                    <Grid>
                      <TextInput
                        source="ipscRegion"
                        label={props.translate(
                          "resources.users.registerForm.fields.ipscRegion"
                        )}
                        validate={[]}
                      />
                    </Grid>
                    <Grid>
                      <TextInput
                        source="ipscAlias"
                        label={props.translate(
                          "resources.users.registerForm.fields.ipscAlias"
                        )}
                        validate={[]}
                      />
                      <BooleanInput
                        source="allowInformations"
                        label={props.translate(
                          "resources.users.registerForm.fields.allowInformations"
                        )}
                      />
                    </Grid>
                    <Grid>
                      <TextInput
                        source="bmdAccountNumber"
                        label={"BMD"}
                        validate={[]}
                      />
                    </Grid>
                  </Grid>
                )
              );
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </Grid>
  )
);
