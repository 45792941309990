import React from "react";
import {
  required,
  translate,
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  Create,
  SimpleForm,
  Edit,
  FileInput,
  DateField,
  BooleanInput,
  Toolbar,
  SaveButton,
  Pagination
} from "react-admin";
import compose from "recompose/compose";
import { Grid, Button } from "@material-ui/core/";
import PageTitle from "../../components/PageTitle";
import restClient from "../../providers/dataProvider/rest";
import { DateInput } from "react-admin-date-inputs";
import RichTextInput from "ra-input-rich-text";
import S3FileUploadInput from "../../components/inputs/S3FileUploadInput";
import {
  rowsPerPageOptions,
  defaultRowsPerPageOption
} from "../../utils/paging";
import PopupBulkDeleteButton from "../../components/PopupBulkDeleteButton";

const AdvertisingFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

const CustomToolbar = props => (
  <Toolbar style={{ justifyContent: "space-between" }} {...props}>
    <SaveButton />
    <Button
      variant="contained"
      color="primary"
      onClick={() => props.history.goBack()}
    >
      Abbrechen
    </Button>
  </Toolbar>
);

export const AdvertisingList = compose(translate)(({ translate, ...props }) => (
  <Grid>
    <PageTitle title={translate("resources.advertising.listPage.headline")} />
    <List
      filters={null}
      exporter={false}
      {...props}
      pagination={
        <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
      }
      bulkActionButtons={<PopupBulkDeleteButton />}
      perPage={defaultRowsPerPageOption}
    >
      <Datagrid rowClick="edit">
        <TextField
          source="name"
          label={translate("resources.advertising.name")}
        />
        <DateField
          source="fromDate"
          label={translate("resources.advertising.fromDate")}
          locales="de-DE"
        />
        <DateField
          source="toDate"
          label={translate("resources.advertising.toDate")}
          locales="de-DE"
        />
      </Datagrid>
    </List>
  </Grid>
));

export const AdvertisingCreate = compose(translate)(
  ({ translate, history, ...props }) => (
    <Grid>
      <PageTitle
        title={translate("resources.advertising.createPage.headline")}
      />
      <Create {...props}>
        <SimpleForm
          redirect="list"
          toolbar={<CustomToolbar history={history} />}
        >
          <BooleanInput source="active" />
          <TextInput
            source="name"
            validate={required()}
            label={translate("resources.advertising.name")}
          />
          <TextInput
            source="shortName"
            label={translate("resources.advertising.shortName")}
          />
          <S3FileUploadInput
            source="imageRaw"
            previewSource="image"
            label={translate("resources.advertising.imageRaw")}
          />
          <DateInput
            label={translate("resources.advertising.fromDate")}
            source="fromDate"
            options={{
              ampm: false,
              clearable: true
            }}
            locales="de-DE"
          />
          <DateInput
            label={translate("resources.advertising.toDate")}
            source="toDate"
            options={{
              ampm: false,
              clearable: true
            }}
            locales="de-DE"
          />
          <TextInput source="buttonText" validate={required()} />
          <TextInput source="buttonLink" />
          <BooleanInput source="detailView" />
          <S3FileUploadInput
            source="detailImageRaw"
            previewSource="detailImage"
            label={translate("resources.advertising.detailImageRaw")}
          />
          <RichTextInput
            source="text"
            toolbar={[["bold", "italic", "underline", "link"]]}
            label={translate("resources.advertising.text")}
          />
        </SimpleForm>
      </Create>
    </Grid>
  )
);

export const AdvertisingEdit = compose(translate)(
  ({ translate, history, ...props }) => (
    <Grid>
      <PageTitle title={translate("resources.advertising.editPage.headline")} />
      <Edit {...props}>
        <SimpleForm
          redirect="list"
          toolbar={<CustomToolbar history={history} />}
        >
          <BooleanInput source="active" />
          <TextInput
            source="name"
            validate={required()}
            label={translate("resources.advertising.name")}
          />
          <TextInput
            source="shortName"
            label={translate("resources.advertising.shortName")}
          />
          <S3FileUploadInput
            source="imageRaw"
            previewSource="image"
            label={translate("resources.advertising.imageRaw")}
          />
          <DateInput
            label={translate("resources.advertising.fromDate")}
            source="fromDate"
            options={{
              ampm: false,
              clearable: true,
              format: "dd.MM.YYYY"
            }}
            locales="de-DE"
          />
          <DateInput
            label={translate("resources.advertising.toDate")}
            source="toDate"
            options={{
              ampm: false,
              clearable: true,
              format: "dd.MM.YYYY"
            }}
            locales="de-DE"
          />
          <TextInput source="buttonText" validate={required()} />
          <TextInput source="buttonLink" />
          <BooleanInput source="detailView" />
          <S3FileUploadInput
            source="detailImageRaw"
            previewSource="detailImage"
            label={translate("resources.advertising.detailImageRaw")}
          />
          <RichTextInput
            source="text"
            toolbar={[["bold", "italic", "underline", "link"]]}
            label={translate("resources.advertising.text")}
          />
        </SimpleForm>
      </Edit>
    </Grid>
  )
);

const uploadFileSuccess = (data, rawFile) => {
  if (data && data) {
    data = data.data;
  }
  rawFile.documentId = data.id;
  rawFile.errorMsg = null;
};

const uploadFileError = (e, rawFile) => {
  rawFile.documentId = null;
  rawFile.errorMsg = e.message;
};

const uploadFile = file => {
  file = file[0];
  let formData = new FormData();
  formData.append("file", file);
  formData.append("name", file.name);
  restClient("UPLOAD", "upload", formData).then(
    data => uploadFileSuccess(data, file),
    e => uploadFileError(e, file)
  );
};
