import React from "react";
import {
  required,
  translate,
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  Create,
  SimpleForm,
  Edit,
  DisabledInput,
  Toolbar,
  SaveButton,
  Pagination
} from "react-admin";
import compose from "recompose/compose";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PageTitle from "../../components/PageTitle";
import {
  defaultRowsPerPageOption,
  rowsPerPageOptions
} from "../../utils/paging";
import PopupBulkDeleteButton from "../../components/PopupBulkDeleteButton";
import CustomToolbar from "../../components/CustomToolbar";

const ClassFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

export const ClassList = compose(translate)(({ translate, ...props }) => (
  <Grid>
    <PageTitle
      title={translate("resources.competitionClasses.listPage.headline")}
    />
    <List
      filters={<ClassFilter />}
      exporter={false}
      pagination={
        <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
      }
      perPage={defaultRowsPerPageOption}
      bulkActionButtons={<PopupBulkDeleteButton />}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
      </Datagrid>
    </List>
  </Grid>
));

export const ClassCreate = compose(translate)(
  ({ translate, history, ...props }) => (
    <Grid>
      <PageTitle
        title={translate("resources.competitionClasses.createPage.headline")}
      />
      <Create {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar {...props} />}>
          <TextInput source="name" validate={required()} />
        </SimpleForm>
      </Create>
    </Grid>
  )
);

export const ClassEdit = compose(translate)(
  ({ translate, history, ...props }) => (
    <Grid>
      <PageTitle
        title={translate("resources.competitionClasses.editPage.headline")}
      />
      <Edit {...props}>
        <SimpleForm
          redirect="list"
          toolbar={<CustomToolbar disabledDelete {...props} />}
        >
          <DisabledInput label="Id" source="id" />
          <TextInput source="name" validate={required()} />
        </SimpleForm>
      </Edit>
    </Grid>
  )
);
