import englishMessages from "ra-language-english";

englishMessages.ra.notification.updated =
  "The changes have been saved successfully.";
export default {
  ...englishMessages,
  BARZAHLUNG: "Cash Payment directly by the Competition.",
  VORAUSZAHLUNG: "Transfer to the club account",
  WIRECARD: "Zahlung mittels externen Zahlungsprovider",
  pos: {
    search: "Search",
    profile: "Profil",
    signout: "Signout",
    configuration: "Configuration",
    language: "Language",
    theme: {
      name: "Theme",
      light: "Light",
      dark: "Dark"
    },
    headerImage: {
      headline: "Welcome to comp | sign",
      headlineMobile: "Welcome to",
      compSign: "comp | sign",
      btnCompetitions: "To the competitions",
      btnClubs: "To the Clubs",
      btnResults: "To the Result Lists"
    }
  },
  dashboard: {
    headline: "Frontpage",
    myUpcomingEvents: {
      title: "My next Events",
      moreButton: "To all my Events"
    },
    myParticipatedEvents: {
      title: "My past participations",
      moreButton: "To all my participations"
    },
    guestContent: {
      compSignFunctions: "comp | sign functions",
      registrationAdvantages: "Registering Benefits",
      registration:
        "To enjoy the premium service, register or log in as a guest.",
      functions: "the full functionality of comp | sign.",
      look: "Siehe here",
      introduction:
        "Here you will find all competitions of the partner clubs including the result lists as well as the possibility to register for training or safety approvals. ",
      introduction2:
        "For shooters the registration for competitions via comp|sign is basically free of charge!",
      introduction3:
        "If you want to enjoy the premium features, please register.",
      introduction4:
        '(You will find the possibility to do so at the top right corner at the button "Login")'
    },
    welcome: {
      headline: "Willkommen bei comp | sign",
      headlineGuest: "The Austrian Sign up portal for marksmen welcomes you!",
      headlineUser: "Welcome back, %{fullName}",
      textGuest:
        "The Austrian registration portal for sport shooters. Register now and benefit from many advantages.",
      textShooter:
        "Find below the competitions in which you are registered and also a review of your completed competitions.",
      greetingsShooter: "The team at comp | sign wishes you continued success",
      textPortalAdmin:
        "The Austrian registration portal for sport shooters. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
      textSystemAdmin:
        "The Austrian registration portal for sport shooters. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
      textClubAdmin:
        "The Austrian registration portal for sport shooters. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.",
      whyRegister: "Benefits of Registration"
    },
    teaser: {
      btnMore: "MORE",
      results: {
        headline: "Result Lists",
        imgTitle: "Siegerteam mit Pokal in den Händen"
      },
      competitions: {
        headline: "Competition",
        imgTitle: "Rauchende Pistole"
      },
      clubs: {
        headline: "Clubs",
        imgTitle: "Menschengruppe hält sich an den Händen"
      }
    },
    competitions: {
      headline: "Competition",
      btnAllCompetitions: "All COMPETITIONS",
      btnCompetition: "To COMPETITION",
      btnEvent: "TO EVENT"
    }
  },
  customAutoComplete: {
    placeholder: "Choose..."
  },
  orderList: {
    id: "Id",
    firstName: "FirstName",
    lastName: "LastName",
    email: "E-Mail",
    payButton: "Payed",
    cancelButton: "Cancel",
    openDetailsButton: "Details"
  },
  appBar: {
    login: "Login",
    profil: "Profil",
    loginForm: {
      loginHeader: "Log In",
      forgotPassword: "Forgot Password?",
      cancel: "Cancel",
      loginDescription: "You can now log in or register here.",
      noAccountYet: "No account yet? Register here.",
      username: "Username",
      password: "Passwort",
      doLogin: "Log In"
    },
    noPaymentUntil: "Membership fee not yet paid.",
    notSupportedBrowser: "Browser is not supported."
  },
  menu: {
    mainTitle: "comp | sign Menu",
    exports: "Exports and evaluations",
    managementTitle: "Administration",
    clubDataTitle: "My Club",
    dashboard: "Start",
    reservations: "Reservation",
    upcomingCompetitions: "Upcoming Competitions",
    competitionArchive: "Result Lists",
    clubs: "Club Overview",
    register: "Register as Shooter",
    registerTitle: "Register Now",
    myData: "My Data",
    myCompetitions: "My Competions",
    profile: "My Profil",
    clubProfile: "Club Profile",
    clubCompetitions: "Club Competitions",
    clubEvents: "Club Events",
    clubReservationCodes: "Reservation Codes",
    clubOrders: "Bills",
    clubItemOrders: "Registrations",
    settings: {
      uploadTypes: "Downloads",
      competitions: "Competitions",
      clubs: "Clubs",
      reservationCodes: "Reservation Codes",
      orders: "Bills",
      orderItems: "Reservations",
      users: "User Management",
      userRoles: "User Roules",
      factors: "Factors",
      competitionClasses: "Classes",
      divisions: "Divisionen",
      levels: "Levels",
      competitionTypes: "Match Types",
      events: "Events"
    },
    pages: {
      whyRegister: "My Advantages as a registered Shooter",
      information: "Information Website",
      about: "Über comp | sign",
      changelog: "About und Changelog",
      costs: "That comp | sign costs",
      advertisings: "Advertising"
    }
  },
  footer: {
    info:
      "These are the voyages of the Starship Enterprise. Its continuing mission, to explore strange new worlds, to seek out new life and new civilizations, to boldly go where no one has gone before.",
    copyright: "© 2019 comp|sign Thomas Tuschl",
    address: "Zwerbach 35a, 3244 Ruprechtshofen",
    email: "support@compsign.net",
    phone: "+43 664 3968212",
    links: "Links",
    socialMedia: "Social Media",
    changelog: "About und Changelog",
    conditions: "AGBs",
    dataProtection: "Data privacy",
    impressum: "Impressum"
  },
  copyButton: {
    tooltip: "Copy"
  },
  backButton: {
    tooltip: "Back"
  },
  premiumdienste: {
    title: "Premium services for registered shooters",
    sortingAndFilter: "Competition participant list filterable and sortable",
    registration:
      "Registration makes the registration process to the competitions much faster, because division, class and factor don't have to be specified again and again",
    edit:
      "Independent change of all data necessary for the competition until the registration is closed, incl. moving to other squads",
    overview: "overview of all registered competitions",
    export:
      "Export list of all participated competitions. (Practical as proof for WBK extensions)",
    waitingList:
      "Waiting list registration in several desired squads at the same time",
    filter: "Filtering of the competitions of the shooter (Pistol, Rifle,...)",
    print: "Export of the competitor lists of a competition for printing",
    notifications: "Reminder functions | Info to shooters",
    favorite:
      "Reminders just before the start of registration of events marked as favorite",
    subscriptions: "Reminders for registered events",
    waitingListNotification:
      "Information if a start place for which one was on the waiting list could not be fixed",
    freePlaces:
      "Information if start place in a squad becomes free, which shooter actually did not mark as option for start",
    newCompNotification:
      "Information when a competition has been put online, which corresponds to the standard weapon category of the shooter",
    price: "Price per year: 20,00 €",
    download: "Independent download of entry fee confirmations",
    subscription: "Subscription function for membership fee management"
  },
  orderCompletePage: {
    orderSucessMessage: "Your Registration was successful.",
    orderMessageWirecard:
      "The payment via external payment provider has been successfully completed. Your starting place is now fixed.",
    orderSucessTitle: "Registration",
    myCompetitions: "To my Competitions",
    competitions: "To the Competitions"
  },
  userRegisterSuccessPage: {
    welcome: "Welcome to Comp|Sign",
    userRegisterSuccessMessage:
      "Your registration was successful. You will shortly receive an e-mail from us to verify your e-mail address.",
    competitions: "To the Competitions"
  },
  orderFailPage: {
    orderFailMessage:
      "Unfortunately, an error occurred during the payment process. Your registration was not completed and your account was not charged. Please try the payment process again or choose another payment option.",
    orderFailTitle: "Registration Error"
  },
  checkout: {
    headline: "Warenkorb & Checkout",
    next: "Next",
    back: "Back",
    finished: "Finished",
    summary: "Registration Details",
    competitions: "Competitions",
    table: {
      competition: "Competition",
      shooter: "Shooter",
      date: "Date",
      price: "Price",
      currency: "€",
      total: "Total Price",
      division: "Division",
      competitionClass: "Competition Class"
    },
    choosePaymentMethod: "Please choose your payment-method:",
    paymentMethod: "Payment-method:",
    goToCompetitions: "To the Competitions",
    agbs:
      "Please accept the terms and conditions and click Done to confirm your registration!",
    waitingListPaymentInfo:
      "*Please do not pay a participation fee for places on the waiting list!",
    select: "Select payment method...",
    paymentError: "Required field",
    toWirecard: "Continue to payment provider"
  },
  resources: {
    orderShow: {
      competitionName: "Competition",
      competitionDate: "Competition Date",
      firstName: "FirstName",
      lastName: "LastName",
      email: "E-Mail",
      squadName: "Squad",
      orderId: "OrderID",
      orderItems: "Order",
      orderDetails: "Order Details:",
      className: "Class",
      factorName: "Faktor",
      divisionName: "Division",
      m: {
        competitionName: "Competition",
        competitionDate: "Competition Date",
        firstName: "FirstName",
        lastName: "LastName",
        email: "E-Mail",
        squadName: "Squad",
        orderDetails: "Bestelldetails:",
        className: "Class",
        factorName: "Faktor",
        divisionName: "Division"
      }
    },
    advertising: {
      listPage: {
        headline: "Advertising"
      },
      createPage: {
        headline: "Advertising"
      },
      editPage: {
        headline: "Advertising"
      },
      fromDate: "from",
      toDate: "to",
      buttonText: "Button Text",
      buttonLink: "Link",
      imageRaw: "Image",
      detailImageRaw: "DetailBild",
      name: "Title",
      shortName: "Short title",
      text: "Description"
    },
    signupFinish: {
      header: "Your registration has been saved!",
      content1:
        "You will shortly receive a confirmation email for the registration, which also contains a link to process the registration.",
      content2:
        "If approved and desired by the club, register again in another division or complete the registration process",
      newSignup: "Sign up again",
      gotoCart: "to the shopping cart",
      goBacktoCompetition: "Close"
    },
    exports: {
      title: "Exports",
      types: {
        title: "Choose Export",
        competitorsGroupedByCompetition:
          "List of all registrations of all competitions",
        allUsers: "List of all shooters",
        advertisments: "Advertisments",
        competitionPractiScore: "Competition-PractiScore",
        competitions: "Competitions"
      },
      clubs: "Clubs considered",
      exportSquads: "Export Squad Data",
      exportOrderItems: "Export Squad Orders",
      division: "Division",
      competitionClass: "Class",
      factor: "Factor",
      submit: "Download",
      competition: "Competition"
    },
    orderEdit: {
      club: "Club",
      country: "Country",
      headline: "Process registrations",
      publicOrderItemEdit: "Edit Registration",
      orderItemNumber: "Registration number",
      firstName: "FirstName",
      lastName: "LastName",
      email: "email",
      competition: "Competition",
      divisions: "Divisions",
      division: "Division",
      competitionClass: "Competition Class",
      factor: "Factor",

      squad: "Squad",
      errorMessage:
        "Error loading Registration. Processing period has already expired."
    },
    manage: {
      name: "Administration"
    },
    competitionList: {
      name: "Registrations",
      competitionName: "Competitions",
      code: "Code",
      used: "Used",
      createdAt: "Creation Date"
    },
    orderItemList: {
      title: "Logins",
      cancel: "Login active, log out?",
      uncancel: "Unsubscribed, unsubscribe?",
      ORDER_SUBMITTED: "Subscription created"
    },
    uploadList: {
      title: "Downloads"
    },
    orderList: {
      country: "Land",
      name: "Invoice",
      titleClubAdmin: "All invoices for Club competitions",
      titleAdmin: "All Invoices",
      id: "#",
      firstName: "FirstName",
      lastName: "LastName",
      email: "email",
      payButton: "payment status",
      edit: "Edit",
      selectedClubName: "Club",
      cancelButton: "",
      openDetailsButton: " ",
      competitionName: "Competition",
      competitionCompetitionDate: "competition date",
      clubName: "Club",
      paid: "Payment status",
      change: "change",
      changeHeader: "change place",
      changeOrderItemSquad: "Swap squad",
      setToPaid: "Paid: mark as open?",
      setToNotpaid: "Open: Mark as paid?",
      paymentMethod: "PaymentMethod",
      squadName: "Squad",
      timeZonedOrderCreateDate: "Registered on",
      divisionName: "Division",
      competitionClassName: "Class",
      timeZonedCancelledDate: "Canceled on",
      isRegistered: "Registration status",
      waitingList: "S/W",
      userId: "User",
      payOrderPopup: {
        title: "Confirm",
        confirm: "Confirm",
        cancel: "Cancel",
        warning:
          "Are you sure you want to set all registrations in the order to paid?"
      },
      cancelOrderPopup: {
        title: "Confirm",
        confirm: "Confirm",
        cancel: "Cancel",
        warning: "Do you really want to cancel all registrations in the order?"
      },
      cancelOrderItemPopup: {
        title: "Cancellation",
        confirm: "Confirm",
        cancel: "Cancel",
        warning: "Do you really want to deregister the shooter?"
      },
      shooterCancelOrderItemPopup: {
        title: "Cancellation",
        confirm: "Confirm",
        cancel: "Cancel",
        warning: "Are you sure you want to deregister?"
      },
      unCancelOrderItemPopup: {
        title: "Undo cancellation?",
        warning: "Do you want to undo the registration?"
      },
      payOrderItemPopup: {
        title: "Pay",
        confirm: "Yes",
        cancel: "No",
        warning: "Are you sure?"
      },
      unPayOrderItemPopup: {
        title: "Undo payment?"
      }
    },
    dashboard: {
      name: "Start"
    },
    userRegister: {
      name: "New shooter"
    },
    reservationCodes: {
      name: "Create Reservation Codes",
      titleAdmin: "All reservation codes",
      titleClubAdmin: "All reservation codes for Club competitions",
      submit: "Create codes",
      amount: "Amount",
      competition: "Competition",
      generating: "The codes are generated!",
      reservationSuccess: "The codes were generated successfully",
      create: "Create new reservation codes"
    },
    reservationCodesList: {
      name: "Reservation Codes",
      headline: "Reservation Codes List",
      submit: "create codes",
      amount: "amount",
      competition: "Competition",
      generate: "Generate new code"
    },
    profile: {
      editProfile: "Edit user profile",
      tabs: {
        notificationSelection: "notifications",
        userInformation: "Profile data",
        userContactData: "Contact data",
        shooterData: "Shooter data",
        reservations: "My reservations"
      },
      reservations: {
        maxReservations: "number of reservations",
        qualification: "Qualification",
        validUntil: "Valid until",
        compClasses: "Competition classes",
        factors: "Factors",
        divisions: "divisions",
        competitions: "Competitions",
        reservation: "Reservation"
      },
      fields: {
        firstName: "FirstName",
        lastName: "LastName",
        emial: "Email",
        notificationSelection: {
          newShooterRegistrations: "New shooter registration",
          newRegistrationsToCompetitions:
            "Registration to one of my competitions",
          registrationChangeRegistrationSuccess:
            "Registration to an competition successful",
          registrationChangePaymentReceived:
            "Payment received for an Registration",
          registrationChangeRegistrationCancelledByAdministrator:
            "Registration cancelled by Club admin",
          registrationChangeRegistrationCancelledByShooter:
            "Registration withdrawn by user",
          registrationChangeRegistrationCancelledNoPayment:
            "Registration cancelled due to non-payment of entry fee",
          competitionStartReminder: "Reminder: competition will start soon",
          competitionPaymentReminder: "Reminder: pending payment",
          memberCancelNoPaymentReminder:
            "Upcoming cancellation due to missing payment",
          memberCancelNoPayment: "Unsubscribe due to missing payment",
          gettingStartingPlace: "Getting starting place",
          newCompetitionCreated:
            "New competition of my weapon class has been created",
          signupPhaseOfFavorizedCompetitionStarted:
            "Reminder: registration phase of my favorite competition starts",
          signupPhaseEndsReminder: "Signup phase ended",
          squadPlaceFree: "A place has become free in a squad"
        }
      }
    },
    client: {
      name: "Client"
    },
    clients: {
      name: "Client",
      fields: {
        name: "Name",
        active: "Active",
        id: "ID"
      }
    },
    unpaidUsers: {
      blockDialog: {
        header: "Block all non-paid users?",
        reallyBlock: "Do you really want to block all non-paid users?",
        cancel: "Cancel",
        doBlock: "Do block now"
      },
      blockButton: "Block unpaid?"
    },
    users: {
      listPage: {
        headline: "All users"
      },
      createPage: {
        headline: "Create User"
      },
      editPage: {
        headline: "Edit User"
      },
      showPage: {
        headline: "User Information"
      },
      confirmPasswordReset: {
        headline: "Reset password",
        resetSuccess:
          "Your password has been successfully reset. You will receive an email with your new password shortly.",
        resetError: "An error has occurred.",
        goToLogin: "Go to login."
      },
      resetPasswordHeadline: "Reset password.",
      resetPasswordFormFinished:
        "You will receive an email with all further information shortly.",
      resetPasswordForm: {
        email: "email",
        reset: "Reset Password"
      },
      name: "user",
      deleteButton: "delete item",
      deleteDialog: {
        header: "Delete this user?",
        reallyDelete:
          "Do you really want to delete this user? This action cannot be undone!",
        cancel: "Cancel",
        doDelete: "Delete now"
      },
      blockButton: {
        block: "Active: Lock?",
        unblock: "Locked: Unlock?"
      },
      verifyButton: {
        verify: "Unconfirmed: Confirm user?",
        unVerify: "Confirmed: Unconfirm?"
      },
      blockDialog: {
        header: "Block this user?",
        headerUnblock: "Unlock this user?",
        reallyBlock: "Do you really want to lock this user?",
        reallyUnblock: "Do you really want to unlock this user?",
        cancel: "Cancel",
        doBlock: "Lock now",
        doUnblock: "Unlock now"
      },
      verifyDialog: {
        header: "Confirm this user?",
        headerUnverify: "Unverify this user?",
        really: "Do you really want to confirm this user?",
        reallyUnverify: "Do you really want to unconfirm for this user?",
        cancel: "Cancel",
        doVerify: "Confirm now",
        doUnverify: "Unverify now."
      },
      fields: {
        isActive: "User is active",
        isBlocked: "User is blocked",
        isEnabled: "User is enabled",
        isDisabled: "User is disabled",
        club: {
          id: "club",
          name: "club"
        },
        email: "email",
        userRole: "UserRole",
        userRoleIds: "user role",
        permissions: "Permission",
        password: "Password",
        password2: "repeat password",
        userRolePk: "user role",
        userRoles: "user role",
        clientPk: "client",
        firstName: "FirstName",
        lastName: "LastName",
        username: "User Name",
        createdAt: "Member since",
        active: "Active",
        client: {
          name: "client",
          id: "Client"
        },
        notPaid: "Unpaid only",
        paymentUntil: "Payment status - Paid until"
      },
      registerForm: {
        header: "Register as a shooter",
        register: "complete registration",
        back: "back",
        next: "next",
        formTabs: {
          userInformation: "profile data",
          userContactData: "contact data",
          shooterData: "shooter data"
        },
        fields: {
          username: "user name",
          salutation: "salutation",
          salutations: {
            mr: "Mr",
            mrs: "Mrs"
          },
          selectedPaymentMethod: "payment method",
          degree: "title",
          firstName: "FirstName",
          lastName: "LastName",
          email: "email",
          password: "Password",
          passwordValidate: "Repeat password",
          selectedLanguage: "Language",
          languages: {
            de: "German",
            en: "English"
          },
          countries: {
            germany: "Germany",
            austria: "Austria"
          },
          address: "Address",
          zip: "Zip Code",
          city: "City",
          state: "State",
          country: "Country",
          telephone: "telephone number",
          competitionClass: "Class",
          competitionDivision: "division",
          competitionDivisionNotification:
            "Notify me of new matches in the following divisions",
          competitionFactor: "factor",
          club: "Club",
          alias: "clubAlias",
          cowboyName: "cowboy name",
          sassNumber: "SASS number",
          ipscNumber: "IPSC number",
          ipscRegion: "IPSC Region",
          ipscAlias: "IPSC Alias",
          allowInformations:
            "Consent to receive reminder and information emails",
          conditions: "I have read and accepted the terms and conditions",
          payment: "I want to register with costs",
          competitionViewType: "Display competitions in list view",
          shooterCompetitionViewType: "Display my competitions in list view"
        }
      }
    },
    userRoles: {
      listPage: { headline: "Alle Benutzerrollen" },
      creatPage: { headline: "Benutzerrole erstellen" },
      editPage: { headline: "Benutzerrole bearbeiten" },
      showPage: { headline: "Benutzerrolinformation" },
      tabs: {
        userRoleData: "Benutzerrolle",
        userRolePermissions: "Berechtigungen",
        permissions: "Berechtigungen"
      },
      fields: {
        ROLE_USER: "Schütze",
        permissions: "Berechtigungen",
        clientPk: "Mandant",
        name: "Benutzerrollen",
        ROLE_ADMIN: "System Administrator",
        client: {
          name: "Mandant",
          id: "Mandant"
        }
      },
      name: "Benutzerrollen",
      list: "Benutzerrollen"
    },
    factors: {
      name: "Faktoren",
      listPage: { headline: "Alle Faktoren" },
      createPage: { headline: "Faktor erstellen" },
      editPage: { headline: "Faktor bearbeiten" }
    },
    competitionClasses: {
      name: "Klassen",
      listPage: {
        headline: "Alle Klassen"
      },
      createPage: {
        headline: "Klasse erstellen"
      },
      editPage: {
        headline: "Klasse bearbeiten"
      }
    },
    divisions: {
      name: "Divisionen",
      listPage: {
        headline: "Alle Divisionen"
      },
      createPage: {
        headline: "Division erstellen"
      },
      editPage: {
        headline: "Division bearbeiten"
      },
      fields: {
        competitionType_ids: "Bewerbstypen"
      }
    },
    levels: {
      name: "Levels",
      listPage: {
        headline: "Alle Levels"
      },
      createPage: {
        headline: "Level erstellen"
      },
      editPage: {
        headline: "Level bearbeiten"
      }
    },
    competitionTypes: {
      name: "Bewerbstypen",
      listPage: {
        headline: "Alle Bewerbstypen"
      },
      createPage: {
        headline: "Bewerbstype erstellen"
      },
      editPage: {
        headline: "Bewerbstype bearbeiten"
      }
    },
    topics: {
      name: "Themengebiete"
    },
    uploadTypes: {
      fields: {
        sortingOrder: "Sortierungspriorität"
      }
    },
    clubProfile: {
      fields: {
        name: "name",
        longName: "Club name (long)",
        address: "Address",
        city: "City",
        zip: "Postal code",
        country: "Country",
        googleMapsLink: "google maps link",
        publicLink: "public link",
        websiteLink: "Club website",
        matchAddress: "match address street",
        matchCity: "Match address city",
        matchCountry: "Match address country",
        matchZip: "match address postal code",
        logo: "club logo",
        contact: {
          firstName: "FirstName",
          lastName: "LastName",
          email: "email",
          telephone: "Phone",
          fullName: "Contact PAYMENTMETHOD_INVALID_FOR_CARTerson"
        }
      }
    },
    clubs: {
      name: "clubs",
      tabs: {
        notificationBlocks: "information blocks",
        club: "Club information"
      },
      publicShow: {
        NO_DATA_AVAILABLE: "We are looking for the requested data.",
        competitions: "Club competitions",
        name: "Name",
        stages: "Stages",
        competitionDate: "date",
        publicLink: "link",
        favourite: "Favourite",
        competitionTypeName: "CompetitionType",
        toCompetitionButton: "",
        toTheCompetition: "To the competition",
        toTheEvent: "To the event",
        events: "Club events",
        eventType: "Type of event",
        archive: "Archive"
      },
      listPage: {
        headline: "All Clubs",
        btnClub: "TO THE CLUB",
        filters: {
          paymentUntilFrom: "Paid By",
          paymentUntil: "Paid To"
        }
      },
      createPage: {
        headline: "Verein erstellen"
      },
      editPage: {
        headline: "Verein bearbeiten"
      },
      showPage: {
        websiteLink: "link to association website",
        googleMapsLink: "Google Maps",
        contactTelephone: "Contact person phone",
        contactInfosHeadline: "Contact information",
        address: "Club address:",
        matchaddress: "matchaddress:",
        contact: "contact:",
        contactEmail: "Email:",
        bankAccountInfosHeadline: "Bank details",
        bank: {
          information: "Bank information",
          name: "Name",
          bic: "BIC",
          iban: "IBAN"
        },
        goToWebsite: "TO ASSOCIATION WEBSITE"
      },
      blockDialog: {
        header: "Block this club?",
        headerUnblock: "Unblock this club?",
        reallyBlock: "Do you really want to block this club?",
        reallyUnblock: "Do you really want to unblock this club?",
        cancel: "Cancel",
        doBlock: "Lock now",
        doUnblock: "Unblock now"
      },
      fields: {
        noPayment: "No payment received so far",
        unlisted: "Unlisted club",
        paymentExpired: "Payment expired",
        validPayment: "Payment has been received",
        isBlocked: "Club is blocked",
        isActive: "Club is active",
        clubAdmins: "club admins",
        contactHeader: "Contact person",
        clubHeader: "club information",
        matchHeader: "competition information",
        paymentHeader: "payment status",
        paymentUntil: "Payment status - Paid until",
        name: "Club name",
        longName: "Club name (long)",
        address: "Address",
        active: "Active",
        city: "City",
        zip: "ZIP Code",
        country: "Country",
        matchAddress: "match address street",
        matchCity: "match address city",
        matchCountry: "match address country",
        matchZip: "match address zip code",
        googleMapsLink: "Google Maps Link",
        logo: "club logo",
        websiteLink: "club website",
        contact: {
          firstName: "FirstName",
          lastName: "LastName",
          email: "email",
          telephone: "Phone",
          fullName: "contact person"
        }
      }
    },
    clubList: {
      name: "Clubs"
    },
    name: "Competition",
    maintenanceWindows: {
      name: "Maintenance Windows",
      cancel: "Cancel",
      clear: "Clear",
      fields: {
        reason: "Reason",
        message: "Message",
        active: "Active",
        toDate: "until",
        fromDate: "from"
      }
    },
    competitions: {
      listFilter: {
        year: "year",
        month: "month",
        country: "country",
        zip: "zip code",
        level: "Level",
        matchType: "Match Type",
        club: "Club",
        clearFilters: "clear filter",
        events: "Special events",
        paidFilter: "Payment pending"
      },
      listPage: {
        event: "Event",
        headline: "Current competitions",
        buttonDetails: "to the competition",
        eventButtonDetails: "to the event",
        resultList: "To the result list",
        downloadCompetitionList: "download list",
        competitionTypeName: "competition type",
        rowsPerPage: "rows per page",
        division: "Division",
        squad: "Squad",
        startDateTime: "start time",
        of: "from",
        filters: {
          dateFrom: "From",
          dateTo: "To",
          club: "Club"
        },
        matchDate: "Match Date",
        squadDate: "Squad Date",
        region: "Region",
        metchPlace: "Location",
        club: "Club",
        matchName: "Match Name",
        level: "Level",
        matchType: "Match Type",
        stages: "Stages",
        registrationStartDate: "Registration from",
        freeSlots: "Free slots",
        actions: "Actions",
        details: "Details",
        register: "Registration",
        listView: "List View",
        listViewGuest: "List View (only available for registered shooters)"
      },
      editPage: {
        headline: "Edit competitions",
        competitionDateChanged: "Start date changed...",
        updateAllDates:
          "Should the match end, registration period and squad start date be updated accordingly?"
      },
      createPage: {
        headline: "Create competition"
      },
      resultPage: {
        headline: "Results list",
        upcomingCompetitions: "To the upcoming competitions",
        clearFilters: "Clear Filter"
      },
      name: "Competitions",
      uploadTypes: "Downloads",
      cancelButton: {
        cancel: "Active: Cancel?",
        unCancel: "Canceled: Reactivate?"
      },
      cancelDialog: {
        header: "Cancel competition",
        undoHeader: "Cancel cancellation",
        reallyCancel: "Do you really want to cancel the Registration?",
        reallyUnCancel: "Do you really want to cancel the cancellation'?",
        cancel: "Cancel",
        doCancel: "Cancel now",
        doUnCancel: "Cancel cancellation"
      },
      fields: {
        paid: "Paid",
        tabs: {
          notificationBlocks: "Information blocks",
          competition: "competition information"
        },
        notificationBlocks: {
          COMPETITION_SIGN_ON_CONFIRMATION: "Registration confirmation",
          COMPETITION_WAITING_LIST_CHANGE_INFORMATION:
            "change of a waiting list place",
          COMPEITION_UPCOMING_INFORMATION: "Upcoming competition",
          COMPETITION_SIGN_OFF_CONFIRMATION:
            "Deregistration from an competition",
          COMPETITION_CANCELATION_CONFIRMATION:
            "Cancellation of an Registration",
          COMPETITION_PAYMENT_RECEIVED: "Payment has been received"
        },
        club: {
          name: "Club",
          id: "club"
        },
        type: {
          name: "type"
        },
        nominationFee: "Nomination fee",
        competitionDate: "competition date",
        date: "start date",
        endDate: "End date",
        competitionHeader: "Competition creation",
        information: "Information",
        level: { id: "Level", name: "Level" },
        stages: "Stages",
        announcementLink: "announcement LINK",
        clubWebsiteLink: "clubWebsite",
        address: "address",
        city: "city",
        zip: "postal code",
        googleMaps: "Google Maps Link",
        registrationStart: "registration start",
        registrationEnd: "registration end",
        competitionTypeName: "competition type",
        minimumShots: "minimum number of shots",
        feeStatus: "payment status",
        publicLink: "Public link",
        division_ids: "Division",
        factor_ids: "Factor",
        competitionClass_ids: "Competition class",
        competitionType_ids: "Competition Type",
        squadHeader: "Squads",
        squadInformationDescription: "SquadInformation",
        signupForm: {
          signup: "Sign up now",
          header: "Signup",
          waitingListSignup: "Signup on waiting list",
          waitingListOnly:
            "Registration is currently only available for a waitlist spot.",
          gotWaitingList: "Waiting list place received!",
          gotStartPlace: "Start place received!",
          timerPrefix: "A Place is still",
          timerSuffix: " seconds reserved for you.",
          timerInfo: "Complete the registration quickly to keep it."
        },
        competitionTypeId: "Competition Type",
        contactHeader: "contact person",
        contact: {
          firstName: "FirstName",
          lastName: "LastName",
          email: "email",
          telephone: "Phone"
        },
        addressHeader: "Location",
        country: "Country",
        googleMapsLink: "Google Maps link",
        dateHeader: "Date information",
        additionalInfo: "Additional information",
        multipleRegistrations: "Multiple registrations allowed",
        paymentViaDeposit: "Payment by wire transfer allowed",
        payWithWirecard: "Payment by wirecard possible",
        nationalCompetition: "National or state championship",
        firstPaymentReminder: "Send first payment reminder",
        secondPaymentReminder: "Send second payment reminder",
        autoSubscriptionCancelation:
          "Automatically unsubscribe subscriber if payment is not received",
        payOnTime: "Payment deadline",
        payOnSite: "Payment possible on site",
        qualification: "qualification competition",
        state: "State",
        unlisted: "Unlisted competition",
        active: "Active",
        NewCompetitionMailSent: "New competition mail already sent",
        viewType: {
          label: "ViewType",
          all: "Show competition everywhere",
          internal: "Show competition only internally",
          external: "Show competition only externally",
          main: "show competition only in main competition list",
          unlisted: "unlisted competition"
        }
      }
    },
    squads: {
      copySquad: "Copy Squad",
      fields: {
        name: "Name",
        information: "Information",
        squadSize: "number of possible participants",
        maxWaitingListSize: "Number of possible waiting list places",
        startDate: "start date",
        startTime: "start time",
        endTime: "end time",
        resultListLink: "resultListLink",
        requireCode: "reservation code only"
      }
    },
    competitionspub: {
      name: "competions",
      signupForm: {
        signup: "Apply now",
        header: "Competition",
        reservationCodeSignup:
          "Registration for this competition is not open yet!",
        reservationCodeOnly:
          "To pre-register, please enter the code you received from the club admin",
        nationalReservationCodeOnly:
          "Please use the code you have received to sign up.",
        nationalReservationCodeSignup:
          "This competition is a national or state championship and therefore the registration is only possible with a code from the national or state sports director!",
        squadReservationCodeOnly:
          "Registration is only possible with a registration code",
        squadReservationCodeSignup: "This is a squad locked by the admin",
        waitingListSignup: "Sign up for waiting list now",
        waitingListOnly: "Only waiting list seats available!",
        squad: "Squad",
        firstName: "First name",
        lastName: "Last name",
        email: "email address",
        registrationCode: "Reservation code",
        nationalRegistrationCode: "code",
        divisions: "Division",
        factors: "Factor",
        classes: "Competition class",
        squadFull: "Selected squad already full.",
        waitingList: "Registration on waiting list for multiple squads",
        waitingListForSquads: "Waiting list for multiple squads",
        club: "club",
        country: "country",
        state: "State",
        paymentMethod: "payment method",
        cash: "cash payment",
        invoice: "Bank transfer",
        user: "shooter"
      }
    },
    competitionshow: {
      freePlaces: "Free Participants",
      downloads: "Downloads",
      noPermitionToSignUp: "You are not authorized to register!",
      competitionRegistrationEnded: "Registration period has already expired.",
      competitionRegistrationEndedInfo:
        "Please contact the club admin for changes to your registration.",
      signUp: "Registrate",
      information: "Description:",
      details: "Details:",
      links: "Links",
      competitionFull: "No free places available in the competition.",
      competitionCancelled: "The competition was cancelled",
      competitionInactive: "The competition was cancelled",
      squadFull: "No free paces available in the squad.",
      detailsTable: {
        clubName: "Club:",
        type: "Typ:",
        level: "Level:",
        stages: "Number of Stages:",
        fee: "Entry Fee:",
        contactPerson: "Club Admin:",
        location: "Shooting Range:",
        googleMapsLink: "Google Maps Link",
        divisionen: "Divisionen:",
        classes: "Classes:",
        factors: "Factor:",
        minimumShots: "Rounds:",
        registrationStart: "Registration from:",
        registrationEnd: "Registration until:",
        feeStatus: "Fee Status:",
        bankAccountInfos: "Bank account:",
        bic: "BIC:",
        iban: "IBAN:",
        freePlaces: "Free Places:",
        eventType: "Competition Typ",
        freeWaitingListPlaces: "Free Waiting List Places:"
      },
      announcementLink: "Announcement-LINK",
      clubWebsiteLink: "Club Website",
      participants: "Participants",
      participantsName: "Name",
      participantsSquad: "Squad",
      participantsClass: "Class",
      participantsDivision: "Division",
      squads: "Squads",
      squad: {
        noPermitionToSignUp: "You are not authorized to register!",
        signUpTo: "Register to",
        signUpToOver: "Registration period has already expired.",
        id: "Squad Nr.",
        annotations: "Informationen",
        startDate: "Starting Date",
        startTime: "Starting Time",
        freePlaces: "Free Places",
        squadSize: "Squad",
        squadMember: "Participants:",
        squadMemberName: "Name",
        squadMemberDivision: "Division",
        squadMemberClass: "Class",
        squadMemberKey: "#",
        freePlacesInSquads: "Free Places",
        waitingList: "Waiting List",
        waitingListOnly: "Only waiting list places left!",
        full: "Squad already full"
      },
      resultListLink: "ONLINE-RESULT LISTS"
    },
    reservations: {
      fields: {
        user_ids: "user",
        competition_ids: "competitions",
        division_ids: "divisions",
        competitionClass_ids: "competition classes",
        factor_ids: "factors",
        qualification: "Qualification competition",
        maxReservations: "Max. reservations",
        validUntil: "Valid until:"
      },
      createReservation: "Create reservation",
      editReservation: "Edit reservation",
      listReservation: "Reservations",
      showReservation: "Reservation"
    },
    misc: {
      tabs: {
        userRoleData: "Benutzerrolle",
        userRolePermissions: "Berechtigungen"
      },
      userRoleCategories: {
        cat_ADMINISTRATION: "Administration",
        cat_OTHER: "Andere Berechtigungen",
        cat_COMPETITIONS: "Competitions",
        cat_USER: "User",
        cat_: "View User",
        cat_CLUB: "Club"
      }
    },
    events: {
      createPage: {
        headline: "Create Event"
      },
      editPage: {
        headline: "Edit Event"
      },
      fields: {
        firstPaymentReminder: "Send first payment reminder",
        secondPaymentReminder: "Send second payment reminder",
        autoSubscriptionCancelation:
          "Automatically unsubscribe subscriber if payment is not received",
        tabs: {
          notificationBlocks: "Information blocks",
          competition: "Competition Information"
        },
        notificationBlocks: {
          COMPETITION_SIGN_ON_CONFIRMATION: "Registration confirmation",
          COMPETITION_WAITING_LIST_CHANGE_INFORMATION:
            "change of a waiting list place",
          COMPEITION_UPCOMING_INFORMATION: "Upcoming competition",
          COMPETITION_SIGN_OFF_CONFIRMATION:
            "Deregistration from an competition",
          COMPETITION_CANCELATION_CONFIRMATION:
            "Cancellation of an Registration",
          COMPETITION_PAYMENT_RECEIVED: "Payment has been received"
        },
        type: {
          name: "Typ"
        },
        club: "Club",
        eventType: "Competition Typ",
        nominationFee: "Nomination Fee",
        competitionDate: "Date",
        competitionHeader: "Bewerbserstellung",
        date: "Date",
        information: "Information",
        level: { id: "Level", name: "Level" },
        stages: "Stages",
        announcementLink: "Announcement-LINK",
        clubWebsiteLink: "Club Website",
        address: "Address",
        city: "State",
        zip: "Zip Code",
        googleMaps: "Google Maps Link",
        registrationStart: "Registration Start",
        registrationEnd: "Registration End",
        competitionTypeName: "Competition Typ",
        minimumShots: "Rounds",
        feeStatus: "Payment Status",
        publicLink: "Public Link",
        division_ids: "Division",
        factor_ids: "Factor",
        competitionClass_ids: "Class",
        competitionType_ids: "Competition Type",
        squadHeader: "Squads",
        squadInformationDescription: "Squad information",
        signupForm: {
          signup: "Register now",
          header: "Registration",
          waitingListSignup: "Registration to Waiting List",
          waitingListOnly:
            "Registration is currently only possible for a place on the waiting list."
        },
        competitionTypeId: "Competition Typ",
        contactHeader: "Club Admin",
        contact: {
          firstName: "FirstName",
          lastName: "LastName",
          email: "Email",
          telephone: "Telephone"
        },
        addressHeader: "Shooting Range",
        country: "Country",
        googleMapsLink: "Google Maps-Link",
        dateHeader: "Date Information",
        additionalInfo: "Information",
        multipleRegistrations: "Multiple registrations allowed",
        paymentViaDeposit: "Payment by bank transfer possible",
        paymentWithWirecard: "Payment by Wirecard possible",
        nationalCompetition: "Country or state championship",
        payOnTime: "Payment deadline",
        payOnSite: "payment on site possible",
        qualification: "qualifying competition",
        state: "State",
        unlisted: "Unlisted competition",
        active: "Active",
        eventName: "Event name"
      }
    }
  },
  competitionList: {
    cancelled: "",
    name: "Competition name",
    clubName: "organizer",
    city: "City",
    address: "address",
    competitionDate: "event date",
    levelName: "event level",
    stages: "event day",
    competitionTypeName: "event type",
    country: "region",
    menu: "",
    openDetailsButton: " ",
    titleClubAdmin: "Club competitions",
    titleAdmin: "All competitions",
    m: {
      cancelled: "Info:",
      name: "Competition name:",
      clubName: "Organizer:",
      city: "City:",
      address: "Address:",
      competitionDate: "Event date:",
      levelName: "Event level:",
      stages: "Event day:",
      competitionTypeName: "Event type:",
      openDetailsButton: " ",
      menu: "menu"
    },
    filters: {
      openFilter: "Filter competitons",
      filtersActive: "Filter active",
      competitionName: "Competition name",
      year: "year",
      month: "month",
      region: "Region",
      clubName: "Club",
      level: "Level",
      competitionType: "event type"
    }
  },
  eventList: {
    titleClubAdmin: "Club Events",
    titleAdmin: "All Events"
  },
  clubList: {
    cancelled: "info",
    name: "organizer",
    publicLinkId: "Public link",
    zip: "Postal Code",
    contactFullName: "Contact",
    actions: "",
    city: "City",
    address: "address",
    competitionDate: "event date",
    level: "Event level",
    stages: "event day",
    competitionTypeName: "event type",
    openDetailsButton: "",
    imagePre: "Logo",
    m: {
      cancelled: "info:",
      name: "organizer:",
      publicLinkId: "link:",
      zip: "Postcode:",
      contactFullName: "Contact:",
      actions: "",
      city: "City:",
      address: "Address:",
      competitionDate: "Event date:",
      level: "Event level:",
      stages: "Event days:",
      competitionTypeName: "event type:",
      openDetailsButton: "",
      imagePre: "logo"
    }
  },
  shooterCompetitionList: {
    headerBookedCompetitions: "My Competitions",
    headerParticipatedCompetitions: "Participated Competitions",
    waitingList: "Waiting List",
    downloadCompetitions: "Download List"
  },
  eventCard: {
    registrationStart: "Registration Start",
    freePlaces: "Free Places"
  },
  bulkDeleteWarningPopup: {
    title: "Delete selected lines?"
  },
  deleteWarningPopup: {
    title: "Do you really want to delete the current item?"
  },
  deleteCompetitionWarningPopup: {
    title: "Do you really want to delete the current competition?"
  },
  changelog: {
    title: "Entwicklungslog",
    features: "Änderungen/Features",
    fixes: "Bugfixes"
  },
  CONFIRM_REGISTRATION: {
    VERIFY_SUCCESS:
      "Your registration has been successfully confirmed! You can log in now",
    GO_TO_LOGIN: "Log in now",
    VERIFYING: "Registration is confirmed...",
    PAYMENT_INFORMATION:
      "For your benefits as a registered shooter and payment modalities please see"
  },
  ERROR: {
    FIELD_MUST_HAVE_AT_LEAST_2_CHARACTERS:
      "Field must have at least 2 characters",
    NO_RESERVATION_LIMIT: "Maximum number of reservations is missing",
    NO_VALID_UNTIL: "Validity date is missing",
    PAYMENTMETHOD_INVALID_FOR_CART:
      "Registration failed. Payment method of the competition does not match the payment method of the registrations in the cart.",
    PAYMENTMETHOD_MISSING: "No payment method selected.",
    CODE_USED: "Reservation code already used up.",
    USED_CODE: "Reservation code already consumed.",
    INVALID_CODE: "Reservation code invalid.",
    NO_USER: "User was not found.",
    NO_EMAIL_ADDRESS: "E-mail was not found.",
    Unauthorized: "User name or password invalid.",
    Inactive_user: "User is locked.",
    Not_verified: "User mail not yet verified.",
    CLIENT_DOES_NOT_EXIST: "Client does not exist",
    PERMISSION_DENIED: "You are not authorized for this action.",
    CLIENT_ALREADY_EXISTS: "Client already exists",
    INVALID_USERROLE: "Permission denied",
    USER_ALREADY_EXISTS: "A user with this username already exists.",
    USER_DOES_NOT_EXIST: "The specified user does not exist",
    EMAIL_ALREADY_EXISTS: "A user with this email address already exists.",
    USERPERMISSION_DOES_NOT_EXIST: "The specified permission does not exist",
    USERROLE_DOES_NOT_EXIST: "The specified role does not exist",
    USERROLE_ALREADY_EXISTS: "The specified role already exists",
    USERROLE_NAME_NOT_VALID: "The specified name is not valid",
    PERMISSION_DENIED_ROLE_NAME_RESERVED: "The specified name is not valid",
    PERMISSION_DENIED_ROLE_ADMIN_NOT_EDITABLE: "This role is not editable",
    PERMISSION_DENIED_ROLE_GLOBAL_NOT_DELETEABLE: "This role cannot be deleted",
    PERMISSION_DENIED_ROLE_ADMIN_NOT_DELETEABLE: "This role cannot be deleted",
    PERMISSION_DENIED_ROLE_CLIENT_GLOBAL_NOT_DELETEABLE_BY_USER:
      "This role cannot be deleted",
    PERMISSION_DENIED_ROLE_NOT_DELETABLE: "This role cannot be deleted",
    CLUB_DOES_NOT_EXIST: "The specified club does not exist",
    USER_SELF_DELETE: "This user cannot be deleted",
    CLUB_NAME_DOES_NOT_EXIST: "Club name not specified.",
    ERROR: "An error has occurred.",
    COMPETITONCLASS_MISSING: "Class not selected.",
    FACTOR_MISSING: "Factor not selected",
    DIVISION_MISSING: "Division not selected",
    SQUAD_FULL:
      "The selected squad is already full, so registration is not possible! Please select another squad",
    SQUAD_ONLY_WAITINGLIST_SPOTS:
      "Selected squad has only more waitlist spots available",
    SQUAD_MISSING: "Squad not selected",
    COMPETITIONCLASS_MISSING: "Competition class not selected",
    COMPETITION_MISSING: "Competition not selected",
    COMPETITION_DOESNT_EXIST: "Competition does not exist",
    CLUB_MISSING: "Club not selected",
    SHOOTERROLE_NOT_FOUND: "Role shooter not defined",
    EMAIL_FORMAT: "No valid email address",
    FIELD_IS_REQUIRED: "A mandatory input is missing ",
    FIELD_IS_NULL: "A mandatory input is missing",
    USERNAME_FIELD_MUST_HAVE_AT_LEAST_3_CHARACTERS:
      "The username should have at least 3 characters",
    PW_FIELD_MUST_HAVE_AT_LEAST_6_CHARACTERS:
      "The password must have at least 6 characters",
    FIRST_NAME_FIELD_MUST_HAVE_AT_LEAST_2_CHARACTERS:
      "The FirstName must be at least 2 characters long",
    LAST_NAME_FIELD_MUST_HAVE_AT_LEAST_2_CHARACTERS:
      "LastName must be at least 2 characters long",
    REGISTRATION_ALREADY_STARTED:
      "The registration window has already started. Really perform cancellation?",
    SELECT_COMPETITION_TYPE: "Select competition type first.",
    NO_SQUAD_DEFINED: "No squad specified.",
    CHECK_REGISTRATION_DATE: "Please check your date information.",
    SQUAD_COULD_NOT_BE_DELETED_IN_USE:
      "Squad cannot be removed as it is in use",
    SQUAD_COULD_NOT_BE_DELETED: "Squad cannot be removed since it is in use",
    ACCESS_ONLY_SHOOTER: "This view is only available to registered shooters.",
    ACCESS_DENIED:
      "This view is not available to you. Please contact an administrator if you have any questions.",
    PREPARED_ORDER_NOT_FOUND:
      "Your reserved start place has been released again due to too long waiting time.\nPlease close this window and log in again!",
    ORDER_ITEM_NOT_FOUND: "Error loading login",
    FILETYPE_NOT_ALLOWED:
      "File type is not supported. Please use .png, .jpg or .jpeg",
    MULTIPLE_REGISTRATION:
      "Multiple registrations for this competition are not allowed",
    MULTIPLE_REGISTRATION_NOT_PAID:
      "Multiple registration is only available for paid registered shooters",
    PREMIUM_FEATURE:
      "Multiple registration is only allowed for registered paid shooters.",
    TOO_EARLY_TO_DELETE:
      "Registrations for this user are still open or recently closed"
  },
  countries: {
    at: "Austria",
    en: "Germany"
  },
  ROLE_ADMIN: "System Administrator",
  ROLE_CLIENT_ADMIN: "Client Administrator",
  PAYMENTMETHOD_REQUIRED: "Payment method required.",
  IS_ADMIN: "Is Administrator.",
  IS_CLIENT_ADMIN: "Is client administrator",
  IS_CLUB_ADMIN: "Is club admin",
  IS_PORTAL_ADMIN: "Is portal administrator",
  PUBLIC_COMPETITION_LIST_VIEW: "Can see public competition list",
  PUBLIC_COMPETITION_REGISTRATION: "Can register for public competitions",
  USER_PROFILE_VIEW: "Can see user profile",
  USER_PROFILE_EDIT: "Can edit user profile",
  USER_CREATE: "Can create user",
  USER_EDIT: "Can edit user",
  USER_DELETE: "Can delete user",
  USER_LIST: "Can view user list",
  USER_VIEW: "Can see users",
  CLUB_LIST: "Can see club list",
  CLUB_EDIT: "Can edit clubs",
  CLUB_VIEW: "Can see clubs",
  CLUB_CREATE: "Can create clubs",
  COMPETITION_CREATE: "Can create competitions",
  COMPETITION_EDIT: "Can edit competitions",
  COMPETITION_LIST: "Can see competition list",
  COMPETITION_VIEW: "Can see competitions",
  ROLE_USER: "Contactor",
  ROLE_GUEST: "Guest",
  ROLE_PORTAL_ADMIN: "Portal Admin",
  ROLE_SYSTEM_ADMIN: "System Admin",
  ROLE_CLUB_ADMIN: "Club Admin",
  IS_NOT_ACTIVE: "Locked",
  IS_NOT_ENABLED: "Not Verified",
  FIELD_IS_REQUIRED: "Mandatory field",
  PW_MUST_MATCH: "The passwords do not match",
  PW_MIN_6_MAX_128_1_UPPER_1_LOWER_1_NUMERIC:
    "Please enter at least 6 characters, including at least one uppercase letter, one lowercase letter and at least one digit.",
  USER_ALREADY_EXISTS: "A user with this username already exists.",
  EMAIL_ALREADY_EXISTS: "A user with this email address already exists.",
  VERIFY_ERROR: "Sorry, your registration could not be confirmed.",
  NO_SQUAD_DEFINED: "No squad specified.",
  FIELD_IS_REQUIRED_IF_BANK_NAME_IS_FILLED:
    "The field is required if the bank name is filled in.",
  FIELD_IS_REQUIRED_IF_BANK_IBAN_IS_FILLED:
    "The field is required if the IBAN is filled.",
  IS_CANCELLED: "cancelled.",
  NO_ITEMS_IN_CART: "You have no postings in the shopping cart.",
  SUCCESS_ORDER:
    "Your booking has just been made. You will receive a confirmation email shortly.",
  SUCCESS_ORDER_ITEM_EDIT: "The changes have been successfully saved.",
  SUCCESS_ORDER_ITEM_CANCEL:
    "You have successfully unsubscribed from the competition.",
  ONLY_ONE_COMPETITION_FOR_GUEST:
    "Guests can only complete one Registration at a time.",
  SUCCESS_PAY: "Registrations have been paid.",
  SUCCESS_UNPAY: "Registration was set to not paid.",
  SUCCESS_CANCEL: "Shooter has been deregistered.",
  SUCCESS_SHOOTER_CANCEL:
    "Your deregistration has been successfully completed.",
  SUCCESS_UNCANCEL: "Cancellation has been cancelled.",
  CODE_USED: "Reservation code already used up.",
  USED_CODE: "Reservation code already consumed.",
  INVALID_CODE: "Reservation code invalid.",
  NO_RESERVATION_LIMIT: "No maximum number of reservations",
  NO_VALID_UNTIL: "No validity period specified",
  ADDED_TO_CART: "Competition has been added to the shopping cart.",
  NO_DOWNLOADS_AVAILABLE: "No files available",
  MUST_START_WITH_HTTP_OR_HTTPS: "The link must start with http or https"
};
