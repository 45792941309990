import React, { Component } from "react";
import { Field } from "redux-form"; // ES6
import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
class SimplerFormIterator extends Component {
  state = {
    item: null,
    items: []
  };

  componentDidMount = () => {
    this.setState({ item: this.props.children }, () => {
      for (let i = 0; i < this.props.initalNumberOfItems; i++) {
        this.addNewItem();
      }
    });
  };

  addNewItem = () => {
    let items = this.state.items;
    items.push(
      React.cloneElement(this.state.item, {
        source: this.props.source + "[" + items.length + "]",
        key: this.props.source + "[" + items.length + "]",
        name: this.props.source + "[" + items.length + "]"
      })
    );
    this.setState({ items: items });
  };

  render = () => {
    return (
      this.state.item &&
      this.state.items.map((item, i) => {
        return item;
      })
    );
  };
}

SimplerFormIterator.propTypes = {
  initalNumberOfItems: PropTypes.number
};

SimplerFormIterator.defaultProps = {
  initalNumberOfItems: 1
};

export default SimplerFormIterator;
