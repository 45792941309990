import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "react-admin";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import { change, Field, Form, reduxForm } from "redux-form";
import { renderTextField } from "./inputs/renderMaterialFields";
import { required } from "./inputs/validator";
import restClient from "../providers/dataProvider/rest";
import { IconButton } from "@material-ui/core";
import CopyIcon from "@material-ui/icons/Dashboard";

const styles = {
  errorMsg: {
    color: "#CB5D58",
    marginTop: "10px"
  },
  msg: {
    marginTop: "10px"
  },
  dialogWrapper: {
    minWidth: "400px",
    paddingTop: "0px",
    paddingBottom: "5px"
  }
};

let CompetitionInvoicePopup = props => {
  const { handleClose, open, errorMessage, fullScreen, competition } = props;

  if (
    competition &&
    competition.nominationFee &&
    (!props.myForm || !props.myForm.values["gross"])
  ) {
    props.dispatch(
      change("competitionInvoiceForm", "gross", "" + competition.nominationFee)
    );
  }

  const download = () => {
    const data = { ...props.myForm.values };
    data.gross = parseFloat(data.gross.replace(/\./g, "").replace(",", "."));

    restClient(
      "CREATE_DOWNLOAD",
      "competition/" + props.competition.id + "/invoice",
      {
        data
      }
    ).then(response => {
      const filename = response.headers
        .get("Content-Disposition")
        .split("filename=")[1];
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = filename;
        a.click();
      });
    });
  };

  const name =
    competition && competition.club.invoiceName
      ? competition.club.invoiceName.split(" ")[0]
      : "[Name]";

  const text =
    "Lieber " +
    name +
    ",\n\n" +
    "im Anhang darf ich dir die Rechnungen für die comp|sign Benutzung " +
    "eurer Bewerbe übermitteln.\n\n" +
    "Ich bitte um Überweisung auf das in der Rechnung angeführte Konto.\n\n" +
    "Danke & lg. - Tuschi -\n" +
    "comp|sign";

  function copyToClipboard() {
    const text = document.getElementById("invoiceText");
    const blob = new Blob([text.innerHTML], { type: "text/html" });
    const item = new window.ClipboardItem({ "text/html": blob });
    navigator.clipboard.write([item]).then(() => {});
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Rechnung erstellen</DialogTitle>
      <DialogContent style={styles.dialogWrapper}>
        <Form onSubmit={download}>
          <h2>{props.translate("resources.clubs.tabs.invoice")}</h2>
          <Field
            name="number"
            id="number"
            component={renderTextField}
            validate={required}
            type="text"
            label="Rechnungsnummer"
            margin="dense"
            fullWidth
            autoFocus
          />
          <Field
            name="gross"
            id="gross"
            component={renderTextField}
            validate={required}
            type="text"
            label="Brutto"
            margin="dense"
            fullWidth
          />
        </Form>

        <div style={{ marginTop: "25px", display: "flex", flexFlow: "row" }}>
          <div style={{ display: "block" }} id={"invoiceText"}>
            <div style={{ fontFamily: "calibri", fontSize: "11pt" }}>
              <p>Lieber {name},</p>
              <p>
                im Anhang darf ich dir die Rechnungen für die comp|sign
                Benutzung eurer Bewerbe übermitteln.
              </p>
              <p>
                Ich bitte um Überweisung auf das in der Rechnung angeführte
                Konto.
              </p>
              <p style={{ fontFamily: "Verdana", fontSize: "10pt" }}>
                <span>Danke & lg.&nbsp;–&nbsp;</span>
                <span
                  style={{
                    fontWeight: "bold",
                    fontStyle: "italic"
                  }}
                >
                  Tuschi
                </span>
                <span>&nbsp;–</span>
                <br />
                <span
                  style={{
                    fontFamily: "calibri",
                    fontSize: "14pt",
                    color: "#476F81",
                    fontWeight: "bold"
                  }}
                >
                  comp|sign
                </span>
              </p>
            </div>
          </div>
          <div>
            <IconButton onClick={() => copyToClipboard()}>
              <CopyIcon />
            </IconButton>
          </div>
        </div>
        {competition && competition.club.invoiceMail ? (
          <div style={{ marginTop: "15px" }}>
            <a
              href={`mailto:${
                competition.club.invoiceMail
              }?subject=Rechnung%20comp%7Csign%20Ben%C3%BCtzungsgeb%C3%BChr&body=${encodeURIComponent(
                text
              )}`}
            >
              {competition.club.invoiceMail}
            </a>
            <a
              href={`mailto:${competition.club.invoiceMail}?subject=Rechnung%20comp%7Csign%20Ben%C3%BCtzungsgeb%C3%BChr`}
              style={{ marginLeft: "10px" }}
            >
              ( Ohne Text )
            </a>
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} style={{ color: "#4F6982" }}>
          Schließen
        </Button>
        <Button style={{ color: "#4F6982" }} onClick={download} color="primary">
          Herunterladen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CompetitionInvoicePopup = reduxForm({
  // a unique name for the form
  form: "competitionInvoiceForm"
})(CompetitionInvoicePopup);

function mapStateToProps(state) {
  return {
    myForm: state.form.competitionInvoiceForm
  };
}

CompetitionInvoicePopup.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  competition: PropTypes.object
};

export default translate(
  connect(mapStateToProps)(withMobileDialog()(CompetitionInvoicePopup))
);
