// React imports
// Material imports
import Grid from "@material-ui/core/Grid";
import deLocale from "date-fns/locale/de";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import PropTypes from "prop-types";
import RichTextInput from "ra-input-rich-text";
import React from "react";
// React Admin imports
import {
  ArrayInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  FormTab,
  minLength,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  translate
} from "react-admin";
import { DateInput } from "react-admin-date-inputs";
import {
  required,
  requiredIfBankNameIsFilled,
  requiredIfIbanIsFilled
} from "../../validators/validators";
import ErrorMessage from "../ErrorMessage";
import PageTitle from "../../components/PageTitle";

import S3FileUploadInput from "../inputs/S3FileUploadInput";
import CustomToolbar from "../CustomToolbar";

const ClubCreate = props => {
  const { translate } = props;

  return (
    <Grid>
      <PageTitle
        title={props.translate("resources.clubs.createPage.headline")}
      />
      <Create title="Create a Club" {...props}>
        <TabbedForm redirect="list" toolbar={<CustomToolbar {...props} />}>
          <FormTab label="resources.clubs.tabs.club">
            <h2>{translate("resources.clubs.fields.clubHeader")}</h2>
            <TextInput source="name" validate={[required(), minLength(2)]} />
            <TextInput source="longName" validate={required()} />
            <TextInput source="address" />
            <TextInput source="city" />
            <TextInput source="zip" />

            <SelectInput
              source="country"
              choices={[
                { id: "at", name: translate("countries.at") },
                { id: "de", name: translate("countries.de") }
              ]}
            />
            <TextInput source="googleMapsLink" />
            <TextInput source="websiteLink" />

            <S3FileUploadInput
              source="logoRaw"
              previewSource="logo"
              label="resources.clubs.fields.logo"
            />

            <h2>{translate("resources.clubs.showPage.bank.information")}</h2>
            <TextInput
              label="resources.clubs.showPage.bank.name"
              source="bankInformation.name"
              validate={requiredIfIbanIsFilled()}
            />
            <TextInput
              label="resources.clubs.showPage.bank.bic"
              source="bankInformation.bic"
            />
            <TextInput
              label="resources.clubs.showPage.bank.iban"
              source="bankInformation.iban"
              validate={requiredIfBankNameIsFilled()}
            />

            <h2>{translate("resources.clubs.fields.matchHeader")}</h2>
            <TextInput source="matchAddress" />
            <TextInput source="matchCity" />
            <TextInput source="matchZip" />
            <SelectInput
              source="matchCountry"
              choices={[
                { id: "at", name: translate("countries.at") },
                { id: "de", name: translate("countries.de") }
              ]}
            />
            <h2>{translate("resources.clubs.fields.contactHeader")}</h2>
            <TextInput source="contact.firstName" />
            <TextInput source="contact.lastName" />
            <TextInput source="contact.email" />
            <TextInput source="contact.telephone" />
            <h2>{translate("resources.clubs.fields.paymentHeader")}</h2>
            <DateInput
              source="paymentUntil"
              label={translate("resources.clubs.fields.paymentUntil")}
              providerOptions={{ utils: MomentUtils, locale: deLocale }}
              options={{
                //format: "d.M.YYYY, HH:mm:ss",
                ampm: false,
                clearable: true
              }}
            />
            <BooleanInput
              source="unlisted"
              options={{ defaultToggled: false }}
            />
          </FormTab>
          <FormTab label="resources.clubs.tabs.notificationBlocks">
            <ArrayInput
              source="notificationBlocks"
              disableRemove
              disableAdd
              label=""
            >
              <SimpleFormIterator disableAdd={true} disableRemove={true}>
                <FormDataConsumer source="">
                  {({ formData, getSource, ...rest }) => {
                    let re = /(\d+)/;
                    let found = getSource().split(re);
                    const index = found[1];
                    return [
                      <p>
                        {formData &&
                        formData.notificationBlocks &&
                        formData.notificationBlocks[index]
                          ? props.translate(
                              "resources.competitions.fields.notificationBlocks." +
                                formData.notificationBlocks[index].type.name
                            )
                          : ""}
                      </p>,
                      <RichTextInput
                        source={"notificationBlocks[" + index + "].text"}
                        toolbar={[["bold", "italic", "underline", "link"]]}
                        label={
                          formData &&
                          formData.notificationBlocks &&
                          formData.notificationBlocks[index]
                            ? formData.notificationBlocks[index].type.name
                            : ""
                        }
                      />
                    ];
                  }}
                </FormDataConsumer>
              </SimpleFormIterator>
            </ArrayInput>
          </FormTab>
        </TabbedForm>
      </Create>

      <ErrorMessage
        errorMessage={props.errorMessage}
        open={props.errorMessage ? true : false}
        onClose={props.hideError}
        translate={props.translate}
      />
    </Grid>
  );
};
ClubCreate.propTypes = {
  translate: PropTypes.func.isRequired,
  errorMessage: PropTypes.object,
  hideError: PropTypes.func.isRequired
};
export default translate(ClubCreate);
