import React, { Component } from "react";

import ReservationCodeForm from "../../components/pages/ReservationCodeForm";
import { connect } from "react-redux";
import { setError } from "../../reducer/errorActions";
import { setSuccess } from "../../reducer/successActions";
import { isClubAdmin } from "../../utils/utils";
import { createReservationCodes, getCompetitions } from "../../utils/restUtils";
import { push } from "react-router-redux";
import moment from "moment";

class ReservationCodeFormPage extends Component {
  state = {
    competitions: [],
    submitButton: true
  };

  componentDidMount = () => {
    this.getCompetitionList();
  };

  handleSubmit = () => {
    let data = {
      amount: this.props.myForm.values.amount,
      competitionId: this.props.myForm.values.selectedCompetition
    };

    this.setState({ submitButton: false });

    createReservationCodes(data).then(
      success => {
        this.setState({ competition: success });
        this.props.dispatch(
          setSuccess({
            message: "resources.reservationCodes.reservationSuccess"
          })
        );
        this.setState({ submitButton: true });
        this.props.dispatch(push("/reservationCodesList"));
      },
      e => {
        this.setState({ competition: null });
        this.props.dispatch(setError(e));
        this.setState({ submitButton: true });
      }
    );
  };

  getCompetitionList = () => {
    getCompetitions(0, 10000, "competitionDate", "ASC", {
      archive: false,
      clubId: this.props.clubId,
      eventsAndComps: true
    }).then(
      data => {
        for (let i = 0; i < data.data.length; i++) {
          data.data[i].name +=
            " (" + moment(data.data[i].competitionDate).format("L") + ")";
        }
        this.setState({ competitions: data.data });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  hideSuccessMessage = () => {
    this.props.dispatch(setSuccess(null));
  };

  render() {
    return (
      <ReservationCodeForm
        handleSubmit={this.handleSubmit}
        csCompetitions={this.state.competitions}
        successMessage={this.props.successMessage}
        hideSuccess={this.hideSuccessMessage}
        submitButton={this.state.submitButton}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    myForm: state.form.reservationCodeForm,
    permissions: state.identity.userData.permissions,
    clubId:
      state.identity &&
      state.identity.userData &&
      state.identity.userData.clubId
        ? state.identity.userData.clubId
        : null,
    successMessage: state.success.successMessage.message
  };
}

export default connect(mapStateToProps)(ReservationCodeFormPage);
