// React imports
import React, { Component } from "react";

// Other imports
import { fetchFile } from "./FileUploadHelper";

// Material imports
import Grid from "@material-ui/core/Grid";

class ImagePreview extends Component {
  state = {
    imageFile: null
  };

  componentDidUpdate = prevProps => {
    if (prevProps.imageId !== this.props.imageId) {
      fetchFile(this.props.imageId, data => {
        var reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onloadend = () => {
          let base64data = reader.result;
          this.setState({ imageFile: base64data });
        };
      });
    }
  };

  componentDidMount = () => {
    fetchFile(this.props.imageId, data => {
      var reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        let base64data = reader.result;
        this.setState({ imageFile: base64data });
      };
    });
  };

  render() {
    return (
      <div>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <img
              src={this.state.imageFile}
              style={
                this.props.imageHeight
                  ? { height: this.props.imageHeight + "px" }
                  : { width: "100%" }
              }
              alt=""
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ImagePreview;
