// React imports
import React, { Fragment, useState } from "react";

// React Admin imports
import { translate } from "react-admin";

// React Redux imports
import { connect } from "react-redux";
import TableSortLabel from "@material-ui/core/TableSortLabel";
// Material imports
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { Icon as MdiIcon } from "@mdi/react";
import { mdiFileDownloadOutline } from "@mdi/js";
import restClient from "../../providers/dataProvider/rest";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import DialogActions from "@material-ui/core/DialogActions";

// Icon imports
import ExpandMore from "@material-ui/icons/ExpandMore";

// Other imports
import PropTypes from "prop-types";
import { togglePopup } from "../../reducer/flagActions";
import moment from "moment";

import ErrorMessage from "../ErrorMessage";
import CompetitionFavouriteButton from "../CompetitionFavouriteButton";
import PageTitle from "../../components/PageTitle";
import BackButton from "../BackButton";
import SuccessMessage from "../SuccessMessage";
import Warning from "@material-ui/icons/Warning";
import { CircularProgress } from "@material-ui/core";
import { FILE_API_PATH } from "../../utils/config";
import SearchBar from "../inputs/SearchBar";
import CompetitionSignupWizard from "./CompetitionSignupWizard";
import { VpnKey } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";

function getFreeSpots(competition) {
  let totalSlots = 0;
  let bookedSlots = 0;
  for (let i = 0; i < competition.squads.length; i++) {
    totalSlots += competition.squads[i].squadSize;
    bookedSlots +=
      competition.squads[i].squadSize >= competition.squads[i].members.length
        ? competition.squads[i].members.length
        : competition.squads[i].squadSize;
  }
  return totalSlots - bookedSlots + " / " + totalSlots;
}

function getFreeWaitingListSpots(competition) {
  let totalWaitingList = 0;
  let bookedWaitingList = 0;
  for (let i = 0; i < competition.squads.length; i++) {
    totalWaitingList += competition.squads[i].maxWaitingListSize;
    if (
      competition.squads[i].squadSize - competition.squads[i].members.length <
      0
    ) {
      bookedWaitingList +=
        competition.squads[i].members.length - competition.squads[i].squadSize;
    }
  }
  return totalWaitingList - bookedWaitingList + " / " + totalWaitingList;
}

function getFreeSpotsNumber(competition) {
  let totalSlots = 0;
  let bookedSlots = 0;
  for (let i = 0; i < competition.squads.length; i++) {
    totalSlots += competition.squads[i].squadSize;
    bookedSlots +=
      competition.squads[i].squadSize >= competition.squads[i].members.length
        ? competition.squads[i].members.length
        : competition.squads[i].squadSize;
  }
  return totalSlots - bookedSlots;
}

function getFreeSpotsIncludingWaitingList(competition) {
  let totalSlots = 0;
  let bookedSlots = 0;
  for (let i = 0; i < competition.squads.length; i++) {
    totalSlots +=
      competition.squads[i].squadSize +
      competition.squads[i].maxWaitingListSize;
    bookedSlots += competition.squads[i].members.length;
  }
  return totalSlots - bookedSlots;
}

const waitingListIsOnlyAvailable = freeSpots => {
  return freeSpots > 0 ? 0 : 1;
};

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`
  };
}

const styles = {
  spacer: {
    display: "block",
    height: "20px"
  },
  marginBottom: {
    marginBottom: "20px"
  },
  cardTitleBgGreen: {
    backgroundColor: "#7E896E",
    padding: "16px"
  },
  cardTitleBgPurple: {
    backgroundColor: "#7C757B",
    padding: "16px"
  },
  cardTitleBgBlue: {
    backgroundColor: "#4F6982",
    padding: "16px"
  },
  cardTitle: {
    color: "#ffffff",
    display: "flex",
    minHeight: "40px",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  signUpBtn: {
    color: "#ffffff",
    backgroundColor: "#4F6982"
  },
  signUpSquadBtn: {
    color: "#ffffff",
    backgroundColor: "#4F6982"
  },
  tableRow: {
    height: "auto",
    verticalAlign: "top"
  },
  tableCell: {
    borderBottomWidth: 0,
    padding: "4px 12px 4px 0"
  },
  tableCellLeft: {
    borderBottomWidth: 0,
    padding: "4px 12px 4px 0",
    width: "22%"
  },
  simpleBtn: {
    color: "#7E896E",
    height: "36px",
    margin: 0,
    padding: "0 12px 0 0",
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 19,
    backgroundColor: "transparent",
    overflow: "hidden"
  },
  list: {
    width: "100%",
    padding: 0
  },
  listItem: {
    padding: "3px 0 8px 0"
  },
  squadTableCell: {
    paddingLeft: 0
  },
  columnCountry: {
    width: 17
  },
  modalWrapper: {
    position: "absolute",
    width: "350px",
    padding: 0,
    backgroundColor: "#ffffff",
    overflow: "auto"
  },
  closeBtn: {
    textAlign: "end"
  },
  modalContent: {
    padding: "8px 20px"
  },
  dialogCompleteWrapper: {
    minWidth: "400px"
  },
  dialogWrapper: {
    minWidth: "400px",
    paddingTop: "0px",
    paddingBottom: "5px"
  },
  simpleTitle: {
    marginBottom: "20px"
  },
  favouriteButton: {
    float: "right"
  },
  expansionCard: {
    marginBottom: "20px"
  },
  panelContainer: {
    display: "block"
  }
};

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#4F6982",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

function contains(a, obj) {
  for (var i = 0; i < a.length; i++) {
    if (a[i] === obj) {
      return true;
    }
  }
  return false;
}

const sortAndFilterParticipants = (participants, sort, filter, props) => {
  let currentSort = sort ? sort : { column: "lastName", order: "asc" };

  participants = participants.filter(participant => {
    if (props.competition.event || !participant.waitingList) {
      if (!filter) return participant;
      let stringToSearchIn =
        participant.lastName +
        participant.firstName +
        participant.competitionClassName +
        participant.divisionName +
        participant.squad.name;

      stringToSearchIn = stringToSearchIn.toLowerCase();
      if (filter && stringToSearchIn.search(filter.toLowerCase()) !== -1) {
        return participant;
      }
    }
  });

  participants.sort((a, b) => {
    let aTrimmedColumnSort = "";
    let bTrimmedColumnSort = "";
    if (currentSort.column === "squad") {
      aTrimmedColumnSort = a.squad.name != null ? a.squad.name.trim() : null;

      bTrimmedColumnSort = b.squad.name != null ? b.squad.name.trim() : null;
    } else {
      aTrimmedColumnSort =
        a[currentSort.column] != null ? a[currentSort.column].trim() : null;

      bTrimmedColumnSort =
        b[currentSort.column] != null ? b[currentSort.column].trim() : null;
    }

    if (aTrimmedColumnSort > bTrimmedColumnSort)
      return currentSort.order === "desc" ? 1 : -1;
    if (aTrimmedColumnSort < bTrimmedColumnSort)
      return currentSort.order === "desc" ? -1 : 1;
    return 0;
  });

  return participants;
};

const CompetitionShow = props => {
  const {
    competition,
    userHasPermission,
    momentFormat,
    translate,
    participants,
    fullScreen
  } = props;

  const [downloading, setDownloading] = useState(null);
  const [expanded, setExpanded] = useState([]);
  const [sort, setSort] = useState({ column: "lastName", order: "desc" });
  const [filter, setFilter] = useState(null);

  let userIsLoggedIn = false;

  if (props.identity && props.identity.permissions) {
    if (props.identity.permissions[0] !== "IS_GUEST") userIsLoggedIn = true;
    else userIsLoggedIn = false;
  }

  const premiumFeaturesActive = userIsLoggedIn && props.paymentValid;

  let signupInitialValues = props.signupInitialValues;

  if (props.identity) {
    signupInitialValues.firstName = props.identity.userFirstName;
    signupInitialValues.lastName = props.identity.userLastName;
    signupInitialValues.email = props.identity.userEmail;
    signupInitialValues.csClasses = props.identity.competitionClassId;
    signupInitialValues.csFactors = props.identity.factorId;
    signupInitialValues.csDivisions = props.identity.divisionId;
    signupInitialValues.club = props.identity.clubId;
  }

  if (props.competition.event) {
    signupInitialValues.Squad = props.competition.squads[0].id;
  }

  const toggleCompetitionRegistrationForm = () => {
    props.dispatch(togglePopup("popupCompetitionRegistrationForm"));
  };
  const competitionSquadRegistration = squadId => {
    if (squadId) {
      props.signupInitialValues.Squad = squadId;
    } else {
      props.signupInitialValues.Squad = null;
    }
    props
      .checkPreRegistration(
        competition.id,
        localStorage.getItem("cartId") != "" &&
          localStorage.getItem("cartId") != undefined &&
          localStorage.getItem("cartId") !== "null"
          ? localStorage.getItem("cartId")
          : 0,
        true
      )
      .then(success => {}, e => {});
  };

  const competitionActive = props.competition && props.competition.active;
  const competitionCancelled =
    props.competition && props.competition.cancelled === true;
  const competitionRegistrationEnded = !props.registrationWindow;

  const fetchFile = id => {
    restClient("DOWNLOAD", "download", {
      id: id
    }).then(response => {
      try {
        setDownloading(null);
        const filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        response.blob().then(blob => {
          if (typeof callback !== "undefined") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      } catch (e) {
        setDownloading(null);
      }
    });
  };

  //Save participants in corresponding squads
  props.competition.squads.map((squad, squadKey) => {
    competition.squads[squadKey].members = [];
    if (participants)
      participants.map((participant, participantKey) => {
        if (squad.id === participant.squad.id) {
          squad.members.push(participant);
        }
      });
  });

  // Only non waiting List members
  props.competition.squads.map((squad, squadKey) => {
    let squadParticipants = 0;
    if (participants)
      participants.map((participant, participantKey) => {
        if (squad.id === participant.squad.id) {
          if (squad.squadSize > squadParticipants) {
            squadParticipants++;
            participant.waitingList = false;
          } else participant.waitingList = true;
        }
      });
  });

  const filteredAndSortedParticipants = sortAndFilterParticipants(
    participants,
    sort,
    filter,
    props
  );

  var waitingList = waitingListIsOnlyAvailable(
    competition ? getFreeSpotsNumber(competition) : 0
  );

  const competitionFull =
    getFreeSpotsIncludingWaitingList(competition) > 0 ? false : true;

  const squadFull = squad =>
    squad.squadSize + squad.maxWaitingListSize > squad.members.length
      ? false
      : true;

  return (
    <div>
      {props.preRegistration !== undefined &&
      props.competition.nationalCompetition !== undefined ? (
        <Dialog
          fullScreen={fullScreen}
          open={props.popupCompetitionRegistrationForm}
          onClose={toggleCompetitionRegistrationForm}
        >
          <DialogContent style={styles.dialogWrapper}>
            <CompetitionSignupWizard
              clubs={props.clubs}
              competition={props.competition}
              squad={signupInitialValues.Squad}
              permissions={props.permissions}
              users={props.csUsers}
              signup={props.signup}
              errorMessage={props.errorMessage}
              onClose={toggleCompetitionRegistrationForm}
              translate={props.translate}
              identity={props.identity}
            />
          </DialogContent>
        </Dialog>
      ) : null}
      <Grid container spacing={16}>
        <Grid item xs={12} sm={12} md={12} lg={8}>
          {!competitionActive || competitionCancelled ? (
            <Typography
              variant="display1"
              style={{
                backgroundColor: "#ffcc00",
                padding: "10px",
                marginBottom: "10px"
              }}
            >
              <Warning style={{ marginRight: "10px" }} />
              {competitionCancelled
                ? translate("resources.competitionshow.competitionCancelled")
                : translate("resources.competitionshow.competitionInactive")}
            </Typography>
          ) : null}
          <Typography variant="display1" style={styles.simpleTitle}>
            {competition.name} |{" "}
            {moment(competition.competitionDate)
              .format(momentFormat)
              .toString()}
            {competition.competitionEndDate
              ? " - " +
                moment(competition.competitionEndDate)
                  .format(momentFormat)
                  .toString()
              : null}
            {competition.favourite === true ||
            competition.favourite === false ? (
              <IconButton style={styles.favouriteButton}>
                <CompetitionFavouriteButton
                  fav={competition.favourite}
                  competitionId={competition.id}
                />
              </IconButton>
            ) : null}
            {competition.registered === true ? (
              <IconButton
                disabled={true}
                style={{
                  color: competition.paid ? "green" : "red",
                  float: "right"
                }}
              >
                <CheckIcon />
              </IconButton>
            ) : null}
          </Typography>

          <Card style={styles.marginBottom}>
            {!competitionActive ||
            competitionCancelled ? null : (userIsLoggedIn &&
                !userHasPermission) ||
              competitionFull ||
              competitionRegistrationEnded ? (
              <CardContent style={{ textAlign: "end" }}>
                <LightTooltip
                  title={
                    competitionFull && !competitionRegistrationEnded
                      ? translate("resources.competitionshow.competitionFull")
                      : !competitionFull && !competitionRegistrationEnded
                      ? translate(
                          "resources.competitionshow.noPermitionToSignUp"
                        )
                      : translate(
                          "resources.competitionshow.competitionRegistrationEnded"
                        )
                  }
                  placement="right"
                >
                  <span>
                    <Button variant="contained" color="primary" disabled={true}>
                      {competitionFull && !competitionRegistrationEnded
                        ? translate("resources.competitionshow.competitionFull")
                        : competitionRegistrationEnded
                        ? translate(
                            "resources.competitionshow.squad.signUpToOver"
                          )
                        : translate("resources.competitionshow.signUp")}
                    </Button>
                  </span>
                </LightTooltip>
              </CardContent>
            ) : (
              <CardContent style={{ textAlign: "end" }}>
                <Grid justify="space-between" container spacing={24}>
                  <Grid item>
                    <BackButton
                      tooltip={translate("backButton.tooltip")}
                      history={props.history}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => {
                        competitionSquadRegistration();
                      }}
                      variant="contained"
                      style={styles.signUpBtn}
                    >
                      {translate("resources.competitionshow.signUp")}
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            )}
            <CardContent>
              <Typography variant="title">
                {translate("resources.competitionshow.information")}
              </Typography>
              <span
                style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}
                dangerouslySetInnerHTML={{
                  __html: competition.information
                }}
              />
              <hr
                style={{
                  marginBottom: "5px",
                  marginTop: "15px"
                }}
              />
            </CardContent>
            <CardContent>
              <Typography variant="title">
                {translate("resources.competitionshow.details")}
              </Typography>
              <div style={styles.spacer}></div>
              <Table style={{ width: "100%" }}>
                <TableRow style={styles.tableRow}>
                  <TableCell style={styles.tableCellLeft}>
                    <Typography>
                      {translate(
                        "resources.competitionshow.detailsTable.clubName"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell style={styles.tableCell}>
                    <Typography variant="subheading">
                      <b>{competition.clubName}</b>
                    </Typography>
                  </TableCell>
                </TableRow>
                {competition.event ? (
                  <TableRow style={styles.tableRow}>
                    <TableCell style={styles.tableCellLeft}>
                      <Typography>
                        {translate(
                          "resources.competitionshow.detailsTable.eventType"
                        )}
                      </Typography>
                    </TableCell>
                    <TableCell style={styles.tableCell}>
                      <Typography variant="subheading">
                        {competition.eventType}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ) : null}

                <TableRow style={styles.tableRow}>
                  <TableCell style={styles.tableCellLeft}>
                    <Typography>
                      {translate(
                        "resources.competitionshow.detailsTable.contactPerson"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell style={styles.tableCell}>
                    {competition.contactName ? (
                      <Typography variant="subheading">
                        {competition.contactName}
                      </Typography>
                    ) : null}
                    {competition.contactEmail ? (
                      <Button
                        disableRipple
                        target="_blank"
                        href={"mailto: " + competition.contactEmail}
                        style={styles.simpleBtn}
                      >
                        {competition.contactEmail}
                      </Button>
                    ) : null}
                    {competition.contactTelephone ? (
                      <Typography variant="subheading">
                        {competition.contactTelephone}
                      </Typography>
                    ) : null}
                  </TableCell>
                </TableRow>

                <TableRow style={styles.tableRow}>
                  <TableCell style={styles.tableCellLeft}>
                    <Typography>
                      {translate(
                        "resources.competitionshow.detailsTable.location"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell style={styles.tableCell}>
                    <Typography variant="subheading">
                      {competition.address +
                        ", " +
                        competition.zip +
                        " " +
                        competition.city +
                        ", "}
                      {translate(props.competitionCountry.countryName)}
                    </Typography>
                    <Button
                      disableRipple
                      target="_blank"
                      href={competition.googleMapsLink}
                      style={styles.simpleBtn}
                    >
                      {translate(
                        "resources.competitionshow.detailsTable.googleMapsLink"
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              </Table>
              <hr
                style={{
                  marginBottom: "15px",
                  marginTop: "15px"
                }}
              />
              <Table style={{ width: "100%" }}>
                {!competition.event
                  ? [
                      <TableRow style={styles.tableRow}>
                        <TableCell style={styles.tableCellLeft}>
                          <Typography>
                            {translate(
                              "resources.competitionshow.detailsTable.type"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                          <Typography variant="subheading">
                            {competition.competitionTypeName}
                          </Typography>
                        </TableCell>
                      </TableRow>,

                      <TableRow style={styles.tableRow}>
                        <TableCell style={styles.tableCellLeft}>
                          <Typography>
                            {translate(
                              "resources.competitionshow.detailsTable.level"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                          <Typography variant="subheading">
                            {competition.levelName}
                          </Typography>
                        </TableCell>
                      </TableRow>,

                      <TableRow style={styles.tableRow}>
                        <TableCell style={styles.tableCellLeft}>
                          <Typography>
                            {translate(
                              "resources.competitionshow.detailsTable.stages"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                          <Typography variant="subheading">
                            {competition.stages}
                          </Typography>
                        </TableCell>
                      </TableRow>,

                      <TableRow style={styles.tableRow}>
                        <TableCell style={styles.tableCellLeft}>
                          <Typography>
                            {translate(
                              "resources.competitionshow.detailsTable.minimumShots"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                          <Typography variant="subheading">
                            {competition.minimumShots}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ]
                  : null}
                <TableRow style={styles.tableRow}>
                  <TableCell style={styles.tableCellLeft}>
                    <Typography>
                      {translate("resources.competitionshow.detailsTable.fee")}
                    </Typography>
                  </TableCell>
                  <TableCell style={styles.tableCell}>
                    <Typography variant="subheading">
                      {competition.nominationFee + " €"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </Table>
              {!competition.event
                ? [
                    <hr
                      style={{
                        marginBottom: "15px",
                        marginTop: "15px"
                      }}
                    />,
                    <Table style={{ width: "100%" }}>
                      <TableRow style={styles.tableRow}>
                        <TableCell style={styles.tableCellLeft}>
                          <Typography>
                            {translate(
                              "resources.competitionshow.detailsTable.divisionen"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                          <Typography variant="subheading">
                            {competition.division
                              ? competition.division.map((value, key) => (
                                  <div>{value.name}</div>
                                ))
                              : null}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow style={styles.tableRow}>
                        <TableCell style={styles.tableCellLeft}>
                          <Typography>
                            {translate(
                              "resources.competitionshow.detailsTable.classes"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                          <Typography variant="subheading">
                            {competition.competitionClass
                              ? competition.competitionClass.map(
                                  (value, key) => <div>{value.name}</div>
                                )
                              : null}
                          </Typography>
                        </TableCell>
                      </TableRow>

                      <TableRow style={styles.tableRow}>
                        <TableCell style={styles.tableCellLeft}>
                          <Typography>
                            {translate(
                              "resources.competitionshow.detailsTable.factors"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                          <Typography variant="subheading">
                            {competition.factor
                              ? competition.factor.map((value, key) => (
                                  <div>{value.name}</div>
                                ))
                              : null}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </Table>
                  ]
                : null}
              <hr
                style={{
                  marginBottom: "15px",
                  marginTop: "15px"
                }}
              />
              <Table style={{ width: "100%" }}>
                <TableRow style={styles.tableRow}>
                  <TableCell style={styles.tableCellLeft}>
                    <Typography>
                      {translate(
                        "resources.competitionshow.detailsTable.registrationStart"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell style={styles.tableCell}>
                    <Typography variant="subheading">
                      {moment(competition.registrationStart)
                        .format("ddd lll")
                        .toString()}
                    </Typography>
                  </TableCell>
                </TableRow>

                <TableRow style={styles.tableRow}>
                  <TableCell style={styles.tableCellLeft}>
                    <Typography>
                      {translate(
                        "resources.competitionshow.detailsTable.registrationEnd"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell style={styles.tableCell}>
                    <Typography variant="subheading">
                      {moment(competition.registrationEnd)
                        .format("ddd lll")
                        .toString()}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={styles.tableRow}>
                  <TableCell style={styles.tableCellLeft}>
                    <Typography>
                      {translate(
                        "resources.competitionshow.detailsTable.freePlaces"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell style={styles.tableCell}>
                    <Typography variant="subheading">
                      {getFreeSpots(competition)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow style={styles.tableRow}>
                  <TableCell style={styles.tableCellLeft}>
                    <Typography>
                      {translate(
                        "resources.competitionshow.detailsTable.freeWaitingListPlaces"
                      )}
                    </Typography>
                  </TableCell>
                  <TableCell style={styles.tableCell}>
                    <Typography variant="subheading">
                      {getFreeWaitingListSpots(competition)}
                    </Typography>
                  </TableCell>
                </TableRow>
              </Table>
              <hr
                style={{
                  marginBottom: "15px",
                  marginTop: "15px"
                }}
              />
              {competition.paymentViaDeposit == true
                ? [
                    <Table style={{ width: "100%" }}>
                      <TableRow style={styles.tableRow}>
                        <TableCell style={styles.tableCellLeft}>
                          <Typography>
                            {translate(
                              "resources.competitionshow.detailsTable.bankAccountInfos"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell style={styles.tableCell}>
                          {competition.club.bankInformation ? (
                            <Typography variant="subheading">
                              {competition.club.bankInformation.name}
                              <br />
                              {translate(
                                "resources.competitionshow.detailsTable.bic"
                              ) +
                                " " +
                                competition.club.bankInformation.bic}
                              <br />
                              {translate(
                                "resources.competitionshow.detailsTable.iban"
                              ) +
                                " " +
                                competition.club.bankInformation.iban}
                            </Typography>
                          ) : null}
                        </TableCell>
                      </TableRow>
                    </Table>,
                    <hr
                      style={{
                        marginBottom: "15px",
                        marginTop: "15px"
                      }}
                    />,
                    <span style={styles.spacer} />
                  ]
                : null}

              <Typography variant="title">
                {translate("resources.competitionshow.links")}
              </Typography>

              <span style={styles.spacer}></span>

              {competition.announcementLink && (
                <>
                  <Button
                    disableRipple
                    target="_blank"
                    href={competition.announcementLink}
                    style={styles.simpleBtn}
                  >
                    {translate("resources.competitionshow.announcementLink")}
                  </Button>
                  <br />
                </>
              )}

              <Button
                disableRipple
                target="_blank"
                href={competition.clubWebsiteLink}
                style={styles.simpleBtn}
              >
                {translate("resources.competitionshow.clubWebsiteLink")}
              </Button>
              <br />
              {competition.resultListLink && (
                <Button
                  disableRipple
                  target="_blank"
                  href={competition.resultListLink}
                  style={styles.simpleBtn}
                >
                  {translate("resources.competitionshow.resultListLink")}
                </Button>
              )}
            </CardContent>
          </Card>

          {competition.competitionUploads.length > 0 &&
          competitionActive &&
          !competitionCancelled ? (
            <Fragment>
              <Typography variant="display1" style={styles.simpleTitle}>
                {translate("resources.competitionshow.downloads")}
              </Typography>
              <Card style={styles.marginBottom}>
                <CardContent>
                  {competition.competitionUploads.map(
                    (competitionUpload, key) => (
                      <a
                        href={
                          FILE_API_PATH + "/" + competitionUpload.document.path
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        download={true}
                        style={{ textDecoration: "none" }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          style={{
                            marginRight: "20px",
                            marginBottom: "5px",
                            marginTop: "5px"
                          }}
                        >
                          {downloading === key ? (
                            <CircularProgress size={24} />
                          ) : (
                            <MdiIcon path={mdiFileDownloadOutline} size={1} />
                          )}
                          {competitionUpload.type.showName
                            ? competitionUpload.document.fileName
                            : competitionUpload.type.name}
                        </Button>
                      </a>
                    )
                  )}
                </CardContent>
              </Card>
            </Fragment>
          ) : null}

          {competition.squads &&
          !competition.event &&
          competitionActive &&
          !competitionCancelled ? (
            <Fragment>
              <PageTitle
                title={translate("resources.competitionshow.squads")}
              />
              {competition.squads.map((squadInformation, key) => (
                <Card style={styles.expansionCard}>
                  <ExpansionPanel
                    onClick={() => setExpanded([...expanded, key])}
                  >
                    <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                      <Typography variant="title" style={styles.heading}>
                        {squadInformation.name} |{" "}
                        {moment(squadInformation.startTime)
                          .format("LT")
                          .toString() +
                          " - " +
                          moment(squadInformation.endTime)
                            .format("LT")
                            .toString()}
                        {" | "}
                        {moment(squadInformation.startDate)
                          .format("ddd ll")
                          .toString()}
                        {" | "}
                        {translate(
                          "resources.competitionshow.squad.freePlacesInSquads"
                        )}
                        {": "}
                        {squadInformation.squadSize -
                          squadInformation.members.length <=
                        0
                          ? "0 (" +
                            (squadInformation.squadSize +
                              squadInformation.maxWaitingListSize -
                              squadInformation.members.length) +
                            ")"
                          : squadInformation.squadSize -
                            squadInformation.members.length +
                            " (" +
                            squadInformation.maxWaitingListSize +
                            ")"}
                      </Typography>
                      {squadInformation.squadSize -
                        squadInformation.members.length <=
                        0 &&
                        (squadInformation.squadSize +
                          squadInformation.maxWaitingListSize -
                          squadInformation.members.length <=
                        0 ? (
                          <Tooltip
                            title={translate(
                              "resources.competitionshow.squad.full"
                            )}
                          >
                            <Warning
                              style={{
                                color: "#cc3300",
                                marginLeft: "10px"
                              }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={translate(
                              "resources.competitionshow.squad.waitingListOnly"
                            )}
                          >
                            <Warning
                              style={{
                                color: "#ffcc00",
                                marginLeft: "10px"
                              }}
                            />
                          </Tooltip>
                        ))}
                      {squadInformation.requireCode ? (
                        <Tooltip
                          title={translate(
                            "resources.competitionspub.signupForm.squadReservationCodeSignup"
                          )}
                        >
                          <VpnKey />
                        </Tooltip>
                      ) : null}
                    </ExpansionPanelSummary>
                    {contains(expanded, key) ? (
                      <ExpansionPanelDetails style={styles.panelContainer}>
                        <List style={styles.list}>
                          <ListItem style={styles.listItem}>
                            {(userIsLoggedIn && !userHasPermission) ||
                            squadFull(squadInformation) ||
                            competitionRegistrationEnded ? (
                              <LightTooltip
                                title={
                                  squadFull(squadInformation)
                                    ? translate(
                                        "resources.competitionshow.squadFull"
                                      )
                                    : !competitionRegistrationEnded
                                    ? translate(
                                        "resources.competitionshow.squad.noPermitionToSignUp"
                                      )
                                    : translate(
                                        "resources.competitionshow.competitionRegistrationEnded"
                                      )
                                }
                                placement="right"
                              >
                                <span>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    disabled={true}
                                  >
                                    {squadFull(squadInformation)
                                      ? translate(
                                          "resources.competitionshow.squadFull"
                                        )
                                      : translate(
                                          "resources.competitionshow.squad.signUpToOver"
                                        )}
                                  </Button>
                                </span>
                              </LightTooltip>
                            ) : (
                              <Button
                                onClick={() => {
                                  competitionSquadRegistration(
                                    squadInformation
                                  );
                                }}
                                variant="contained"
                                style={styles.signUpSquadBtn}
                              >
                                {translate(
                                  "resources.competitionshow.squad.signUpTo"
                                ) +
                                  " " +
                                  squadInformation.name}
                              </Button>
                            )}
                          </ListItem>
                          <ListItem style={styles.listItem}>
                            <ListItemText
                              secondary={translate(
                                "resources.competitionshow.squad.annotations"
                              )}
                              primary={squadInformation.information}
                            />
                          </ListItem>
                          {squadInformation.requireCode ? (
                            <ListItem style={styles.listItem}>
                              <VpnKey />
                              <ListItemText
                                secondary={translate(
                                  "resources.competitionspub.signupForm.squadReservationCodeOnly"
                                )}
                                primary={translate(
                                  "resources.competitionspub.signupForm.squadReservationCodeSignup"
                                )}
                              />
                            </ListItem>
                          ) : null}
                          <ListItem style={styles.listItem}>
                            <ListItemText
                              secondary={translate(
                                "resources.competitionshow.squad.startDate"
                              )}
                              primary={moment(squadInformation.startDate)
                                .format(momentFormat)
                                .toString()}
                            />
                            <ListItemText
                              secondary={translate(
                                "resources.competitionshow.squad.startTime"
                              )}
                              primary={
                                moment(squadInformation.startTime)
                                  .format("LT")
                                  .toString() +
                                " - " +
                                moment(squadInformation.endTime)
                                  .format("LT")
                                  .toString()
                              }
                            />
                          </ListItem>

                          <ListItem style={styles.listItem}>
                            <ListItemText
                              secondary={translate(
                                "resources.competitionshow.squad.squadSize"
                              )}
                              primary={squadInformation.squadSize}
                            />
                          </ListItem>
                        </List>
                        {!props.hideSubscriptions &&
                        competitionActive &&
                        !competitionCancelled ? (
                          <>
                            <div style={styles.spacer}></div>
                            <PageTitle
                              title={translate(
                                "resources.competitionshow.squad.squadMember"
                              )}
                            />
                            <Table>
                              <TableHead>
                                <TableRow>
                                  <TableCell style={styles.squadTableCell}>
                                    {translate(
                                      "resources.competitionshow.squad.squadMemberKey"
                                    )}
                                  </TableCell>
                                  <TableCell style={styles.squadTableCell}>
                                    {" "}
                                  </TableCell>
                                  <TableCell style={styles.squadTableCell}>
                                    {translate(
                                      "resources.competitionshow.squad.squadMemberName"
                                    )}
                                  </TableCell>
                                  <TableCell style={styles.squadTableCell}>
                                    {translate(
                                      "resources.competitionshow.squad.squadMemberDivision"
                                    )}
                                  </TableCell>
                                  <TableCell style={styles.squadTableCell}>
                                    {translate(
                                      "resources.competitionshow.squad.squadMemberClass"
                                    )}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {squadInformation.members.map((member, key) => {
                                  return (
                                    <TableRow key={key}>
                                      <TableCell style={styles.squadTableCell}>
                                        {key + 1}
                                      </TableCell>

                                      <TableCell style={styles.squadTableCell}>
                                        {member.country ? (
                                          <img
                                            src={
                                              window.location.origin +
                                              "/images/countries/" +
                                              member.country +
                                              ".png"
                                            }
                                            width="25"
                                          />
                                        ) : null}
                                      </TableCell>

                                      <TableCell style={styles.squadTableCell}>
                                        {member.lastName +
                                          " " +
                                          member.firstName +
                                          " " +
                                          (squadInformation.squadSize < key + 1
                                            ? "(" +
                                              translate(
                                                "resources.competitionshow.squad.waitingList"
                                              ) +
                                              ")"
                                            : "")}
                                      </TableCell>
                                      <TableCell style={styles.squadTableCell}>
                                        {member.divisionName}
                                      </TableCell>
                                      <TableCell style={styles.squadTableCell}>
                                        {member.competitionClassName}
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </>
                        ) : null}
                      </ExpansionPanelDetails>
                    ) : null}
                  </ExpansionPanel>
                </Card>
              ))}
            </Fragment>
          ) : null}
        </Grid>

        {!props.hideSubscriptions &&
        competitionActive &&
        !competitionCancelled ? (
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <PageTitle
              title={translate("resources.competitionshow.participants")}
            />
            <Card>
              <SearchBar
                active={premiumFeaturesActive}
                onChange={e => setFilter(e.target.value)}
              />
              <CardContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="dense">
                        {" "}
                        <Tooltip
                          title={
                            premiumFeaturesActive
                              ? "Sortierung"
                              : "Sortierung nur für Premiummitglieder"
                          }
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={
                              sort.column === "country" && premiumFeaturesActive
                            }
                            direction={sort.order}
                            onClick={() => {
                              if (!premiumFeaturesActive) return;
                              setSort({
                                column: "country",
                                order: sort.order === "asc" ? "desc" : "asc"
                              });
                            }}
                          ></TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      <TableCell padding="dense">
                        <Tooltip
                          title={
                            premiumFeaturesActive
                              ? "Sortierung"
                              : "Sortierung nur für Premiummitglieder"
                          }
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={
                              sort.column === "lastName" &&
                              premiumFeaturesActive
                            }
                            direction={sort.order}
                            onClick={() => {
                              if (!premiumFeaturesActive) return;
                              setSort({
                                column: "lastName",
                                order: sort.order === "asc" ? "desc" : "asc"
                              });
                            }}
                          >
                            {translate(
                              "resources.competitionshow.participantsName"
                            )}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                      {competition.event
                        ? null
                        : [
                            <TableCell
                              padding="dense"
                              style={styles.squadTableCell}
                            >
                              <Tooltip
                                title={
                                  premiumFeaturesActive
                                    ? "Sortierung"
                                    : "Sortierung nur für Premiummitglieder"
                                }
                                enterDelay={300}
                              >
                                <TableSortLabel
                                  active={
                                    sort.column === "competitionClassName" &&
                                    premiumFeaturesActive
                                  }
                                  direction={sort.order}
                                  onClick={() => {
                                    if (!premiumFeaturesActive) return;
                                    setSort({
                                      column: "competitionClassName",
                                      order:
                                        sort.order === "asc" ? "desc" : "asc"
                                    });
                                  }}
                                >
                                  {translate(
                                    "resources.competitionshow.participantsClass"
                                  )}
                                </TableSortLabel>
                              </Tooltip>
                            </TableCell>,
                            <TableCell
                              padding="dense"
                              style={styles.squadTableCell}
                            >
                              <Tooltip
                                title={
                                  premiumFeaturesActive
                                    ? "Sortierung"
                                    : "Sortierung nur für Premiummitglieder"
                                }
                                enterDelay={300}
                              >
                                <TableSortLabel
                                  active={
                                    sort.column === "divisionName" &&
                                    premiumFeaturesActive
                                  }
                                  direction={sort.order}
                                  onClick={() => {
                                    if (!premiumFeaturesActive) return;
                                    setSort({
                                      column: "divisionName",
                                      order:
                                        sort.order === "asc" ? "desc" : "asc"
                                    });
                                  }}
                                >
                                  {translate(
                                    "resources.competitionshow.participantsDivision"
                                  )}
                                </TableSortLabel>
                              </Tooltip>
                            </TableCell>,
                            <TableCell
                              padding="dense"
                              style={styles.squadTableCell}
                            >
                              <Tooltip
                                title={
                                  premiumFeaturesActive
                                    ? "Sortierung"
                                    : "Sortierung nur für Premiummitglieder"
                                }
                                enterDelay={300}
                              >
                                <TableSortLabel
                                  active={
                                    sort.column === "squad" &&
                                    premiumFeaturesActive
                                  }
                                  direction={sort.order}
                                  onClick={() => {
                                    if (!premiumFeaturesActive) return;
                                    setSort({
                                      column: "squad",
                                      order:
                                        sort.order === "asc" ? "desc" : "asc"
                                    });
                                  }}
                                >
                                  {translate(
                                    "resources.competitionshow.participantsSquad"
                                  )}
                                </TableSortLabel>
                              </Tooltip>
                            </TableCell>
                          ]}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredAndSortedParticipants &&
                      filteredAndSortedParticipants.map((participant, key) => (
                        <TableRow>
                          <TableCell
                            padding="none"
                            style={styles.columnCountry}
                          >
                            {participant.country ? (
                              <img
                                src={
                                  window.location.origin +
                                  "/images/countries/" +
                                  participant.country +
                                  ".png"
                                }
                                width="25"
                              />
                            ) : null}
                          </TableCell>
                          <TableCell
                            padding="dense"
                            style={styles.squadTableCell}
                          >
                            {participant.lastName} {participant.firstName}{" "}
                            {props.competition.event && participant.waitingList
                              ? "(Warteliste)"
                              : null}
                          </TableCell>
                          {competition.event
                            ? null
                            : [
                                <TableCell
                                  padding="dense"
                                  style={styles.squadTableCell}
                                >
                                  {participant.competitionClassName
                                    ? participant.competitionClassName
                                    : null}
                                </TableCell>,

                                <TableCell
                                  padding="dense"
                                  style={styles.squadTableCell}
                                >
                                  {participant.divisionName
                                    ? participant.divisionName
                                    : null}
                                </TableCell>,
                                <TableCell
                                  padding="dense"
                                  style={styles.squadTableCell}
                                >
                                  {participant.squad.name}
                                </TableCell>
                              ]}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          </Grid>
        ) : null}
      </Grid>
      <Dialog
        fullScreen={false}
        open={props.finishModal}
        onClose={props.closeFinishModal}
      >
        <DialogContent style={styles.dialogCompleteWrapper}>
          <b>{props.translate("resources.signupFinish.header")}</b>
          <p>{props.translate("resources.signupFinish.content1")}</p>
          <p>{props.translate("resources.signupFinish.content2")}</p>
          {/* {userIsLoggedIn ? (
            <p>{props.translate("resources.signupFinish.content")}</p>
          ) : (
            <p></p>
          )} */}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.closeFinishModal}>
            {props.translate("resources.signupFinish.goBacktoCompetition")}
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage
        successMessage={props.successMessage}
        open={!!props.successMessage}
        onClose={props.hideSuccess}
        translate={props.translate}
      />
      <ErrorMessage
        errorMessage={props.errorMessage}
        open={!!props.errorMessage}
        onClose={props.hideError}
        translate={props.translate}
      />
    </div>
  );
};

CompetitionShow.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  finishModal: PropTypes.bool.isRequired,
  csSquads: PropTypes.array.isRequired,
  signup: PropTypes.func.isRequired,
  competition: PropTypes.exact({
    id: PropTypes.number,
    name: PropTypes.string,
    clubName: PropTypes.string,
    date: PropTypes.objectOf(Date),
    information: PropTypes.string,
    type: PropTypes.string,
    levelName: PropTypes.string,
    stages: PropTypes.number,
    nominationFee: PropTypes.number,
    announcementLink: PropTypes.string,
    clubWebsiteLink: PropTypes.string,
    contactFirstName: PropTypes.string,
    contactLastName: PropTypes.string,
    contactEmail: PropTypes.string,
    contactTelephone: PropTypes.string,
    address: PropTypes.string,
    zip: PropTypes.number,
    city: PropTypes.string,
    country: PropTypes.string,
    googleMapsLink: PropTypes.string,
    registrationStart: PropTypes.objectOf(Date),
    registrationEnd: PropTypes.objectOf(Date),
    division: PropTypes.string,
    class: PropTypes.string,
    factor: PropTypes.string,
    minimumShots: PropTypes.number,
    squadInformationDescription: PropTypes.string,
    feeStatus: PropTypes.string,
    favourite: PropTypes.boolean
  }).isRequired,
  userHasPermission: PropTypes.bool.isRequired,
  identity: PropTypes.any,
  userDatas: PropTypes.any,
  errorMessage: PropTypes.any,
  hideError: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
  clubs: PropTypes.array
};

function mapStateToProps(state) {
  return {
    popupCompetitionRegistrationForm:
      state.flags.popupOpen.popupCompetitionRegistrationForm,
    paymentValid:
      state.identity && state.identity.userData
        ? state.identity.userData.paymentValid == "true" ||
          state.identity.userData.paymentValid == true
        : false
  };
}

export default connect(mapStateToProps)(
  withStyles(styles)(translate(withMobileDialog()(CompetitionShow)))
);
