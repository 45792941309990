import React from "react";
import {
  List,
  Datagrid,
  Edit,
  Create,
  SimpleForm,
  TextField,
  EditButton,
  TextInput,
  BooleanInput,
  NullableBooleanInput,
  BooleanField,
  Filter
} from "react-admin";
import Icon from "@material-ui/icons/LocalTaxi";

export const ClientIcon = Icon;

const ClientFilter = props => (
  <Filter {...props}>
    <TextInput elStyle={{ width: 380 }} source="name" alwaysOn />
    <NullableBooleanInput
      source="active"
      options={{ defaultToggled: true }}
      alwaysOn
    />
  </Filter>
);

export const ClientList = props => (
  <List {...props} filters={<ClientFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="active" />
      <EditButton basePath="/clients" />
    </Datagrid>
  </List>
);

const ClientTitle = ({ record }) => {
  return <span>Client {record ? `"${record.license}"` : ""}</span>;
};

export const ClientEdit = props => (
  <Edit title={<ClientTitle />} {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="active" />
    </SimpleForm>
  </Edit>
);

export const ClientCreate = props => (
  <Create title="Create a Client" {...props}>
    <SimpleForm redirect="list">
      <TextInput source="name" />
    </SimpleForm>
  </Create>
);
