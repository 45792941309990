import {
  SET_ORDER,
  SET_ORDER_BY,
  SET_PAGE,
  SET_PER_PAGE,
  SET_FILTERS
} from "./filterSettingsActions";
import { defaultRowsPerPageOption } from "../utils/paging";

const INITIAL_STATE = {
  orderBy: "id",
  order: "asc",
  page: 0,
  perPage: defaultRowsPerPageOption,
  filters: {}
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case SET_ORDER_BY:
      return {
        ...state,
        orderBy: data.orderBy
      };

    case SET_ORDER:
      return {
        ...state,
        order: data.order
      };
    case SET_PAGE:
      return {
        ...state,
        page: data.page
      };
    case SET_PER_PAGE:
      return {
        ...state,
        perPage: data.perPage
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: data.filters
      };
    default:
      return state;
  }
};

export default reducer;
