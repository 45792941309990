// React imports
import React, { Component } from "react";
import { Link } from "react-router-dom";

// React Admin imports
import { translate } from "react-admin";

// Material Ui imports
import { withStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

// React Admin imports
import { Responsive } from "react-admin";

// Icon imports
import Navigation from "@material-ui/icons/Navigation";
import Phone from "@material-ui/icons/Phone";
import Mail from "@material-ui/icons/Mail";

import { Icon as MdiIcon } from "@mdi/react";
import { mdiFacebook, mdiTwitter, mdiInstagram } from "@mdi/js";

const styles = {
  footerBackground: {
    backgroundColor: "#ffffff"
  },
  footerCol: {
    backgroundColor: "#ffffff",
    padding: "30px 30px"
  },
  logo: {
    marginBottom: "15px"
  },
  simpleBtn: {
    color: "#4d4d4d",
    height: "36px",
    margin: 0,
    padding: 0,
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 19,
    backgroundColor: "transparent"
  },
  footerList: {
    marginBottom: "20px"
  },
  footerListItem: {
    paddingLeft: "0px",
    paddingTop: "0px",
    paddingBottom: "0px"
  },
  footerListItemIcon: {
    color: "#4F6982",
    marginRight: "0px"
  },
  footerColHeadline: {
    color: "#4F6982",
    paddingLeft: "5px",
    marginBottom: "10px"
  },
  footerLink: {
    paddingLeft: "8px",
    lineHeight: "32px"
  },
  socialMediaBtn: {
    backgroundColor: "#bcbcbc",
    marginRight: "8px",
    marginBottom: "8px"
  },
  link: {
    textDecoration: "none"
  }
};

const SocialMediaSegment = props => {
  if (!props.show) return null;
  return (
    <Grid item xs={4}>
      <div style={styles.footerCol}>
        <Typography variant="headline" style={styles.footerColHeadline}>
          {translate("footer.socialMedia")}
        </Typography>
        <IconButton
          style={styles.socialMediaBtn}
          aria-label="Facebook"
          variant="fab"
        >
          <MdiIcon path={mdiFacebook} size={1} color="#ffffff" />
        </IconButton>
        <IconButton
          style={styles.socialMediaBtn}
          aria-label="Twitter"
          variant="fab"
        >
          <MdiIcon path={mdiTwitter} size={1} color="#ffffff" />
        </IconButton>
        <IconButton
          style={styles.socialMediaBtn}
          aria-label="Twitter"
          variant="fab"
        >
          <MdiIcon path={mdiInstagram} size={1} color="#ffffff" />
        </IconButton>
      </div>
    </Grid>
  );
};

class Footer extends Component {
  // eslint-disable-next-line react/no-deprecated
  /*componentWillMount() {
    const { width } = this.props;
  }*/

  render() {
    const { classes, translate, ...rest } = this.props;

    return (
      <Responsive
        medium={
          <Grid container spacing={0} style={styles.footerBackground}>
            <Grid item xs={12} sm={6} md={4}>
              <div style={styles.footerCol}>
                <div style={styles.logo}>
                  <img
                    src="images/comp_sign_Logo_cmyk.png"
                    width="150px"
                    alt=""
                  />
                </div>
                <List style={styles.footerList}>
                  <ListItem style={styles.footerListItem}>
                    <ListItemIcon>
                      <Navigation style={styles.footerListItemIcon} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body1">
                        {translate("footer.address")}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem style={styles.footerListItem}>
                    <ListItemIcon>
                      <Phone style={styles.footerListItemIcon} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body1">
                        {translate("footer.phone")}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                  <ListItem style={styles.footerListItem}>
                    <ListItemIcon>
                      <Mail style={styles.footerListItemIcon} />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body1">
                        {translate("footer.email")}
                      </Typography>
                    </ListItemText>
                  </ListItem>
                </List>
                <Typography variant="body1">
                  {translate("footer.copyright")}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <div style={styles.footerCol}>
                <Typography variant="headline" style={styles.footerColHeadline}>
                  {translate("footer.links")}
                </Typography>
                <List style={styles.footerList}>
                  <Link to="/impressum" style={styles.link}>
                    <ListItem button style={styles.footerListItem}>
                      <ListItemText>
                        <Typography variant="body1" style={styles.footerLink}>
                          {translate("footer.impressum")}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </Link>
                  <Link to="/datenschutz" style={styles.link}>
                    <ListItem button style={styles.footerListItem}>
                      <ListItemText>
                        <Typography variant="body1" style={styles.footerLink}>
                          {translate("footer.dataProtection")}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </Link>
                  <Link to="/conditions" style={styles.link}>
                    <ListItem button style={styles.footerListItem}>
                      <ListItemText>
                        <Typography variant="body1" style={styles.footerLink}>
                          {translate("footer.conditions")}
                        </Typography>
                      </ListItemText>
                    </ListItem>
                  </Link>
                </List>
              </div>
            </Grid>
            <SocialMediaSegment show={false} />
          </Grid>
        }
      />
    );
  }
}

export default withStyles(styles)(translate(Footer));
