import React from "react";
import { Title, translate } from "react-admin";
import CardActions from "@material-ui/core/CardActions";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid
} from "@material-ui/core";
import { Field, Form, reduxForm } from "redux-form";
import Typography from "@material-ui/core/Typography";
import ErrorMessage from "../ErrorMessage";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { getOrderItemsForCompetition } from "../../utils/restUtils";
import { setError } from "../../reducer/errorActions";
import {
  isAdmin,
  isClubAdmin,
  isPortalAdmin,
  isShooter
} from "../../utils/utils";
import CustomAutocompleteInput from "../CustomAutocompleteInput";

const styles = {
  fullWidth: {
    width: "100%"
  },
  changeBtn: {
    color: "#ffffff",
    backgroundColor: "#7C757B",
    marginTop: "50px",
    width: "100%"
  }
};

const required = (value, allValues, props) =>
  value !== undefined && value !== "" && value !== null
    ? undefined
    : props.translate("FIELD_IS_REQUIRED");

const renderCustomLabel = label => {
  const labelStyle = {
    color: "gray",
    fontSize: "12px"
  };
  return <label style={labelStyle}>{label}</label>;
};

const renderCustomAutocompleteInput = ({
  input,
  label,
  type,
  options,
  source,
  translate,
  validate,
  defaultValue,
  isMulti,
  isDisabled,
  meta: { touched, error, warning, invalid }
}) => {
  return (
    <>
      <CustomAutocompleteInput
        validate={validate}
        {...input}
        isMulti={isMulti}
        isDisabled={isDisabled}
        invalid={invalid}
        placeholder={label}
        source={source}
        type={type}
        options={options}
        translate={translate}
        defaultValue={defaultValue}
        label={renderCustomLabel(label)}
        touched={touched}
        error={error}
        warning={warning}
        isClearable={false}
      />
      {touched &&
        ((error && (
          <Typography>
            <span style={{ color: "red", fontSize: "12px" }}>
              {translate(error)}
            </span>
          </Typography>
        )) ||
          (warning && <p>{warning}</p>))}
    </>
  );
};

class OrderItemChange extends React.Component {
  state = {
    competition: this.props.orderItem.competition,
    source: this.props.orderItem,
    orderItems: [],
    squadItems: []
  };

  componentDidMount() {
    getOrderItemsForCompetition(this.state.competition.id)
      .then(orderItems => this.setState({ orderItems: orderItems }))
      .catch(err => this.props.dispatch(setError(err)));
  }

  render() {
    const competition = this.state.competition || {};
    const source = this.state.source || {};
    const props = this.props;
    const { submitting, handleSubmit } = this.props;

    let onSubmit = values => {
      const target = this.state.orderItems.find(o => o.id === values.targetId);

      this.setState({ loading: true }, () => {
        new Promise(resolve => {
          props.onSubmit(this.state.source, target);
          resolve();
        }).finally(() => this.setState({ loading: false }));
      });
    };

    const chooseSquad = (event, squadId) => {
      if (squadId) {
        const items = this.state.orderItems.filter(o => o.squad.id === squadId);
        items.forEach(
          item => (item.name = item.firstName + " " + item.lastName)
        );
        this.setState({ squadItems: items });
      }
    };

    return (
      <Card elevation={0}>
        <Title title="TITLE" />
        <CardActions>Actions</CardActions>
        <CardContent>
          <Grid container spacing={0}>
            <Form
              onSubmit={handleSubmit(onSubmit.bind(this))}
              style={{ minHeight: "512px", width: "100%", minWidth: "512px" }}
            >
              <Grid item xs={12}>
                <Typography variant="headline">
                  {props.translate("resources.orderList.changeHeader")}
                </Typography>
                <div style={styles.spacer} />
                <Typography variant="subheading">
                  {competition.name} |{" "}
                  {moment(competition.competitionDate)
                    .format(competition.competitionEndDate ? "DD.MM" : "L")
                    .toString()}
                  {competition.competitionEndDate
                    ? " - " +
                      moment(competition.competitionEndDate)
                        .format("L")
                        .toString()
                    : null}
                </Typography>
                <Typography variant="subheading">
                  {source.squad.name} {" | "}
                  {source.firstName} {source.lastName}
                </Typography>
                <Field
                  id="squad"
                  name="squad"
                  source="squad"
                  label={"Squad"}
                  validate={[required]}
                  options={competition.squads}
                  translate={props.translate}
                  formName={props.form}
                  component={renderCustomAutocompleteInput}
                  onChange={chooseSquad}
                />
                <Field
                  id="targetId"
                  name="targetId"
                  source="targetId"
                  label={props.translate("resources.orderItemList.title")}
                  validate={[required]}
                  options={this.state.squadItems}
                  translate={this.props.translate}
                  formName={props.form}
                  component={renderCustomAutocompleteInput}
                  isDisabled={!this.state.squadItems}
                />
                <Button
                  disabled={submitting}
                  variant="contained"
                  style={styles.changeBtn}
                  type="submit"
                >
                  {submitting ? <CircularProgress /> : null}
                  {props.translate("resources.orderList.changeOrderItemSquad")}
                </Button>
              </Grid>
            </Form>
            <ErrorMessage
              errorMessage={props.errorMessage}
              open={!!props.errorMessage}
              onClose={props.hideError}
              translate={props.translate}
            />
          </Grid>
        </CardContent>
      </Card>
    );
  }
}

OrderItemChange.propTypes = {
  orderItem: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    myForm: state.form.orderItemChange
  };
}

export default translate(
  reduxForm({
    // a unique name for the form
    form: "orderItemChange"
  })(connect(mapStateToProps)(OrderItemChange))
);
