import React, { Component, Fragment } from "react";
import ReservationCodesList from "../../components/pages/ReservationCodesList";
import { translate } from "react-admin";
import { getRservationCodes } from "../../utils/restUtils";
import { setError } from "../../reducer/errorActions";
import { connect } from "react-redux";
import moment from "moment";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { WithPermissions } from "ra-core";
import { isAdmin, isClubAdmin } from "../../utils/utils";
import Typography from "@material-ui/core/Typography";
import PageTitle from "../../components/PageTitle";
import download from "downloadjs";
import { exportReservationCodeList } from "../../utils/restUtils";
import { defaultRowsPerPageOption } from "../../utils/paging";

class ReservationCodeListPage extends Component {
  state = {
    reservationCodes: [],
    currentPage: 0,
    rowsPerPage: defaultRowsPerPageOption,
    totalItemsCount: 0,
    order: "desc",
    property: "id",
    filters: []
  };

  getData = (page, rowsPerPage, property, order, filter) => {
    getRservationCodes(page, rowsPerPage, property, order, filter).then(
      data => {
        this.decorateData(data);
        this.setState({
          reservationCodes: data.data,
          totalItemsCount: data.total
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  decorateData = data => {
    for (let i = 0; i < data.data.length; i++) {
      let item = data.data[i];
      item.createdAt = moment(item.createdAt)
        .format("DD.MM.YYYY HH:mm")
        .toString();
      if (item.used == true) {
        item.used = <CheckIcon />;
      } else {
        item.used = <ClearIcon />;
      }
    }
  };

  handlePageChange = (page, rowsPerPage) => {
    this.getData(
      page,
      rowsPerPage,
      this.state.property,
      this.state.order,
      this.state.filters
    );
    this.setState({ currentPage: page, rowsPerPage: rowsPerPage });
  };

  handleSort = (property, order) => {
    this.getData(
      this.state.currentPage,
      this.state.rowsPerPage,
      property,
      order,
      this.state.filters
    );
    this.setState({ property: property, order: order });
  };

  handleFilter = e => {
    var obj = this.state.filters;
    obj[e.target.id] = e.target.value;

    this.getData(
      this.state.currentPage,
      this.state.rowsPerPage,
      this.state.property,
      this.state.order,
      obj
    );
    this.setState({ filters: obj });
  };

  componentDidMount = () => {
    this.getData(
      this.state.currentPage,
      this.state.rowsPerPage,
      this.state.property,
      this.state.order,
      this.state.filters
    );
  };

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  export = () => {
    exportReservationCodeList(
      this.state.currentPage,
      this.state.rowsPerPage,
      this.state.property,
      this.state.order,
      this.state.filters
    )
      .then(response => {
        return response.blob();
      })
      .then(
        data => {
          download(
            data,
            this.createFilename("Reservierungscodes"),
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
  };

  createFilename(name) {
    return (
      name +
      "-" +
      new Date().getFullYear() +
      "-" +
      (new Date().getMonth() + 1) +
      "-" +
      new Date().getDate() +
      "_" +
      new Date().getHours() +
      "_" +
      new Date().getMinutes() +
      "_" +
      new Date().getSeconds() +
      ".xlsx"
    );
  }

  render = () => (
    <WithPermissions
      render={({ permissions }) => (
        <Fragment>
          <Typography gutterBottom variant="headline">
            {this.props.translate(
              isClubAdmin(permissions)
                ? "resources.reservationCodes.titleClubAdmin"
                : "resources.reservationCodes.titleAdmin"
            )}
          </Typography>
          <ReservationCodesList
            reservationCodes={this.state.reservationCodes}
            competitionGroups={this.state.competitionGroups}
            page={this.state.currentPage}
            rowsPerPage={this.state.rowsPerPage}
            total={this.state.totalItemsCount}
            sortCallback={this.handleSort}
            errorMessage={this.props.httpErrorMsg}
            handleChangePage={this.handlePageChange}
            hideError={this.hideErrorMessage}
            translate={this.props.translate}
            disable_keys={["id", "userName"]}
            translationPrefix={"resources.competitionList"}
            dateParseString={moment.HTML5_FMT.DATETIME_LOCAL_MS}
            momentFormat={"L"}
            locale={"de-at"}
            handleFilter={this.handleFilter}
            generateCodeLinkAction={() =>
              this.props.history.push("/reservationCodes")
            }
            export={this.export}
          />
        </Fragment>
      )}
    />
  );
}
const mapStateToProps = state => {
  return {
    httpErrorMsg: state.error.httpError.message
  };
};
export default connect(mapStateToProps)(translate(ReservationCodeListPage));
