import { InputLabel, withStyles, Typography } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";

class CustomAutocompleteInput extends Component {
  state = {
    multi: null,
    errors: []
  };

  customStyles = {
    selectContainer: base => ({
      ...base,
      margin: "200px",
      padding: "0px"
    }),
    singleValue: base => ({
      ...base,
      margin: "0px"
    }),
    placeholder: base => ({
      ...base,
      margin: "0px"
    }),
    input: base => ({
      ...base,
      margin: "0px"
    }),
    control: (base, state) => ({
      ...base,
      borderRadius: "0px",
      borderTop: "0px",
      borderLeft: "0px",
      borderRight: "0px",
      // This line disable the blue border
      boxShadow: state.isFocused ? 0 : 0,
      borderBottom: state.isFocused
        ? (this.props.touched && this.props.error && "2px solid red") ||
          "2px solid #303f9f"
        : (this.props.touched && this.props.error && "2px solid red") ||
          "1px solid rgba(0, 0, 0, 0.42)",
      "&:hover": {
        borderBottom: state.isFocused
          ? (this.props.touched && this.props.error && "2px solid red") ||
            "2px solid #303f9f"
          : (this.props.touched && this.props.error && "2px solid red") ||
            "2px solid rgba(0, 0, 0, 0.87)"
      }
    }),
    valueContainer: base => ({
      ...base,
      padding: "0px 0px"
    })
  };

  findOption(obj, needle) {
    for (var i = 0; i < obj.length; i++) {
      if (obj[i].value == needle) {
        return obj[i];
      }
    }
  }

  componentDidMount = () => {
    if (this.props && this.props.defaultValue)
      this.handleChangeMulti(
        this.findOption(
          this.formatOptions(this.props.options),
          this.props.defaultValue
        )
      );
  };

  getIds = values => {
    let ids = [];
    if (!values || values.length <= 0) return ids;
    values.forEach(value => {
      if (value.id !== undefined && value.id !== null) ids.push(value.id);
    });
    return ids;
  };

  handleChangeMulti = value => {
    this.setState({ multi: value });
    const { onChange } = this.props;
    if (
      (value && value.id != undefined && value.id != null) ||
      this.props.isMulti
    )
      return onChange(this.props.isMulti ? this.getIds(value) : value.id);
    else return onChange(value);
  };

  handleBlur = () => {
    const { onBlur } = this.props;
    return this.state.multi &&
      this.state.multi.id != undefined &&
      this.state.multi.id != null
      ? onBlur(this.state.multi.id)
      : onBlur(this.getIds(this.state.multi));
  };

  formatOptions = options => {
    let optionList = [];
    if (options)
      options.map((option, key) =>
        optionList.push({
          id: option.id,
          value: option.id,
          label: option.name
        })
      );
    return optionList;
  };

  render() {
    const multi = this.state.multi;
    const isMulti = this.props.isMulti;
    var labelStyle = {
      color: "white",
      fontSize: "12px",
      visibility: "hidden"
    };
    return (
      <>
        {multi ? (
          <InputLabel style={{ color: "grey", fontSize: "12px" }}>
            {this.props.label}
          </InputLabel>
        ) : (
          <label style={labelStyle}>placeholder</label>
        )}
        <Select
          isDisabled={this.props.isDisabled}
          {...this.props.input}
          blurInputOnSelect={false}
          isClearable={
            this.props.isClearable != null ? this.props.isClearable : true
          }
          styles={this.customStyles}
          className={this.props.className}
          placeholder={
            (this.props.touched &&
              (this.props.error && (
                <span style={{ color: "red", margin: "0px" }}>
                  {this.props.translate(this.props.placeholder)}
                </span>
              ))) || (
              <span style={{ margin: "0px" }}>
                {this.props.translate(this.props.label)}
              </span>
            )
          }
          components={{
            IndicatorSeparator: () => null
          }}
          value={multi}
          autoWidth={true}
          options={this.formatOptions(this.props.options)}
          onChange={this.handleChangeMulti}
          onFocus={this.props.onFocus}
          onBlur={this.handleBlur}
          onDrop={this.props.onDrop}
          onDragStart={this.props.onDragStart}
          isMulti={isMulti}
          onKeyDown={e => {
            if (e.keyCode === 27) {
              this.handleChangeMulti(null);
            }
            if (e.keyCode === 8 && !isMulti) {
              this.handleChangeMulti(null);
            }
          }}
        />
        <div
          style={{ marginBottom: "5px", minHeight: "1px", minWidth: "1px" }}
        ></div>
      </>
    );
  }
}
export default connect()(CustomAutocompleteInput);
