import React, { Component } from "react";
import { translate } from "react-admin";
import { verifyToken } from "../../utils/restUtils";
import { togglePopup } from "../../reducer/flagActions";
import { connect } from "react-redux";
import ConfirmRegistration from "../../components/pages/ConfirmRegistration";

class ConfirmRegistrationPage extends Component {
  state = {
    token: null,
    verify: false,
    showLoginLink: false,
    showError: false
  };

  componentDidMount = () => {
    let { token } = this.props.match.params;
    this.setState({ token: token, verify: true }, () => {
      verifyToken(token).then(
        success => this.setState({ verify: false, showLoginLink: true }),
        error => this.setState({ verify: false, showError: true })
      );
    });
  };

  goToLogin = () => {
    this.props.dispatch(togglePopup("popupLogin"));
    this.props.history.push("/competitionList");
  };

  render = () => (
    <ConfirmRegistration
      translate={this.props.translate}
      verify={this.state.verify}
      showLoginLink={this.state.showLoginLink}
      showError={this.state.showError}
      goToLogin={this.goToLogin}
    />
  );
}
const mapStateToProps = state => ({});
export default connect(mapStateToProps)(translate(ConfirmRegistrationPage));
