// React imports
import React from "react";

// Redux imports
import { connect } from "react-redux";

// Other imports
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

const styles = {
  //color: "red",
  marginBottom: "20px"
};

const PageTitle = props => (
  <Grid item xs={12}>
    <Typography variant="display1" style={styles}>
      {props.title}
    </Typography>
  </Grid>
);

PageTitle.propTypes = {
  title: PropTypes.string.isRequired
};
export default connect()(PageTitle);
