import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Title } from "react-admin";
import { Form, Field, reduxForm, blur, touch } from "redux-form";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import Button from "@material-ui/core/Button";
import {
  renderSelectField,
  renderTextField,
  renderCheckbox
} from "../inputs/renderMaterialFields";
import { required, password, passwordEquals } from "../inputs/validator";
import { translate } from "react-admin";
import ErrorMessage from "../ErrorMessage";
import { asyncUsernameAndEmailValidator } from "../../components/inputs/validator";
import StepButton from "@material-ui/core/StepButton";
import { FormDataConsumer } from "ra-core";
import { austrianStates, germanStates } from "../../utils/states";
import CustomAutocompleteInput from "../CustomAutocompleteInput";
import countries from "./country-codes-case-lower.json";

const RenderCountryOptions = () => {
  return countries.map(country => {
    return <option value={country.countryCode}>{country.countryName}</option>;
  });
};

const styles = {
  simpleTitle: {
    marginBottom: "30px"
  },
  stepperContent: {
    padding: "24px"
  }
};

const renderCustomLabel = label => {
  var labelStyle = {
    color: "gray",
    fontSize: "16px"
  };
  return <label style={labelStyle}>{label}</label>;
};

const renderMultiSelectInput = ({
  input,
  label,
  type,
  options,
  source,
  translate,
  validate,
  defaultValue,
  isMulti,
  meta: { touched, error, warning, invalid }
}) => (
  <>
    <CustomAutocompleteInput
      validate={validate}
      {...input}
      isMulti={isMulti}
      invalid={invalid}
      placeholder={label}
      source={source}
      type={type}
      options={options}
      translate={translate}
      defaultValue={defaultValue}
      label={renderCustomLabel(label)}
      touched={touched}
      error={error}
      warning={warning}
    />
    {touched &&
      ((error && (
        <Typography>
          <span style={{ color: "red", fontSize: "12px" }}>
            {translate(error)}
          </span>
        </Typography>
      )) ||
        (warning && <p>{warning}</p>))}
  </>
);

const renderCustomAutocompleteInput = ({
  input,
  label,
  type,
  options,
  source,
  translate,
  validate,
  defaultValue,
  onBlur,
  meta: { touched, error, warning, invalid }
}) => (
  <>
    <CustomAutocompleteInput
      validate={validate}
      {...input}
      invalid={invalid}
      placeholder={label}
      source={source}
      type={type}
      options={options}
      translate={translate}
      defaultValue={defaultValue}
      label={renderCustomLabel(label)}
      touched={touched}
      error={error}
      warning={warning}
    />
    {touched &&
      ((error && (
        <Typography>
          <span style={{ color: "red", fontSize: "12px" }}>
            {translate(error)}
          </span>
        </Typography>
      )) ||
        (warning && <p>{warning}</p>))}
  </>
);

function StateOptions(props) {
  return props.states.map(item => <option value={item.id}>{item.name}</option>);
}

function getSteps() {
  return ["Profildaten", "Kontaktdaten", "Schützendaten"];
}

function getStepContent(step, props) {
  const { translate } = props;
  return (
    <>
      <div style={{ display: step == 0 ? "block" : "none" }}>
        <div style={styles.stepperContent}>
          {
            <div>
              <Field
                name="username"
                id="username"
                component={renderTextField}
                validate={required}
                type="text"
                label={translate(
                  "resources.users.registerForm.fields.username"
                )}
              />
            </div>
          }
          {
            <div>
              <Field
                name="email"
                id="email"
                component={renderTextField}
                validate={required}
                type="email"
                label={translate("resources.users.registerForm.fields.email")}
              />
            </div>
          }

          {
            <div>
              <Field
                name="password"
                id="password"
                component={renderTextField}
                validate={[required, password]}
                type="password"
                label={translate(
                  "resources.users.registerForm.fields.password"
                )}
              />
            </div>
          }

          {
            <div>
              <Field
                name="passwordValid"
                id="passwordValid"
                component={renderTextField}
                validate={[required, passwordEquals]}
                type="password"
                label={translate(
                  "resources.users.registerForm.fields.passwordValidate"
                )}
              />
            </div>
          }

          {
            <div>
              <Field
                name="selectedLanguage"
                component={renderSelectField}
                validate={[required]}
                label={translate(
                  "resources.users.registerForm.fields.selectedLanguage"
                )}
              >
                <option value="" />
                <option value={"de"}>
                  {translate(
                    "resources.users.registerForm.fields.languages.de"
                  )}
                </option>
                <option value={"en"}>
                  {translate(
                    "resources.users.registerForm.fields.languages.en"
                  )}
                </option>
              </Field>
            </div>
          }
        </div>
      </div>
      <div style={{ display: step == 1 ? "block" : "none" }}>
        <div style={styles.stepperContent}>
          {
            <div>
              <Field
                name="salutation"
                component={renderSelectField}
                validate={required}
                label={translate(
                  "resources.users.registerForm.fields.salutation"
                )}
              >
                <option value="" />
                <option value={"mrs"}>
                  {translate(
                    "resources.users.registerForm.fields.salutations.mrs"
                  )}
                </option>
                <option value={"mr"}>
                  {translate(
                    "resources.users.registerForm.fields.salutations.mr"
                  )}
                </option>
              </Field>
            </div>
          }

          {
            <div>
              <Field
                name="degree"
                id="degree"
                component={renderTextField}
                validate={[]}
                type="text"
                label={translate("resources.users.registerForm.fields.degree")}
              />
            </div>
          }

          {
            <div>
              <Field
                name="firstName"
                id="firstName"
                component={renderTextField}
                validate={required}
                type="text"
                label={translate(
                  "resources.users.registerForm.fields.firstName"
                )}
              />
            </div>
          }

          {
            <div>
              <Field
                name="lastName"
                id="lastName"
                component={renderTextField}
                validate={required}
                type="text"
                label={translate(
                  "resources.users.registerForm.fields.lastName"
                )}
              />
            </div>
          }

          {
            <div>
              <Field
                name="address"
                id="address"
                component={renderTextField}
                validate={required}
                type="text"
                label={translate("resources.users.registerForm.fields.address")}
              />
            </div>
          }

          {
            <div>
              <Field
                name="zip"
                id="zip"
                component={renderTextField}
                validate={required}
                type="text"
                label={translate("resources.users.registerForm.fields.zip")}
              />
            </div>
          }

          {
            <div>
              <Field
                name="city"
                id="city"
                component={renderTextField}
                validate={required}
                type="text"
                label={translate("resources.users.registerForm.fields.city")}
              />
            </div>
          }

          {
            <div>
              <Field
                name="country"
                component={renderSelectField}
                validate={[required]}
                label={translate("resources.users.registerForm.fields.country")}
              >
                <option value="" />
                <RenderCountryOptions />
              </Field>
            </div>
          }

          {
            <FormDataConsumer source="">
              {({ formData }) =>
                formData &&
                formData.country &&
                (formData.country === "de" || formData.country === "at") ? (
                  <div>
                    <Field
                      name="state"
                      id="state"
                      component={renderSelectField}
                      validate={[required]}
                      label={translate(
                        "resources.users.registerForm.fields.state"
                      )}
                    >
                      <option value="" />
                      {formData &&
                      formData.country &&
                      formData.country === "at" ? (
                        <StateOptions states={austrianStates} />
                      ) : formData &&
                        formData.country &&
                        formData.country === "de" ? (
                        <StateOptions states={germanStates} />
                      ) : null}
                    </Field>
                  </div>
                ) : null
              }
            </FormDataConsumer>
          }

          {
            <div>
              <Field
                name="telephone"
                id="telephone"
                component={renderTextField}
                validate={[]}
                type="text"
                label={translate(
                  "resources.users.registerForm.fields.telephone"
                )}
              />
            </div>
          }
        </div>
      </div>
      <div style={{ display: step == 2 ? "block" : "none" }}>
        <div style={styles.stepperContent}>
          {
            <div>
              <Field
                name="competitionClass"
                component={renderSelectField}
                validate={[required]}
                label={translate(
                  "resources.users.registerForm.fields.competitionClass"
                )}
              >
                <option value="" />
                {props.competitionClasses
                  ? props.competitionClasses.map((item, key) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                  : null}
              </Field>
            </div>
          }

          {
            <div>
              <Field
                id="division"
                translate={props.translate}
                name="division"
                component={renderSelectField}
                validate={[required]}
                label={props.translate(
                  "resources.users.registerForm.fields.competitionDivision"
                )}
              >
                <option value="" />
                {props.competitionDivisions
                  ? props.competitionDivisions.map((item, key) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                  : null}
              </Field>
            </div>
          }

          {
            <div>
              <Field
                name="factor"
                component={renderSelectField}
                validate={[required]}
                label={translate(
                  "resources.users.registerForm.fields.competitionFactor"
                )}
              >
                <option value="" />
                {props.competitionFactors
                  ? props.competitionFactors.map((item, key) => (
                      <option value={item.id}>{item.name}</option>
                    ))
                  : null}
              </Field>
            </div>
          }

          {
            <div>
              <Field
                name="club"
                source="club.id"
                component={renderCustomAutocompleteInput}
                validate={[required]}
                filter={{ unlisted: true }}
                options={props.clubs}
                translate={props.translate}
                label={translate("resources.users.registerForm.fields.club")}
              />
            </div>
          }

          {
            <div>
              <Field
                name="alias"
                id="alias"
                component={renderTextField}
                validate={[]}
                type="text"
                label={translate("resources.users.registerForm.fields.alias")}
              />
            </div>
          }

          {
            <div>
              <Field
                name="cowboyName"
                id="cowboyName"
                component={renderTextField}
                validate={[]}
                type="text"
                label={translate(
                  "resources.users.registerForm.fields.cowboyName"
                )}
              />
            </div>
          }

          {
            <div>
              <Field
                name="sassNumber"
                id="sassNumber"
                component={renderTextField}
                validate={[]}
                type="text"
                label={translate(
                  "resources.users.registerForm.fields.sassNumber"
                )}
              />
            </div>
          }

          {
            <div>
              <Field
                name="ipscNumber"
                id="ipscNumber"
                component={renderTextField}
                validate={[]}
                type="text"
                label={translate(
                  "resources.users.registerForm.fields.ipscNumber"
                )}
              />
            </div>
          }

          {
            <div>
              <Field
                name="ipscRegion"
                id="ipscRegion"
                component={renderTextField}
                validate={[]}
                type="text"
                label={translate(
                  "resources.users.registerForm.fields.ipscRegion"
                )}
              />
            </div>
          }

          {
            <div>
              <Field
                name="ipscAlias"
                id="ipscAlias"
                component={renderTextField}
                validate={[]}
                type="text"
                label={translate(
                  "resources.users.registerForm.fields.ipscAlias"
                )}
              />
            </div>
          }

          {
            <div>
              <Field
                name="allowInformations"
                id="allowInformations"
                component={renderCheckbox}
                validate={required}
                label={translate(
                  "resources.users.registerForm.fields.allowInformations"
                )}
              />
            </div>
          }
          {
            <div>
              <Field
                name="conditions"
                component={renderCheckbox}
                validate={required}
                label={translate(
                  "resources.users.registerForm.fields.conditions"
                )}
              />
            </div>
          }
          {
            <div>
              <Field
                name="payment"
                component={renderCheckbox}
                validate={required}
                label={translate("resources.users.registerForm.fields.payment")}
              />
            </div>
          }
        </div>
      </div>
    </>
  );
}

class UserRegisterForm extends Component {
  state = {
    activeStep: 0,
    completed: {},
    errorSteps: {}
  };

  totalSteps = () => {
    return getSteps().length;
  };

  keyToStep = {
    username: 0,
    email: 0,
    password: 0,
    passwordValidate: 0,
    selectedLanguage: 0,
    salutation: 1,
    title: 1,
    firstName: 1,
    lastName: 1,
    address: 1,
    zip: 1,
    city: 1,
    country: 1,
    state: 1,
    telephone: 1,
    competitionClass: 2,
    division: 2,
    factor: 2,
    club: 2,
    alias: 2,
    cowboyName: 2,
    sassNumber: 2,
    ipscAlias: 2,
    ipscNumber: 2,
    ipscRegion: 2,
    allowInformations: 2,
    conditions: 2,
    payment: 2
  };

  handleNext = () => {
    Object.keys(this.props.myForm.registeredFields).forEach(key => {
      if (
        typeof this.keyToStep[key] !== "undefined" &&
        this.keyToStep[key] <= this.state.activeStep
      ) {
        this.props.dispatch(touch("userRegisterForm", key));
        this.props.dispatch(blur("userRegisterForm", key));
      }
    });
    let activeStep;
    this.setStepErrors();

    if (this.isLastStep() && !this.allStepsCompleted()) {
      // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
      const steps = getSteps();
      activeStep = steps.findIndex((step, i) => !(i in this.state.completed));
    } else {
      activeStep = this.state.activeStep + 1;
    }
    this.setState({
      activeStep
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1
    });
  };

  handleStep = step => () => {
    this.setState({
      activeStep: step
    });
  };

  handleComplete = () => {
    const { completed } = this.state;
    completed[this.state.activeStep] = true;
    this.setState({
      completed
    });
    this.handleNext();
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: {}
    });
  };

  completedSteps() {
    return Object.keys(this.state.completed).length;
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps();
  }

  setStepErrors = () => {
    let errorSteps = {};
    if (!this.props.myForm.syncErrors) {
      this.setState({ errorSteps: {} });
      return;
    }
    Object.keys(this.props.myForm.syncErrors).forEach(key => {
      if (
        typeof this.keyToStep[key] !== "undefined" &&
        this.keyToStep[key] <= this.state.activeStep
      ) {
        errorSteps[this.keyToStep[key]] = 1;
      }
    });
    this.setState({ errorSteps });
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="display1" style={styles.simpleTitle}>
            {this.props.translate("resources.users.registerForm.header")}
          </Typography>

          <Card>
            <Title title="TITLE" />
            <CardActions>Actions</CardActions>
            <CardContent>
              <Form onSubmit={this.props.handleSubmit}>
                <Stepper nonLinear activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const labelProps = {};

                    if (this.state.errorSteps[index] === 1) {
                      labelProps.error = true;
                    }
                    return (
                      <Step key={label}>
                        <StepButton
                          onClick={this.handleStep(index)}
                          completed={this.state.completed[index]}
                        >
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </StepButton>
                      </Step>
                    );
                  })}
                </Stepper>
                <Typography style={styles.instructions}>
                  {getStepContent(activeStep, this.props)}
                </Typography>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={this.handleBack}
                    style={styles.button}
                  >
                    {this.props.translate("resources.users.registerForm.back")}
                  </Button>
                  {activeStep !== steps.length - 1 ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleNext}
                      style={{
                        backgroundColor: "#4F6982",
                        color: "#ffffff"
                      }}
                    >
                      {this.props.translate(
                        "resources.users.registerForm.next"
                      )}
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      style={{ backgroundColor: "#4F6982", color: "#ffffff" }}
                      onClick={() => {
                        Object.keys(this.props.myForm.registeredFields).forEach(
                          key => {
                            this.props.dispatch(touch("userRegisterForm", key));
                            this.props.dispatch(blur("userRegisterForm", key));
                          }
                        );
                        this.setStepErrors();
                        if (this.props.valid) {
                          this.props.handleSubmit();
                        }
                      }}
                    >
                      {this.props.translate(
                        "resources.users.registerForm.register"
                      )}
                    </Button>
                  )}
                </div>
              </Form>

              <ErrorMessage
                errorMessage={this.props.errorMessage}
                open={this.props.errorMessage ? true : false}
                onClose={this.props.hideError}
                translate={this.props.translate}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }
}

UserRegisterForm.propTypes = {
  handleSubmit: PropTypes.func,
  competitionClasses: PropTypes.array.isRequired,
  competitionDivisions: PropTypes.array.isRequired,
  competitionFactors: PropTypes.array.isRequired,
  clubs: PropTypes.array.isRequired,
  errorMessage: PropTypes.string,
  hideError: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    myForm: state.form.userRegisterForm
  };
}

export default translate(
  reduxForm({
    // a unique name for the form
    form: "userRegisterForm",
    asyncValidate: asyncUsernameAndEmailValidator,
    asyncBlurFields: ["username", "email"]
  })(connect(mapStateToProps)(UserRegisterForm))
);
