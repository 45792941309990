// React imports
import React from "react";

// React Admin imports
import { translate } from "react-admin";

// Material imports
import Grid from "@material-ui/core/Grid";
import ErrorMessage from "../ErrorMessage";
import PropTypes from "prop-types";

// Other imports
import { ClubListCard } from "../../components/Cards";
import PageTitle from "../../components/PageTitle";
import { getClubShowImageSrc } from "../../utils/imageUtils";

const ClubList = props => {
  const { translate } = props;
  return (
    <div>
      <Grid container spacing={16}>
        <PageTitle title={translate("resources.clubs.listPage.headline")} />
        {props.clubs.map(record => (
          <Grid item xs={12} sm={6} md={4}>
            <ClubListCard
              imageUrl={getClubShowImageSrc(record.logo)}
              imageTitle="Live from space album cover"
              name={record.name}
              address={record.address}
              zip={record.zip}
              place={record.city}
              contactPerson={record.contactFullName}
              buttonLabel={translate("resources.clubs.listPage.btnClub")}
              btnClickHandler={record.publicLinkAction}
            />
          </Grid>
        ))}

        <ErrorMessage
          errorMessage={props.errorMessage}
          open={props.errorMessage ? true : false}
          onClose={props.hideError}
          translate={props.translate}
        />
      </Grid>
    </div>
  );
};
ClubList.propTypes = {
  clubs: PropTypes.array.isRequired,
  translate: PropTypes.func.isRequired,
  errorMessage: PropTypes.object,
  hideError: PropTypes.func.isRequired
};
export default translate(ClubList);
