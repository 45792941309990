import React, { Component } from "react";
import { REDUX_FORM_NAME, translate, WithPermissions } from "react-admin";
import { connect } from "react-redux";
import { change } from "redux-form";
import ClubCreate from "../../components/pages/ClubCreate";
import { getNotificationTypes } from "../../utils/restUtils";

class ClubCreatePage extends Component {
  componentDidUpdate = props => {};
  componentDidMount = () => {
    getNotificationTypes().then(
      data => {
        this.setState(
          {
            notificationTypes: data ? data : []
          },
          () => {
            let defaultBlocks = [];
            for (let i = 0; i < this.state.notificationTypes.length; i++) {
              defaultBlocks.push({
                text: "",
                type: this.state.notificationTypes[i]
              });
            }
            this.props.dispatch(
              change(REDUX_FORM_NAME, "notificationBlocks", defaultBlocks)
            );
            this.props.dispatch(change(REDUX_FORM_NAME, "squads", [{}]));
          }
        );
      },
      () => {}
    );
  };

  render = () => {
    return (
      <WithPermissions
        render={({ permissions }) => <ClubCreate {...this.props} />}
      />
    );
  };
}

const mapStateToProps = state => {
  return {
    httpErrorMsg: state.error.httpError.message,
    permissions: state.identity.userData.permissions
  };
};

export default connect(mapStateToProps)(translate(ClubCreatePage));
