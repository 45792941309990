// React imports
import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";
import { push } from "react-router-redux";

// React Admin imports
import { UserMenu, translate, Responsive, AUTH_LOGIN } from "react-admin";

// Material imports
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";

// Icon imports
import SettingsIcon from "@material-ui/icons/Settings";
import Person from "@material-ui/icons/Person";
import ExpandMore from "@material-ui/icons/ExpandMore";
import ShoppingCart from "@material-ui/icons/ShoppingCart";
import MenuIcon from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";

import { togglePopup, toggleMenu, setPopup } from "../../reducer/flagActions";
import LoginFormPopup from "../../components/LoginFormPopup";
import { userLogin } from "react-admin";
import authProvider from "../../providers/authProvider";
import { changeUser } from "../../reducer/userActions";
import NoPaymentInfo from "../../components/NoPaymentInfo";
import NotSupportedBrowserMessage from "../../components/NotSupportedBrowserMessage";
import LocaleSwitcher from "../../components/LocaleSwitcher";

export const drawerWidth = 300;
export const closedDrawerWidth = 55;

const styles = {
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden"
  },
  spacer: {
    flex: 1
  },
  userBar: {
    display: "inline-flex"
  },
  customAppBar: {
    backgroundColor: "#4F6982",
    height: 120,
    justifyContent: "center"
  },
  toolBar: {
    padding: "0 20px"
  },
  logo: {
    paddingLeft: "18px"
  },
  shoppingCartBtn: {
    color: "#ffffff",
    position: "relative"
  },
  shoppingCartNumber: {
    fontSize: "10px",
    color: "#ffffff",
    backgroundColor: "#7E896E",
    padding: "2px 5px",
    borderRadius: "8px",
    marginLeft: "-12px",
    marginTop: "25px",
    zIndex: 1
  },
  profileBtn: {
    width: "100%",
    margin: "0 0 10px 0",
    justifyContent: "flex-start"
  },
  leftIcon: {
    marginRight: "10px"
  }
};

const UserMenuButton = props => (
  <IconButton edge="end" aria-label="openusermenu" style={{ color: "#ffffff" }}>
    <ExpandMore />
  </IconButton>
);

class CustomAppBar extends Component {
  state = {
    userId: null,
    loginError: null
  };

  toggleMenu = () => {
    this.props.dispatch(toggleMenu("sidebar"));
  };

  openLogin = () => {
    this.props.dispatch(togglePopup("popupLogin"));
  };

  cancelLogin = () => {
    this.props.dispatch(togglePopup("popupLogin"));
  };

  doLogin = () => {
    let loginData = this.props.loginData.values;

    if (loginData) {
      let prom = authProvider(AUTH_LOGIN, loginData);
      prom.then(
        data => {
          this.props.dispatch(changeUser(data));
          this.props.dispatch(setPopup("popupLogin", false));
          //this.props.dispatch(toggleSidebar());
          this.setState({ loginError: null });
        },
        e => {
          this.setState({ loginError: e.message });
        }
      );
    }
  };

  goToHome() {
    window.location.hash = "#/dashboard";
  }

  render() {
    const {
      identity,
      translate,
      userId,
      userFullName,
      userEmail,
      loginOpen,
      loginData,
      userLogin,
      dispatch,
      userPaymentValid,
      userPaymentUntil,
      open,
      cartAmount,
      ...rest
    } = this.props;
    // let userId = 0;

    return (
      <AppBar style={styles.customAppBar} position="static" {...rest}>
        <Responsive
          small={
            <Toolbar style={styles.toolBar}>
              <IconButton onClick={this.toggleMenu} style={styles.menuButton}>
                {open ? <Close /> : <MenuIcon style={{ color: "#ffffff" }} />}
              </IconButton>

              <div style={styles.logo} onClick={this.goToHome}>
                <img
                  src="images/compsign-logo-white.png"
                  width="100px"
                  alt=""
                />
              </div>

              <span style={styles.spacer} />

              {/**
               <RefreshButton
                style={{ color: "#ffffff" }}
                icon={<RefreshIcon />}
                label={""}
              />
               */}

              {/* <IconButton
                style={styles.shoppingCartBtn}
                onClick={() => this.props.dispatch(push("/cart"))}
              >
                <ShoppingCart />
              </IconButton> */}
              {/* <span style={styles.shoppingCartNumber}>{cartAmount}</span> */}

              <div>
                <div style={{ textAlign: "center" }}>
                  <LocaleSwitcher></LocaleSwitcher>
                </div>
                <div>
                  {userId ? (
                    <div>
                      <List>
                        <ListItem>
                          <ListItemText>
                            <IconButton disabled style={styles.shoppingCartBtn}>
                              <Person />
                            </IconButton>
                          </ListItemText>
                          <ListItemSecondaryAction>
                            <UserMenu
                              {...this.props}
                              icon={<UserMenuButton {...this.props} />}
                            >
                              {/*<MenuItemLink
                            to={"profile/" + userId}
                            primaryText={translate("pos.profile")}
                            leftIcon={<SettingsIcon />}
                          />*/}
                              <Button
                                contained="true"
                                href={"#/profile/" + userId}
                                style={styles.profileBtn}
                              >
                                <SettingsIcon style={styles.leftIcon} />{" "}
                                {translate("appBar.profil")}
                              </Button>
                            </UserMenu>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={() => this.openLogin()}
                        style={{ color: "#ffffff", textTransform: "none" }}
                      >
                        {translate("appBar.login")}
                      </Button>
                    </div>
                  )}
                  <LoginFormPopup
                    open={this.props.loginOpen}
                    handleClose={this.cancelLogin}
                    handleLogin={this.doLogin}
                    errorMessage={this.state.loginError}
                  />
                </div>
              </div>
            </Toolbar>
          }
          medium={
            <Toolbar style={styles.toolBar}>
              <IconButton onClick={this.toggleMenu} style={styles.menuButton}>
                {open ? <Close /> : <MenuIcon style={{ color: "#ffffff" }} />}
              </IconButton>

              <div style={styles.logo} onClick={this.goToHome}>
                <img
                  src="images/compsign-logo-white.png"
                  width="250px"
                  alt=""
                />
              </div>

              <span style={styles.spacer} />

              {/**
               <RefreshButton
                style={{ color: "#ffffff" }}
                icon={<RefreshIcon />}
                label={""}
              />
               */}

              {/* <IconButton
                style={styles.shoppingCartBtn}
                onClick={() => this.props.dispatch(push("/cart"))}
              >
                <ShoppingCart />
              </IconButton> */}
              {/* <span style={styles.shoppingCartNumber}>{cartAmount}</span> */}

              <div>
                <div style={{ textAlign: "center" }}>
                  <LocaleSwitcher></LocaleSwitcher>
                </div>
                <div>
                  {userId ? (
                    <div>
                      <List>
                        <ListItem>
                          <ListItemText
                            primary={
                              <Typography
                                variant="h6"
                                style={{
                                  color: "#ffffff",
                                  paddingRight: "10px"
                                }}
                              >
                                {this.props.userFullName}
                              </Typography>
                            }
                            secondary={
                              <Typography
                                variant="caption"
                                style={{
                                  color: "#ffffff",
                                  paddingRight: "10px"
                                }}
                              >
                                {this.props.userEmail}
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction>
                            <UserMenu
                              {...this.props}
                              icon={<UserMenuButton {...this.props} />}
                            >
                              {/*<MenuItemLink
                            to={"profile/" + userId}
                            primaryText={translate("pos.profile")}
                            leftIcon={<SettingsIcon />}
                          />*/}
                              <Button
                                contained="true"
                                href={"#/profile/" + userId}
                                style={styles.profileBtn}
                              >
                                <SettingsIcon style={styles.leftIcon} />{" "}
                                {translate("appBar.profil")}
                              </Button>
                            </UserMenu>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </div>
                  ) : (
                    <div>
                      <Button
                        onClick={() => this.openLogin()}
                        style={{ color: "#ffffff", textTransform: "none" }}
                      >
                        {translate("appBar.login")}
                      </Button>
                    </div>
                  )}
                  <LoginFormPopup
                    open={this.props.loginOpen}
                    handleClose={this.cancelLogin}
                    handleLogin={this.doLogin}
                    errorMessage={this.state.loginError}
                  />
                </div>
              </div>
            </Toolbar>
          }
        />

        <NoPaymentInfo
          open={
            this.props.userId &&
            (this.props.userPaymentValid == false ||
              this.props.userPaymentValid == "false")
              ? true
              : false
          }
          message={translate("appBar.noPaymentUntil")}
        />
        <NotSupportedBrowserMessage
          message={translate("appBar.notSupportedBrowser")}
        />
      </AppBar>
    );
  }
}

const mapStateToProps = state => ({
  userId: state.identity.userData.id,
  userFullName: state.identity.userData.userFullName,
  userEmail: state.identity.userData.userEmail,
  userPaymentUntil: state.identity.userData.paymentUntil,
  userPaymentValid: state.identity.userData.paymentValid,
  loginOpen: state.flags.popupOpen.popupLogin,
  loginData: state.form.userLoginForm,
  cartAmount: state.cart.amount
});

const mapDispatchToProps = dispatch => {
  return { userLogin, dispatch };
};

const enhance = compose(
  translate,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

export default enhance(CustomAppBar);
