import React, { Component } from "react";
import CompetitionCreate from "../../pages/competition/CompetitionCreate";
import { translate } from "react-admin";
import { connect } from "react-redux";

class CompetitionCopyPage extends Component {
  state = {
    toCopy: null
  };

  componentDidMount = () => {
    let { id } = this.props.match.params;
    this.setState({
      toCopy: id
    });
  };

  render = () => (
    <CompetitionCreate
      toCopy={this.state.toCopy}
      {...this.props}
      basePath="/competitions"
      resource="competitions"
    />
  );
}
const mapStateToProps = state => {};
export default connect(mapStateToProps)(translate(CompetitionCopyPage));
