import React, { Component } from "react";
import { translate } from "react-admin";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import CompetitionShow from "../../components/pages/CompetitionShow";
import { setCart } from "../../reducer/cartActions";
import { setError } from "../../reducer/errorActions";
import { togglePopup } from "../../reducer/flagActions";
import { setSuccess } from "../../reducer/successActions";
import countries from "../../components/pages/country-codes-case-lower.json";

import {
  checkPreRegistration,
  checkRegistrationWindow,
  completeOrder,
  createOrder,
  getAllClubs,
  getCompetitionFromPublicID,
  getFavourite,
  getOrderItemsForCompetition,
  getProfile,
  getRegistrations,
  getReservations,
  getUsers
} from "../../utils/restUtils";
import {
  getTimeDiffToNow,
  isAdmin,
  isClubAdmin,
  isGuest,
  isPortalAdmin
} from "../../utils/utils";

class CompetitionShowPage extends Component {
  state = {
    competition: null,
    squads: null,
    userHasPermission: false,
    users: [],
    participants: [],
    signupInitialValues: {
      userFirstName: null,
      userLastName: null,
      userEmail: null,
      club: null
    },
    userDatas: null,
    reservation: false,
    finishModal: false,
    registrationWindow: false,
    squadWaitingListOnlyAvailable: null,
    clubs: []
  };

  resetCart = () => {
    let cartList = [];
    this.props.dispatch(setCart({ amount: cartList.length, list: cartList }));
    localStorage.setItem("cartList", JSON.stringify(cartList));
    localStorage.setItem("cartAmount", cartList.length);
    localStorage.setItem("cartId", null);
  };

  reserveOrder = (token, order) => {
    if (!!order.id && !!token) {
      completeOrder(token, order).then(
        success => {
          this.props.dispatch(setError(null));
          let permissions = this.props.identity.userData.permissions;
          this.props.dispatch(togglePopup("popupCompetitionRegistrationForm"));
          if (
            !isAdmin(permissions) &&
            !isClubAdmin(permissions) &&
            !isPortalAdmin(permissions)
          ) {
            this.setState({ finishModal: true });
            this.props.dispatch(setCart({ amount: success.orderItems.length }));
            localStorage.setItem("cartId", success.id);
            localStorage.setItem("cartAmount", success.orderItems.length);
          } else {
            this.resetCart();
            this.props.history.push("/orderComplete");
          }
          this.setState({ squadWaitingListOnlyAvailable: null });
        },
        e => this.props.dispatch(setError(e))
      );
    } else {
      createOrder(order).then(
        success => {
          this.props.dispatch(setError(null));
          let permissions = this.props.identity.userData.permissions;
          this.props.dispatch(togglePopup("popupCompetitionRegistrationForm"));
          if (
            !isAdmin(permissions) &&
            !isClubAdmin(permissions) &&
            !isPortalAdmin(permissions)
          ) {
            this.setState({ finishModal: true });
            this.props.dispatch(setCart({ amount: success.orderItems.length }));
            localStorage.setItem("cartId", success.id);
            localStorage.setItem("cartAmount", success.orderItems.length);
          } else {
            this.resetCart();
            this.props.history.push("/orderComplete");
          }
          this.setState({ squadWaitingListOnlyAvailable: null });
        },
        e => this.props.dispatch(setError(e))
      );
    }
  };

  closeFinishModal = () => {
    this.setState({ finishModal: false });
  };

  toggleCompetitionRegistrationForm = () => {
    this.props.dispatch(togglePopup("popupCompetitionRegistrationForm"));
  };

  componentDidMount = () => {
    let { id } = this.props.match.params;

    let f = (favorites, registrations) => {
      getCompetitionFromPublicID(id).then(
        success => {
          if (favorites) success.favourite = favorites.includes(success.id);
          if (registrations) {
            success.registered = registrations[success.id] !== undefined;
            success.paid = registrations[success.id];
          }

          this.setState(
            {
              competition: success,
              csDivisions: success.division,
              csFactors: success.factor,
              csClasses: success.competitionClass,
              csSquads: success.squads
            },
            this.getParticipants(success.id),
            this.checkCompetitionRegistrationWindow(success.id),
            this.checkCompetitionPreRegistration(success.id, 0, false)
          );
        },
        e => {
          this.setState({ competition: null });
          this.props.dispatch(setError(e));
        }
      );

      if (this.props.identity.userData.id) {
        getProfile(this.props.identity.userData.id).then(
          data => {
            this.setState({ userDatas: data });
          },
          e => {
            this.props.dispatch(setError(e));
          }
        );

        getReservations(id, this.props.identity.userData.id).then(
          data => {
            this.setState({ reservation: data.data });
          },
          e => {
            this.props.dispatch(setError(e));
          }
        );
      }

      getAllClubs().then(data => {
        this.setState({ clubs: data ? data : [] }, () => {});
      });

      if (
        isAdmin(this.props.identity.userData.permissions) ||
        isClubAdmin(this.props.identity.userData.permissions) ||
        isPortalAdmin(this.props.identity.userData.permissions)
      ) {
        getUsers(1, 9999, { active: true, userRole: "ROLE_USER" }).then(
          data => {
            const users = [];
            data.map((user, key) => {
              users.push({
                id: user.id,
                name: user.lastName + ", " + user.firstName,
                userFirstName: user.firstName,
                userLastName: user.lastName,
                userEmail: user.email,
                value: user.id,
                club: user.club,
                factorId: user.factorId ? user.factorId : null,
                competitionClassId: user.competitionClassId
                  ? user.competitionClassId
                  : null,
                divisionId: user.divisionId ? user.divisionId : null,
                country: user.country,
                state: user.state
              });
            });
            this.setState({ csUsers: users });
          },
          e => {
            this.props.dispatch(setError(e));
          }
        );
      }
      var content = document.getElementById("contentTop");
      content.scrollIntoView(true);
    };

    if (
      this.props.identity.userData.permissions &&
      !isGuest(this.props.identity.userData.permissions)
    ) {
      getFavourite()
        .then(favorites =>
          getRegistrations().then(registrations => f(favorites, registrations))
        )
        .catch(() => f());
    } else {
      f();
    }
  };

  getParticipants = id => {
    getOrderItemsForCompetition(id).then(
      success => {
        this.setState({ participants: success });
      },
      e => {
        this.setState({ participants: [] });
        this.props.dispatch(setError(e));
      }
    );
  };

  checkCompetitionPreRegistration = (competitionId, orderId, toggle) =>
    checkPreRegistration(competitionId, orderId).then(
      data => {
        if (toggle) this.toggleCompetitionRegistrationForm();
        this.setState({ preRegistration: data.data });
      },
      e => this.props.dispatch(setError(e))
    );

  checkCompetitionRegistrationWindow = competitionId =>
    checkRegistrationWindow(competitionId).then(
      data => this.setState({ registrationWindow: data.data }),
      e => this.props.dispatch(setError(e))
    );

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  hideSuccessMessage = () => {
    this.props.dispatch(setSuccess(null));
  };

  getCountryFromCode = () => {
    return countries.find(
      country => country.countryCode === this.state.competition.country
    );
  };

  render() {
    return this.state.competition !== " " && this.state.competition !== null ? (
      <CompetitionShow
        competitionCountry={this.getCountryFromCode()}
        csSquads={this.state.csSquads}
        csDevisions={this.state.csDivisions}
        csFactors={this.state.csFactors}
        csClasses={this.state.csClasses}
        csUsers={this.state.csUsers}
        signup={this.reserveOrder}
        competition={this.state.competition}
        userHasPermission={true}
        errorMessage={this.props.httpErrorMsg}
        hideError={this.hideErrorMessage}
        identity={this.props.identity.userData}
        signupInitialValues={this.state.signupInitialValues}
        momentFormat={"ddd L"}
        userDatas={this.state.userDatas}
        participants={this.state.participants}
        translate={this.props.translate}
        permissions={this.props.identity.userData.permissions}
        competitonId={this.state.competition.id}
        finishModal={this.state.finishModal}
        closeFinishModal={this.closeFinishModal}
        checkPreRegistration={this.checkCompetitionPreRegistration}
        preRegistration={this.state.preRegistration && !this.state.reservation}
        successMessage={this.props.successMessage}
        hideSuccess={this.hideSuccessMessage}
        history={this.props.history}
        registrationWindow={this.state.registrationWindow}
        hideSubscriptions={
          this.state.competition &&
          getTimeDiffToNow(this.state.competition.registrationStart) > 0
            ? true
            : false
        }
        squadWaitingListOnlyAvailable={this.state.squadWaitingListOnlyAvailable}
        clubs={this.state.clubs}
      />
    ) : this.state.competition === " " ? (
      <Redirect to={"/competitionspub"} />
    ) : null;
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    popupCompetitionRegistrationForm:
      state.flags.popupOpen.popupCompetitionRegistrationForm,
    identity: state.identity,
    cart: state.cart,
    httpErrorMsg: state.error.httpError.message,
    successMessage: state.success.successMessage.message
  };
};
export default connect(mapStateToProps)(translate(CompetitionShowPage));
