import moment from "moment";

export const getTimeDiff = (start, end) => {
  end = moment(end);
  start = moment(start);
  var duration = moment.duration(end.diff(start));
  var minutes = duration.as("minutes");
  return minutes;
};

export const getTimeDiffToNow = from => {
  var now = moment(new Date()); //todays date
  var end = moment(from); // another date
  return getTimeDiff(now, end);
};

export const noPaymentRowStyle = (record, index) => {
  let noPayment = true;
  if (
    !record.userRoles ||
    (record.userRoles &&
      record.userRoles[0] &&
      record.userRoles[0].name === "ROLE_USER")
  ) {
    if (record.paymentUntil) {
      let minutes = getTimeDiffToNow(record.paymentUntil);
      if (minutes > 0) {
        noPayment = false;
      }
    }
  } else {
    noPayment = false;
  }

  return {
    backgroundColor: noPayment ? "#ff6666" : "white"
  };
};

export const paymintInTime = paymentUntil => {
  return paymentUntil == null ||
    (paymentUntil != null && getTimeDiffToNow(paymentUntil) < 0)
    ? false
    : true;
};

export const isAdmin = permissions => {
  if (
    permissions &&
    (permissions.indexOf("IS_ADMIN") > -1 ||
      permissions.indexOf("IS_PORTAL_ADMIN") > -1)
  ) {
    return true;
  }
  return false;
};

export const isClubAdmin = permissions => {
  if (permissions && permissions.indexOf("IS_CLUB_ADMIN") > -1) {
    return true;
  }
  return false;
};

export const isPortalAdmin = permissions => {
  if (permissions && permissions.indexOf("IS_PORTAL_ADMIN") > -1) {
    return true;
  }
  return false;
};

export const isGuest = permissions => {
  if (
    permissions &&
    permissions.length === 1 &&
    permissions[0] === "IS_GUEST"
  ) {
    return true;
  }
  return false;
};

export const isShooter = permissions => {
  if (
    !isAdmin(permissions) &&
    !isClubAdmin(permissions) &&
    !isGuest(permissions)
  ) {
    return true;
  }
  return false;
};

export const resolveProjectionPropertyName = property => {
  switch (property) {
    case "squadName":
      return "squad.name";

    case "clubName":
      return "competition.club.name";

    case "competitionName":
      return "competition.name";

    case "competitionCompetitionDate":
      return "competition.competitionDate";

    case "divisionName":
      return "division.name";

    case "competitionClassName":
      return "competitionClass.name";

    case "factorName":
      return "factor.name";

    case "timeZonedCancelledDate":
      return "cancelledDate";

    case "timeZonedOrderCreateDate":
      return "order.createDateTime";

    default:
      return property;
  }
};
