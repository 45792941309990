import { SET_ORDERS, SET_ORDER_FILTER_SETTINGS } from "./orderListActions";

const INITIAL_STATE = {
  orders: [],
  filterSettings: {
    orderBy: "id",
    order: "asc",
    page: 0,
    perPage: 25,
    filters: {}
  }
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case SET_ORDER_FILTER_SETTINGS:
      return {
        filterSettings: data.filterSettings
      };
    default:
      return state;
  }
};

export default reducer;
