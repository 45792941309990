// React imports
import React from "react";

// React Admin imports
import { translate } from "react-admin";

// Redux imports
import { connect } from "react-redux";

// Material imports
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// Icon imports

// Other imports
import PropTypes from "prop-types";

const styles = {
  backBtn: {
    justifyContent: "center"
  },
  backColor: {
    color: "#00"
  }
};

const BackButton = props => (
  <IconButton onClick={() => props.history.goBack()} style={styles.backBtn}>
    <Tooltip title={props.tooltip ? props.tooltip : "Zurück"}>
      <KeyboardBackspaceIcon style={styles.backColor} />
    </Tooltip>
  </IconButton>
);

export default connect()(translate(BackButton));
