import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

const styles = {
  alignCenter: {
    justifyContent: "center"
  },
  spacer: {
    display: "block",
    height: "20px"
  },
  notification: {
    backgroundColor: "rgba(67, 160, 71, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#43A047",
    padding: "8px 10px"
  },
  error: {
    backgroundColor: "rgba(211, 46, 47, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#D32E2F",
    padding: "8px 10px"
  },
  notificationText: {
    color: "#ffffff"
  },
  loginBtn: {
    display: "flex",
    justifyContent: "flex-end"
  }
};

const ConfirmRegistration = props => {
  const { translate, verify, showLoginLink, showError, goToLogin } = props;
  return (
    <Grid container spacing={16} style={styles.alignCenter}>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            {verify && (
              <Typography variant="body1">
                {translate("CONFIRM_REGISTRATION.VERIFYING")}
              </Typography>
            )}

            {showLoginLink && [
              <div style={styles.notification}>
                <Typography variant="body1" style={styles.notificationText}>
                  {translate("CONFIRM_REGISTRATION.VERIFY_SUCCESS")}
                </Typography>
              </div>,
              <div>
                <Typography variant="body1" style={{ paddingTop: "10px" }}>
                  {translate("CONFIRM_REGISTRATION.PAYMENT_INFORMATION")}
                </Typography>
                <Typography>
                  <a href="https://compsign.at/meine-vorteile-als-registrierter-schuetze/">
                    https://compsign.at/meine-vorteile-als-registrierter-schuetze/
                  </a>
                </Typography>
              </div>,
              <div style={styles.spacer}></div>,
              <div style={styles.loginBtn}>
                <Button onClick={() => goToLogin()} variant="outlined">
                  {translate("CONFIRM_REGISTRATION.GO_TO_LOGIN")}
                </Button>
              </div>
            ]}

            {showError && (
              <div style={styles.error}>
                <Typography variant="body1" style={styles.notificationText}>
                  {translate("VERIFY_ERROR")}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
ConfirmRegistration.propTypes = {
  translate: PropTypes.func.isRequired,
  verify: PropTypes.bool.isRequired,
  showLoginLink: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  goToLogin: PropTypes.func.isRequired
};
export default ConfirmRegistration;
