import React from "react";
import {
  translate,
  FunctionField,
  Show,
  SimpleShowLayout,
  CardActions,
  ListButton,
  EditButton,
  FormDataConsumer,
  DateField
} from "react-admin";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { getTimeDiffToNow } from "../../utils/utils";
import { compose } from "recompose";
import Grid from "@material-ui/core/Grid";
import PageTitle from "../../components/PageTitle";

const UserRoleListRenderer = translate(({ translate, record }) => {
  return (
    <span style={{ display: "block", marginRight: "3px" }}>
      {translate(record.name)}
    </span>
  );
});

const UserRoleListRendererUl = translate(({ translate, record }) => {
  return <li>{translate(record.name)}</li>;
});

const UserTitle = ({ record }) => {
  return (
    <span>User {record ? `"${record.firstName} ${record.lastName}"` : ""}</span>
  );
};

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right"
};

const Actions = ({ basePath, data, refresh }) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
    {/*<DeleteButton basePath={basePath} record={data} />*/}
  </CardActions>
);

export const UserShow = compose(translate)(({ ...props }) => {
  return (
    <Grid>
      <PageTitle title={props.translate("resources.users.showPage.headline")} />
      <Show title={<UserTitle />} actions={<Actions />} {...props}>
        <SimpleShowLayout>
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => (
              <Grid direction="column">
                <Grid>
                  <TextField
                    label={props.translate(
                      "resources.users.registerForm.fields.username"
                    )}
                    value={formData.username}
                    disabled
                  />
                </Grid>
                <Grid>
                  <TextField
                    {...rest}
                    label={props.translate(
                      "resources.users.registerForm.fields.firstName"
                    )}
                    value={formData.firstName}
                    disabled
                  />
                </Grid>
                <Grid>
                  <TextField
                    label={props.translate(
                      "resources.users.registerForm.fields.lastName"
                    )}
                    value={formData.lastName}
                    disabled
                  />
                </Grid>
              </Grid>
            )}
          </FormDataConsumer>

          <FunctionField
            label="resources.users.fields.userRole"
            render={record => (
              <ul>
                {record &&
                  typeof record.userRoles !== "undefined" &&
                  record.userRoles.map((role, index) => (
                    <UserRoleListRendererUl
                      key={role.id}
                      record={role}
                      isLast={!(index < record.userRoles.length - 1)}
                    />
                  ))}
              </ul>
            )}
          />
          <FunctionField
            label="resources.users.fields.active"
            render={record =>
              record.active
                ? props.translate("resources.users.fields.isActive")
                : props.translate("resources.users.fields.isBlocked")
            }
          />
          <FormDataConsumer>
            {({ formData, dispatch, ...rest }) => {
              return (
                formData &&
                formData.userRoles &&
                formData.userRoles.some(e => e.name === "ROLE_USER") && (
                  <Grid>
                    <Grid>
                      <TextField
                        label={props.translate(
                          "resources.users.registerForm.fields.salutation"
                        )}
                        value={
                          formData.salutation === "mr"
                            ? props.translate(
                                "resources.users.registerForm.fields.salutations.mr"
                              )
                            : props.translate(
                                "resources.users.registerForm.fields.salutations.mrs"
                              )
                        }
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        label={props.translate(
                          "resources.users.registerForm.fields.degree"
                        )}
                        value={formData.degree}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        label={props.translate(
                          "resources.users.registerForm.fields.selectedLanguage"
                        )}
                        value={
                          formData.selectedLanguage === "de"
                            ? props.translate(
                                "resources.users.registerForm.fields.languages.de"
                              )
                            : formData.selectedLanguage === "en"
                            ? props.translate(
                                "resources.users.registerForm.fields.languages.en"
                              )
                            : null
                        }
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={formData.address}
                        label={props.translate(
                          "resources.users.registerForm.fields.address"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={formData.zip}
                        label={props.translate(
                          "resources.users.registerForm.fields.zip"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={formData.city}
                        label={props.translate(
                          "resources.users.registerForm.fields.city"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={formData.state}
                        label={props.translate(
                          "resources.users.registerForm.fields.state"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        label={props.translate(
                          "resources.users.registerForm.fields.country"
                        )}
                        value={
                          formData.country === "at"
                            ? props.translate(
                                "resources.users.registerForm.fields.countries.austria"
                              )
                            : formData.country === "de"
                            ? props.translate(
                                "resources.users.registerForm.fields.countries.germany"
                              )
                            : null
                        }
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={formData.telephone}
                        label={props.translate(
                          "resources.users.registerForm.fields.telephone"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={
                          formData.competitionClass &&
                          formData.competitionClass.name
                            ? formData.competitionClass.name
                            : null
                        }
                        label={props.translate(
                          "resources.users.registerForm.fields.competitionDivision"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={
                          formData.factor && formData.factor.name
                            ? formData.factor.name
                            : null
                        }
                        label={props.translate(
                          "resources.users.registerForm.fields.competitionFactor"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={
                          formData.club && formData.club.name
                            ? formData.club.name
                            : null
                        }
                        label={props.translate(
                          "resources.users.registerForm.fields.club"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={formData.alias}
                        label={props.translate(
                          "resources.users.registerForm.fields.alias"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={formData.cowboyName}
                        label={props.translate(
                          "resources.users.registerForm.fields.cowboyName"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={formData.sassNumber}
                        label={props.translate(
                          "resources.users.registerForm.fields.sassNumber"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={formData.ipscNumber}
                        label={props.translate(
                          "resources.users.registerForm.fields.ipscNumber"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={formData.ipscRegion}
                        label={props.translate(
                          "resources.users.registerForm.fields.ipscRegion"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      <TextField
                        value={formData.ipscAlias}
                        label={props.translate(
                          "resources.users.registerForm.fields.ipscAlias"
                        )}
                        disabled
                      />
                    </Grid>
                    <Grid>
                      {props.translate(
                        "resources.users.registerForm.fields.allowInformations"
                      )}
                      <Checkbox checked={formData.allowInformations} disabled />
                    </Grid>
                    <Grid>
                      <h2>
                        {props.translate(
                          "resources.clubs.fields.paymentHeader"
                        )}
                      </h2>
                      <DateField source="paymentUntil" />
                      <FunctionField
                        label={" "}
                        render={record =>
                          formData.paymentUntil &&
                          getTimeDiffToNow(formData.paymentUntil) < 0
                            ? props.translate(
                                "resources.clubs.fields.paymentExpired"
                              )
                            : !formData.paymentUntil
                            ? props.translate(
                                "resources.clubs.fields.noPayment"
                              )
                            : props.translate(
                                "resources.clubs.fields.validPayment"
                              )
                        }
                      />
                    </Grid>
                  </Grid>
                )
              );
            }}
          </FormDataConsumer>
        </SimpleShowLayout>
      </Show>
    </Grid>
  );
});
