import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "react-admin";
import { Typography } from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";

const styles = {
  errorMsg: {
    color: "#CB5D58",
    marginTop: "10px"
  },
  msg: {
    marginTop: "10px"
  }
};

let CancelCompetitionPopup = props => {
  const {
    handleCancel,
    handleForceCancel,
    handleClose,
    open,
    errorMessage,
    fullScreen
  } = props;
  const checkAndSubmit = () => {
    if (errorMessage === "ERROR.REGISTRATION_ALREADY_STARTED") {
      handleForceCancel();
    } else {
      handleCancel();
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {props.isCancelled
          ? props.translate("resources.competitions.cancelDialog.undoHeader")
          : props.translate("resources.competitions.cancelDialog.header")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="p" component="p" style={styles.msg}>
            {props.isCancelled
              ? props.translate(
                  "resources.competitions.cancelDialog.reallyUnCancel"
                )
              : props.translate(
                  "resources.competitions.cancelDialog.reallyCancel"
                )}
          </Typography>

          {errorMessage && (
            <Typography variant="p" component="p" style={styles.errorMsg}>
              {props.translate(errorMessage)}
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} style={{ color: "#4F6982" }}>
          {props.translate("resources.competitions.cancelDialog.cancel")}
        </Button>
        <Button
          style={{ color: "#4F6982" }}
          onClick={checkAndSubmit}
          color="primary"
        >
          {props.isCancelled
            ? props.translate("resources.competitions.cancelDialog.doUnCancel")
            : props.translate("resources.competitions.cancelDialog.doCancel")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CancelCompetitionPopup.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isCancelled: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleForceCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
};

export default translate(connect()(withMobileDialog()(CancelCompetitionPopup)));
