import {
  checkUsernameExistence,
  checkEmailExistence
} from "../../utils/restUtils";

const PWREG = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,128}$/);
const PWREGEDIT = new RegExp(/(^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,128}$)|(^$)/);

export const required = (value, allValues, props) =>
  value && value !== "" ? undefined : props.translate("FIELD_IS_REQUIRED");

export const password = (value, allValues, props) =>
  PWREG.test(value)
    ? undefined
    : props.translate("PW_MIN_6_MAX_128_1_UPPER_1_LOWER_1_NUMERIC");

export const passwordEdit = (value, allValues, props) =>
  PWREGEDIT.test(value) || value === "" || typeof value == "undefined"
    ? undefined
    : props.translate("PW_MIN_6_MAX_128_1_UPPER_1_LOWER_1_NUMERIC");

export const passwordEquals = (value, allValues, props) =>
  value === allValues.password ? undefined : props.translate("PW_MUST_MATCH");

export const emailAsyncValidation = async (values, v, props) => {
  if (
    // check user is logged in
    props.initialized &&
    // check it is a creation
    ((values.email && !props.record) ||
      //or edit check if its edit, we dont need username validation if its equals with the init from data
      values.email !== props.record.email)
  ) {
    const data = await checkEmailExistence(values.email);
    if (data) {
      throw { email: props.translate("EMAIL_ALREADY_EXISTS") };
    }
  }
};

const usernameAsyncValidation = async (values, v, props) => {
  if (
    // check user is logged in
    props.initialized &&
    // check it is a creation
    ((values.username && !props.record) ||
      //or edit check if its edit, we dont need username validation if its equals with the init from data
      values.username !== props.record.username)
  ) {
    const data = await checkUsernameExistence(values.username);
    if (data) {
      throw { username: props.translate("USER_ALREADY_EXISTS") };
    }
  }
};

function composeAsyncValidators(validatorFns) {
  return async (values, dispatch, props, field) => {
    const validatorFn = validatorFns[field];
    if (!validatorFn) return null;
    await validatorFn(values, dispatch, props, field);
  };
}

export const asyncUsernameAndEmailValidator = composeAsyncValidators({
  username: usernameAsyncValidation,
  email: emailAsyncValidation
});
