export const SET_IDENTITY = "SET_IDENTITY";
export const RELOAD_USER = "RELOAD_USER";
export const UPDATE_USERDATA = "UPDATE_USERDATA";

export const changeUser = userData => ({
  type: SET_IDENTITY,
  payload: userData
});

export const updateUserData = userData => ({
  type: UPDATE_USERDATA,
  payload: userData
});

export const reloadUser = () => ({
  type: RELOAD_USER
});
