import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "react-admin";
import { Typography } from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";

const styles = {
  errorMsg: {
    color: "#CB5D58",
    marginTop: "10px"
  },
  msg: {
    marginTop: "10px"
  }
};

let BlockPopup = props => {
  const {
    handleBlock,
    handleClose,
    open,
    errorMessage,
    resource,
    fullScreen
  } = props;
  const checkAndSubmit = () => {
    handleBlock();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {props.isBlocked
          ? props.translate(
              "resources." + resource + ".blockDialog.headerUnblock"
            )
          : props.translate("resources." + resource + ".blockDialog.header")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography variant="p" component="p" style={styles.msg}>
            {props.isBlocked
              ? props.translate(
                  "resources." + resource + ".blockDialog.reallyUnblock"
                )
              : props.translate(
                  "resources." + resource + ".blockDialog.reallyBlock"
                )}
          </Typography>

          {errorMessage && (
            <Typography variant="p" component="p" style={styles.errorMsg}>
              {props.translate(errorMessage)}
            </Typography>
          )}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} style={{ color: "#4F6982" }}>
          {props.translate("resources." + resource + ".blockDialog.cancel")}
        </Button>
        <Button
          style={{ color: "#4F6982" }}
          onClick={checkAndSubmit}
          color="primary"
        >
          {props.isBlocked
            ? props.translate(
                "resources." + resource + ".blockDialog.doUnblock"
              )
            : props.translate("resources." + resource + ".blockDialog.doBlock")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BlockPopup.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleBlock: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  isBlocked: PropTypes.bool.isRequired,
  resource: PropTypes.string.isRequired
};

export default translate(connect()(withMobileDialog()(BlockPopup)));
