import React, { Component } from "react";
import { FileField } from "react-admin";
import { FILE_API_PATH, UPLOAD_TYPES_SUB_FOLDER } from "../../utils/config";

class FileFieldInput extends Component {
  render() {
    let fileName = this.props.record.fileName
      ? this.props.record.fileName
      : this.props.record.rawFile.name;

    if (fileName && fileName.includes("/")) {
      fileName = fileName.split(/[\\\/]/).pop();
    }
    if (fileName && fileName.includes("+")) {
      fileName = fileName.replaceAll("+", " ");
    }

    return (
      <div>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={
            this.props.record.fileName && !this.props.record.rawFile.name
              ? FILE_API_PATH + "/" + this.props.record.fileName
              : FILE_API_PATH +
                "/" +
                UPLOAD_TYPES_SUB_FOLDER +
                "/" +
                (this.props.record.rawFile.prefix
                  ? this.props.record.rawFile.prefix + "/"
                  : "") +
                this.props.record.rawFile.name
          }
        >
          {fileName}
        </a>
      </div>
    );
  }
}

export default FileFieldInput;
