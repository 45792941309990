// React imports
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Grid from "@material-ui/core/Grid";
// Material imports
import IconButton from "@material-ui/core/IconButton";
import Step from "@material-ui/core/Step";
import StepContent from "@material-ui/core/StepContent";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Chip from "@material-ui/core/Chip";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/DeleteForever";
// Other imports
import PropTypes from "prop-types";
import React, { useState, input } from "react";
import { translate } from "react-admin";
import Moment from "react-moment";
// Redux imports
import { connect } from "react-redux";
import { Form, reduxForm } from "redux-form";
import SuccessMessage from "../SuccessMessage";
import { change, Field } from "redux-form";
import { required } from "../../validators/validators";
import { blur, touch } from "redux-form";
import {
  renderSelectField,
  renderTextField
} from "../inputs/renderMaterialFields";

const styles = {
  fullWidth: {
    width: "100%"
  },
  spacer: {
    height: "20px"
  },
  signupBtn: {
    color: "#ffffff",
    backgroundColor: "#4F6981",
    marginTop: "40px",
    width: "100%"
  },
  waitingListAlert: {
    padding: "8px 8px",
    marginTop: "20px"
  },
  waitingListAlertText: {
    color: "#E8AC61",
    lineHeight: "28px",
    display: "inline-flex",
    verticalAlign: "middle"
  },
  root: {
    width: "90%"
  },
  greyButton: {
    marginRight: "10px"
  },
  colorButton: {
    backgroundColor: "#7e886e",
    marginRight: "20px"
  },
  actionsContainer: {
    marginBottom: "10px"
  },
  resetContainer: {
    padding: "10px"
  },
  simpleTitle: {
    marginBottom: "20px"
  },
  totalPrice: {
    fontWeight: "bold"
  },
  alignCenter: {
    textAlign: "center"
  },
  alignRight: {
    textAlign: "right"
  },
  alignLeft: {
    textAlign: "left"
  },
  paymentError: {
    color: "red"
  },
  waitingListPaymentInfo: {
    color: "red",
    fontWeight: "bold"
  }
};

const renderCheckbox = ({ props, input, label, error }) => (
  <FormControlLabel
    control={<Checkbox onChange={input.onChange} />}
    label={<FormHelperText error={error}>{label}</FormHelperText>}
  />
);

function getSteps() {
  return ["Bewerbe", "Zahlungsart", "Zusammenfassung"];
}

function getStepContent(step, props, paymentError, onChange) {
  const { translate } = props;

  let paymentMethods = props.paymentMethods;

  if (paymentMethods.length === 1) {
    props.dispatch(change("checkoutForm", "paymentMethod", paymentMethods[0]));
  }

  switch (step) {
    case 0:
      return (
        <div>
          <CardContent>
            <Table className={""} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">
                    {translate("checkout.table.competition")}
                  </TableCell>
                  <TableCell align="right">
                    {translate("checkout.table.shooter")}
                  </TableCell>
                  <TableCell align="left">
                    {translate("checkout.table.division")}
                  </TableCell>
                  <TableCell align="left">
                    {translate("checkout.table.competitionClass")}
                  </TableCell>
                  <TableCell align="right">
                    {translate("checkout.table.date")}
                  </TableCell>
                  <TableCell align="right" style={styles.alignRight}>
                    {translate("checkout.table.price")}
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.cart.map((row, index) => (
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    {row.squads ? (
                      <TableCell align="left">
                        <Typography>{row.competitionName}</Typography>
                        <Typography>
                          {"Squads:" +
                            row.squads.map(squadName => " " + squadName)}
                        </Typography>
                      </TableCell>
                    ) : (
                      <TableCell align="left">{row.competitionName}</TableCell>
                    )}

                    <TableCell align="right">
                      {row.firstName} {row.lastName}
                    </TableCell>
                    <TableCell align="left">{row.divisionName}</TableCell>
                    <TableCell align="left">
                      {row.competitionClassName}
                    </TableCell>
                    <TableCell align="left">
                      <Moment format="DD.MM.YYYY">
                        {row.competitionCompetitionDate}
                      </Moment>
                    </TableCell>
                    <TableCell align="right" style={styles.alignRight}>
                      {row.competitionNominationFee}{" "}
                      {translate("checkout.table.currency")}
                      {row.isWaitingList ? "*" : null}
                    </TableCell>
                    <TableCell>
                      <IconButton
                        onClick={() => props.handleDelete(index)}
                        style={styles.deleteBtn}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell
                    align="right"
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    {props.getTotalPrice()}{" "}
                    {translate("checkout.table.currency")}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    {translate("checkout.table.total")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {props.hasWaitingListItems ? (
              <Typography style={styles.waitingListPaymentInfo}>
                {translate("checkout.waitingListPaymentInfo")}
              </Typography>
            ) : null}
          </CardContent>
        </div>
      );
    case 1:
      return (
        <div>
          <CardContent>
            <Typography variant="headline">
              {translate(
                paymentMethods && !paymentMethods.length > 1
                  ? "checkout.choosePaymentMethod"
                  : "checkout.paymentMethod"
              )}
            </Typography>
          </CardContent>
          <CardContent>
            <Grid container spacing={0}></Grid>
            <form>
              {paymentMethods.length > 1 ? (
                <Field
                  name="paymentMethod"
                  component={renderSelectField}
                  error={paymentError}
                  onChange={onChange}
                  label={translate(
                    "resources.users.registerForm.fields.selectedPaymentMethod"
                  )}
                >
                  <option value=" ">{translate("checkout.select")}</option>
                  {paymentMethods.map(paymentMethod => (
                    <option value={paymentMethod}>
                      {translate(paymentMethod)}
                    </option>
                  ))}
                </Field>
              ) : (
                <Chip label={translate(paymentMethods[0])} />
              )}
            </form>
            {paymentError ? (
              <Typography style={styles.paymentError}>
                {translate("checkout.paymentError")}
              </Typography>
            ) : null}
          </CardContent>
        </div>
      );
    case 2:
      return (
        <div>
          <CardContent>
            <Typography variant="headline">
              {translate("checkout.summary")}
            </Typography>
          </CardContent>
          <CardContent>
            <Table className={""} size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="left">
                    {translate("checkout.table.competition")}
                  </TableCell>
                  <TableCell align="right">
                    {translate("checkout.table.shooter")}
                  </TableCell>
                  <TableCell align="right">
                    {translate("checkout.table.division")}
                  </TableCell>
                  <TableCell align="right">
                    {translate("checkout.table.competitionClass")}
                  </TableCell>
                  <TableCell align="right">
                    {translate("checkout.table.date")}
                  </TableCell>
                  <TableCell align="right" style={styles.alignRight}>
                    {translate("checkout.table.price")}
                  </TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.cart.map((row, index) => (
                  <TableRow>
                    <TableCell align="left"></TableCell>
                    {row.squads ? (
                      <TableCell align="left">
                        <Typography>{row.competitionName}</Typography>
                        <Typography>
                          {"Squads:" +
                            row.squads.map(squadName => " " + squadName)}
                        </Typography>
                      </TableCell>
                    ) : (
                      <TableCell align="left">{row.competitionName}</TableCell>
                    )}
                    <TableCell align="right">
                      {row.firstName} {row.lastName}
                    </TableCell>
                    <TableCell align="left">{row.divisionName}</TableCell>
                    <TableCell align="left">
                      {row.competitionClassName}
                    </TableCell>
                    <TableCell align="left">
                      <Moment format="DD.MM.YYYY">
                        {row.competitionCompetitionDate}
                      </Moment>
                    </TableCell>
                    <TableCell align="right" style={styles.alignRight}>
                      {row.competitionNominationFee}{" "}
                      {row.isWaitingList ? "*" : null}
                      {translate("checkout.table.currency")}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="right"></TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell
                    align="right"
                    style={{ textAlign: "right", fontWeight: "bold" }}
                  >
                    {props.getTotalPrice()}{" "}
                    {translate("checkout.table.currency")}
                  </TableCell>
                  <TableCell
                    align="left"
                    style={{ textAlign: "left", fontWeight: "bold" }}
                  >
                    {translate("checkout.table.total")}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
            {props.hasWaitingListItems ? (
              <Typography style={styles.waitingListPaymentInfo}>
                {translate("checkout.waitingListPaymentInfo")}
              </Typography>
            ) : null}
          </CardContent>
        </div>
      );
    default:
      return "Unknown step";
  }
}

let CheckoutForm = props => {
  const { translate } = props;

  const renderFromHelper = ({ input, touched, error }) => {
    if (!(touched && error)) {
      return;
    } else {
      return <FormHelperText>{error}</FormHelperText>;
    }
  };
  const [paymentMethod, setPaymentMethod] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(0);
  const [paymentError, setPaymentError] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    if (
      props.cart.length < 2 ||
      (localStorage.getItem("token") != "null" &&
        localStorage.getItem("token") != null &&
        localStorage.getItem("token") != "")
    ) {
      if (steps.length == activeStep + 1) {
        if (props.myForm && props.myForm.values && props.myForm.values.agbs) {
          if (props.myForm.values.paymentMethod !== "WIRECARD") {
            props.submitOrder();
            props.resetCart();
            props.history.push("/orderComplete");
          } else {
            props.submitOrder();
          }
        } else {
          setError(true);
        }
      } else if (steps.length == activeStep + 2) {
        props.dispatch(touch("userRegisterForm", "paymentMethod"));
        props.dispatch(blur("userRegisterForm", "paymentMethod"));
        if (
          props.myForm &&
          props.myForm.values &&
          props.myForm.values.paymentMethod &&
          props.myForm.values.paymentMethod != " "
        )
          setActiveStep(prevActiveStep => prevActiveStep + 1);
        else setPaymentError(true);
      } else {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  };

  const handleBack = () => {
    props.dispatch(change("checkoutForm", "paymentMethod", " "));
    props.dispatch(change("checkoutForm", "agbs", false));
    setError(false);
    setPaymentError(false);
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const onChange = e => {
    if (e.target.value && e.target.value != null && e.target.value != " ")
      setPaymentError(false);
    else setPaymentError(true);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Typography variant="display1" style={styles.simpleTitle}>
          {translate("checkout.headline")}
        </Typography>
        <Card>
          <CardContent>
            <Grid container spacing={0}>
              <Grid item xs={12}>
                {props.cart.length > 0 ? (
                  <Form name={"myForm"} initalValues={props.initialValues}>
                    <div className={styles.root}>
                      <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>
                              <Typography>
                                {getStepContent(
                                  index,
                                  props,
                                  paymentError,
                                  onChange
                                )}
                              </Typography>
                              <div styles={styles.actionsContainer}>
                                <div>
                                  {activeStep === steps.length - 1 ? (
                                    <span styles={{ marginLeft: "20px" }}>
                                      <Field
                                        name="agbs"
                                        id="agbs"
                                        component={renderCheckbox}
                                        onChange={(e, value) =>
                                          value
                                            ? setError(false)
                                            : setError(true)
                                        }
                                        type="checkbox"
                                        label={translate("checkout.agbs")}
                                        error={error}
                                      />
                                    </span>
                                  ) : null}
                                  <div>
                                    <Button
                                      disabled={activeStep === 0}
                                      onClick={handleBack}
                                      style={styles.greyButton}
                                    >
                                      {translate("checkout.back")}
                                    </Button>
                                    <Button
                                      variant="contained"
                                      color="primary"
                                      onClick={handleNext}
                                      style={styles.colorButton}
                                    >
                                      {activeStep === steps.length - 1
                                        ? props.myForm &&
                                          props.myForm.values &&
                                          props.myForm.values.paymentMethod ===
                                            "WIRECARD"
                                          ? translate("checkout.toWirecard")
                                          : translate("checkout.finished")
                                        : translate("checkout.next")}
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </StepContent>
                          </Step>
                        ))}
                      </Stepper>
                      {activeStep === steps.length && <div></div>}
                    </div>
                  </Form>
                ) : (
                  <div>
                    <SuccessMessage
                      successMessage={props.successMessage}
                      open={props.successMessage ? true : false}
                      onClose={props.hideSuccess}
                      translate={props.translate}
                    />
                    <Typography variant="subheading">
                      {props.translate("NO_ITEMS_IN_CART")}
                    </Typography>
                    <div style={styles.spacer}></div>
                    <Button
                      variant="contained"
                      color="primary"
                      style={styles.colorButton}
                      onClick={props.goToCompetitionsLinkAction}
                    >
                      {props.translate("checkout.goToCompetitions")}
                    </Button>
                  </div>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

CheckoutForm = reduxForm({
  // a unique name for the form
  form: "checkoutForm"
})(CheckoutForm);

CheckoutForm.propTypes = {
  resetCart: PropTypes.func,
  cart: PropTypes.array.isRequired,
  submitOrder: PropTypes.array.isRequired,
  successMessage: PropTypes.object,
  hideSuccess: PropTypes.func.isRequired
};

function mapStateToProps(state) {
  return {
    myForm: state.form.checkoutForm
  };
}

export default translate(
  connect(mapStateToProps)(withStyles(styles)(CheckoutForm))
);
