import React, { Component } from "react";
import { CheckboxGroupInput, GET_LIST } from "react-admin";
import restClient from "../../providers/dataProvider/rest";

class PermissionGroupInput extends Component {
  state = {
    options: []
  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dependsOnValue !== this.props.dependsOnValue) {
      this.fetchData(nextProps);
    }
  }

  fetchData(props) {
    this.fetchPermissions().then(options => {
      let categorizedOptions = [];
      for (let i = 0; i < options.length; i++) {
        let option = options[i];
        if (
          categorizedOptions.filter(o => o.category === option.category)
            .length === 0
        ) {
          categorizedOptions.push({
            category: option.category,
            permissions: options.filter(o => o.category === option.category)
          });
        }
      }
      this.setState({ options: categorizedOptions });
    });
  }

  fetchPermissions = () => {
    return new Promise((resolve, reject) => {
      restClient(GET_LIST, "userPermissions", {
        pagination: { page: 0, perPage: 200 },
        sort: { field: "", order: "" },
        filter: {}
      })
        .then(data => {
          resolve(data.data);
        })
        .catch(e => {
          reject(null);
        });
    });
  };

  render() {
    return (
      <div>
        {this.state.options.map((option, idx) => (
          <CheckboxGroupInput
            {...this.props}
            key={idx}
            label={"resources.misc.userRoleCategories.cat_" + option.category}
            source="permissions"
            choices={option.permissions}
          />
        ))}
      </div>
    );
  }
}

PermissionGroupInput.propTypes = CheckboxGroupInput.propTypes;
PermissionGroupInput.defaultProps = CheckboxGroupInput.defaultProps;

export default PermissionGroupInput;
