import { SET_CART } from "./cartActions";

const INITIAL_STATE = {
  amount: 0,
  list: []
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case SET_CART:
      return {
        amount: data.cart.amount,
        list: data.cart.list
      };
    default:
      return state;
  }
};

export default reducer;
