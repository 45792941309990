import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import deLocale from "date-fns/locale/de";
import MomentUtils from "material-ui-pickers/utils/moment-utils";
import moment from "moment-timezone";
import RichTextInput from "ra-input-rich-text";
import React, { Component } from "react";
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  FileInput,
  FormDataConsumer,
  FormTab,
  minLength,
  minValue,
  NumberInput,
  RadioButtonGroupInput,
  REDUX_FORM_NAME,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  translate,
  WithPermissions
} from "react-admin";
import { DateTimeInput } from "react-admin-date-inputs";
import { connect } from "react-redux";
import { change } from "redux-form";
import CustomToolbar from "../../components/CustomToolbar";
import FileFieldInput from "../../components/inputs/FileFieldInput";
import PageTitle from "../../components/PageTitle";
import { setError } from "../../reducer/errorActions";
import {
  getCompetition,
  getNotificationTypes,
  getUploadTypes,
  getUserClubs,
  upload
} from "../../utils/restUtils";
import { isAdmin, isClubAdmin } from "../../utils/utils";
import {
  globalPaymentMethodValidator,
  required,
  waitingListRequired
} from "../../validators/validators";
import countries from "./../../components/pages/country-codes-case-lower.json";

const RenderCountryOptions = () => {
  return countries.map(country => {
    return { id: country.countryCode, name: country.countryName };
  });
};

class EventCreate extends Component {
  state = { notificationTypes: [], divisionChoices: [], uploadTypes: [] };
  copySquad = squadID => {
    try {
      let squads = this.props.squads ? this.props.squads : [];
      let data = squads[squadID.split("[")[1].split("]")[0]];
      squads.push(data);
      this.props.dispatch(change(REDUX_FORM_NAME, "squads", squads));
    } catch (e) {
      /*nothing*/
    }
  };

  componentDidUpdate = props => {
    if (props.toCopy !== this.props.toCopy) {
      getCompetition(this.props.toCopy).then(
        data => {
          this.props.dispatch(change(REDUX_FORM_NAME, "club", data.clubId));
          this.props.dispatch(change(REDUX_FORM_NAME, "name", data.name));
          this.props.dispatch(
            change(REDUX_FORM_NAME, "information", data.information)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "competitionTypeId", data.competitionTypeId)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "level", data.level ? data.level.id : null)
          );
          this.props.dispatch(change(REDUX_FORM_NAME, "stages", data.stages));
          this.props.dispatch(
            change(REDUX_FORM_NAME, "nominationFee", data.nominationFee)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "announcementLink", data.announcementLink)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "clubWebsiteLink", data.clubWebsiteLink)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "contact.firstName", data.contactFirstName)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "contact.lastName", data.contactLastName)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "contact.email", data.contactEmail)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "contact.telephone", data.contactTelephone)
          );
          this.props.dispatch(change(REDUX_FORM_NAME, "address", data.address));
          this.props.dispatch(change(REDUX_FORM_NAME, "city", data.city));
          this.props.dispatch(change(REDUX_FORM_NAME, "zip", data.zip));
          this.props.dispatch(change(REDUX_FORM_NAME, "country", data.country));
          this.props.dispatch(
            change(REDUX_FORM_NAME, "googleMapsLink", data.googleMapsLink)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "competitionDate", data.competitionDate)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "registrationStart", data.registrationStart)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "registrationEnd", data.registrationEnd)
          );
          data.division_ids = [];
          for (let i = 0; i < data.division.length; i++) {
            data.division_ids.push(data.division[i].id);
          }
          this.props.dispatch(
            change(REDUX_FORM_NAME, "division_ids", data.division_ids)
          );
          data.competitionClass_ids = [];
          for (let i = 0; i < data.competitionClass.length; i++) {
            data.competitionClass_ids.push(data.competitionClass[i].id);
          }
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "competitionClass_ids",
              data.competitionClass_ids
            )
          );
          data.factor_ids = [];
          for (let i = 0; i < data.factor.length; i++) {
            data.factor_ids.push(data.factor[i].id);
          }
          this.props.dispatch(
            change(REDUX_FORM_NAME, "factor_ids", data.factor_ids)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "minimumShots", data.minimumShots)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "paymentViaDeposit", data.paymentViaDeposit)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "payOnTime", data.payOnTime)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "payOnSite", data.payOnSite)
          );
          this.props.dispatch(change(REDUX_FORM_NAME, "viewType", "UNLISTED"));
          this.props.dispatch(
            change(REDUX_FORM_NAME, "eventType", data.eventType)
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "startingPlaces", data.squads[0].squadSize)
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "waitingListPlaces",
              data.squads[0].maxWaitingListSize
            )
          );

          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "firstPaymentReminder",
              data.firstPaymentReminder
            )
          );

          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "secondPaymentReminder",
              data.secondPaymentReminder
            )
          );

          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "autoSubscriptionCancelation",
              data.autoSubscriptionCancelation
            )
          );

          this.props.dispatch(change(REDUX_FORM_NAME, "squads", data.squads));
          getNotificationTypes().then(
            types => {
              let defaultBlocks = [];
              for (let i = 0; i < types.length; i++) {
                let found = false;
                for (let j = 0; j < data.notificationBlocks.length; j++) {
                  if (data.notificationBlocks[j].type.id == types[i].id) {
                    defaultBlocks.push({
                      text: data.notificationBlocks[j].text,
                      type: this.state.notificationTypes[i]
                    });
                    found = true;
                  }
                }
                if (!found) {
                  defaultBlocks.push({
                    text: "",
                    type: this.state.notificationTypes[i]
                  });
                }
              }
              this.props.dispatch(
                change(REDUX_FORM_NAME, "notificationBlocks", defaultBlocks)
              );
            },
            e => {
              /* do nothing */
            }
          );
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
    }
  };

  setContactFromClub = (event, key, payload) => {
    let contact = null;
    for (let [index, value] of Object.entries(this.props.clubs.data)) {
      if (value.id == key) {
        contact = value.contact;
      }
    }

    if (contact != null) {
      this.props.dispatch(
        change(
          REDUX_FORM_NAME,
          "contact.firstName",
          contact && contact.firstName ? contact.firstName : null
        )
      );
      this.props.dispatch(
        change(
          REDUX_FORM_NAME,
          "contact.lastName",
          contact && contact.lastName ? contact.lastName : null
        )
      );
      this.props.dispatch(
        change(
          REDUX_FORM_NAME,
          "contact.email",
          contact && contact.email ? contact.email : null
        )
      );
      this.props.dispatch(
        change(
          REDUX_FORM_NAME,
          "contact.telephone",
          contact && contact.telephone ? contact.telephone : null
        )
      );
    }
  };

  setAddressFromClub = (event, key, payload) => {
    let address = null;
    let country = null;
    let city = null;
    let zip = null;
    let googleMapsLink = null;
    let clubWebsiteLink;
    for (let [index, value] of Object.entries(this.props.clubs.data)) {
      if (value.id === key) {
        address = value.matchAddress;
        country = value.matchCountry;
        zip = value.matchZip;
        city = value.matchCity;
        googleMapsLink = value.googleMapsLink;
        clubWebsiteLink = value.websiteLink;
      }
    }

    this.props.dispatch(
      change(REDUX_FORM_NAME, "address", address ? address : null)
    );

    this.props.dispatch(
      change(REDUX_FORM_NAME, "country", country ? country : null)
    );

    this.props.dispatch(change(REDUX_FORM_NAME, "zip", zip ? zip : null));

    this.props.dispatch(change(REDUX_FORM_NAME, "city", city ? city : null));

    this.props.dispatch(
      change(
        REDUX_FORM_NAME,
        "clubWebsiteLink",
        clubWebsiteLink ? clubWebsiteLink : null
      )
    );

    this.props.dispatch(
      change(
        REDUX_FORM_NAME,
        "googleMapsLink",
        googleMapsLink ? googleMapsLink : null
      )
    );
  };

  componentDidMount = () => {
    moment.tz.setDefault("UTC");
    this.props.dispatch(change(REDUX_FORM_NAME, "event", true));
    isClubAdmin(this.props.permissions) &&
      getUserClubs().then(
        data => {
          if (data.length > 0) {
            data = data[0];
          } else {
            data = null;
          }

          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "clubWebsiteLink",
              data.websiteLink ? data.websiteLink : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "contact.firstName",
              data.contact && data.contact.firstName
                ? data.contact.firstName
                : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "contact.lastName",
              data.contact && data.contact.lastName
                ? data.contact.lastName
                : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "contact.email",
              data.contact && data.contact.email ? data.contact.email : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "contact.telephone",
              data.contact && data.contact.telephone
                ? data.contact.telephone
                : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "address",
              data.matchAddress ? data.matchAddress : null
            )
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "city",
              data.matchCity ? data.matchCity : null
            )
          );
          this.props.dispatch(
            change(REDUX_FORM_NAME, "zip", data.matchZip ? data.matchZip : null)
          );
          this.props.dispatch(
            change(
              REDUX_FORM_NAME,
              "country",
              data.matchCountry ? data.matchCountry : null
            )
          );
        },
        () => {}
      );
    this.getUploadTypesData();
  };

  getUploadTypesData = () => {
    let defaultUploadType = [];
    getUploadTypes(0, 100, "sortingOrder", "asc", {}).then(
      data => {
        this.setState({
          uploadTypes: data.data
        });

        for (let i = 0; i < data.data.length; i++) {
          defaultUploadType.push({
            documentRaw: { rawFile: { documentId: null } },
            document: {},
            type: this.state.uploadTypes[i]
          });
        }
        this.props.dispatch(
          change(REDUX_FORM_NAME, "competitionUploads", defaultUploadType)
        );
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  uploadFileSuccess = (data, rawFile, index) => {
    let formData = new FormData();
    formData.append("file", rawFile);
    formData.append("name", rawFile.name);

    rawFile.documentId = data.id;
    rawFile.errorMsg = null;
    const { dispatch } = this.props;
    setTimeout(() => {
      dispatch(setError(null));
      dispatch(
        change(
          REDUX_FORM_NAME,
          "competitionUploads[" + index + "].document.fileName",
          rawFile.name
        )
      );
      dispatch(
        change(
          REDUX_FORM_NAME,
          "competitionUploads[" + index + "].document.path",
          data.key
        )
      );
      dispatch(change(REDUX_FORM_NAME, "tmp", new Date().getTime()));
    }, 10);
  };

  uploadFileError = (e, rawFile) => {
    rawFile.documentId = null;
    rawFile.errorMsg = e.message;

    const { dispatch } = this.props;
    dispatch(setError({ message: e.message }));
    setTimeout(() => {
      this.hideErrorMessage();
    }, 2000);
    dispatch(change(REDUX_FORM_NAME, this.props.source, null));
    dispatch(change(REDUX_FORM_NAME, this.props.previewSource, null));
  };

  uploadFile(file, index) {
    file = file[0];
    const prefix = (Math.random() + 1).toString(36).substring(2);
    const filename = prefix + "/" + file.name.split(".")[0];
    file.prefix = prefix;

    let formData = new FormData();
    formData.append("file", file);
    formData.append("name", filename);

    upload(formData)
      .then(data => this.uploadFileSuccess(data, file, index))
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  errorLabel = label => <span style={{ color: "red" }}>{label}</span>;

  render = () => {
    return (
      <WithPermissions
        render={({ permissions }) => (
          <Grid>
            <PageTitle
              title={this.props.translate(
                "resources.events.createPage.headline"
              )}
            />
            <Create {...this.props}>
              <TabbedForm
                redirect="list"
                validate={globalPaymentMethodValidator}
                toolbar={<CustomToolbar {...this.props} />}
              >
                <FormTab label="resources.competitions.fields.tabs.competition">
                  {isAdmin(permissions) ? (
                    <ReferenceInput
                      source="club"
                      reference="clubs"
                      allowEmpty={false}
                      filter={{ active: true }}
                      resource={this.props.resource}
                      validate={isAdmin(permissions) ? required() : []}
                      perPage={100}
                      sort={{ field: "name", order: "ASC" }}
                      onChange={(event, key, payload) => {
                        this.setContactFromClub(event, key, payload);
                        this.setAddressFromClub(event, key, payload);
                      }}
                    >
                      <AutocompleteInput optionText="name" />
                    </ReferenceInput>
                  ) : null}

                  <TextInput
                    source="name"
                    validate={[required(), minLength(2)]}
                  />
                  <TextInput source="eventType" validate={required()} />
                  <RichTextInput
                    source="information"
                    toolbar={[["bold", "italic", "underline", "link"]]}
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <NumberInput
                    source="nominationFee"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <TextInput source="clubWebsiteLink" />
                </FormTab>
                <FormTab label="resources.competitions.fields.contactHeader">
                  <TextInput
                    source="contact.firstName"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <TextInput
                    source="contact.lastName"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <TextInput
                    source="contact.email"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <TextInput
                    source="contact.telephone"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.addressHeader">
                  <TextInput
                    source="address"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <TextInput
                    source="city"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <TextInput
                    source="zip"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                  <SelectInput
                    source="country"
                    validate={isClubAdmin(permissions) ? required() : []}
                    choices={RenderCountryOptions()}
                  />
                  <TextInput
                    source="googleMapsLink"
                    validate={isClubAdmin(permissions) ? required() : []}
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.dateHeader">
                  <DateTimeInput
                    label={this.props.translate(
                      "resources.competitions.fields.date"
                    )}
                    source="competitionDate"
                    providerOptions={{ utils: MomentUtils, locale: deLocale }}
                    options={{
                      format: "DD.MM.YYYY, HH:mm:ss",
                      ampm: false,
                      clearable: true,
                      disablePast: true
                    }}
                    validate={required()}
                  />

                  <DateTimeInput
                    label={this.props.translate(
                      "resources.competitions.fields.registrationStart"
                    )}
                    source="registrationStart"
                    providerOptions={{ utils: MomentUtils, locale: deLocale }}
                    options={{
                      disablePast: true,
                      format: "DD.MM.YYYY, HH:mm:ss",
                      ampm: false,
                      clearable: true
                    }}
                    validate={required()}
                  />
                  <DateTimeInput
                    label={this.props.translate(
                      "resources.competitions.fields.registrationEnd"
                    )}
                    source="registrationEnd"
                    providerOptions={{ utils: MomentUtils, locale: deLocale }}
                    options={{
                      format: "DD.MM.YYYY, HH:mm:ss",
                      ampm: false,
                      clearable: true,
                      disablePast: true
                    }}
                    validate={required()}
                  />
                </FormTab>
                <FormTab label="resources.competitions.fields.additionalInfo">
                  <BooleanInput
                    label={
                      !this.props.payOnSite &&
                      !this.props.paymentViaDeposit &&
                      this.props.submitFailed
                        ? this.errorLabel(
                            this.props.translate(
                              "resources.competitions.fields.payOnSite"
                            )
                          )
                        : "resources.competitions.fields.payOnSite"
                    }
                    source="payOnSite"
                  />
                  <ReferenceInput
                    source="club"
                    reference="clubs"
                    perPage={100}
                    sort={{ field: "name", order: "ASC" }}
                    resource={this.props.resource}
                  >
                    <FormDataConsumer>
                      {({ formData, ...rest }) => {
                        if (
                          (rest.choices && formData && formData.club) ||
                          rest.choices.length === 1
                        )
                          return rest.choices.find(club => {
                            if (
                              isClubAdmin(permissions) &&
                              rest.choices.length == 1
                            )
                              return (
                                rest.choices[0].bankInformation &&
                                rest.choices[0].bankInformation.name
                              );
                            else
                              return (
                                club.id == formData.club &&
                                club.bankInformation &&
                                club.bankInformation.name
                              );
                          }) ? (
                            <BooleanInput
                              label={
                                !this.props.payOnSite &&
                                !this.props.paymentViaDeposit &&
                                this.props.submitFailed
                                  ? this.errorLabel(
                                      this.props.translate(
                                        "resources.competitions.fields.paymentViaDeposit"
                                      )
                                    )
                                  : "resources.competitions.fields.paymentViaDeposit"
                              }
                              source="paymentViaDeposit"
                              disabled={true}
                            />
                          ) : null;
                      }}
                    </FormDataConsumer>
                  </ReferenceInput>
                  <NumberInput
                    source="payOnTime"
                    step={1}
                    defaultValue={9}
                    validate={[required(), minValue(9)]}
                  />
                  <NumberInput
                    source="startingPlaces"
                    label="resources.squads.fields.squadSize"
                    validate={(required(), minValue(0))}
                  />
                  <NumberInput
                    source="waitingListPlaces"
                    label="resources.squads.fields.maxWaitingListSize"
                    validate={[waitingListRequired(), minValue(0)]}
                  />
                  <Divider />
                  <BooleanInput
                    source="firstPaymentReminder"
                    defaultValue={false}
                  />
                  <BooleanInput
                    source="secondPaymentReminder"
                    defaultValue={false}
                  />
                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      {
                        return formData.firstPaymentReminder ||
                          formData.secondPaymentReminder ? (
                          <BooleanInput
                            source="autoSubscriptionCancelation"
                            defaultValue={false}
                            label={this.props.translate(
                              "resources.competitions.fields.autoSubscriptionCancelation"
                            )}
                          />
                        ) : (
                          <BooleanInput
                            source="disabledAutoSubscriptionCancelation"
                            defaultValue={false}
                            options={{
                              disabled: true
                            }}
                            label={this.props.translate(
                              "resources.competitions.fields.autoSubscriptionCancelation"
                            )}
                          />
                        );
                      }
                    }}
                  </FormDataConsumer>

                  <Divider />
                  <RadioButtonGroupInput
                    source="viewType"
                    defaultValue={"ALL"}
                    label="resources.competitions.fields.viewType.label"
                    choices={[
                      {
                        id: "ALL",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.all"
                        )
                      },
                      {
                        id: "INTERNAL",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.internal"
                        )
                      },
                      {
                        id: "EXTERNAL",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.external"
                        )
                      },
                      {
                        id: "UNLISTED",
                        name: this.props.translate(
                          "resources.competitions.fields.viewType.unlisted"
                        )
                      }
                    ]}
                  />
                </FormTab>
                <FormTab label="resources.competitions.uploadTypes">
                  <TextInput source="announcementLink" />
                  <ArrayInput
                    source="competitionUploads"
                    disableRemove
                    disableAdd
                    label=""
                  >
                    <SimpleFormIterator disableAdd={true} disableRemove={true}>
                      <FormDataConsumer source="">
                        {({ formData, getSource, ...rest }) => {
                          let re = /(\d+)/;
                          let found = getSource().split(re);
                          const index = found[1];
                          if (formData.competitionUploads !== undefined) {
                            return [
                              <div>
                                <label>
                                  {formData.competitionUploads[index].type.name}
                                </label>
                                <FileInput
                                  maxSize={20000000}
                                  source={
                                    "competitionUploads[" +
                                    index +
                                    "].documentRaw"
                                  }
                                  label={""}
                                  multiple={false}
                                  options={{
                                    onDropAccepted: file =>
                                      this.uploadFile(file, index)
                                  }}
                                >
                                  <FileFieldInput
                                    source={
                                      "competitionUploads[" +
                                      index +
                                      "].documentRaw.rawFile"
                                    }
                                    title={
                                      "formData.competitionUploads[" +
                                      index +
                                      "].type.name"
                                    }
                                  />
                                </FileInput>
                              </div>
                            ];
                          } else {
                            return null;
                          }
                        }}
                      </FormDataConsumer>
                    </SimpleFormIterator>
                  </ArrayInput>
                </FormTab>
              </TabbedForm>
            </Create>
          </Grid>
        )}
      />
    );
  };
}

const mapStateToProps = state => {
  return {
    httpErrorMsg: state.error.httpError.message,
    permissions: state.identity.userData.permissions,
    competitionTypes: state.admin.resources.competitionTypes,
    clubs: state.admin.resources.clubs,
    payOnSite:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.payOnSite
        : null,
    paymentViaDeposit:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.paymentViaDeposit
        : null,

    submitFailed: state.form[REDUX_FORM_NAME]
      ? state.form[REDUX_FORM_NAME].submitFailed
      : null,

    squads:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.squads
        : null
  };
};

export default connect(mapStateToProps)(translate(EventCreate));
