import { Grid, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import EditIcon from "@material-ui/icons/Edit";
import MoneyOffIcon from "@material-ui/icons/MoneyOff";
import MoneyOnIcon from "@material-ui/icons/AttachMoney";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { translate, WithPermissions } from "react-admin";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { push } from "react-router-redux";
import CancelButton from "../../components/CancelButton";
import OrderList from "../../components/pages/OrderList";
import { setError } from "../../reducer/errorActions";
import { getOrders, payOrder, cancelOrder } from "../../utils/restUtils";
import { isAdmin, isClubAdmin } from "../../utils/utils";
import PageTitle from "../../components/PageTitle";
import { togglePopup } from "../../reducer/flagActions";
import { setSuccess } from "../../reducer/successActions";
import { setOrderFilterSettings } from "../../reducer/orderListActions";

const styles = {
  simpleTitle: {
    marginBottom: "20px"
  }
};

const debounce = (fn, delay) => {
  let timer = null;
  return function(...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

class OrderListPage extends Component {
  state = {
    orders: [],
    currentPage: 0,
    rowsPerPage: 10,
    totalItemsCount: 0,
    archive: false,
    filters: [],
    clickedButtonId: null,
    clickedButton: null
  };

  constructor(props) {
    super(props);
    this.getDataDebounced = debounce(this.getData, 300);
  }

  handleFilter = e => {
    var obj = this.props.filterSettings.filters;
    obj[e.target.id] = e.target.value;

    this.getDataDebounced(
      this.props.filterSettings.page,
      this.props.filterSettings.perPage,
      this.props.filterSettings.orderBy,
      this.props.filterSettings.order,
      obj
    );
    this.props.dispatch(
      setOrderFilterSettings({
        ...this.props.filterSettings,
        filters: obj,
        page: 0
      })
    );
  };

  //type = true -> archived
  //type = false -> upcoming
  getData = (page, rowsPerPage, orderBy, order, filters) => {
    getOrders(page, rowsPerPage, orderBy, order, filters).then(
      data => {
        this.decorateData(data);
        this.setState({
          orders: data.data,
          totalItemsCount: data.total,
          refreshing: false
        });
      },
      e => {
        this.setState({ refreshing: false });
        this.props.dispatch(setError(e));
      }
    );
  };

  handlePay = () => {
    payOrder(this.state.clickedButton.id, this.state.clickedButton.active).then(
      data => {
        const orders = this.state.orders;
        for (let i = 0; i < orders.length; i++) {
          if (orders[i].id === data.id)
            orders[i].payButton = this.payButton(
              data.id,
              !this.state.clickedButton.active
            );
        }
        this.setState({ orders: orders });
        this.handlePayClose();
        this.props.dispatch(
          setSuccess(
            this.state.clickedButton.active
              ? { message: "SUCCESS_PAY" }
              : { message: "SUCCESS_UNPAY" }
          )
        );
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  handleCancel = () => {
    cancelOrder(this.state.clickedButton.id).then(
      data => {
        const orders = this.state.orders;
        this.props.dispatch(
          setSuccess(
            !this.state.clickedButton.active
              ? { message: "SUCCESS_CANCEL" }
              : { message: "SUCCESS_UNCANCEL" }
          )
        );
        for (let i = 0; i < orders.length; i++) {
          if (orders[i].id === data.id)
            orders[i].cancelButton = (
              <CancelButton
                itemId={data.id}
                isCancelled={!this.state.clickedButton.active}
                setLabel={"resources.competitions.cancelButton.cancel"}
                unsetLabel={"resources.competitions.cancelButton.unCancel"}
                cancelHandler={e => {
                  this.props.dispatch(togglePopup("cancelOrderPopup"));
                  this.setState({
                    clickedButton: {
                      id: data.id,
                      active: !this.state.clickedButton.active
                    }
                  });
                }}
              />
            );
        }
        this.setState({ orders: orders });
        this.handleCancelClose();
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  handleCancelClose = () => {
    this.props.dispatch(togglePopup("cancelOrderPopup"));
  };

  handlePayClose = () => {
    this.props.dispatch(togglePopup("payOrderPopup"));
  };

  payButton = (id, paid) => {
    return paid ? (
      <Tooltip title={this.props.translate("resources.orderList.setToPaid")}>
        <IconButton
          onClick={e => {
            this.props.dispatch(togglePopup("payOrderPopup"));
            this.setState({ clickedButton: { id: id, active: paid } });
          }}
        >
          <MoneyOnIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title={this.props.translate("resources.orderList.setToNotpaid")}>
        <IconButton
          onClick={e => {
            this.props.dispatch(togglePopup("payOrderPopup"));
            this.setState({ clickedButton: { id: id, active: paid } });
          }}
        >
          <MoneyOffIcon />
        </IconButton>
      </Tooltip>
    );
  };

  OpenDetailsButton = competitionId => {
    let path = "/orderDetails/" + competitionId;
    let pathEdit = "/orders/" + competitionId;
    return (
      <div>
        <IconButton component={Link} to={path}>
          <ZoomInIcon />
        </IconButton>
      </div>
    );
  };

  decorateData = data => {
    for (let i = 0; i < data.data.length; i++) {
      let item = data.data[i];
      item.paid = true;
      item.cancelled = true;
      for (let j = 0; j < item.orderItems.length; j++) {
        if (item.orderItems[j].paid == false) item.paid = false;
        if (item.orderItems[j].cancelled == false) item.cancelled = false;
      }

      item.openDetailsButton = this.OpenDetailsButton(item.id);
    }
  };

  handlePageChange = (page, rowsPerPage) => {
    this.getData(
      page,
      rowsPerPage,
      this.props.filterSettings.orderBy,
      this.props.filterSettings.order,
      this.props.filterSettings.filters
    );
    this.props.dispatch(
      setOrderFilterSettings({
        ...this.props.filterSettings,
        page: page,
        rowsPerPage: rowsPerPage
      })
    );
  };

  handleSort = (property, order) => {
    getOrders(
      this.props.filterSettings.page,
      this.props.filterSettings.perPage,
      property,
      order,
      this.props.filterSettings.filters
    ).then(
      data => {
        this.decorateData(data);
        this.setState({ orders: data.data, totalItemsCount: data.total });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
    this.props.dispatch(
      setOrderFilterSettings({
        ...this.props.filterSettings,
        orderBy: property,
        order: order
      })
    );
  };

  componentDidMount = () => {
    this.getData(
      this.props.filterSettings.page,
      this.props.filterSettings.perPage,
      this.props.filterSettings.orderBy,
      this.props.filterSettings.order,
      this.props.filterSettings.filters
    );
  };

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  hideSuccessMessage = () => {
    this.props.dispatch(setSuccess(null));
  };

  handleRefresh = () => {
    this.setState({ refreshing: true });
    this.getData(
      this.props.filterSettings.currentPage,
      this.props.filterSettings.rowsPerPage,
      this.props.filterSettings.orderBy,
      this.props.filterSettings.order,
      this.props.filterSettings.filters
    );
  };

  render = () => (
    <WithPermissions
      render={({ permissions }) => (
        <Fragment>
          <Typography gutterBottom variant="headline">
            {this.props.translate(
              isClubAdmin(permissions)
                ? "resources.orderList.titleClubAdmin"
                : "resources.orderList.titleAdmin"
            )}
          </Typography>
          <OrderList
            filterValues={this.props.filterSettings.filters}
            handleRefresh={this.handleRefresh}
            refreshing={this.state.refreshing}
            successMessage={this.props.successMessage}
            hideSuccess={this.hideSuccessMessage}
            handleCancelClose={this.handleCancelClose}
            handlePayClose={this.handlePayClose}
            handleCancel={this.handleCancel}
            handlePay={this.handlePay}
            orders={this.state.orders}
            page={this.props.filterSettings.page}
            rowsPerPage={this.props.filterSettings.perPage}
            total={this.state.totalItemsCount}
            handleChangePage={this.handlePageChange}
            handleChangeRowsPerPage={this.handlePageChange}
            handleArchiveChange={this.handleArchiveChange}
            sortCallback={this.handleSort}
            errorMessage={this.props.httpErrorMsg}
            hideError={this.hideErrorMessage}
            translate={this.props.translate}
            translationPrefix={"resources.orderList"}
            dateParseString={moment.HTML5_FMT.DATETIME_LOCAL_MS}
            momentFormat={"L"}
            locale={"de-at"}
            permissions={permissions}
            handleFilter={this.handleFilter}
          />
        </Fragment>
      )}
    />
  );
}
const mapStateToProps = state => {
  return {
    filterSettings: state.orderList.filterSettings,
    httpErrorMsg: state.error.httpError.message,
    successMessage: state.success.successMessage.message
  };
};
export default connect(mapStateToProps)(translate(OrderListPage));
