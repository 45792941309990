// React imports
import React, { Fragment } from "react";

// React Admin imports
import { translate } from "react-admin";

// Redux imports
import { connect } from "react-redux";

// Material imports
import Tooltip from "@material-ui/core/Tooltip";

import {
  ClubIcon,
  CompetitionTypeIcon,
  DateIcon,
  LevelIcon,
  RegionIcon,
  SpecialEventIcon
} from "./IconLibrary";

// Other imports
import PropTypes from "prop-types";

import moment from "moment";
import withWidth from "@material-ui/core/withWidth";

moment.locale("de-at");

const InfoWrapper = props => (
  <Tooltip title={props.title}>
    <span
      style={{
        whiteSpace: "nowrap",
        flexWrap: "nowrap",
        marginRight: "3px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "60vw"
      }}
    >
      {props.icon} {props.text}
    </span>
  </Tooltip>
);

const CompetitionInformationIcons = withWidth()(props => {
  const { record, width } = props;

  return (
    <Fragment>
      {record.event
        ? [
            <InfoWrapper
              key={5}
              title="Sonderveranstaltung"
              text={"Sonderveranstaltung"}
              icon={<SpecialEventIcon fontSize="inherit" />}
            />,
            " "
          ]
        : null}
      <InfoWrapper
        key={0}
        title="Datum"
        icon={<DateIcon fontSize="inherit" />}
        text={
          record.competitionDate
            ? moment(record.competitionDate)
                .format("ddd L")
                .toString() +
              (record.competitionEndDate
                ? " - " +
                  moment(record.competitionEndDate)
                    .format("ddd L")
                    .toString()
                : "")
            : null
        }
      />{" "}
      <InfoWrapper
        key={1}
        title="Region"
        icon={<RegionIcon fontSize="inherit" />}
        text={record.country ? record.country.toUpperCase() : null}
      />{" "}
      <InfoWrapper
        key={2}
        title="Austragender Verein"
        icon={<ClubIcon fontSize="inherit" />}
        text={record.clubShortName ? record.clubShortName : null}
      />{" "}
      {record.event ? null : (
        <InfoWrapper
          key={3}
          title="Level"
          icon={<LevelIcon fontSize="inherit" />}
          text={record.levelName ? record.levelName : null}
        />
      )}{" "}
      {record.event ? (
        <InfoWrapper
          key={4}
          title="Sonderveranstaltung"
          icon={<CompetitionTypeIcon fontSize="inherit" />}
          text={record.eventType ? record.eventType : null}
        />
      ) : (
        <InfoWrapper
          key={4}
          title="Match Typ"
          icon={<CompetitionTypeIcon fontSize="inherit" />}
          text={record.competitionTypeName ? record.competitionTypeName : null}
        />
      )}
    </Fragment>
  );
});

CompetitionInformationIcons.propTypes = {
  record: PropTypes.object.isRequired
};
export default connect()(translate(CompetitionInformationIcons));
