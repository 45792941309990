import Grid from "@material-ui/core/Grid";
import React from "react";
import {
  NullableBooleanInput,
  CardActions,
  Datagrid,
  DateField,
  EditButton,
  Filter,
  FunctionField,
  List,
  ListButton,
  Show,
  SimpleShowLayout,
  TextField,
  TextInput,
  translate,
  WithPermissions,
  CreateButton,
  Pagination
} from "react-admin";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import BlockButton from "../../components/BlockButton";
import BlockClubPopup from "../../components/BlockClubPopup";
import ImagePreview from "../../components/inputs/ImagePreview";
import { ClubFlagsChip } from "../../components/MiscChips";
import { setError } from "../../reducer/errorActions";
import { togglePopup } from "../../reducer/flagActions";
import { updateClub } from "../../utils/restUtils";
import {
  getTimeDiffToNow,
  noPaymentRowStyle,
  isClubAdmin,
  isAdmin
} from "../../utils/utils";
import PageTitle from "../../components/PageTitle";
import { DateInput } from "react-admin-date-inputs";
import {
  rowsPerPageOptions,
  defaultRowsPerPageOption
} from "../../utils/paging";
import PopupBulkDeleteButton from "../../components/PopupBulkDeleteButton";

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right"
};

const Actions = ({ basePath, data, refresh }) => (
  <CardActions style={cardActionStyle}>
    <EditButton basePath={basePath} record={data} />
    <ListButton basePath={basePath} />
    {/*<DeleteButton basePath={basePath} record={data} />*/}
  </CardActions>
);

const ClubFilter = translate(props => (
  <Filter {...props}>
    <TextInput source="name" alwaysOn />
    <DateInput
      label={props.translate(
        "resources.clubs.listPage.filters.paymentUntilFrom"
      )}
      source="paymentUntilFrom"
      options={{
        ampm: false,
        clearable: true
      }}
      alwaysOn
    />
    <DateInput
      label={props.translate("resources.clubs.listPage.filters.paymentUntilTo")}
      source="paymentUntilTo"
      options={{
        ampm: false,
        clearable: true
      }}
      alwaysOn
    />
    <TextInput source="address" alwaysOn />
    <TextInput source="zip" alwaysOn />
    <TextInput source="city" alwaysOn />
    <NullableBooleanInput
      source="active"
      options={{ defaultToggled: true }}
      alwaysOn
    />
  </Filter>
));

const CreateActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  data,
  refresh
}) => (
  <CardActions style={cardActionStyle}>
    <WithPermissions
      render={({ permissions }) => [
        filters &&
          React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: "button"
          }),
        isAdmin(permissions) && !isClubAdmin(permissions) && (
          <CreateButton basePath={basePath} record={data} />
        )
      ]}
    />
  </CardActions>
);
const mapStateToProps = state => ({
  blockClubPopupOpen: state.flags.popupOpen.popupBlockClub,
  blockClubData: state.flags.data.popupBlockClub,
  httpErrorMsg: state.error.httpError.message
});

const mapDispatchToProps = dispatch => ({
  push,
  dispatch
});

export const ClubList = translate(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(props => {
    const doBlockClub = doBlock => {
      let data = { id: props.blockClubData.id, active: !doBlock };
      updateClub(data).then(
        data => {
          props.dispatch(togglePopup("popupBlockClub"));
          props.dispatch(push("/clubs_"));
          props.dispatch(push("/clubs"));
          props.dispatch(setError(null));
        },
        e => {
          props.dispatch(setError(e));
        }
      );
    };

    const PostPagination = props => (
      <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
    );

    return (
      <WithPermissions
        render={({ permissions }) => (
          <Grid>
            <PageTitle
              title={props.translate("resources.clubs.listPage.headline")}
            />
            <List
              {...props}
              actions={<CreateActions />}
              filters={<ClubFilter />}
              sort={{ field: "name", order: "ASC" }}
              bulkActionButtons={<PopupBulkDeleteButton />}
              perPage={defaultRowsPerPageOption}
              pagination={<PostPagination />}
            >
              <Datagrid rowStyle={noPaymentRowStyle}>
                <TextField source="name" />
                <DateField source="paymentUntil" locales="de-at" />
                <TextField source="address" />
                <TextField source="zip" />
                <TextField source="city" />
                <TextField
                  sortBy="contact.lastName"
                  source="contact.fullName"
                />

                <FunctionField
                  render={record => (
                    <ClubFlagsChip
                      active={record.active}
                      translate={props.translate}
                    />
                  )}
                />

                <EditButton label="" />
                <FunctionField
                  render={record =>
                    permissions &&
                    (permissions.indexOf("IS_ADMIN") > -1 ||
                      permissions.indexOf("IS_PORTAL_ADMIN") > -1) && (
                      <BlockButton
                        itemId={record.id}
                        blockHandler={() =>
                          props.dispatch(
                            togglePopup("popupBlockClub", {
                              id: record.id,
                              isBlocked: record.active ? false : true
                            })
                          )
                        }
                        isBlocked={record.active ? false : true}
                      />
                    )
                  }
                />
              </Datagrid>
            </List>
            <BlockClubPopup
              open={props.blockClubPopupOpen}
              handleClose={() => props.dispatch(togglePopup("popupBlockClub"))}
              handleBlock={() =>
                doBlockClub(
                  props.blockClubData ? props.blockClubData.isBlocked : false
                )
              }
              errorMessage={props.httpErrorMsg}
              isBlocked={
                props.blockClubData ? props.blockClubData.isBlocked : false
              }
            />
          </Grid>
        )}
      />
    );
  })
);

const WithProps = ({ children, ...props }) => children(props);

export const ClubShow = translate(props => (
  <Grid>
    <PageTitle title={props.translate("resources.clubs.fields.clubHeader")} />
    <Show actions={<Actions />} {...props}>
      <SimpleShowLayout>
        <TextField source="longName" />
        <TextField source="address" />
        <TextField source="city" />
        <TextField source="zip" />
        <WithProps>
          {({ record, ...props }) => {
            if (record && record.logo && record.logo.id) {
              return <ImagePreview imageId={record.logo.id}></ImagePreview>;
            } else {
              return null;
            }
          }}
        </WithProps>
        <TextField source="country" />
        <TextField source="googleMapsLink" />
        <TextField source="publicLinkId" />
        <TextField source="websiteLink" />

        <FunctionField
          label={" "}
          render={record =>
            record.bankInformation && (
              <div>
                <h2>
                  {props.translate("resources.clubs.showPage.bank.information")}
                  :
                </h2>
                {props.translate("resources.clubs.showPage.bank.name")}:
                {record.bankInformation.name}
                <br />
                {props.translate("resources.clubs.showPage.bank.bic")}:
                {record.bankInformation.bic}
                <br />
                {props.translate("resources.clubs.showPage.bank.iban")}:
                {record.bankInformation.iban}
                <br />
              </div>
            )
          }
        />

        <h2>{props.translate("resources.clubs.fields.matchHeader")}</h2>
        <TextField source="matchAddress" />
        <TextField source="matchCity" />
        <TextField source="matchCountry" />
        <TextField source="matchZip" />
        <h2>{props.translate("resources.clubs.fields.contactHeader")}</h2>
        <TextField source="contact.firstName" />
        <TextField source="contact.lastName" />
        <TextField source="contact.email" />
        <TextField source="contact.telephone" />

        {/*
      <h2>{props.translate("resources.clubs.fields.clubAdmins")}</h2>
      <WithProps>
        {({ record, ...props }) => {
          return (
            <Table>
              <TableBody>
                {record.clubAdmins &&
                  record.clubAdmins.map((item, key) => {
                    return (
                      <TableRow key={key}>
                        <TableCell>
                          {item.firstName} {item.lastName}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          );
        }}
      </WithProps>
      */}

        <h2>{props.translate("resources.clubs.fields.paymentHeader")}</h2>
        <DateField source="paymentUntil" />
        <FunctionField
          label={" "}
          render={record =>
            record.paymentUntil && getTimeDiffToNow(record.paymentUntil) < 0
              ? props.translate("resources.clubs.fields.paymentExpired")
              : !record.paymentUntil
              ? props.translate("resources.clubs.fields.noPayment")
              : props.translate("resources.clubs.fields.validPayment")
          }
        />
        <FunctionField
          label="resources.users.fields.active"
          render={record =>
            record.active
              ? props.translate("resources.clubs.fields.isActive")
              : props.translate("resources.clubs.fields.isBlocked")
          }
        />
      </SimpleShowLayout>
    </Show>
  </Grid>
));
