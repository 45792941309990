// React imports
import React from "react";

// Material imports
import { Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import PropTypes from "prop-types";
// Icon imports
import ErrorIcon from "@material-ui/icons/Error";

let SuccessMessage = props => {
  const styles = {
    error: {
      backgroundColor: "#62ad72",
      color: "#ffffff"
    },
    icon: {
      fontSize: 20,
      marginRight: 10,
      color: "#ffffff"
    },
    message: {
      display: "flex",
      alignItems: "center"
    },
    errorText: {
      color: "#ffffff"
    }
  };
  return (
    <Snackbar open={props.open} onClose={props.onClose} autoHideDuration={7000}>
      <SnackbarContent
        style={styles.error}
        aria-describedby="error-snackbar"
        message={
          <span id="error-snackbar" style={styles.message}>
            <ErrorIcon style={styles.icon} />
            {props.successMessage &&
              props.successMessage
                .split(",")
                .map(item => (
                  <Typography style={styles.errorText}>
                    {props.translate(item)}
                  </Typography>
                ))}
          </span>
        }
      />
    </Snackbar>
  );
};

SuccessMessage.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  successMessage: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired
};

export default SuccessMessage;
