import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";

const styles = theme => ({
  margin: {
    marginLeft: 40,
    marginTop: 20,
    width: 300
  }
});

function InputWithIcon(props) {
  const { classes, onChange, active } = props;

  return (
    <TextField
      onChange={onChange}
      className={classes.margin}
      id="input-with-icon-textfield"
      label={active ? "Suche" : "Suche nur für Premiummitglieder"}
      disabled={!active}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        )
      }}
    />
  );
}

InputWithIcon.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(InputWithIcon);
