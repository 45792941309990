import React, { Component } from "react";

import CheckoutForm from "../../components/pages/CheckoutForm";
import { connect } from "react-redux";
import { setCart } from "../../reducer/cartActions";
import {
  createOrder,
  getOrderItemsPublic,
  deleteOrderItem,
  submitOrder,
  redirectWirecardOrder,
  getOrderItem
} from "../../utils/restUtils";
import { setError } from "../../reducer/errorActions";
import { setSuccess } from "../../reducer/successActions";
import { translate } from "react-admin";

class CheckoutFormPage extends Component {
  state = {
    orderItems: [],
    paymentMethods: [],
    hasWaitingListItems: false
  };

  getOrderItemForOrder = () => {
    getOrderItemsPublic(0, 1000, "id", "asc", {
      reserved: "RESERVED",
      orderId: localStorage.getItem("cartId"),
      cart: true
    }).then(
      data => {
        this.setState({
          orderItems: this.groupWaitingListOrderItems(data.data),
          paymentMethods: this.extractPaymentMethods(data.data)
        });
        localStorage.setItem("cartAmount", data.data.length);
        localStorage.setItem("cartList", data.data);
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  groupWaitingListOrderItems = orderItems => {
    let groupedOrderItems = [];
    let currentUuid;
    orderItems.forEach((orderItem, key) => {
      if (orderItem.multipleWaitingList) {
        if (orderItem.multipleWaitingListUuid !== currentUuid) {
          orderItem.squads = [orderItem.squadName];
          currentUuid = orderItem.multipleWaitingListUuid;
          orderItem.competitionName =
            orderItem.competitionName +
            " (" +
            this.props.translate("shooterCompetitionList.waitingList") +
            ")";
          orderItem.isWaitingList = true;
          this.setState({ hasWaitingListItems: true });
          groupedOrderItems.push(orderItem);
        } else
          groupedOrderItems[groupedOrderItems.length - 1].squads.push(
            orderItem.squadName
          );
      } else {
        if (orderItem.squadSize < orderItem.squadSubscriptionSize) {
          orderItem.competitionName =
            orderItem.competitionName +
            " (" +
            this.props.translate("shooterCompetitionList.waitingList") +
            ")";
          this.setState({ hasWaitingListItems: true });
          orderItem.isWaitingList = true;
        }
        groupedOrderItems.push(orderItem);
      }
    });
    return groupedOrderItems;
  };

  extractPaymentMethods = orderItems => {
    let paymentMethods = [];
    if (orderItems[0]) {
      if (orderItems[0].competitionPayOnSite) paymentMethods.push("BARZAHLUNG");

      if (orderItems[0].competitionPaymentViaDeposit)
        paymentMethods.push("VORAUSZAHLUNG");

      if (orderItems[0].competitionPaymentWithWirecard)
        paymentMethods.push("WIRECARD");
    }
    return paymentMethods;
  };

  handleDelete = deletedIndex => {
    let cartList = [];
    this.state.orderItems.map((item, index) => {
      if (deletedIndex !== index) cartList.push(item);
    });
    deleteOrderItem(this.state.orderItems[deletedIndex].id).then(
      data => {
        this.getOrderItemForOrder();
        this.props.dispatch(
          setCart({ amount: cartList.length, list: cartList })
        );
      },
      e => this.props.dispatch(setError(e))
    );
    localStorage.setItem("cartList", JSON.stringify(cartList));
  };

  getTotalPrice = () => {
    let sum = 0;
    this.state.orderItems.map((item, index) => {
      if (!item.isWaitingList) sum += item.competitionNominationFee;
    });
    return sum;
  };

  resetCart = () => {
    let cartList = [];
    this.props.dispatch(setCart({ amount: cartList.length, list: cartList }));
    localStorage.setItem("cartList", JSON.stringify(cartList));
    localStorage.setItem("cartAmount", cartList.length);
  };

  submitOrder = () => {
    /*let data = {
      firstName: this.props.cart.list[0].form.firstName,
      lastName: this.props.cart.list[0].form.lastName,
      email: this.props.cart.list[0].form.email,
      orderItems: []
    };

    for (let i = 0; i < this.props.cart.list.length; i++) {
      let orderItem = {
        firstName: this.props.cart.list[i].form.firstName,
        lastName: this.props.cart.list[i].form.lastName,
        email: this.props.cart.list[i].form.email,
        competitionId: this.props.cart.list[i].competition.id,
        : this.props.cart.list[i].form.csDevisions,
        factorId: this.props.cart.list[i].form.csFactors,
        squadId: this.props.cart.list[i].form.Squad,
        userId: this.props.cart.list[i].form.csUsers,
        reservationCode: this.props.cart.list[i].form.registrationCode
      };

      data.orderItems.push(orderItem);
    }

    createOrder(data).then(
      data => {
        this.props.dispatch(setError(null));
        this.props.dispatch(
          setSuccess({
            message: "SUCCESS_ORDER"
          })
        );
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );*/
    let orderId = localStorage.getItem("cartId");
    let paymentMethod = this.props.paymentMethod;

    if (orderId !== null && orderId !== "null") {
      if (paymentMethod !== "WIRECARD")
        submitOrder(orderId, paymentMethod).then(
          data => {
            this.props.dispatch(setSuccess("ORDER_SUBMITTED"));
            this.props.dispatch(setCart({ amount: 0, cartId: null }));
            localStorage.removeItem("cartId");
            this.setState({ orderItems: [] });
          },
          e => setError(e)
        );
      else
        redirectWirecardOrder(orderId, paymentMethod).then(
          data => {
            window.location.href = data["payment-redirect-url"];
          },
          e => setError(e)
        );
    }
  };

  // eslint-disable-next-line react/no-deprecated
  componentWillMount = () => {
    if (localStorage.getItem("cartId") !== "null") this.getOrderItemForOrder();
  };

  hideSuccessMessage = () => {
    this.props.dispatch(setSuccess(null));
  };

  render() {
    return (
      <CheckoutForm
        hasWaitingListItems={this.state.hasWaitingListItems}
        paymentMethods={this.state.paymentMethods}
        history={this.props.history}
        cart={this.state.orderItems}
        handleDelete={this.handleDelete}
        resetCart={this.resetCart}
        submitOrder={this.submitOrder}
        successMessage={this.props.successMessage}
        hideSuccess={this.hideSuccessMessage}
        getTotalPrice={this.getTotalPrice}
        goToCompetitionsLinkAction={() =>
          this.props.history.push("/competitionList")
        }
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart,
    paymentMethod:
      state.form.checkoutForm && state.form.checkoutForm.values
        ? state.form.checkoutForm.values.paymentMethod
        : null,
    successMessage: state.success.successMessage.message
  };
};

export default translate(connect(mapStateToProps)(CheckoutFormPage));
