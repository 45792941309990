import React, { Component } from "react";
import { translate, REDUX_FORM_NAME, WithPermissions } from "react-admin";
import { connect } from "react-redux";
import { change } from "redux-form";
import ClubEdit from "../../components/pages/ClubEdit";
import { getNotificationTypes } from "../../utils/restUtils";

const ClubTitle = ({ record }) => {
  return <span>{record ? record.name : ""}</span>;
};
class ClubEditPage extends Component {
  state = {
    defaultNotificationBlocks: []
  };
  componentDidMount = () => {};

  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.formExists == false && this.props.formExists == true) {
      getNotificationTypes().then(
        data => {
          let defaultBlocks = [];
          for (let i = 0; i < data.length; i++) {
            defaultBlocks.push({
              text: "",
              type: data[i]
            });
          }
          this.setState({
            defaultNotificationBlocks: defaultBlocks
          });
        },
        () => {}
      );
    }
    if (
      prevProps.formExists &&
      prevState.defaultNotificationBlocks.length == 0 &&
      this.state.defaultNotificationBlocks.length > 0 &&
      this.props.notificationBlocks
    ) {
      let tmpBlocks = [];
      for (let i = 0; i < this.state.defaultNotificationBlocks.length; i++) {
        for (let j = 0; j < this.props.notificationBlocks.length; j++) {
          if (
            this.state.defaultNotificationBlocks[i].type.id ===
            this.props.notificationBlocks[j].type.id
          ) {
            tmpBlocks[i] = this.props.notificationBlocks[j];
          }
        }
        if (!tmpBlocks[i]) {
          tmpBlocks[i] = this.state.defaultNotificationBlocks[i];
        }
      }
      this.props.dispatch(
        change(REDUX_FORM_NAME, "notificationBlocks", tmpBlocks)
      );
    }
  };

  render = () => {
    return (
      <WithPermissions
        render={({ permissions }) => <ClubEdit {...this.props} />}
      />
    );
  };
}

const mapStateToProps = state => {
  return {
    httpErrorMsg: state.error.httpError.message,
    permissions: state.identity.userData.permissions,
    formExists:
      typeof state.form[REDUX_FORM_NAME] != "undefined" ? true : false,
    notificationBlocks:
      state.form[REDUX_FORM_NAME] && state.form[REDUX_FORM_NAME].values
        ? state.form[REDUX_FORM_NAME].values.notificationBlocks
        : null
  };
};

export default connect(mapStateToProps)(translate(ClubEditPage));
