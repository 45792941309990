import "babel-polyfill";
import React, { Component } from "react";
import { Admin, Resource } from "react-admin";
import "./theme/styles/App.css";
import authProvider, { fetchIdentity } from "./providers/authProvider";
import CustomLayout from "./theme/layouts/CustomLayout";
import Menu from "./theme/partials/Menu.js";
import customRoutes from "./routes";
import germanMessages from "./i18n/de";
import englishMessages from "./i18n/en";
import dataProviderFactory from "./providers/dataProvider";
import addUploadFeature from "./providers/dataProvider/addUploadFeature";
import ProfileEdit from "./pages/profile";
import userReducer from "./reducer/userReducer";
import flagReducer from "./reducer/flagReducer";
import errorReducer from "./reducer/errorReducer";
import successReducer from "./reducer/successReducer";
import cartReducer from "./reducer/cartReducer";
import filterSettingsReducer from "./reducer/filterSettingsReducer";
import orderItemReducer from "./reducer/orderItemReducer";
import orderListReducer from "./reducer/orderListReducer";
import LogoutButton from "./components/LogoutButton";
import userSaga from "./sagas/";
import { ClientCreate, ClientEdit, ClientList } from "./pages/client";
import { UserList } from "./pages/user/list";
import { OrderItemEdit } from "./pages/order/index";
import { UserShow } from "./pages/user/show";
import UserEditPage from "./pages/user/UserEditPage";
import UserCreatePage from "./pages/user/UserCreatePage";
import { ClubList, ClubShow } from "./pages/club";
import ClubCreate from "./pages/club/ClubCreatePage";
import ClubEdit from "./pages/club/ClubEditPage";
import ClubProfileEdit from "./pages/club/ClubProfileEditPage";
import {
  UserRoleCreate,
  UserRoleEdit,
  UserRoleList,
  UserRoleShow
} from "./pages/userRoles";
import { FactorCreate, FactorEdit, FactorList } from "./pages/factors";
import {
  AdvertisingCreate,
  AdvertisingEdit,
  AdvertisingList
} from "./pages/advertising";
import { LevelCreate, LevelEdit, LevelList } from "./pages/levels";
import {
  CompetitionTypeCreate,
  CompetitionTypeEdit,
  CompetitionTypeList
} from "./pages/competitionTypes";
import { ClassCreate, ClassEdit, ClassList } from "./pages/classes";
import { DivisionCreate, DivisionEdit, DivisionList } from "./pages/divisions";
import CompetitionCreate from "./pages/competition/CompetitionCreate";
import EventCreate from "./pages/event/EventCreate";
import EventEdit from "./pages/event/EventEdit";
import CompetitionEdit from "./pages/competition/CompetitionEdit";
import { CompetitionList } from "./pages/competition/";
import { EventList } from "./pages/event/";
import CompetitionPage from "./pages/competition/CompetitionListPage";
import CompetitionDetailPage from "./pages/competition/CompetitionShowPage";
import ShooterOrderItemEdit from "./pages/personalPages/ShooterOrderItemEdit";
import {
  UploadTypeCreate,
  UploadTypeEdit,
  UploadTypeList
} from "./pages/uploadTypes";
import {
  ReservationCreate,
  ReservationEdit,
  ReservationList,
  ReservationShow
} from "./pages/reservations";
import Dashboard from "./pages/dashboard/Dashboard";

const i18nProvider = locale => {
  if (locale === "en") {
    return englishMessages;
  }
  // Always fallback on english
  return germanMessages;
};

class App extends Component {
  state = { uploadCapableDataProvider: null };

  async componentWillMount() {
    await fetchIdentity().catch(e => console.log(e));
    const dataProvider = await dataProviderFactory("rest");

    const uploadCapableDataProvider = addUploadFeature(dataProvider);

    this.setState({ uploadCapableDataProvider });
  }

  componentWillUnmount() {
    this.dataProvider();
  }

  render() {
    const { uploadCapableDataProvider } = this.state;
    const locale = localStorage.getItem("locale") || "de";

    if (!uploadCapableDataProvider) {
      return (
        <div className="loader-container">
          <div className="loader">Loading...</div>
        </div>
      );
    }

    return (
      <Admin
        title="Compsign"
        dataProvider={uploadCapableDataProvider}
        authProvider={authProvider}
        customRoutes={customRoutes}
        customReducers={{
          identity: userReducer,
          flags: flagReducer,
          error: errorReducer,
          success: successReducer,
          cart: cartReducer,
          filterSettings: filterSettingsReducer,
          orderItem: orderItemReducer,
          orderList: orderListReducer
        }}
        customSagas={[userSaga]}
        dashboard={Dashboard}
        loginPage={false}
        logoutButton={LogoutButton}
        appLayout={CustomLayout}
        menu={Menu}
        locale={locale}
        i18nProvider={i18nProvider}
      >
        {permissions => [
          <Resource name="profile" edit={ProfileEdit} />,
          <Resource
            name="clients"
            list={ClientList}
            edit={ClientEdit}
            create={ClientCreate}
          />,
          <Resource
            name="users"
            list={UserList}
            edit={UserEditPage}
            create={UserCreatePage}
            show={UserShow}
          />,
          <Resource
            name="uploadTypes"
            list={UploadTypeList}
            edit={UploadTypeEdit}
            create={UploadTypeCreate}
          />,
          <Resource name="orderItems" edit={OrderItemEdit} />,
          <Resource
            name="userRoles"
            list={UserRoleList}
            edit={UserRoleEdit}
            create={UserRoleCreate}
            show={UserRoleShow}
          />,
          <Resource
            name="factors"
            list={FactorList}
            edit={FactorEdit}
            create={FactorCreate}
          />,
          <Resource
            name="advertisings"
            list={AdvertisingList}
            edit={AdvertisingEdit}
            create={AdvertisingCreate}
          />,
          <Resource
            name="competitionClasses"
            list={ClassList}
            edit={ClassEdit}
            create={ClassCreate}
          />,
          <Resource
            name="divisions"
            list={DivisionList}
            edit={DivisionEdit}
            create={DivisionCreate}
          />,
          <Resource
            name="levels"
            list={LevelList}
            edit={LevelEdit}
            create={LevelCreate}
          />,
          <Resource
            name="clubs"
            list={ClubList}
            edit={ClubEdit}
            create={ClubCreate}
            show={ClubShow}
          />,
          <Resource name="clubProfile" edit={ClubProfileEdit} />,
          <Resource name="notificationTypes" />,
          <Resource
            name="competitionspub"
            list={CompetitionPage}
            show={CompetitionDetailPage}
          />,
          <Resource
            name="competitions"
            create={CompetitionCreate}
            edit={CompetitionEdit}
            list={CompetitionList}
          />,
          <Resource
            name="events"
            create={EventCreate}
            edit={EventEdit}
            list={EventList}
          />,
          <Resource name="divisions" />,
          <Resource name="factors" />,
          <Resource name="competitionClasses" />,
          <Resource
            name="competitionTypes"
            list={CompetitionTypeList}
            edit={CompetitionTypeEdit}
            create={CompetitionTypeCreate}
          />,
          <Resource name="squads" />,
          <Resource
            name="reservations"
            list={ReservationList}
            edit={ReservationEdit}
            create={ReservationCreate}
            show={ReservationShow}
          />,
          <Resource name="shooterOrderItems" edit={ShooterOrderItemEdit} />
        ]}
      </Admin>
    );
  }
}

export default App;
