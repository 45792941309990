import React, { Component } from "react";
import { translate } from "react-admin";
import { connect } from "react-redux";
import { FormControlLabel, Switch } from "@material-ui/core";
import { updateProfileWithCompetitionView } from "../utils/restUtils";
import { change, touch } from "redux-form";
import { isGuest } from "../utils/utils";
import PropTypes from "prop-types";
import { moment } from "moment";

class CompetitionViewSwitch extends Component {
  state = {
    checked: this.props.listType
  };

  handleChange = (event, isGuest) => {
    this.updateViewTypeInIdentityAndLocalStorageAndState(event.target.checked);
    if (!isGuest)
      updateProfileWithCompetitionView(event.target.checked).then(data => {
        this.updateViewTypeInIdentityAndLocalStorageAndState(data);
      });
  };

  updateViewTypeInIdentityAndLocalStorageAndState = viewType => {
    this.setState({ checked: viewType });
    localStorage.setItem("competitionViewType", viewType);
    var identity = this.props.identity;
    identity.userData.competitionViewType = viewType;
    this.props.dispatch(change("identity", identity));
    this.props.dispatch(touch("form"));
  };

  render = () => (
    <FormControlLabel
      control={
        <Switch
          onChange={event =>
            this.handleChange(
              event,
              isGuest(this.props.identity.userData.permissions)
            )
          }
          disabled={
            isGuest(this.props.identity.userData.permissions) ||
            (!isGuest(this.props.identity.userData.permissions) &&
              (this.props.identity.userData.paymentValid === false ||
                this.props.identity.userData.paymentValid === "false"))
          }
          checked={this.props.listType === true}
        />
      }
      label={
        isGuest(this.props.identity.userData.permissions)
          ? this.props.translate(
              "resources.competitions.listPage.listViewGuest"
            )
          : this.props.translate("resources.competitions.listPage.listView")
      }
    />
  );
}

CompetitionViewSwitch.propTypes = {
  listType: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    identity: state.identity
  };
};

export default connect(mapStateToProps)(translate(CompetitionViewSwitch));
