import { Tooltip, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import React, { Component, Fragment } from "react";
import { translate, WithPermissions } from "react-admin";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CancelButton from "../../components/CancelButton";
import OrderItemList from "../../components/pages/OrderItemList";
import { setError } from "../../reducer/errorActions";
import { togglePopup } from "../../reducer/flagActions";
import { setSuccess } from "../../reducer/successActions";
import {
  setFilters,
  setOrder,
  setOrderBy,
  setPage,
  setPerPage
} from "../../reducer/filterSettingsActions";
import {
  cancelOrderItem,
  changeSquad,
  exportFilteredOrderItems,
  getAllClubs,
  getOrderItems,
  getUsers,
  loadOrderItem,
  payOrderItem
} from "../../utils/restUtils";
import {
  isAdmin,
  isClubAdmin,
  isPortalAdmin,
  resolveProjectionPropertyName
} from "../../utils/utils";
import download from "downloadjs";
import { defaultRowsPerPageOption } from "../../utils/paging";
import { Autorenew } from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import Close from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import OrderItemChange from "../../components/pages/OrderItemChange";
import { GenericUsersIcon } from "../../components/IconLibrary";

const styles = {
  simpleTitle: {
    marginBottom: "20px"
  }
};

const debounce = (fn, delay) => {
  let timer = null;
  return function(...args) {
    const context = this;
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, delay);
  };
};

class OrderItemListPage extends Component {
  state = {
    orderItems: [],
    currentPage: 0,
    rowsPerPage: defaultRowsPerPageOption,
    totalItemsCount: 0,
    archive: false,
    filters: [],
    fullsearch: "",
    clickedButton: null,
    loading: false,
    clubs: [],
    users: [],
    changeOrderItem: null
  };

  constructor(props) {
    super(props);
    this.getDataDebounced = debounce(this.getData, 300);
  }

  handleArchiveChange = name => event => {
    this.getData(this.state.currentPage, this.state.rowsPerPage);
  };

  //type = true -> archived
  //type = false -> upcoming
  getData = (page, rowsPerPage, field, order, filter) => {
    getOrderItems(page, rowsPerPage, field, order, filter).then(
      data => {
        this.decorateData(data);
        this.setState({
          orderItems: data.data,
          totalItemsCount: data.total,
          refreshing: false
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  editButton = competitionId => {
    let pathEdit = "/orderItems/" + competitionId;
    return (
      <div>
        <IconButton component={Link} to={pathEdit}>
          <EditIcon />
        </IconButton>
      </div>
    );
  };

  handlePay = () => {
    payOrderItem(
      this.state.clickedButton.id,
      this.state.clickedButton.active
    ).then(
      data => {
        const orderItems = this.state.orderItems;
        for (let i = 0; i < orderItems.length; i++) {
          if (orderItems[i].id === data.id)
            orderItems[i].paid = this.payButton(
              data.id,
              !this.state.clickedButton.active
            );
        }
        this.setState({ orderItems: orderItems });
        this.props.dispatch(
          setSuccess(
            this.state.clickedButton.active
              ? { message: "SUCCESS_UNPAY" }
              : { message: "SUCCESS_PAY" }
          )
        );
        this.handlePayClose();
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  handleCancel = () => {
    cancelOrderItem(this.state.clickedButton.id).then(
      data => {
        const orderItems = this.state.orderItems;
        this.props.dispatch(
          setSuccess(
            data.cancelled
              ? { message: "SUCCESS_CANCEL" }
              : { message: "SUCCESS_UNCANCEL" }
          )
        );
        for (let i = 0; i < orderItems.length; i++) {
          if (orderItems[i].id === data.id)
            orderItems[i].cancelButton = (
              <CancelButton
                itemId={data.id}
                isCancelled={data.cancelled}
                setLabel={"resources.orderItemList.cancel"}
                unsetLabel={"resources.orderItemList.uncancel"}
                cancelHandler={e => {
                  this.props.dispatch(togglePopup("cancelOrderItemPopup"));
                  this.setState({
                    clickedButton: { id: data.id, active: data.cancelled },
                    unCancelled: data.cancelled
                  });
                }}
              />
            );
        }
        this.setState({ orderItems: orderItems });
        this.handleCancelClose();
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  hideSuccessMessage = () => {
    this.props.dispatch(setSuccess(null));
  };

  handleCancelClose = () =>
    this.props.dispatch(togglePopup("cancelOrderItemPopup"));

  handlePayClose = () => this.props.dispatch(togglePopup("payOrderItemPopup"));

  handleExport = () => {
    this.setState({ loading: true });
    exportFilteredOrderItems(
      this.props.filterSettings.filters,
      this.props.filterSettings.orderBy,
      this.props.filterSettings.order
    )
      .then(response => {
        this.setState({ loading: false });
        return response.blob();
      })
      .then(
        data => {
          download(
            data,
            "Bewerbs-Anmeldungen",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
  };

  payButton = (id, paid) => {
    return paid ? (
      <Tooltip title={this.props.translate("resources.orderList.setToPaid")}>
        <IconButton
          onClick={e => {
            this.props.dispatch(togglePopup("payOrderItemPopup"));
            this.setState({
              clickedButton: { id: id, active: paid },
              unPaid: paid
            });
          }}
        >
          <CheckIcon />
        </IconButton>
      </Tooltip>
    ) : (
      <Tooltip title={this.props.translate("resources.orderList.setToNotpaid")}>
        <IconButton
          onClick={e => {
            this.props.dispatch(togglePopup("payOrderItemPopup"));
            this.setState({
              clickedButton: { id: id, active: paid },
              unPaid: paid
            });
          }}
        >
          <ClearIcon />
        </IconButton>
      </Tooltip>
    );
  };

  changeButton = orderItem => {
    return orderItem.cancelled ? (
      <></>
    ) : (
      <Tooltip title={this.props.translate("resources.orderList.change")}>
        <IconButton
          onClick={e => {
            loadOrderItem(orderItem.id)
              .then(oi => {
                this.setState({ changeOrderItem: oi }, () =>
                  this.props.dispatch(togglePopup("changeOrderItemPopup"))
                );
              })
              .catch(err => this.props.dispatch(setError(err)));
          }}
        >
          <Autorenew />
        </IconButton>
      </Tooltip>
    );
  };

  handleChange = (source, target) => {
    changeSquad(source.id, target.id)
      .then(() => {
        this.handleChangeClose();
        this.handleRefresh();
      })
      .catch(err => this.props.dispatch(setError(err)));
  };

  handleChangeClose = () => {
    this.props.dispatch(togglePopup("changeOrderItemPopup"));
  };

  decorateData = data => {
    for (let i = 0; i < data.data.length; i++) {
      let item = data.data[i];
      item.userId = item.userId ? <GenericUsersIcon /> : "";
      item.edit = this.editButton(item.id);
      item.paidState = item.paid;
      item.paid = this.payButton(item.id, item.paid);
      item.change = this.changeButton(item);
      if (item.competitionEvent !== null && item.competitionEvent === true) {
        item.squadName = "";
      }
      if (item.cancelled == true) {
        item.cancelButton = "Abgemeldet";
      } else {
        item.cancelButton = (
          <CancelButton
            type=""
            itemId={item.id}
            isCancelled={item.cancelled}
            setLabel={"resources.orderItemList.cancel"}
            unsetLabel={"resources.orderItemList.uncancel"}
            cancelHandler={e => {
              this.props.dispatch(togglePopup("cancelOrderItemPopup"));
              this.setState({
                clickedButton: { id: item.id, active: item.cancelled },
                unCancelled: item.cancelled
              });
            }}
          />
        );
      }
      if (item.waitingList === true)
        item.waitingList = (
          <Typography style={{ width: 20, textAlign: "center" }}>W</Typography>
        );
      else if (item.waitingList === false)
        item.waitingList = (
          <Typography style={{ width: 20, textAlign: "center" }}>S</Typography>
        );
      else
        item.waitingList = (
          <Typography style={{ width: 20, textAlign: "center" }}>-</Typography>
        );
    }
  };

  handlePageChange = (page, rowsPerPage) => {
    this.getData(
      page,
      rowsPerPage,
      this.props.filterSettings.orderBy,
      this.props.filterSettings.order,
      this.props.filterSettings.filters
    );
    this.props.dispatch(setPage(page));
    this.props.dispatch(setPerPage(rowsPerPage));
  };

  handleFilter = e => {
    var obj = this.props.filterSettings.filters;
    obj[e.target.id] = e.target.value;

    this.getDataDebounced(
      this.props.filterSettings.page,
      this.props.filterSettings.perPage,
      this.props.filterSettings.orderBy,
      this.props.filterSettings.order,
      obj
    );
    this.props.dispatch(setFilters(obj));
    this.props.dispatch(setPage(0));
  };

  handleSort = (property, order) => {
    let resolvedProperty = resolveProjectionPropertyName(property);
    getOrderItems(
      this.props.filterSettings.page,
      this.props.filterSettings.perPage,
      resolvedProperty,
      order,
      this.props.filterSettings.filters
    ).then(
      data => {
        this.decorateData(data);
        this.setState({ orderItems: data.data, totalItemsCount: data.total });
        this.props.dispatch(setOrder(order));
        this.props.dispatch(setOrderBy(resolvedProperty));
        this.props.dispatch(setPage(0));
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  componentDidMount = () => {
    this.getData(
      this.props.filterSettings.page,
      this.props.filterSettings.perPage,
      this.props.filterSettings.orderBy,
      this.props.filterSettings.order,
      this.props.filterSettings.filters
    );

    getAllClubs().then(data => {
      this.setState({ clubs: data ? data : [] }, () => {});
    });

    if (
      isAdmin(this.props.identity.userData.permissions) ||
      isClubAdmin(this.props.identity.userData.permissions) ||
      isPortalAdmin(this.props.identity.userData.permissions)
    ) {
      getUsers(1, 9999, { active: true, userRole: "ROLE_USER" }).then(
        data => {
          const users = [];
          data.map((user, key) => {
            users.push({
              id: user.id,
              name: user.lastName + ", " + user.firstName,
              userFirstName: user.firstName,
              userLastName: user.lastName,
              userEmail: user.email,
              value: user.id,
              club: user.club,
              factorId: user.factorId ? user.factorId : null,
              competitionClassId: user.competitionClassId
                ? user.competitionClassId
                : null,
              divisionId: user.divisionId ? user.divisionId : null,
              country: user.country,
              state: user.state
            });
          });
          this.setState({ users: users });
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
    }
  };

  handleRefresh = () => {
    this.setState({ refreshing: true });
    this.getDataDebounced(
      this.props.filterSettings.page,
      this.props.filterSettings.perPage,
      this.props.filterSettings.orderBy,
      this.props.filterSettings.order,
      this.props.filterSettings.filters
    );
  };

  render = () => (
    <WithPermissions
      render={({ permissions }) => (
        <Fragment>
          <Typography gutterBottom variant="headline">
            {this.props.translate("resources.orderItemList.title")}
          </Typography>

          <Dialog
            open={this.props.changeOrderItemPopup}
            onClose={this.handleChangeClose}
          >
            <DialogContent style={styles.dialogWrapper}>
              <div style={styles.closeBtn}>
                <IconButton aria-label="Close" onClick={this.handleChangeClose}>
                  <Close />
                </IconButton>
              </div>
              <OrderItemChange
                orderItem={this.state.changeOrderItem}
                translate={this.props.translate}
                onSubmit={this.handleChange}
              />
            </DialogContent>
          </Dialog>
          <OrderItemList
            refreshing={this.state.refreshing}
            handleRefresh={this.handleRefresh}
            loading={this.state.loading}
            handleExport={this.handleExport}
            successMessage={this.props.successMessage}
            hideSuccess={this.hideSuccessMessage}
            handleCancelClose={this.handleCancelClose}
            handlePayClose={this.handlePayClose}
            handleCancel={this.handleCancel}
            handlePay={this.handlePay}
            orderItems={this.state.orderItems}
            filterValues={this.props.filterSettings.filters}
            page={this.props.filterSettings.page}
            rowsPerPage={this.props.filterSettings.perPage}
            total={this.state.totalItemsCount}
            handleChangePage={this.handlePageChange}
            handleChangeRowsPerPage={this.handlePageChange}
            handleArchiveChange={this.handleArchiveChange}
            sortCallback={this.handleSort}
            errorMessage={this.props.httpErrorMsg}
            hideError={this.hideErrorMessage}
            translate={this.props.translate}
            translationPrefix={"resources.orderList"}
            dateParseString={moment.ISO_8601}
            momentFormat={"DD.MM. YYYY HH:mm"}
            locale={"de_DE"}
            permissions={permissions}
            handleFilter={this.handleFilter}
            unCancelled={this.state.unCancelled}
            unPaid={this.state.unPaid}
            clubs={this.state.clubs}
            users={this.state.users}
            identity={this.props.identity}
          />
        </Fragment>
      )}
    />
  );
}

const mapStateToProps = state => {
  return {
    changeOrderItemPopup: state.flags.popupOpen.changeOrderItemPopup,
    httpErrorMsg: state.error.httpError.message,
    successMessage: state.success.successMessage.message,
    filterSettings: state.filterSettings,
    identity: state.identity
  };
};

export default connect(mapStateToProps)(translate(OrderItemListPage));
