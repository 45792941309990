import { SET_IDENTITY, RELOAD_USER, UPDATE_USERDATA } from "./userActions";

const INITIAL_STATE = {
  userData: {}
};

export default (previousState = INITIAL_STATE, { type, payload }) => {
  if (type === SET_IDENTITY) {
    return { ...previousState, ...payload };
  }
  if (type === UPDATE_USERDATA) {
    return { userData: { ...previousState.userData, ...payload } };
  }
  if (type === RELOAD_USER) {
    //console.log("RELOAD");
  }
  return previousState;
};
