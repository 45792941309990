import moment from "moment";
import React, { Component } from "react";
import { translate } from "react-admin";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import OrderShow from "../../components/pages/OrderShow";
import { setError } from "../../reducer/errorActions";
import { getOrder, getOrderItem } from "../../utils/restUtils";

class OrderShowPage extends Component {
  state = {
    order: null,
    orderItems: null
  };

  componentDidMount = () => {
    let { id } = this.props.match.params;
    getOrder(id).then(
      success => {
        this.setState({ order: success });
      },
      e => {
        this.setState({ order: null });
        this.props.dispatch(setError(e));
      }
    );
    getOrderItem(id).then(
      success => {
        this.setState({ orderItems: success.content });
      },
      e => {
        this.setState({ orderItems: null });
        this.props.dispatch(setError(e));
      }
    );
  };

  render() {
    return this.state.order !== " " &&
      this.state.order !== null &&
      this.state.orderItems !== " " &&
      this.state.orderItems !== null ? (
      <OrderShow
        translate={this.props.translate}
        order={this.state.order}
        orderItems={this.state.orderItems}
        userHasPermission={true}
        momentFormat={"L"}
        dateParseString={moment.HTML5_FMT.DATETIME_LOCAL_MS}
        translationPrefix={"resources.orderShow"}
      />
    ) : this.state.order === " " ? (
      <Redirect to={"/orderList"} />
    ) : null;
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {};
};

export default connect(mapStateToProps)(translate(OrderShowPage));
