// React imports
import React, { useState } from "react";

// Redux imports
import { connect } from "react-redux";

// Icon imports
import Favorite from "@material-ui/icons/Star";
import FavoriteBorder from "@material-ui/icons/StarBorder";

// Other imports
import PropTypes from "prop-types";

import { setFavourite } from "../utils/restUtils";
import { setError } from "../reducer/errorActions";

import { IconButton } from "@material-ui/core";

const CompetitionFavouriteButton = props => {
  const [favourite, changeValue] = useState(props.fav);

  const handleClick = (val, e) => {
    changeValue(val);
  };

  return favourite === true ? (
    <IconButton
      aria-label="Delete"
      onClick={() => {
        setFavourite({ id: props.competitionId, data: false }).then(
          success => null,
          e => {
            props.dispatch(setError(e));
          }
        );
        handleClick(false);
      }}
    >
      <Favorite />
    </IconButton>
  ) : (
    <IconButton
      aria-label="Delete"
      onClick={() => {
        setFavourite({ id: props.competitionId, data: true }).then(
          success => null,
          e => {
            props.dispatch(setError(e));
          }
        );
        handleClick(true);
      }}
    >
      <FavoriteBorder />
    </IconButton>
  );
};

CompetitionFavouriteButton.propTypes = {
  fav: PropTypes.bool.isRequired,
  competitionId: PropTypes.number.isRequired
};
export default connect()(CompetitionFavouriteButton);
