import React from "react";
import { connect } from "react-redux";
import { Responsive, userLogout } from "react-admin";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import ExitIcon from "@material-ui/icons/PowerSettingsNew";

const styles = {
  logoutBtn: {
    width: "100%",
    margin: "0 0 10px 0",
    justifyContent: "flex-start"
  },
  leftIcon: {
    marginRight: "10px"
  }
};

const LogoutButton = ({ userLogout, ...rest }) => (
  <Responsive
    xsmall={
      <MenuItem onClick={userLogout} {...rest}>
        <ExitIcon /> Logout
      </MenuItem>
    }
    medium={
      <Button onClick={userLogout} style={styles.logoutBtn} {...rest}>
        <ExitIcon style={styles.leftIcon} /> Logout
      </Button>
    }
  />
);
const redirectTo = "/dashboard";
const myCustomUserLogout = () => userLogout(redirectTo);
export default connect(
  undefined,
  { userLogout: myCustomUserLogout }
)(LogoutButton);
