import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { translate } from "react-admin";
import { Form, Field, reduxForm, blur, touch } from "redux-form";
import { renderTextField } from "./inputs/renderMaterialFields";
import { required } from "./inputs/validator";
import { Typography } from "@material-ui/core";
import withMobileDialog from "@material-ui/core/withMobileDialog";

const styles = {
  errorMsg: {
    color: "#CB5D58",
    marginTop: "10px"
  }
};

let LoginFormPopup = props => {
  const { fullScreen, handleClose } = props;
  const keyPressed = event => {
    if (event && event.key.toUpperCase() == "ENTER") {
      checkAndSubmit();
    }
  };

  const checkAndSubmit = () => {
    Object.keys(props.myForm.registeredFields).forEach(key => {
      props.dispatch(touch("userLoginForm", key));
      props.dispatch(blur("userLoginForm", key));
    });
    if (props.valid) {
      props.handleLogin();
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
        {props.translate("appBar.loginForm.loginHeader")}
      </DialogTitle>
      <DialogContent>
        <Form onSubmit={checkAndSubmit}>
          {
            <div>
              <Field
                name="username"
                id="username"
                component={renderTextField}
                validate={required}
                type="text"
                label={props.translate("appBar.loginForm.username")}
                margin="dense"
                fullWidth
                autoFocus
                onKeyPress={keyPressed}
              />
            </div>
          }
          {
            <div>
              <Field
                name="password"
                id="password"
                component={renderTextField}
                validate={required}
                type="password"
                label={props.translate("appBar.loginForm.password")}
                margin="dense"
                fullWidth
                onKeyPress={keyPressed}
              />
            </div>
          }
        </Form>

        <DialogContentText>
          {props.errorMessage && (
            <Typography variant="p" component="p" style={styles.errorMsg}>
              {props.translate("ERROR." + props.errorMessage)}
            </Typography>
          )}
          <Button
            type="button"
            style={{ marginTop: "8px" }}
            href="#/userRegister"
            onClick={props.handleClose}
          >
            {props.translate("appBar.loginForm.noAccountYet")}
          </Button>

          <Button
            type="button"
            style={{ marginTop: "8px" }}
            href="#/resetPassword"
            onClick={props.handleClose}
          >
            {props.translate("appBar.loginForm.forgotPassword")}
          </Button>
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          style={{ color: "#4F6982" }}
          onClick={handleClose}
          color="primary"
        >
          {props.translate("appBar.loginForm.cancel")}
        </Button>
        <Button
          type="submit"
          style={{ color: "#4F6982" }}
          onClick={checkAndSubmit}
          color="primary"
        >
          {props.translate("appBar.loginForm.doLogin")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

LoginFormPopup = reduxForm({
  // a unique name for the form
  form: "userLoginForm"
})(LoginFormPopup);

function mapStateToProps(state) {
  return {
    myForm: state.form.userLoginForm
  };
}

LoginFormPopup.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string
};

export default withMobileDialog()(
  translate(connect(mapStateToProps)(LoginFormPopup))
);
