import { SET_ERROR } from "./errorActions";

const INITIAL_STATE = {
  httpError: {
    status: null,
    body: null,
    name: null,
    message: null
  }
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case SET_ERROR:
      // eslint-disable-next-line no-case-declarations
      let error = data.error ? data.error : null;
      if (error && error.message) {
        if (error.message.indexOf("Error: ") === 0) {
          error.message = error.message.substr(7);
        }
      }
      return {
        httpError: {
          status: error ? error.status : null,
          body: error ? error.body : null,
          name: error ? error.name : null,
          message: error ? error.message : null
        }
      };
    default:
      return state;
  }
};

export default reducer;
