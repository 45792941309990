import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { changeLocale as changeLocaleAction } from "react-admin";

class LocaleSwitcher extends Component {
  switchToGerman = () => {
    this.props.changeLocale("de");
  };
  switchToEnglish = () => {
    this.props.changeLocale("en");
  };

  render() {
    const { changeLocale } = this.props;
    return (
      <div>
        <img
          src={window.location.origin + "/images/countries/at.png"}
          width="25"
          height="15"
          style={{ marginRight: "10px" }}
          onClick={this.switchToGerman}
        />
        <img
          src={window.location.origin + "/images/countries/gb.png"}
          width="25"
          height="15"
          onClick={this.switchToEnglish}
        />
      </div>
    );
  }
}

export default connect(
  undefined,
  { changeLocale: changeLocaleAction }
)(LocaleSwitcher);
