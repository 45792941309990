import React from "react";
import { translate } from "react-admin";
import PageTitle from "../../components/PageTitle";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { withRouter } from "react-router-dom";
import { Component } from "react";
import { setCart } from "../../reducer/cartActions";
// Redux imports
import { connect } from "react-redux";
import {
  isAdmin,
  isClubAdmin,
  isPortalAdmin,
  isShooter
} from "../../utils/utils";

const styles = {
  teaserButton: {
    color: "#7e886e"
  }
};

class OrderCompletePage extends Component {
  render() {
    return (
      <div>
        <PageTitle
          title={this.props.translate("orderCompletePage.orderSucessTitle")}
        />
        <Card>
          <CardContent style={{ maxWidth: "1200px" }}>
            <p>
              {this.props.translate("orderCompletePage.orderSucessMessage")}
            </p>
            <Button
              onClick={() =>
                isShooter(this.props.identity.userData.permissions)
                  ? this.props.history.push("/myCompetitions")
                  : this.props.history.push("/competitionList")
              }
              style={styles.teaserButton}
            >
              {isShooter(this.props.identity.userData.permissions)
                ? this.props.translate("orderCompletePage.myCompetitions")
                : this.props.translate("orderCompletePage.competitions")}
            </Button>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    identity: state.identity,
    cart: state.cart,
    httpErrorMsg: state.error.httpError.message,
    successMessage: state.success.successMessage.message
  };
};

export default withRouter(
  connect(mapStateToProps)(translate(OrderCompletePage))
);
