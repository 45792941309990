import Grid from "@material-ui/core/Grid";
import React from "react";
import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  Edit,
  List,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  Show,
  SimpleForm,
  TextField,
  translate,
  DateField,
  Pagination
} from "react-admin";
import { DateTimeInput } from "react-admin-date-inputs";
import PageTitle from "../../components/PageTitle";
import { Chip } from "@material-ui/core";
import CustomToolbar from "../../components/CustomToolbar";
import {
  rowsPerPageOptions,
  defaultRowsPerPageOption
} from "../../utils/paging";
import PopupBulkDeleteButton from "../../components/PopupBulkDeleteButton";

const CustomChipField = ({ source, subSource, record = {} }) =>
  record && record[source] ? (
    <span>
      {record[source].map(value => (
        <Chip label={value[subSource]} />
      ))}
    </span>
  ) : null;

export const ReservationCreate = translate(props => (
  <Grid>
    <PageTitle
      title={props.translate("resources.reservations.createReservation")}
    />
    <Create {...props}>
      <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
        <ReferenceArrayInput
          sort={{ field: "username", order: "ASC" }}
          reference="users"
          source="user_ids"
        >
          <AutocompleteArrayInput required optionText="username" />
        </ReferenceArrayInput>

        <ReferenceArrayInput reference="competitions" source="competition_ids">
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput reference="divisions" source="division_ids">
          <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          reference="competitionClasses"
          source="competitionClass_ids"
        >
          <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput reference="factors" source="factor_ids">
          <SelectArrayInput />
        </ReferenceArrayInput>
        <BooleanInput
          label="resources.reservations.fields.qualification"
          source="qualification"
        />
        <NumberInput
          source="maxReservations"
          step={1}
          label="resources.reservations.fields.maxReservations"
        />
        <DateTimeInput
          options={{
            ampm: false,
            clearable: true
          }}
          source="validUntil"
          label="resources.reservations.fields.validUntil"
        />
      </SimpleForm>
    </Create>
  </Grid>
));

export const ReservationList = translate(props => (
  <Grid>
    <PageTitle>
      title={props.translate("resources.reservations.listReservation")}
    </PageTitle>
    <List
      bulkActionButtons={<PopupBulkDeleteButton />}
      exporter={false}
      pagination={
        <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
      }
      perPage={defaultRowsPerPageOption}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <CustomChipField
          source="users"
          subSource="username"
          record={props.record}
        />
        <CustomChipField
          source="divisions"
          subSource="name"
          record={props.record}
        />
        <BooleanField source="qualification" />
        <TextField source="maxReservations" />
        <DateField locales="de-DE" source="validUntil" showTime />
      </Datagrid>
    </List>
  </Grid>
));

export const ReservationShow = translate(props => (
  <Grid>
    <PageTitle>
      title={props.translate("resources.reservations.showReservation")}
    </PageTitle>
    <Show {...props}>
      <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
        <ReferenceArrayInput reference="competitions" source="competition_ids">
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput reference="divisions" source="division_ids">
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          reference="competitionClasses"
          source="competitionClass_ids"
        >
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput reference="factors" source="factor_ids">
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <BooleanField
          label="resources.reservations.fields.qualification"
          source="qualification"
        />
        <TextField
          source="maxReservations"
          step={1}
          label="resources.reservations.fields.maxReservations"
        />
        <TextField
          source="validUntil"
          label="resources.reservations.fields.validUntil"
        />
      </SimpleForm>
    </Show>
  </Grid>
));

export const ReservationEdit = translate(props => (
  <Grid>
    <PageTitle>
      title={props.translate("resources.reservations.editReservation")}
    </PageTitle>
    <Edit {...props}>
      <SimpleForm redirect="list" toolbar={<CustomToolbar />}>
        <ReferenceArrayInput reference="users" source="user_ids">
          <AutocompleteArrayInput required optionText="username" />
        </ReferenceArrayInput>
        <ReferenceArrayInput reference="competitions" source="competition_ids">
          <AutocompleteArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput reference="divisions" source="division_ids">
          <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          reference="competitionClasses"
          source="competitionClass_ids"
        >
          <SelectArrayInput />
        </ReferenceArrayInput>
        <ReferenceArrayInput reference="factors" source="factor_ids">
          <SelectArrayInput />
        </ReferenceArrayInput>
        <BooleanInput
          label="resources.reservations.fields.qualification"
          source="qualification"
        />
        <NumberInput
          source="maxReservations"
          step={1}
          label="resources.reservations.fields.maxReservations"
        />
        <DateTimeInput
          options={{
            ampm: false,
            clearable: true
          }}
          source="validUntil"
          label="resources.reservations.fields.validUntil"
        />
      </SimpleForm>
    </Edit>
  </Grid>
));
