// React imports
import React from "react";

// React Admin imports
import { translate } from "react-admin";

// Redux imports
import { connect } from "react-redux";

// Material imports
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// Icon imports
import DeleteIcon from "@material-ui/icons/DeleteForever";

// Other imports
import PropTypes from "prop-types";

const styles = {
  deleteBtn: {
    justifyContent: "center",
    color: "#CB5D58"
  }
};

const DeleteButton = ({ handleDelete, translate, itemId, ...rest }) => (
  <Tooltip title={translate("resources.users.deleteButton")}>
    <IconButton
      onClick={() => handleDelete(itemId)}
      style={styles.deleteBtn}
      {...rest}
    >
      <DeleteIcon />
    </IconButton>
  </Tooltip>
);

DeleteButton.propTypes = {
  handleDelete: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired
};
export default connect()(translate(DeleteButton));
