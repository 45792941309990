// React imports
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// Material imports
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React, { useState } from "react";
import ErrorMessage from "../ErrorMessage";
import BackButton from "../BackButton";
import { Switch } from "@material-ui/core";
import { EventListCard } from "../Cards";
import moment from "moment";
import CompetitionInformationIcons from "../CompetitionInformationIcons";
import DownloadIcon from "@material-ui/icons/GetApp";
import restClient from "../../providers/dataProvider/rest";
import {
  getClubShowImageSrc,
  getCompetitionListImageSrc
} from "../../utils/imageUtils";
import { FILE_API_PATH } from "../../utils/config";
import countries from "../../components/pages/country-codes-case-lower.json";

const styles = {
  root: {
    flexGrow: 1
  },
  paper: {
    textAlign: "center"
  },
  spacer: {
    height: "20px"
  },
  cardTitleBgGreen: {
    backgroundColor: "#7E896E"
  },
  cardTitleBgPurple: {
    backgroundColor: "#7C757B"
  },
  cardTitle: {
    color: "#ffffff",
    display: "flex",
    minHeight: "40px",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  notification: {
    backgroundColor: "rgba(67, 160, 71, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#43A047",
    padding: "8px 10px"
  },
  error: {
    backgroundColor: "rgba(211, 46, 47, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#D32E2F",
    padding: "8px 10px"
  },
  info: {
    backgroundColor: "rgba(26, 118, 210, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#43A047",
    padding: "8px 10px"
  },
  notificationText: {
    color: "#ffffff"
  },
  clubInfoCard: {
    display: "flex",
    marginBottom: "20px"
  },
  clubInfoDetails: {
    display: "flex",
    flexDirection: "column"
  },
  clubInfoContent: {
    flex: "1 0 auto"
  },
  clubInfoCover: {
    minWidth: "151px",
    backgroundSize: "contain"
  },
  clubInfoControls: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "14px",
    paddingBottom: "8px"
  },
  clubInfoDate: {
    marginBottom: "8px"
  },
  clubInfoButton: {
    color: "#7e886e"
  },
  simpleTitle: {
    marginBottom: "20px"
  }
};

const ClubShowPublic = props => {
  const { club, translate } = props;

  const [archiveCompetitionView, setArchiveCompetitionView] = useState(false);

  const handleSwitchChange = () => {
    props.handleArchiveChange();
    setArchiveCompetitionView(!archiveCompetitionView);
  };

  const getFreeSpots = competition => {
    let totalSlots = 0;
    let bookedSlots = 0;
    for (let i = 0; i < competition.squads.length; i++) {
      if (!competition.squads[i].multipleWaitingList) {
        totalSlots += competition.squads[i].squadSize;
        bookedSlots +=
          competition.squads[i].squadSize >=
          competition.squads[i].subscriptionSize
            ? competition.squads[i].subscriptionSize
            : competition.squads[i].squadSize;
      }
    }
    return totalSlots - bookedSlots + " / " + totalSlots;
  };

  const btnClickHandler = publicLink => {
    props.history.push("/competitionDetails/" + publicLink);
  };

  const getDownloadsActions = (competition, translate) => {
    let actions = [];
    if (
      competition.competitionUploads &&
      competition.competitionUploads.length > 0
    ) {
      competition.competitionUploads.map(competitionUpload =>
        actions.push({
          callBack: () => fetchFileFromBucket(competitionUpload.document.path),
          text: competitionUpload.type.showName
            ? competitionUpload.document.fileName
            : competitionUpload.type.name,
          icon: <DownloadIcon />
        })
      );
    } else
      actions = [
        {
          text: translate("NO_DOWNLOADS_AVAILABLE"),
          icon: <DownloadIcon />
        }
      ];

    return actions;
  };

  const fetchFile = id => {
    restClient("DOWNLOAD", "download", {
      id: id
    }).then(response => {
      try {
        const filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        response.blob().then(blob => {
          if (typeof callback !== "undefined") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      } catch (e) {}
    });
  };

  const fetchFileFromBucket = filePath => {
    window.open(FILE_API_PATH + "/" + filePath);
  };

  const getCountryFromCode = currentCountry => {
    return countries.find(country => country.countryCode === currentCountry)
      .countryName;
  };

  return (
    <Grid container spacing={16}>
      {club == null ? (
        <Grid item xs={12}>
          <div style={styles.error}>
            <Typography variant="body1" style={styles.notificationText}>
              {translate("resources.clubs.publicShow.NO_DATA_AVAILABLE")}
            </Typography>
          </div>
        </Grid>
      ) : (
        [
          <Grid item xs={12}>
            <BackButton
              history={props.history}
              tooltip={translate("backButton.tooltip")}
            />
            <Card style={styles.clubInfoCard}>
              <CardMedia
                style={styles.clubInfoCover}
                image={getClubShowImageSrc(club.logo)}
              />
              <div style={styles.clubInfoDetails}>
                <CardContent style={styles.clubInfoContent}>
                  <Typography variant="display2">{club.name}</Typography>
                </CardContent>
                {club.websiteLink ? (
                  <div style={styles.clubInfoControls}>
                    <Button
                      size="small"
                      style={styles.clubInfoButton}
                      target="_blank"
                      href={club.websiteLink}
                    >
                      {translate("resources.clubs.showPage.goToWebsite")}
                    </Button>
                  </div>
                ) : null}
              </div>
            </Card>
          </Grid>,
          <Grid item xs={12}>
            <Typography variant="display1" style={styles.simpleTitle}>
              {translate("resources.clubs.showPage.contactInfosHeadline")}
            </Typography>
            <Card>
              <CardContent>
                <Typography variant="headline">
                  {translate("resources.clubs.showPage.address")}
                </Typography>
                <Typography variant="body1">
                  {club.address + ", " + club.zip + " " + club.city}
                </Typography>
              </CardContent>
              {club.contact ? (
                <CardContent>
                  <Typography variant="headline">
                    {translate("resources.clubs.showPage.contact")}
                  </Typography>
                  <Typography variant="body1">
                    {club.contact.salutation
                      ? club.contact.salutation + " "
                      : null}
                    {club.contact.degree ? club.contact.degree + " " : null}
                    {club.contact.firstName
                      ? club.contact.firstName + " "
                      : null}
                    {club.contact.lastName ? club.contact.lastName + " " : null}
                  </Typography>
                  <Typography varaint="body1">
                    {club.contact.email ? club.contact.email + " " : null}
                  </Typography>
                  <Typography varaint="body1">
                    {club.contact.telephone ? club.contact.telephone : null}
                  </Typography>
                </CardContent>
              ) : null}
              <CardContent>
                <Typography variant="headline">
                  {translate("resources.clubs.showPage.matchaddress")}
                </Typography>
                <Typography variant="body1">
                  {club.matchAddress && club.matchZip && club.matchCity
                    ? club.matchAddress +
                      ", " +
                      club.matchZip +
                      " " +
                      club.matchCity +
                      " "
                    : club.address + ", " + club.zip + " " + club.city + " "}
                </Typography>
                <Typography>
                  {club.matchAddress && club.matchZip && club.matchCity
                    ? getCountryFromCode(club.matchCountry)
                    : getCountryFromCode(club.country)}
                </Typography>
                <Typography>
                  {club.googleMapsLink ? (
                    <div style={styles.clubInfoControls}>
                      <Button
                        size="small"
                        style={styles.clubInfoButton}
                        target="_blank"
                        href={club.googleMapsLink}
                      >
                        {translate("resources.clubs.showPage.googleMapsLink")}
                      </Button>
                    </div>
                  ) : null}
                </Typography>
              </CardContent>
            </Card>
          </Grid>,
          <Grid item xs={12}>
            {club.bankInformation
              ? [
                  <Typography variant="display1" style={styles.simpleTitle}>
                    {translate(
                      "resources.clubs.showPage.bankAccountInfosHeadline"
                    )}
                  </Typography>,
                  <Card>
                    <CardContent>
                      <Typography variant="body1">
                        {translate("resources.clubs.showPage.bank.name")}
                        {": "}
                        {club.bankInformation.name}
                        <br />
                        {translate("resources.clubs.showPage.bank.bic")}
                        {": "}
                        {club.bankInformation.bic}
                        <br />
                        {translate("resources.clubs.showPage.bank.iban")}
                        {": "}
                        {club.bankInformation.iban}
                      </Typography>
                    </CardContent>
                  </Card>
                ]
              : null}
          </Grid>,
          <Grid item xs={12}>
            {club.competitions
              ? [
                  <Typography variant="display1" style={styles.simpleTitle}>
                    {translate("resources.clubs.publicShow.competitions")}
                  </Typography>,
                  <Switch
                    checked={archiveCompetitionView}
                    onChange={handleSwitchChange}
                    value={archiveCompetitionView}
                  />,
                  <>{translate("resources.clubs.publicShow.archive")}</>,
                  <>
                    {archiveCompetitionView ? (
                      <>
                        {club.archiveCompetitions.map((record, key) => {
                          return (
                            <EventListCard
                              actions={getDownloadsActions(record, translate)}
                              freeSpots={getFreeSpots(record)}
                              key={"competition-" + key}
                              imageUrl={getCompetitionListImageSrc(
                                record.competitionTypeImage
                              )}
                              isEvent={record.event}
                              imageTitle={record.imageTitle}
                              header={record.name}
                              fav={record.favourite}
                              registered={record.registered}
                              date={moment(record.competitionDate)
                                .format(props.momentFormat)
                                .toString()}
                              club={record.clubName}
                              place={record.city}
                              infoText={record.information}
                              stages={record.stages}
                              type={record.competitionTypeName}
                              level={record.levelName}
                              id={record.id}
                              buttonLabel={translate(
                                "resources.competitions.listPage.buttonDetails"
                              )}
                              infoIcons={
                                <CompetitionInformationIcons record={record} />
                              }
                              btnClickHandler={() =>
                                btnClickHandler(record.publicLink)
                              }
                              translate={translate}
                              registrationStart={moment(
                                record.registrationStart
                              )
                                .format(props.momentFormatWithTime)
                                .toString()}
                            />
                          );
                        })}
                      </>
                    ) : (
                      <>
                        {club.upcomingCompetitions.map((record, key) => {
                          return (
                            <EventListCard
                              actions={getDownloadsActions(record, translate)}
                              freeSpots={getFreeSpots(record)}
                              key={"competition-" + key}
                              imageUrl={getCompetitionListImageSrc(
                                record.competitionTypeImage
                              )}
                              isEvent={record.event}
                              imageTitle={record.imageTitle}
                              header={record.name}
                              fav={record.favourite}
                              registered={record.registered}
                              paid={record.paid}
                              date={moment(record.competitionDate)
                                .format(props.momentFormat)
                                .toString()}
                              club={record.clubName}
                              place={record.city}
                              infoText={record.information}
                              stages={record.stages}
                              type={record.competitionTypeName}
                              level={record.levelName}
                              id={record.id}
                              buttonLabel={translate(
                                "resources.competitions.listPage.buttonDetails"
                              )}
                              infoIcons={
                                <CompetitionInformationIcons record={record} />
                              }
                              btnClickHandler={() =>
                                btnClickHandler(record.publicLink)
                              }
                              translate={translate}
                              registrationStart={moment(
                                record.registrationStart
                              )
                                .format(props.momentFormatWithTime)
                                .toString()}
                            />
                          );
                        })}
                      </>
                    )}
                  </>
                ]
              : null}
          </Grid>
        ]
      )}
      <ErrorMessage
        errorMessage={props.errorMessage}
        open={props.errorMessage ? true : false}
        onClose={props.hideError}
        translate={props.translate}
      />
    </Grid>
  );
};
ClubShowPublic.propTypes = {
  club: PropTypes.object.isRequired,
  translate: PropTypes.func.isRequired,
  hideError: PropTypes.func.isRequired
};
export default ClubShowPublic;
