// React imports
import React from "react";

// Material imports
import { Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

// Icon imports
import WarningIcon from "@material-ui/icons/Warning";

import PropTypes from "prop-types";

const browsersMinimumVersions = {
  chrome: 49,
  safari: 10,
  firefox: 52,
  explorer: 11
};

const getBrowserDatas = () => {
  var nAgt = navigator.userAgent;
  var browserName = navigator.appName;
  var fullVersion = "" + parseFloat(navigator.appVersion);
  var majorVersion = parseInt(navigator.appVersion, 10);
  var verOffset, ix;

  if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
    browserName = "Microsoft Internet Explorer";
    fullVersion = nAgt.substring(verOffset + 5);
  } else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
    browserName = "Chrome";
    fullVersion = nAgt.substring(verOffset + 7);
  } else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
    browserName = "Safari";
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf("Version")) !== -1)
      fullVersion = nAgt.substring(verOffset + 8);
  } else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
    browserName = "Firefox";
    fullVersion = nAgt.substring(verOffset + 8);
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(";")) !== -1)
    fullVersion = fullVersion.substring(0, ix);
  if ((ix = fullVersion.indexOf(" ")) !== -1)
    fullVersion = fullVersion.substring(0, ix);

  majorVersion = parseInt("" + fullVersion, 10);
  if (isNaN(majorVersion)) {
    fullVersion = "" + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
  }
  return {
    browserName: browserName,
    fullVersion: fullVersion,
    majorVersion: majorVersion,
    appName: navigator.appName,
    userAgent: navigator.userAgent
  };
};

const checkBrowserSupport = () => {
  var browser = getBrowserDatas();
  switch (browser.browserName) {
    case "Chrome":
      return browser.majorVersion >= browsersMinimumVersions.chrome;
    case "Safari":
      return browser.majorVersion >= browsersMinimumVersions.safari;
    case "Firefox":
      return browser.majorVersion >= browsersMinimumVersions.firefox;
    case "Edge":
      return browser.majorVersion >= browsersMinimumVersions.edge;
    case "Microsoft Internet Explorer":
      return browser.majorVersion >= browsersMinimumVersions.explorer;

    default:
      return true;
  }
};

let NotSupportedBrowserMessage = props => {
  const isSupported = checkBrowserSupport(getBrowserDatas());

  const styles = {
    error: {
      backgroundColor: "red",
      color: "#ffffff"
    },
    icon: {
      fontSize: 20,
      marginRight: 10,
      color: "#ffffff"
    },
    message: {
      display: "flex",
      alignItems: "center"
    },
    errorText: {
      color: "#ffffff"
    }
  };
  return (
    <Snackbar
      open={!isSupported}
      autoHideDuration={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <SnackbarContent
        style={styles.error}
        aria-describedby="not-supported-browser-snackbar"
        message={
          <span id="not-supported-browser-snackbar" style={styles.message}>
            <WarningIcon style={styles.icon} />
            <Typography style={styles.errorText}>{props.message}</Typography>
          </span>
        }
      />
    </Snackbar>
  );
};
NotSupportedBrowserMessage.propTypes = {
  message: PropTypes.string.isRequired
};
export default NotSupportedBrowserMessage;
