import React from "react";
import {
  required,
  translate,
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  Create,
  SimpleForm,
  Edit,
  DisabledInput,
  ReferenceArrayInput,
  SelectArrayInput,
  Toolbar,
  SaveButton,
  Pagination
} from "react-admin";
import compose from "recompose/compose";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PageTitle from "../../components/PageTitle";
import {
  rowsPerPageOptions,
  defaultRowsPerPageOption
} from "../../utils/paging";
import PopupBulkDeleteButton from "../../components/PopupBulkDeleteButton";
import CustomToolbar from "../../components/CustomToolbar";

const DivisionFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

export const DivisionList = compose(translate)(({ translate, ...props }) => (
  <Grid>
    <PageTitle title={translate("resources.divisions.listPage.headline")} />
    <List
      filters={<DivisionFilter />}
      exporter={false}
      pagination={
        <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
      }
      bulkActionButtons={<PopupBulkDeleteButton />}
      perPage={defaultRowsPerPageOption}
      {...props}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="name" />
      </Datagrid>
    </List>
  </Grid>
));

export const DivisionCreate = compose(translate)(
  ({ translate, history, ...props }) => (
    <Grid>
      <PageTitle title={translate("resources.divisions.createPage.headline")} />
      <Create {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar {...props} />}>
          <TextInput source="name" validate={required()} />
          <ReferenceArrayInput
            source="competitionType_ids"
            reference="competitionTypes"
            allowEmpty
          >
            <SelectArrayInput optionText="name" />
          </ReferenceArrayInput>
        </SimpleForm>
      </Create>
    </Grid>
  )
);

export const DivisionEdit = compose(translate)(
  ({ translate, history, ...props }) => (
    <Grid>
      <PageTitle title={translate("resources.divisions.editPage.headline")} />
      <Edit {...props}>
        <SimpleForm
          redirect="list"
          toolbar={<CustomToolbar disabledDelete {...props} />}
        >
          <DisabledInput label="Id" source="id" />
          <TextInput source="name" validate={required()} />
          <ReferenceArrayInput
            source="competitionType_ids"
            reference="competitionTypes"
            allowEmpty
          >
            <SelectArrayInput optionValue="id" optionText="name" />
          </ReferenceArrayInput>
        </SimpleForm>
      </Edit>
    </Grid>
  )
);
