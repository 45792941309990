import React, { Component } from "react";
import {
  SelectArrayInput,
  GET_LIST,
  ArrayInput,
  SelectInput,
  SimpleFormIterator,
  REDUX_FORM_NAME
} from "react-admin";
import { change } from "redux-form";
import { connect } from "react-redux";
import restClient from "../../providers/dataProvider/rest";
import { getUserRoles } from "../../utils/restUtils";
import SimplerFormIterator from "../../components/SimplerFromIterator";

class RoleSelectInput extends Component {
  state = {
    options: []
  };

  componentDidMount() {
    this.fetchData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.dependsOnValue !== this.props.dependsOnValue) {
      this.fetchData(nextProps);
    }
  }

  fetchData(props) {
    getUserRoles().then(options => {
      this.setState({ options: options }, () => {
        props &&
          props.type &&
          props.type == "create" &&
          this.props.dispatch(change(REDUX_FORM_NAME, "userRoleIds", [0]));
      });
    });
  }

  required = (message = "FIELD_IS_REQUIRED") => value =>
    value ? undefined : message;

  render() {
    return this.state.options.length > 0 ? (
      <ArrayInput label={""} source="userRoleIds">
        <SimplerFormIterator>
          <SelectInput
            {...this.props}
            choices={this.state.options}
            optionValue="id"
            optionText="name"
            filter={{ name: "ROLE_CLIENT_" }}
            label="resources.users.fields.userRole"
            allowEmpty
          />
        </SimplerFormIterator>
      </ArrayInput>
    ) : null;
  }
}

RoleSelectInput.propTypes = SelectArrayInput.propTypes;
RoleSelectInput.defaultProps = SelectArrayInput.defaultProps;

export default connect()(RoleSelectInput);
