import { SET_ORDER_ITEM } from "./orderItemAction";

const INITIAL_STATE = {
  orderItem: null
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case SET_ORDER_ITEM:
      return {
        data
      };
    default:
      return state;
  }
};

export default reducer;
