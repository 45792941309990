// React imports
import React, { Component } from "react";
import { connect } from "react-redux";
import compose from "recompose/compose";

// React Admin imports
import { translate, MenuItemLink, WithPermissions } from "react-admin";

// Material Ui imports
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { Divider } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

// Icon imports
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const styles = {
  root: {
    color: "#4D4D4D"
  },
  active: {
    color: "#4F6982"
  }
};

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#4F6982",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

class MenuElem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  hasPermission = (allPermissions, givenPermissions) => {
    // show item if no explicit permission is set
    if (givenPermissions.length === 0) {
      return true;
    }
    if (givenPermissions.includes("*")) {
      return true;
    }
    // hack to prevent registered user from seeing "register now"
    if (this.props.userId) {
      givenPermissions = givenPermissions.filter(n => n !== "IS_GUEST");
    }
    let intersect = allPermissions.filter(function(n) {
      return givenPermissions.indexOf(n) > -1;
    });
    if (intersect.length > 0) {
      return true;
    }
    return false;
  };

  openClose = key => {
    if (typeof this.state[[`open${key}`]] == "undefined") {
      this.setState({ [`open${key}`]: false });
    } else {
      this.setState({ [`open${key}`]: !this.state[[`open${key}`]] });
    }
  };

  render() {
    const {
      items,
      onMenuClick,
      logout,
      translate,
      profile,
      classes,
      userId,
      clubId
    } = this.props;
    return (
      <WithPermissions
        location={userId}
        render={({ permissions }) => (
          <div>
            {items.map((item, index) =>
              permissions &&
              this.hasPermission(
                permissions ? permissions : [],
                item.permissions ? item.permissions : []
              ) ? (
                item.type === "divider" ? (
                  <Divider key={index} />
                ) : item.type === "spacer" ? (
                  <div style={{ height: "100px" }}></div>
                ) : item.type === "menutitle" ? (
                  <Typography
                    variant="headline"
                    style={{
                      paddingLeft: "18px",
                      paddingTop: "15px",
                      paddingBottom: "10px",
                      color: "#4f6982"
                    }}
                  >
                    {translate(`menu.${item.label}`)}
                  </Typography>
                ) : !item.items ? (
                  <LightTooltip
                    key={item.name + index}
                    title={translate(`menu.${item.label}`, {
                      smart_count: 2
                    })}
                    placement="right"
                  >
                    {item.name.startsWith("http") ||
                    item.name.startsWith("//") ? (
                      <MenuItemLink
                        href={item.name}
                        component="a"
                        primaryText={translate(`menu.${item.label}`, {
                          smart_count: 2
                        })}
                        leftIcon={item.icon}
                        onClick={onMenuClick}
                        classes={classes}
                        style={{
                          height: "inherit",
                          minHeight: "24px",
                          whiteSpace: "inherit"
                        }}
                      />
                    ) : (
                      <MenuItemLink
                        to={
                          `/${item.name}` +
                          (item.name === "profile"
                            ? `/${userId}`
                            : item.name === "clubProfile"
                            ? `/${clubId}`
                            : "")
                        }
                        primaryText={translate(`menu.${item.label}`, {
                          smart_count: 2
                        })}
                        leftIcon={item.icon}
                        onClick={onMenuClick}
                        classes={classes}
                        style={{
                          height: "inherit",
                          minHeight: "24px",
                          whiteSpace: "inherit"
                        }}
                      />
                    )}
                  </LightTooltip>
                ) : (
                  <div key={item.name + index}>
                    <ListItem
                      button
                      onClick={() => this.openClose(item.name)}
                      style={{ marginLeft: "-8px" }}
                    >
                      {item.icon ? item.icon : null}
                      <ListItemText primary={translate(`menu.${item.label}`)} />
                      {typeof this.state[[`open${item.name}`]] == "undefined" ||
                      this.state[[`open${item.name}`]] ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </ListItem>
                    <Collapse
                      in={
                        typeof this.state[[`open${item.name}`]] ==
                          "undefined" || this.state[[`open${item.name}`]]
                      }
                      timeout="auto"
                      unmountOnExit
                    >
                      <MenuElem
                        items={item.items}
                        onMenuClick={onMenuClick}
                        translate={translate}
                        logout={logout}
                        profile={profile}
                      />
                    </Collapse>
                  </div>
                )
              ) : null
            )}
          </div>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  identity: state.identity,
  userId: state.identity.userData.id,
  clubId: state.identity.userData.clubId
});

const enhance = compose(
  translate,
  withStyles(styles),
  connect(mapStateToProps)
);

export default enhance(MenuElem);
