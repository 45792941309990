import React, { Component } from "react";
import { translate } from "react-admin";
import { setError } from "../../reducer/errorActions";
import { connect } from "react-redux";
import ResetPassword from "../../components/pages/ResetPassword";
import { resetPassword } from "../../utils/restUtils";
class ResetPasswordPage extends Component {
  state = { success: false };

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  handleSubmit = () => {
    let data = { ...this.props.myForm.values };

    resetPassword(data).then(
      data => {
        this.props.dispatch(setError(null));
        this.setState({ success: true });
      },
      e => {
        this.props.dispatch(setError(e));
        this.setState({ success: false });
      }
    );
  };

  render = () => (
    <ResetPassword
      translate={this.props.translate}
      handleSubmit={this.handleSubmit}
      errorMessage={this.props.httpErrorMsg}
      hideError={this.hideErrorMessage}
      showError={this.state.showError}
      success={this.state.success}
    />
  );
}
const mapStateToProps = state => {
  return {
    myForm: state.form.resetPasswordForm,
    httpErrorMsg: state.error.httpError.message
  };
};
export default connect(mapStateToProps)(translate(ResetPasswordPage));
