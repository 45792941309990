import ClearAllIcon from "@material-ui/icons/ClearAll";
import DBIcon from "@material-ui/icons/Dashboard";
import EventIcon from "@material-ui/icons/Event";
import FlagIcon from "@material-ui/icons/Flag";
import GroupIcon from "@material-ui/icons/Group";
import ListIcon from "@material-ui/icons/List";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import SecurityIcon from "@material-ui/icons/Security";
import SettingsIcon from "@material-ui/icons/Settings";
import TrackChangesIcon from "@material-ui/icons/TrackChanges";
import HelpIcon from "@material-ui/icons/Help";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import GetAppIcon from "@material-ui/icons/GetApp";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import React from "react";

export const DateIcon = props => <EventIcon {...props} />;
export const RegionIcon = props => <LocationOnIcon {...props} />;
export const ClubIcon = props => <SecurityIcon {...props} />;
export const LevelIcon = props => <ClearAllIcon {...props} />;
export const CompetitionTypeIcon = props => <TrackChangesIcon {...props} />;
export const CompetitionIcon = props => <EventIcon {...props} />;
export const ResultIcon = props => <FlagIcon {...props} />;
export const DashboardIcon = props => <DBIcon {...props} />;
export const GenericSettingsIcon = props => <SettingsIcon {...props} />;
export const GenericListIcon = props => <ListIcon {...props} />;
export const GenericUsersIcon = props => <GroupIcon {...props} />;
export const GenericHelpIcon = props => <HelpIcon {...props} />;
export const SignUpIcon = props => <PersonAddIcon {...props} />;
export const ProfileIcon = props => <AccountBoxIcon {...props} />;
export const GenericDownloadIcon = props => <GetAppIcon {...props} />;
export const SpecialEventIcon = props => <CardTravelIcon {...props} />;
