// React imports
import React from "react";

// React Admin imports
import { translate } from "react-admin";

// Redux imports
import { connect } from "react-redux";

// Material imports
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// Icon imports
import BlockIcon from "@material-ui/icons/Cancel";
import UnblockIcon from "@material-ui/icons/CheckCircle";

// Other imports
import PropTypes from "prop-types";

const styles = {
  blockBtn: {
    justifyContent: "center"
  },
  blockColor: {
    color: "#E8AC61"
  },
  unBlockColor: {
    color: "#73B163"
  }
};

const BlockButton = ({
  blockHandler,
  isBlocked,
  translate,
  itemId,
  ...rest
}) => (
  <Tooltip
    title={
      isBlocked
        ? translate("resources.users.blockButton.unblock")
        : translate("resources.users.blockButton.block")
    }
  >
    <IconButton
      onClick={() => blockHandler(itemId)}
      style={styles.blockBtn}
      {...rest}
    >
      {isBlocked ? (
        <BlockIcon style={styles.blockColor} />
      ) : (
        <UnblockIcon style={styles.unBlockColor} />
      )}
    </IconButton>
  </Tooltip>
);

BlockButton.propTypes = {
  blockHandler: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
  isBlocked: PropTypes.bool.isRequired
};
export default connect()(translate(BlockButton));
