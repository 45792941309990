import React from "react";
import { translate, Toolbar, SaveButton, DeleteButton } from "react-admin";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import PopupDeleteButton from "./PopupDeleteButton";

const CustomToolbar = props => {
  return (
    <Toolbar {...props} style={{ display: "flex", justifyContent: "flex-end" }}>
      <SaveButton redirect="list" />
      <Button
        style={{ marginLeft: "auto" }}
        variant="contained"
        onClick={() => props.history.push(props.basePath)}
      >
        {props.translate("ra.action.cancel")}
      </Button>
      {props.match &&
        props.match.params &&
        props.match.params.id &&
        !props.disabledDelete && (
          <PopupDeleteButton style={{ marginLeft: "15px" }} />
        )}
    </Toolbar>
  );
};

export default translate(withRouter(CustomToolbar));
