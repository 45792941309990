import React from "react";
import {
  Create,
  List,
  Edit,
  Datagrid,
  TextField,
  Show,
  SimpleShowLayout,
  TextInput,
  ReferenceInput,
  SelectInput,
  Filter,
  TabbedForm,
  FormTab,
  Responsive,
  SimpleList,
  translate,
  FunctionField,
  ListButton,
  CardActions,
  EditButton,
  DeleteButton,
  Pagination
} from "react-admin";
import CrossIcon from "@material-ui/icons/LocalTaxi";
import CheckIcon from "@material-ui/icons/LocalTaxi";
import PermissionGroupInput from "../../components/inputs/PermissionGroupInput";
import compose from "recompose/compose";
import Icon from "@material-ui/icons/LocalTaxi";

import Grid from "@material-ui/core/Grid";
import PageTitle from "../../components/PageTitle";
import CustomToolbar from "../../components/CustomToolbar";
import {
  defaultRowsPerPageOption,
  rowsPerPageOptions
} from "../../utils/paging";
import PopupBulkDeleteButton from "../../components/PopupBulkDeleteButton";

export const UserRoleIcon = Icon;

const cardActionStyle = {
  zIndex: 2,
  display: "inline-block",
  float: "right"
};

const required = (message = "FIELD_IS_REQUIRED") => value =>
  value ? undefined : message;

const minLength3 = (message = "FIELD_MINLENGTH_3") => value =>
  value && value.length >= 3 ? undefined : message;

const Actions = ({ permissions, ...props }) => {
  return (
    <CardActions style={cardActionStyle}>
      {permissions &&
      (permissions.indexOf("IS_CLIENT_ADMIN") >= 0 ||
        permissions.indexOf("IS_ADMIN") >= 0) ? (
        <EditButton record={props.data} basePath={props.basePath} />
      ) : null}
      <ListButton {...props} />
      {permissions &&
      (permissions.indexOf("IS_CLIENT_ADMIN") >= 0 ||
        permissions.indexOf("IS_ADMIN") >= 0) ? (
        <DeleteButton
          record={props.data}
          basePath={props.basePath}
          resource={props.resource}
        />
      ) : null}
    </CardActions>
  );
};

const UserRoleFilter = props => (
  <Filter {...props}>
    <ReferenceInput
      source="clientPk"
      reference="clients"
      alwaysOn
      perPage={100}
      sort={{ field: "name", order: "ASC" }}
    >
      <SelectInput optionText="name" optionValue="id" />
    </ReferenceInput>
    <TextInput elStyle={{ width: 380 }} source="name" alwaysOn />
  </Filter>
);

export const UserRoleList = compose(translate)(
  ({ permissions, translate, ...props }) => (
    <Grid>
      <Grid item xs={12}>
        <PageTitle title={translate("resources.userRoles.listPage.headline")} />
      </Grid>
      <List
        title="resources.userRoles.list"
        filters={<UserRoleFilter />}
        sort={{ field: "id", order: "DESC" }}
        exporter={false}
        bulkActionButtons={<PopupBulkDeleteButton />}
        pagination={
          <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
        }
        perPage={defaultRowsPerPageOption}
        {...props}
      >
        <Responsive
          small={
            <SimpleList
              primaryText={record => record.name}
              leftIcon={record =>
                record.active ? <CheckIcon /> : <CrossIcon />
              }
            />
          }
          medium={
            <Datagrid>
              <TextField source="client.name" />
              <FunctionField
                label="resources.userRoles.fields.name"
                render={record => {
                  return translate(record.name);
                }}
              />
              {permissions &&
              (permissions.indexOf("IS_CLIENT_ADMIN") >= 0 ||
                permissions.indexOf("IS_ADMIN")) >= 0 ? (
                <EditButton {...props} />
              ) : null}
            </Datagrid>
          }
        />
      </List>
    </Grid>
  )
);

const PermissionListRendererUl = translate(({ translate, record }) => {
  return <li>{translate(record)}</li>;
});

const UserRoleShowTitle = ({ record }) => (
  <span>
    {translate("resources.userRole.show", {
      name: record.name
    })}
  </span>
);

export const UserRoleShow = compose(translate)(({ permissions, ...props }) => {
  return (
    <Grid>
      <PageTitle
        title={props.translate("resources.userRoles.showPage.headline")}
      />
      <Show
        title={<UserRoleShowTitle />}
        actions={<Actions permissions={permissions} {...props} />}
        {...props}
      >
        <SimpleShowLayout>
          <FunctionField
            label="resources.userRoles.name"
            render={record => {
              return props.translate(record.name);
            }}
          />

          <FunctionField
            label="resources.userRoles.fields.permissions"
            render={record => {
              return (
                <ul>
                  {record.permissions &&
                    record.permissions.map((permission, pi) => {
                      return (
                        <PermissionListRendererUl
                          key={pi}
                          record={permission}
                        />
                      );
                    })}
                </ul>
              );
            }}
          />
        </SimpleShowLayout>
      </Show>
    </Grid>
  );
});

export const UserRoleCreate = compose(translate)(
  ({ permissions, ...props }) => (
    <Grid>
      <PageTitle
        title={props.translate("resources.userRoles.creatPage.headline")}
      />
      <Create title="resources.userRole.create" {...props}>
        <TabbedForm redirect="list" toolbar={<CustomToolbar {...props} />}>
          <FormTab label="resources.tabs.userRoleData">
            <TextInput source="name" validate={[required(), minLength3()]} />

            {permissions && permissions.indexOf("IS_ADMIN") !== -1 && (
              <ReferenceInput
                source="client.id"
                reference="clients"
                validate={[]}
                allowEmpty
                perPage={100}
                sort={{ field: "name", order: "ASC" }}
              >
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            )}
          </FormTab>
          <FormTab label="resources.tabs.userRolePermissions">
            <PermissionGroupInput
              source="permissions"
              reference="userPermissions"
            />
          </FormTab>
        </TabbedForm>
      </Create>
    </Grid>
  )
);

const UserRoleEditTitle = ({ record }) => (
  <span>
    {translate("resources.userRole.edit", {
      name: "" + record.name
    })}
  </span>
);

export const UserRoleEdit = compose(translate)(({ permissions, ...props }) => {
  return (
    <Grid>
      <PageTitle
        title={props.translate("resources.userRoles.editPage.headline")}
      />
      <Edit title={<UserRoleEditTitle />} {...props}>
        <TabbedForm redirect="list" toolbar={<CustomToolbar />}>
          <FormTab label="resources.userRoles.tabs.userRoleData">
            <TextInput source="name" validate={[required(), minLength3()]} />

            {permissions && permissions.indexOf("IS_ADMIN") !== -1 && (
              <ReferenceInput
                source="client.id"
                perPage={100}
                sort={{ field: "name", order: "ASC" }}
                reference="clients"
                validate={[]}
                allowEmpty
              >
                <SelectInput optionText="name" optionValue="id" />
              </ReferenceInput>
            )}
          </FormTab>
          <FormTab label="resources.userRoles.tabs.userRolePermissions">
            <PermissionGroupInput
              source="permissions"
              reference="userPermissions"
            />
          </FormTab>
        </TabbedForm>
      </Edit>
    </Grid>
  );
});
