import React from "react";
import Grid from "@material-ui/core/Grid";
import {
  BooleanField,
  BooleanInput,
  Create,
  Datagrid,
  DisabledInput,
  Edit,
  List,
  NumberInput,
  Pagination,
  required,
  SimpleForm,
  TextField,
  TextInput,
  translate
} from "react-admin";
import PageTitle from "../../components/PageTitle";
import compose from "recompose/compose";
import CustomToolbar from "../../components/CustomToolbar";
import {
  defaultRowsPerPageOption,
  rowsPerPageOptions
} from "../../utils/paging";
import PopupBulkDeleteButton from "../../components/PopupBulkDeleteButton";

export const UploadTypeList = compose(translate)(({ translate, ...props }) => {
  return (
    <Grid>
      <PageTitle title={translate("resources.uploadList.title")} />
      <List
        {...props}
        sort={{ field: "sortingOrder", order: "ASC" }}
        bulkActionButtons={<PopupBulkDeleteButton />}
        pagination={
          <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
        }
        perPage={defaultRowsPerPageOption}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="sortingOrder" />
          <BooleanField source="showName" />
        </Datagrid>
      </List>
    </Grid>
  );
});

export const UploadTypeCreate = compose(translate)(props => (
  <Grid>
    <PageTitle title={translate("resources.uploadList.title")} />
    <Create {...props}>
      <SimpleForm redirect="list" toolbar={<CustomToolbar {...props} />}>
        <TextInput source="name" validate={required()} />
        <NumberInput source="sortingOrder" />
        <BooleanInput source="showName" />
      </SimpleForm>
    </Create>
  </Grid>
));

export const UploadTypeEdit = compose(translate)(props => {
  return (
    <Grid>
      <PageTitle title={translate("resources.uploadList.title")} />
      <Edit {...props}>
        <SimpleForm redirect="list" toolbar={<CustomToolbar {...props} />}>
          <DisabledInput label="Id" source="id" />
          <TextInput source="name" validate={required()} />
          <NumberInput source="sortingOrder" />
          <BooleanInput source="showName" />
        </SimpleForm>
      </Edit>
    </Grid>
  );
});
