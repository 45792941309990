// React import
import React, { Component } from "react";
import { Document, Page, pdfjs } from "react-pdf";

// Material imports
import Grid from "@material-ui/core/Grid";

// Other imports
import { fetchFile } from "./FileUploadHelper";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class PdfPreview extends Component {
  state = {
    pdf: null
  };

  componentDidUpdate = prevProps => {
    if (prevProps.pdfid !== this.props.pdfid) {
      fetchFile(this.props.pdfid, data => {
        this.setState({ pdf: data });
      });
    }
  };

  componentDidMount = () => {
    fetchFile(this.props.pdfid, data => {
      this.setState({ pdf: data });
    });
  };

  render() {
    return (
      <Grid container spacing={3}>
        <Grid item xs={3}>
          <Document file={this.state.pdf} onLoadError={console.error}>
            <Page pageNumber={1} />
          </Document>
        </Grid>
      </Grid>
    );
  }
}

export default PdfPreview;
