// React imports
import React, { Component } from "react";
import { connect } from "react-redux";

// React Admin imports
import { Notification, translate } from "react-admin";

// Material Ui imports
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import withWidth, { isWidthUp } from "@material-ui/core/withWidth";

// Other imports
import AppBar from "../partials/AppBar";
import Sidebar from "../partials/Sidebar";
import Menu from "../partials/Menu";
import Footer from "../partials/Footer";
import { Typography } from "@material-ui/core";
import HeaderImage from "../../components/HeaderImage";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import GetWidth from "./GetWidth";
import { HEADER_IMAGE, HEADER_IMAGE_MOBILE } from "../../utils/imageUtils";

/* const CustomSidebar = props => <Sidebar size={240} {...props} />;
const CustomLayout = props => (
    <Layout appBar={AppBar} sidebar={CustomSidebar} {...props} />
); */

const styles = () => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    //backgroundColor: theme.palette.background.default,
    backgroundColor: "#eeeeee",
    position: "relative"
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
    overflowX: "auto"
  },
  contentWithSidebar: {
    display: "flex",
    flexGrow: 1
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 2,
    padding: "67px 20px 20px",
    marginTop: "0"
  },
  headerImgWrapper: {
    height: "270px"
  }
});

class CustomLayout extends Component {
  state = { width: "md" };

  render() {
    const {
      children,
      classes,
      dashboard,
      logout,
      open,
      title,
      translate
    } = this.props;

    return (
      <div className={classes.root}>
        <GetWidth callback={width => this.setState({ width })} />
        <AppBar title={title} open={open} logout={logout} />
        <div className={classes.headerImgWrapper}>
          <HeaderImage
            desktopImage={HEADER_IMAGE}
            mobileImage={HEADER_IMAGE_MOBILE}
            content={
              <div>
                {isWidthUp("md", this.state.width) && (
                  <div>
                    <Typography
                      variant="display2"
                      style={{
                        color: "#444444",
                        marginBottom: "30px",
                        textShadow:
                          "-1px -1px 1px rgba(0,0,0,0.1), 1px 1px 1px rgba(255,255,255,0.5)"
                      }}
                    >
                      {translate("pos.headerImage.headline")}
                    </Typography>
                    <Button
                      variant="outlined"
                      size="large"
                      href="#/competitionList"
                      style={{ color: "#444444", marginRight: "30px" }}
                      onClick={() => {
                        window.scrollTo({
                          left: 0,
                          top: this.myRef.offsetTop,
                          behavior: "smooth"
                        });
                      }}
                    >
                      {translate("pos.headerImage.btnCompetitions")}
                    </Button>
                    <Button
                      variant="outlined"
                      size="large"
                      href="#/results"
                      onClick={() => {
                        window.scrollTo({
                          left: 0,
                          top: this.myRef.offsetTop,
                          behavior: "smooth"
                        });
                      }}
                      style={{ color: "#444444" }}
                    >
                      {translate("pos.headerImage.btnResults")}
                    </Button>
                  </div>
                )}
                {!isWidthUp("md", this.state.width) && (
                  <div>
                    <Typography
                      variant="display1"
                      style={{
                        color: "#444444",
                        marginBottom: "30px",
                        textShadow:
                          "-1px -1px 1px rgba(0,0,0,0.1), 1px 1px 1px rgba(255,255,255,0.5)"
                      }}
                    >
                      {translate("pos.headerImage.headlineMobile")}
                      <br />
                      {translate("pos.headerImage.compSign")}
                    </Typography>
                    <Button
                      variant="contained"
                      size="small"
                      href="#/competitionList"
                      style={{
                        color: "#444444",
                        marginRight: "20px",
                        marginBottom: "20px"
                      }}
                      onClick={() => {
                        window.scrollTo({
                          left: 0,
                          top: this.myRef.offsetTop,
                          behavior: "smooth"
                        });
                      }}
                    >
                      {translate("pos.headerImage.btnCompetitions")}
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      href="#/results"
                      onClick={() => {
                        window.scrollTo({
                          left: 0,
                          top: this.myRef.offsetTop,
                          behavior: "smooth"
                        });
                      }}
                      style={{ color: "#444444", marginBottom: "20px" }}
                    >
                      {translate("pos.headerImage.btnResults")}
                    </Button>
                  </div>
                )}
              </div>
            }
          ></HeaderImage>
        </div>
        <main className={classes.contentWithSidebar}>
          <Sidebar>
            <Menu logout={logout} hasDashboard={!!dashboard} />
          </Sidebar>
          <div
            id="contentTop"
            ref={ref => (this.myRef = ref)}
            style={{ minHeight: "650px" }}
            className={classes.content}
          >
            {children}
          </div>
        </main>

        <footer>
          <Footer />
        </footer>
        <Notification />
      </div>
    );
  }
}

export default compose(
  translate,
  connect(state => ({ isLoading: state.admin.loading > 0 })),
  withStyles(styles)
)(CustomLayout);
