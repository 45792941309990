export const TOGGLE_POPUP = "TOGGLE_POPUP";
export const SET_POPUP = "SET_POPUP";
export const CLOSE_MENU = "CLOSE_MENU";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const UPDATE_DATA = "UPDATE_DATA";

export const togglePopup = (popup, data) => ({
  type: TOGGLE_POPUP,
  id: popup,
  data: data
});

export const setPopup = (popup, state) => ({
  type: SET_POPUP,
  id: popup,
  data: state
});

export const toggleMenu = menuName => ({
  type: TOGGLE_MENU,
  id: menuName
});

export const closeMenu = menuName => ({
  type: CLOSE_MENU,
  id: menuName
});

export const updateData = data => ({
  type: UPDATE_DATA,
  data: data
});
