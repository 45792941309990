import React, { Component } from "react";
import EventCreate from "../../pages/event/EventCreate";
import { translate } from "react-admin";
import { connect } from "react-redux";

class EventCopyPage extends Component {
  state = {
    toCopy: null
  };

  componentDidMount = () => {
    let { id } = this.props.match.params;
    this.setState({
      toCopy: id
    });
  };

  render = () => (
    <EventCreate
      toCopy={this.state.toCopy}
      {...this.props}
      basePath="/events"
      resource="events"
    />
  );
}
const mapStateToProps = state => {};
export default connect(mapStateToProps)(translate(EventCopyPage));
