import restClient from "../../providers/dataProvider/rest";

export const fetchFile = (id, callback) => {
  return new Promise((resolve, reject) => {
    restClient("DOWNLOAD", "download", {
      id: id
    })
      .then(response => {
        try {
          const filename = response.headers
            .get("Content-Disposition")
            .split("filename=")[1];
          response.blob().then(blob => {
            if (typeof callback !== "undefined") {
              callback(blob);
              return;
            }
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement("a");
            a.href = url;
            a.download = filename;
            a.click();
          });
        } catch (e) {
          console.log(e);
        }
      })
      .catch(e => {
        reject(null);
      });
  });
};

export const getMatches = (obj, pathString) => {
  let regex = /^(.*)\.(.*)$/;
  let matches = pathString.match(regex);
  return matches;
};

export const getId = (obj, pathString) => {
  if (typeof obj == "undefined") {
    return null;
  }
  let matches = getMatches(obj, pathString);

  let id = null;

  if (matches.length >= 3) {
    id = obj[matches[1]][matches[2]];
  }
  return id;
};

export const getNewFileId = (obj, pathString) => {
  let matches = getMatches(obj, pathString);
  let document = null;
  if (matches.length >= 3) {
    document = obj[matches[1] + "Raw"];
    if (document && document.rawFile) {
      return document.rawFile.documentId;
    }
  }
  return null;
};

export const getNewFileType = (obj, pathString) => {
  let matches = getMatches(obj, pathString);
  let document = null;
  if (matches.length >= 3) {
    document = obj[matches[1] + "Raw"];
    if (document && document.rawFile) {
      return document.rawFile.type;
    }
  }
  return "";
};

export const getFileType = (obj, pathString) => {
  let matches = getMatches(obj, pathString);
  let document = null;
  if (matches.length >= 3) {
    document = obj[matches[1]];
    if (document && document.fileType) {
      return document.fileType;
    }
  }
  return "";
};
