import React from "react";
import { translate } from "react-admin";
import PageTitle from "../../components/PageTitle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";

const styles = {
  teaserButton: {
    color: "#7e886e"
  }
};

const userRegisterSuccessPage = props => (
  <div>
    <PageTitle title={props.translate("userRegisterSuccessPage.welcome")} />
    <Card>
      <CardContent style={{ maxWidth: "1200px" }}>
        <p>
          {props.translate(
            "userRegisterSuccessPage.userRegisterSuccessMessage"
          )}
        </p>
        <Button
          onClick={() => props.history.push("/competitionList")}
          style={styles.teaserButton}
        >
          {props.translate("userRegisterSuccessPage.competitions")}
        </Button>
      </CardContent>
    </Card>
  </div>
);

export default translate(userRegisterSuccessPage);
