export const SET_ORDER_FILTER_SETTINGS = "SET_ORDER_FILTER_SETTINGS";
export const SET_ORDERS = "SET_ORDERS";

export const setOrders = orders => ({
  type: SET_ORDERS,
  orders: orders
});

export const setOrderFilterSettings = filterSettings => ({
  type: SET_ORDER_FILTER_SETTINGS,
  filterSettings: filterSettings
});
