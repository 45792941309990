import React from "react";
import {
  required,
  translate,
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  Create,
  SimpleForm,
  Edit,
  DisabledInput,
  BooleanInput,
  Toolbar,
  SaveButton,
  Pagination
} from "react-admin";
import compose from "recompose/compose";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import PageTitle from "../../components/PageTitle";
import S3FileUploadInput from "../../components/inputs/S3FileUploadInput";
import {
  defaultRowsPerPageOption,
  rowsPerPageOptions
} from "../../utils/paging";
import PopupBulkDeleteButton from "../../components/PopupBulkDeleteButton";
import CustomToolbar from "../../components/CustomToolbar";

const CompetitionFilter = props => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
  </Filter>
);

export const CompetitionTypeList = compose(translate)(
  ({ translate, ...props }) => {
    return (
      <Grid>
        <PageTitle
          title={translate("resources.competitionTypes.listPage.headline")}
        />
        <List
          filters={<CompetitionFilter />}
          exporter={false}
          pagination={
            <Pagination rowsPerPageOptions={rowsPerPageOptions} {...props} />
          }
          perPage={defaultRowsPerPageOption}
          bulkActionButtons={<PopupBulkDeleteButton />}
          {...props}
        >
          <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
          </Datagrid>
        </List>
      </Grid>
    );
  }
);

export const CompetitionTypeCreate = compose(translate)(
  ({ translate, history, ...props }) => {
    return (
      <Grid>
        <PageTitle
          title={translate("resources.competitionTypes.editPage.headline")}
        />
        <Create {...props}>
          <SimpleForm redirect="list" toolbar={<CustomToolbar {...props} />}>
            <DisabledInput label="Id" source="id" />
            <TextInput source="name" validate={required()} />
            <TextInput
              source="signupReservationTimeout"
              validate={required()}
              type="number"
            />
            <S3FileUploadInput source="logoRaw" previewSource="logo" />
            <BooleanInput
              label="Default image"
              source="isDefault"
              options={{ defaultToggled: false }}
            />
          </SimpleForm>
        </Create>
      </Grid>
    );
  }
);

export const CompetitionTypeEdit = compose(translate)(
  ({ translate, history, ...props }) => {
    return (
      <Grid>
        <PageTitle
          title={translate("resources.competitionTypes.editPage.headline")}
        />
        <Edit {...props}>
          <SimpleForm
            redirect="list"
            toolbar={<CustomToolbar disabledDelete {...props} />}
          >
            <DisabledInput label="Id" source="id" />
            <TextInput source="name" validate={required()} />
            <TextInput
              source="signupReservationTimeout"
              validate={required()}
              type="number"
            />
            <S3FileUploadInput source="logoRaw" previewSource="logo" />
            <BooleanInput
              label="Default image"
              source="isDefault"
              options={{ defaultToggled: false }}
            />
          </SimpleForm>
        </Edit>
      </Grid>
    );
  }
);
