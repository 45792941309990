import { API_PATH, SUB_FOLDER } from "./config";

//competition type images
const DEFAULT_COMPETITION_TYPE = "";
const COMPETITION_TYPE_RESOLUTION = "490x327";

//club logo
const DEFAULT_CLUB_LOGO = "defaultClubLogo.png";
const CLUB_SHOW_RESOLUTION = "250x250";

//ads
const AD_RESOLUTION = "300x300";
const AD_SHOW_RESOLUTION = "800x800";

//static images
export const EVENT_IMAGE =
  API_PATH +
  "/" +
  COMPETITION_TYPE_RESOLUTION +
  "/" +
  SUB_FOLDER +
  "/eventDefaultImage.jpg";

const TEASER_RESOLUTION = "490x327";

export const TEASER_IMAGE_1 =
  API_PATH + "/" + TEASER_RESOLUTION + "/" + SUB_FOLDER + "/teaserimg1.jpg";
export const TEASER_IMAGE_2 =
  API_PATH + "/" + TEASER_RESOLUTION + "/" + SUB_FOLDER + "/teaserimg2.jpg";
export const TEASER_IMAGE_3 =
  API_PATH + "/" + TEASER_RESOLUTION + "/" + SUB_FOLDER + "/teaserimg3.jpg";

const HEADER_RESOLUTION = "1500x438";
const HEADER_RESOLUTION_MOBILE = "600x300";

export const HEADER_IMAGE =
  API_PATH + "/" + HEADER_RESOLUTION + "/" + SUB_FOLDER + "/headerimg1.jpg";

export const HEADER_IMAGE_MOBILE =
  API_PATH +
  "/" +
  HEADER_RESOLUTION_MOBILE +
  "/" +
  SUB_FOLDER +
  "/headerimg1.jpg";

export const getClubShowImageSrc = logo =>
  API_PATH + "/" + (logo && logo.path ? logo.path : DEFAULT_CLUB_LOGO);

export const getAdsImageSrc = ad =>
  API_PATH +
  "/" +
  AD_RESOLUTION +
  "/" +
  (ad && ad.image && ad.image.path ? ad.image.path : DEFAULT_CLUB_LOGO);

export const getAdImageSrc = image =>
  API_PATH + "/" + (image && image.path ? image.path : DEFAULT_CLUB_LOGO);

export const getCompetitionListImageSrc = image =>
  API_PATH +
  "/" +
  COMPETITION_TYPE_RESOLUTION +
  "/" +
  (image && image.path ? image.path : DEFAULT_COMPETITION_TYPE);
