import React, { Component } from "react";
import PropTypes from "prop-types";

export default class OutsideListener extends Component {
  constructor(props) {
    super(props);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener("mouseup", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    try {
      if (
        this.props.active &&
        this.wrapperRef &&
        !this.wrapperRef.contains(event.target) &&
        React.createElement(event.target).type.tagName != "A"
      ) {
        this.props.onOutsideClick();
      }
    } catch (e) {
      /* do nothing */
    }
  }

  render() {
    return <div ref={this.setWrapperRef}>{this.props.children}</div>;
  }
}

OutsideListener.propTypes = {
  children: PropTypes.element.isRequired,
  active: PropTypes.bool.isRequired,
  onOutsideClick: PropTypes.func.isRequired
};
