import {
  MenuItem,
  Typography,
  CircularProgress,
  ListItemIcon
} from "@material-ui/core";
import React, { Component } from "react";
import restClient from "../providers/dataProvider/rest";

class ButtonMenuDownload extends Component {
  state = {
    disabled: false
  };

  download = id => {
    this.setState({ disabled: true });
    restClient("DOWNLOAD", "download", {
      id: id
    }).then(response => {
      try {
        const filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        response.blob().then(blob => {
          if (typeof callback !== "undefined") {
            this.setState({ disabled: false });

            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
          this.setState({ disabled: false });
        });
      } catch (e) {
        this.setState({ disabled: true });
      }
    });
  };

  render() {
    const { menuItem } = this.props;

    return (
      <MenuItem
        onClick={() => this.download(menuItem.documentId)}
        disabled={this.state.disabled}
      >
        {this.state.disabled && (
          <CircularProgress size={24} style={{ marginRight: "15px" }} />
        )}
        {!this.state.disabled &&
          typeof menuItem.icon !== "undefined" &&
          menuItem.icon !== null && (
            <ListItemIcon>{menuItem.icon}</ListItemIcon>
          )}

        <Typography variant="inherit" noWrap>
          {menuItem.text}
        </Typography>
      </MenuItem>
    );
  }
}

export default ButtonMenuDownload;
