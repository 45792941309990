import React, { Component } from "react";
import { translate } from "react-admin";
import AdShowPublic from "../../components/pages/AdShowPublic";
import { getAdFromPublicID } from "../../utils/restUtils";
import { setError } from "../../reducer/errorActions";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
class ClubShowPublicPage extends Component {
  state = {
    ad: null
  };

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  decorateData = club => {};

  componentDidMount = () => {
    let { id } = this.props.match.params;

    getAdFromPublicID(id).then(
      success => {
        this.setState({ ad: success });
      },
      e => {
        this.setState({ ad: null });
        this.props.dispatch(setError(e));
      }
    );
  };
  render = () => {
    return (
      <AdShowPublic
        ad={this.state.ad}
        translate={this.props.translate}
        errorMessage={this.props.httpErrorMsg}
        hideError={this.hideErrorMessage}
        history={this.props.history}
      />
    );
  };
}

function mapStateToProps(state) {
  return {
    myForm: state.form.userRegisterForm,
    httpErrorMsg: state.error.httpError.message
  };
}
export default connect(mapStateToProps)(translate(ClubShowPublicPage));
