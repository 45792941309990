// React imports
import React, { Component } from "react";

// React Admin imports

// Material Ui imports
import withWidth from "@material-ui/core/withWidth";

// Other imports
import compose from "recompose/compose";

class GetWidth extends Component {
  componentDidMount = () => {
    this.props.callback(this.props.width);
  };
  componentDidUpdate = prevProps => {
    if (!prevProps || prevProps.width !== this.props.width) {
      this.props.callback(this.props.width);
    }
  };
  render() {
    const { width } = this.props;

    return "";
  }
}

export default compose(withWidth())(GetWidth);
