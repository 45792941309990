import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./theme/styles/index.css";
import moment from "moment";
import "material-design-icons-iconfont/dist/material-design-icons.css";

moment.fn.toJSON = function() {
  return this.local().format("YYYY-MM-DD[T]HH:mm:ss");
};

ReactDOM.render(<App />, document.getElementById("root"));
