import React from "react";
import { translate } from "react-admin";
import PageTitle from "../../components/PageTitle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

const dataProtectionPage = translate => (
  <div>
    <PageTitle title={"Datenschutzerklärung & Haftungsausschluss"} />
    <Card>
      <CardContent style={{ maxWidth: "1200px" }}>
        <p>
          Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen.
          Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der
          gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen
          Datenschutzinformationen informieren wir Sie über die wichtigsten
          Aspekte der Datenverarbeitung im Rahmen unserer Website.
        </p>

        <p>
          <h2>Datenspeicherung</h2>
        </p>

        <p>
          Um die Premiumfunktionen nutzen zu können, ist eine Registrierung des
          Teilnehmers (Schützen) erforderlich.
        </p>

        <p>
          Gespeichert werden die für unterschiedliche Bewerbstypen benötigen
          Daten des Schützen wie
          <br />
          <br />
          Profildaten: Benutzername, Mailadresse, Sprache
          <br />
          Kontaktdaten: Anrede, TitelAnmeldungen zu Bewerben oder sonstigen
          Veranstaltungen, Vorname, Nachname, Adresse, PLZ, Ort, Bundesland,
          Land, Telefonummer
          <br />
          Schützendaten: Klasse, Division, Faktor, Verein, Alias, Cowboy Name,
          SASS Nr, IPSC-Nr, IPSC-Region, IPSC-Alias
        </p>

        <p>
          Bei den Anmeldungen zu Bewerben oder sonstigen Veranstaltungen, werden
          die für den Zeitraum des Bewerbs oder Veranstaltung benötigen Daten
          gespeichert.
          <br />
          Die Publizierung von Ergebnislisten, welche Personenbezogene Daten
          enthalten, erfolgt vom veranstaltenden Verein und nicht von comp|sign.
        </p>

        <p>
          <h2>Kontakt mit uns</h2>
        </p>

        <p>
          Wenn Sie per E-Mail Kontakt ( datenschutz@compsign.net ) mit uns
          Kontakt aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung
          der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns
          gespeichert.
        </p>

        <p>
          <h2>Cookies</h2>
        </p>

        <p>
          Unsere Website verwendet sogenannte Cookies. Dabei handelt es sich um
          kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät
          abgelegt werden. Sie richten keinen Schaden an.
          <br />
          Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten.
          Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese
          löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch
          wiederzuerkennen.
        </p>

        <p>
          Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so
          einrichten, dass er Sie über das Setzen von Cookies informiert und Sie
          dies nur im Einzelfall erlauben.
          <br />
          Bei der Deaktivierung von Cookies kann die Funktionalität unserer
          Website eingeschränkt sein.
        </p>

        <p>
          <h2>Web-Analyse</h2>
        </p>

        <p>
          Diese Website benutzt Google Analytics, einen Webanalysedienst der
          Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“,
          Textdateien, die auf Ihrem Computer gespeichert werden und die eine
          Analyse der Benutzung der Website durch Sie ermöglicht. Die durch den
          Cookie erzeugten Informationen über Ihre Benutzung diese Website
          (einschließlich Ihrer IP-Adresse) wird an einen Server von Google in
          den USA übertragen und dort gespeichert. Google wird diese
          Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um
          Reports über die Websiteaktivitäten für die Websitebetreiber
          zusammenzustellen und um weitere mit der Websitenutzung und der
          Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird
          Google diese Informationen gegebenenfalls an Dritte übertragen, sofern
          dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im
          Auftrag von Google verarbeiten. Google wird in keinem Fall Ihre
          IP-Adresse mit anderen Daten der Google in Verbindung bringen. Sie
          können die Installation der Cookies durch eine entsprechende
          Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch
          darauf hin, daß Sie in diesem Fall gegebenenfalls nicht sämtliche
          Funktionen dieser Website voll umfänglich nutzen können. Durch die
          Nutzung dieser Website erklären Sie sich mit der Bearbeitung der über
          Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und
          Weise und zu dem zuvor benannten Zweck einverstanden. (Quelle: AGB von
          Google Analytics)
        </p>

        <p>
          comp|sign weist ausdrücklich darauf hin, dass die Datenübertragung im
          Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken
          aufweisen und nicht lückenlos vor dem Zugriff durch Dritte geschützt
          werden kann. Grundsätzlich kann die Internetseite von comp|sign im
          Allgemeinen ohne Angaben zur Person und ohne Übermittlung persönlicher
          Daten abgerufen werden.
        </p>

        <p>
          <b>Ihre Rechte lt Art 15 DSGVO</b>
        </p>

        <p>
          Laut Art. 15 DSGVO stehen Ihnen grundsätzlich die Rechte auf Auskunft,
          Berichtigung, Löschung, Einschränkung, Daten-Übertragbarkeit, Widerruf
          und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer
          Daten gegen das Datenschutzrecht verstößt oder Ihre
          datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden
          sind, können Sie sich bei der Aufsichtsbehörde beschweren. In
          Österreich ist dies die Datenschutzbehörde.
        </p>

        <p>Datenschutzrechtlich verantwortlich ist</p>

        <p>
          Thomas Tuschl
          <br />
          Zwerbach 35a
          <br />
          3244 Ruprechtshofen
          <br />
          Tel +43 664/3968212
          <br />
          Mail office@compsign.net
        </p>

        <h1>Haftungsausschluss</h1>

        <h2>1. Inhalt des Portal comp|sign</h2>

        <p>
          Der Portalbetreiber übernimmt keinerlei Gewähr für die Aktualität,
          Korrektheit, Vollständigkeit oder Qualität der bereitgestellten
          Informationen. Haftungsansprüche gegen den Portalbetreiber, welche
          sich auf alle Art von Schäden beziehen, die durch die Nutzung der
          angebotenen Informationen bzw. durch die Nutzung fehlerhafter und
          unvollständiger Informationen verursacht wurden, sind grundsätzlich
          ausgeschlossen.
          <br />
          Alle Angebote sind freibleibend und unverbindlich. Der Portalbetreiber
          behält es sich ausdrücklich vor, das Portal comp|sign zu verändern, zu
          ergänzen, zu löschen oder die Veröffentlichung zeitweise oder
          endgültig einzustellen.
        </p>

        <p>
          <h2>2. Verweise &amp; Links</h2>
        </p>

        <p>
          Der Portalbetreiber übernimmt keinerlei Haftung für die im Portal
          comp|sign angeführten direkten oder indirekten Verweisen auf fremde
          Webseiten ("Hyperlinks") oder von Vereinen bereitgestellte Foto- oder
          Videoaufnahmen.
        </p>

        <p>
          <h2>3. Urheberrechte</h2>
        </p>

        <p>
          Für all im Portal comp|sign dargestellten Bilder wurden Lizenzen über
          www.shutterstock.com erworben
        </p>

        <h2>4. Datenschutz</h2>

        <p>
          Sofern innerhalb des Internetangebotes die Möglichkeit zur Eingabe
          persönlicher oder geschäftlicher Daten (Emailadressen, Namen,
          Anschriften) besteht, so erfolgt die Preisgabe dieser Daten seitens
          des Nutzers auf ausdrücklich freiwilliger Basis. Die Inanspruchnahme
          und Bezahlung aller angebotenen Dienste ist - soweit technisch möglich
          und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe
          anonymisierter Daten oder eines Pseudonyms gestattet. Die Nutzung der
          im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten
          Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie
          Emailadressen durch Dritte zur Übersendung von nicht ausdrücklich
          angeforderten Informationen ist nicht gestattet. Rechtliche Schritte
          gegen die Versender von sogenannten Spam-Mails bei Verstößen gegen
          dieses Verbot sind ausdrücklich vorbehalten.
        </p>

        <p>
          <h2>5. Rechtswirksamkeit dieses Haftungsausschlusses</h2>
        </p>

        <p>
          Dieser Haftungsausschluss ist als Teil des Internetangebotes zu
          betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile
          oder einzelne Formulierungen dieses Textes der geltenden Rechtslage
          nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben
          die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit
          davon unberührt.
        </p>

        <p>&nbsp;</p>
      </CardContent>
    </Card>
  </div>
);

export default translate(dataProtectionPage);
