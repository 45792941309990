import React from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const MultipleSelect = props => {
  return (
    <FormControl style={{ minWidth: 240, maxWidth: 400 }}>
      <InputLabel id={props.name + "-label"}>{props.label}</InputLabel>
      <Select
        labelId={props.name + "-label"}
        id={props.name}
        name={props.name}
        multiple
        value={props.value}
        onChange={props.handleChange}
        input={<Input />}
        MenuProps={MenuProps}
      >
        {props.options.map(option => (
          <MenuItem key={option.label} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultipleSelect;
