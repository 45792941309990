import { SET_SUCCESS } from "./successActions";

const INITIAL_STATE = {
  successMessage: {
    message: null
  }
};

const reducer = (state = INITIAL_STATE, data) => {
  switch (data.type) {
    case SET_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      let success = data.success ? data.success : null;
      return {
        successMessage: {
          message: success ? success.message : null
        }
      };
    default:
      return state;
  }
};

export default reducer;
