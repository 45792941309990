import React, { Component, Fragment } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { translate } from "react-admin";
import PageTitle from "../../components/PageTitle";
import ExportSelect from "../../components/inputs/ExportSelect";
import { Grid } from "@material-ui/core";

class ExportPage extends Component {
  state = { selectLongMenu: false };
  selectLongMenu = long => {
    this.setState({ selectLongMenu: long });
  };
  render = () => (
    <Fragment>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <PageTitle title={this.props.translate("resources.exports.title")} />
          <Card>
            <CardContent>
              <Grid container spacing={0}>
                <Grid
                  style={{ minHeight: this.state.selectLongMenu ? 800 : 400 }}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <ExportSelect
                    selectLongMenu={this.selectLongMenu}
                    {...this.props}
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default translate(ExportPage);
