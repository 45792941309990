// React imports
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// Other imports
import compose from "recompose/compose";

// React Admin imports
import { translate, WithPermissions } from "react-admin";

// Material imports
import { withStyles } from "@material-ui/core/styles";

// Icon imports
import {
  ClubIcon,
  GenericSettingsIcon,
  CompetitionIcon,
  DashboardIcon,
  GenericListIcon,
  GenericUsersIcon,
  ResultIcon,
  GenericHelpIcon,
  SignUpIcon,
  ProfileIcon,
  GenericDownloadIcon
} from "../../components/IconLibrary";

// Partial imports
import MenuElem from "./MenuElem";
import { MenuItem } from "@material-ui/core";

const styles = {
  root: {
    color: "#000"
  },
  active: {
    color: "#4F6982"
  }
};

const items = [
  {
    name: null,
    icon: null,
    type: "menutitle",
    label: "mainTitle",
    permissions: ["*"]
  },
  {
    name: "dashboard",
    label: "dashboard",
    icon: <DashboardIcon />,
    permissions: ["*"]
  },
  {
    name: "competitionList",
    label: "upcomingCompetitions",
    icon: <CompetitionIcon />,
    permissions: ["*"]
  },
  {
    name: "results",
    label: "competitionArchive",
    icon: <ResultIcon />,
    permissions: ["*"]
  },
  {
    name: "clubList",
    label: "clubs",
    icon: <ClubIcon />,
    permissions: ["*"]
  },
  {
    name: null,
    icon: null,
    type: "menutitle",
    label: "registerTitle",
    permissions: ["IS_GUEST"]
  },
  {
    name: "userRegister",
    label: "register",
    icon: <SignUpIcon />,
    permissions: ["IS_GUEST"]
  },
  {
    name: null,
    icon: null,
    type: "menutitle",
    label: "myData",
    permissions: ["IS_USER_SHOOTER"]
  },
  {
    name: "myCompetitions",
    label: "myCompetitions",
    icon: <CompetitionIcon />,
    permissions: ["IS_USER_SHOOTER"]
  },
  {
    name: "profile",
    label: "profile",
    icon: <ProfileIcon />,
    permissions: ["IS_USER_SHOOTER"]
  },
  {
    name: null,
    icon: null,
    type: "menutitle",
    label: "clubDataTitle",
    permissions: ["IS_CLUB_ADMIN"]
  },
  {
    name: "clubProfile",
    label: "clubProfile",
    icon: <ClubIcon />,
    permissions: ["IS_CLUB_ADMIN"]
  },
  {
    name: "competitions",
    label: "clubCompetitions",
    icon: <CompetitionIcon />,
    permissions: ["IS_CLUB_ADMIN"]
  },
  {
    name: "events",
    label: "clubEvents",
    icon: <CompetitionIcon />,
    permissions: ["IS_CLUB_ADMIN"]
  },
  {
    name: "orderItemList",
    label: "clubItemOrders",
    icon: <GenericUsersIcon />,
    permissions: ["IS_CLUB_ADMIN"]
  },
  {
    name: null,
    icon: null,
    type: "menutitle",
    label: "managementTitle",
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "clubs",
    label: "settings.clubs",
    icon: <ClubIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "competitions",
    label: "settings.competitions",
    icon: <CompetitionIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "events",
    label: "settings.events",
    icon: <CompetitionIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "uploadTypes",
    label: "settings.uploadTypes",
    icon: <GenericListIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "orderList",
    label: "settings.orders",
    icon: <GenericListIcon />,
    permissions: ["IS_ADMIN"]
  },
  {
    name: "orderItemList",
    label: "settings.orderItems",
    icon: <GenericListIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "reservationCodesList",
    label: "settings.reservationCodes",
    icon: <GenericListIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN", "IS_CLUB_ADMIN"]
  },
  {
    name: "reservations",
    label: "reservations",
    icon: <GenericListIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "users",
    label: "settings.users",
    icon: <GenericUsersIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "userRoles",
    label: "settings.userRoles",
    icon: <GenericUsersIcon />,
    permissions: ["IS_ADMIN"]
  },
  {
    name: "exports",
    label: "exports",
    icon: <GenericDownloadIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN", "IS_CLUB_ADMIN"]
  },
  {
    name: "factors",
    label: "settings.factors",
    icon: <GenericSettingsIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "competitionClasses",
    label: "settings.competitionClasses",
    icon: <GenericSettingsIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "divisions",
    label: "settings.divisions",
    icon: <GenericSettingsIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "levels",
    label: "settings.levels",
    icon: <GenericSettingsIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "competitionTypes",
    label: "settings.competitionTypes",
    icon: <GenericSettingsIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: "advertisings",
    label: "pages.advertisings",
    icon: <GenericHelpIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  },
  {
    name: null,
    icon: null,
    label: null,
    type: "spacer",
    permissions: ["*"]
  },
  {
    name: null,
    icon: null,
    label: null,
    type: "divider",
    permissions: ["*"]
  },
  {
    name: "https://compsign.at",
    label: "pages.information",
    icon: <GenericHelpIcon />,
    permissions: ["*"]
  },
  {
    name: "https://compsign.at/ueber-compsign/",
    label: "pages.about",
    icon: <GenericHelpIcon />,
    permissions: ["*"]
  },
  {
    name: "https://compsign.at/was-compsign-kostet/",
    label: "pages.costs",
    icon: <GenericHelpIcon />,
    permissions: ["*"]
  },
  {
    name: "https://compsign.at/meine-vorteile-als-registrierter-schuetze/",
    label: "pages.whyRegister",
    icon: <GenericHelpIcon />,
    permissions: ["*"]
  },
  {
    name: "changelog",
    label: "pages.changelog",
    icon: <GenericListIcon />,
    permissions: ["IS_ADMIN", "IS_PORTAL_ADMIN"]
  }
];

const Menu = ({ onMenuClick, translate, logout, classes, userId }) => {
  return (
    <WithPermissions
      location={userId}
      render={({ permissions }) => (
        <div>
          <MenuElem
            items={items}
            onMenuClick={onMenuClick}
            translate={translate}
          />
        </div>
      )}
    />
  );
};

const enhance = compose(
  withRouter,
  connect(
    state => ({
      theme: state.theme,
      locale: state.i18n.locale,
      userId: state.identity.userData.id
    }),
    {}
  ),
  translate,
  withStyles(styles)
);

export default enhance(Menu);
