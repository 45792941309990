import React, { Component } from "react";
import { FileInput, REDUX_FORM_NAME, translate } from "react-admin";
import { upload } from "../../utils/restUtils";
import S3ImagePreview from "./S3ImagePreview";
import { change, touch, untouch } from "redux-form";
import { connect } from "react-redux";
import { setError } from "../../reducer/errorActions";
import ErrorMessage from "../ErrorMessage";
import ImageFieldInput from "./ImageFieldInput";

class FileUploadInput extends Component {
  state = {
    loading: false
  };
  uploadFileSuccess = (data, rawFile) => {
    const { dispatch } = this.props;
    const match = /\.(\w+)$/.exec(data.key);
    const type = match ? `image/${match[1]}` : "image";
    setTimeout(() => {
      dispatch(setError(null));
      dispatch(change(REDUX_FORM_NAME, "tmp", new Date().getTime()));
      dispatch(
        change(REDUX_FORM_NAME, this.props.source, {
          fileName: data.key,
          path: data.key,
          fileType: type
        })
      );
      dispatch(
        change(REDUX_FORM_NAME, this.props.previewSource, {
          fileName: data.key,
          path: data.key,
          fileType: type
        })
      );
    }, 10);
    this.setState({ loading: false });
  };

  uploadFileError = (e, rawFile) => {
    rawFile.documentId = null;
    rawFile.errorMsg = e.message;

    const { dispatch } = this.props;
    dispatch(setError({ message: e.message }));
    setTimeout(() => {
      this.hideErrorMessage();
    }, 2000);
    dispatch(change(REDUX_FORM_NAME, this.props.source, null));
    dispatch(change(REDUX_FORM_NAME, this.props.previewSource, null));

    setTimeout(() => {
      dispatch(touch(REDUX_FORM_NAME, "documents[0].name"));
      dispatch(untouch(REDUX_FORM_NAME, "documents[0].name"));
    }, 10);
  };

  uploadFile(file) {
    this.setState({ loading: true });
    file = file[0];
    let formData = new FormData();
    const prefix = (Math.random() + 1).toString(36).substring(2);
    const filename = prefix + "/" + file.name.split(".")[0];

    file.prefix = prefix;

    formData.append("file", file);
    formData.append("name", prefix + "/" + filename);

    upload(formData)
      .then(data => this.uploadFileSuccess(data, file))
      .catch(e => {
        this.setState({ loading: false });
      });
  }

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  render() {
    const prev = this.props.source;
    return (
      <span>
        <FileInput
          source={this.props.source}
          label={this.props.label}
          multiple={false}
          options={{ onDropAccepted: file => this.uploadFile(file) }}
        >
          <ImageFieldInput title="fileName" source="name" />
        </FileInput>
        <S3ImagePreview
          source={prev}
          previewSource={this.props.previewSource}
          loading={this.state.loading}
          validate={[]}
        ></S3ImagePreview>
        <ErrorMessage
          errorMessage={this.props.errorMessage}
          open={this.props.errorMessage ? true : false}
          onClose={this.hideError}
          translate={this.props.translate}
        />
      </span>
    );
  }
}

function mapStateToProps(state) {
  return {
    errorMessage: state.error.httpError.message
  };
}

export default connect(mapStateToProps)(translate(FileUploadInput)); //eslint-disable-line react-redux/prefer-separate-component-file
