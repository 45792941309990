// React imports
import React, { PureComponent, Component, Children, cloneElement } from "react";
import { connect } from "react-redux";

// Other imports
import { toggleMenu } from "../../reducer/flagActions";
import compose from "recompose/compose";
import PropTypes from "prop-types";
import clsx from "clsx";

// Material Ui imports
import Drawer from "@material-ui/core/Drawer";
import { withStyles, createStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";

// React Admin imports
import { Responsive } from "react-admin";

// Icon imports
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import MenuIcon from "@material-ui/icons/Menu";

import OutsideListener from "../../components/OutsideListener";

export const DRAWER_WIDTH = 300;
export const CLOSED_DRAWER_WIDTH = 0;

export const DRAWER_WIDTH_RESPONSIVE = "100%";

const styles = theme =>
  createStyles({
    drawerPaper: {
      position: "relative",
      height: "100%",
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      marginTop: 0,
      [theme.breakpoints.only("xs")]: {
        marginTop: 0,
        height: "100vh",
        position: "inherit",
        backgroundColor: theme.palette.background.default
      },
      [theme.breakpoints.up("md")]: {
        marginTop: 0
      }
    },
    drawerOpen: {
      width: DRAWER_WIDTH,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        backgroundColor: "#ffffff",
        borderRight: "1px solid #eeeeee"
      })
    },
    drawerOpenResponsive: {
      width: DRAWER_WIDTH_RESPONSIVE,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        backgroundColor: "#ffffff",
        borderRight: "1px solid #eeeeee"
      })
    },
    drawerClose: {
      width: CLOSED_DRAWER_WIDTH,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: "hidden",
      backgroundColor: "#eeeeee"
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      ...theme.mixins.toolbar
    },
    toolbarOpen: {
      backgroundColor: "#fff",
      padding: "0 8px"
    },
    toolbarClosed: {
      backgroundColor: "#eeeeee",
      padding: "0 0 0 20px"
    }
  });

// We shouldn't need PureComponent here as it's connected
// but for some reason it keeps rendering even though mapStateToProps returns the same object
class Sidebar extends Component {
  // eslint-disable-next-line react/no-deprecated
  toggleMenu = () => {
    this.props.dispatch(toggleMenu("sidebar"));
  };

  render() {
    const {
      children,
      classes,
      closedSize,
      open,
      size,
      width,
      ...rest
    } = this.props;

    return (
      <Responsive
        xsmall={
          <Drawer
            onClose={this.toggleMenu}
            open={open}
            PaperProps={{
              className: clsx(classes.drawerPaper, {
                [classes.drawerOpenResponsive]: open,
                [classes.drawerClose]: !open
              })
            }}
            {...rest}
            className={clsx(classes.drawer, {
              [classes.drawerOpenResponsive]: open,
              [classes.drawerClose]: !open
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpenResponsive]: open,
                [classes.drawerClose]: !open
              })
            }}
          >
            <div
              className={clsx(classes.toolbar, {
                [classes.toolbarOpen]: open,
                [classes.toolbarClosed]: !open
              })}
            >
              <IconButton onClick={this.toggleMenu}>
                {open ? (
                  <ChevronLeftIcon style={{ color: "#4F6982" }} />
                ) : (
                  <MenuIcon style={{ color: "#4F6982" }} />
                )}
              </IconButton>
            </div>
            {open
              ? cloneElement(Children.only(children), { dense: true })
              : null}
          </Drawer>
        }
        small={
          <Drawer
            onClose={this.toggleMenu}
            open={open}
            PaperProps={{
              className: clsx(classes.drawerPaper, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            }}
            {...rest}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            }}
          >
            <div
              className={clsx(classes.toolbar, {
                [classes.toolbarOpen]: open,
                [classes.toolbarClosed]: !open
              })}
            >
              <IconButton onClick={this.toggleMenu}>
                {open ? (
                  <ChevronLeftIcon style={{ color: "#4F6982" }} />
                ) : (
                  <MenuIcon style={{ color: "#4F6982" }} />
                )}
              </IconButton>
            </div>
            {open
              ? cloneElement(Children.only(children), { dense: true })
              : null}
          </Drawer>
        }
        medium={
          <Drawer
            onClose={this.toggleMenu}
            open={open}
            PaperProps={{
              className: clsx(classes.drawerPaper, {
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            }}
            {...rest}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            }}
          >
            <div
              className={clsx(classes.toolbar, {
                [classes.toolbarOpen]: open,
                [classes.toolbarClosed]: !open
              })}
            >
              <IconButton onClick={this.toggleMenu}>
                {open ? (
                  <ChevronLeftIcon style={{ color: "#4F6982" }} />
                ) : (
                  <MenuIcon style={{ color: "#4F6982" }} />
                )}
              </IconButton>
            </div>
            {open
              ? cloneElement(Children.only(children), { dense: true })
              : null}
          </Drawer>
        }
      />
    );
  }
}

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  closedSize: PropTypes.number,
  open: PropTypes.bool.isRequired,
  size: PropTypes.number,
  width: PropTypes.string
};

Sidebar.defaultProps = {
  size: DRAWER_WIDTH,
  closedSize: CLOSED_DRAWER_WIDTH
};

const mapStateToProps = state => ({
  open: state.flags.menuOpen.sidebar,
  locale: state.locale // force redraw on locale change
});

export default compose(
  connect(mapStateToProps),
  withStyles(styles),
  withWidth({ resizeInterval: Infinity }) // used to initialize the visibility on first render
)(Sidebar);
