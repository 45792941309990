import React, { Component } from "react";

import { getUserRoles } from "../../utils/restUtils";
import { UserCreate } from "./create";

class UserCreatePage extends Component {
  state = {
    clubAdminRoleId: null,
    userRoleId: null
  };
  componentDidMount() {
    getUserRoles().then(options => {
      options.map(item => {
        if (item.name === "ROLE_CLUB_ADMIN") {
          this.setState({ clubAdminRoleId: item.id });
        }
        if (item.name === "ROLE_USER") {
          this.setState({ userRoleId: item.id });
        }
      });
    });
  }

  render = () => (
    <UserCreate
      clubAdminRoleId={this.state.clubAdminRoleId}
      userRoleId={this.state.userRoleId}
      {...this.props}
    />
  );
}

export default UserCreatePage;
