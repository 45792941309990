// React imports
import React from "react";

// Material imports
import { Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import PropTypes from "prop-types";
// Icon imports
import WarningIcon from "@material-ui/icons/Warning";

let NoPaymentInfo = props => {
  const styles = {
    error: {
      backgroundColor: "orange",
      color: "#ffffff"
    },
    icon: {
      fontSize: 20,
      marginRight: 10,
      color: "#ffffff"
    },
    message: {
      display: "flex",
      alignItems: "center"
    },
    errorText: {
      color: "#ffffff"
    }
  };
  return (
    <Snackbar
      open={props.open}
      onClose={props.onClose}
      autoHideDuration={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      <SnackbarContent
        style={styles.error}
        aria-describedby="no-payment-snackbar"
        message={
          <span id="no-payment-snackbar" style={styles.message}>
            <WarningIcon style={styles.icon} />
            <Typography style={styles.errorText}>{props.message}</Typography>
          </span>
        }
      />
    </Snackbar>
  );
};

NoPaymentInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired
};

export default NoPaymentInfo;
