import React from "react";
import Chip from "@material-ui/core/Chip";

const colors = {
  compsignblue: "#135182",
  turquoise: "#64A8A1",
  green: "#3C6E39",
  yellow: "#DC9F29",
  red: "#E5322C",
  blue: "#009EE3",
  purple: "#A3185B",
  poisonGreen: "#95C21E",
  pink: "#E81473"
};

export const SmallChip = ({ label, backgroundColor, translate }) => (
  <Chip
    style={{
      color: "white",
      fontSize: "10px",
      height: "25px",
      lineHeight: "20px",
      marginRight: "5px",
      backgroundColor: backgroundColor
    }}
    label={translate ? translate(label) : label}
  />
);

export const UserFlagsChips = ({ active, enabled, translate }) => {
  return (
    <div>
      {!active && (
        <SmallChip
          label="IS_NOT_ACTIVE"
          backgroundColor={colors.blue}
          translate={translate}
        />
      )}
      {!enabled && (
        <SmallChip
          label="IS_NOT_ENABLED"
          backgroundColor={colors.red}
          translate={translate}
        />
      )}
    </div>
  );
};

export const CompetitionFlagChips = ({ cancelled, translate }) => {
  return (
    <div>
      {cancelled && (
        <SmallChip
          label="IS_CANCELLED"
          backgroundColor={colors.red}
          translate={translate}
        />
      )}
    </div>
  );
};

export const ClubFlagsChip = ({ active, translate }) => {
  return (
    <div>
      {!active && (
        <SmallChip
          label="IS_NOT_ACTIVE"
          backgroundColor={colors.blue}
          translate={translate}
        />
      )}
    </div>
  );
};
