import { Typography, Card, Button, Grid } from "@material-ui/core";
import React from "react";
import { translate, Loading, Toolbar, changeLocale } from "react-admin";
import { connect } from "react-redux";
import {
  getPublicOrderItemForEdit,
  updatePublicOrderItem,
  getCompetitionDevisions,
  getCompetitionFactors,
  getCompetitionClasses,
  getCompetitionSquads,
  cancelPublicOrderItem
} from "../../utils/restUtils";
import PublicOrderItemEdit from "./PublicOrderItemEdit";
import { setOrderItem } from "../../reducer/orderItemAction";
import { setSuccess } from "../../reducer/successActions";
import { setError } from "../../reducer/errorActions";
import SuccessMessage from "../../components/SuccessMessage";
import ErrorMessage from "../../components/ErrorMessage";
import { togglePopup } from "../../reducer/flagActions";
import GenericPopup from "../../components/GenericPopup";
import { changeLocale as changeLocaleAction } from "react-admin";
import { bindActionCreators } from "redux";

class PublicOrderItemEditPage extends React.Component {
  state = { orderItem: null, error: false, cancelled: null };
  componentDidMount = () => {
    let orderItem;
    if (this.props && this.props.match && this.props.match.params)
      getPublicOrderItemForEdit(
        this.props.match.params.id,
        this.props.match.params.token
      ).then(
        async success => {
          orderItem = {
            ...success.data,
            choices: {
              divisions: [],
              competitionClasses: [],
              factors: [],
              squads: []
            }
          };

          if (orderItem.locale && this.props.locale !== orderItem.locale) {
            this.props.changeLocale(orderItem.locale);
          }

          await Promise.all([
            getCompetitionDevisions({
              competitionId: orderItem.competition.id
            }).then(sucess => {
              orderItem.choices.divisions = sucess;
            }),

            getCompetitionClasses({
              competitionId: orderItem.competition.id
            }).then(sucess => {
              orderItem.choices.competitionClasses = sucess;
            }),

            getCompetitionFactors({
              competitionId: orderItem.competition.id
            }).then(sucess => {
              orderItem.choices.factors = sucess;
            }),

            getCompetitionSquads({
              competitionId: orderItem.competition.id,
              orderItemId: orderItem.id,
              excludeFullSquads: true
            }).then(sucess => {
              orderItem.choices.squads = sucess;
            })
          ]);
          this.props.dispatch(setOrderItem(orderItem));
        },
        e => {
          this.props.dispatch(setError(e));
          this.setState({ error: true });
        }
      );
  };

  handleSubmit = values => {
    updatePublicOrderItem(
      this.props.match.params.id,
      this.props.match.params.token,
      values
    ).then(
      success => {
        this.props.dispatch(
          setSuccess({
            message: "SUCCESS_ORDER_ITEM_EDIT"
          })
        );
        this.props.history.push("/");
      },
      e => this.props.dispatch(setError(e))
    );
  };

  handleCancel = () => {
    cancelPublicOrderItem(
      this.props.match.params.id,
      this.props.match.params.token
    ).then(
      success => {
        this.setState({ cancelled: true });
        this.handleCancelClose();
        this.props.dispatch(
          setSuccess({
            message: "SUCCESS_ORDER_ITEM_CANCEL"
          })
        );
        this.props.history.push("/dashboard");
      },
      e => this.props.dispatch(setError(e))
    );
  };

  hideSuccessMessage = () => {
    this.props.dispatch(setSuccess(null));
  };
  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  handleCancelClose = () =>
    this.props.dispatch(togglePopup("cancelOrderItemPopup"));

  render = () => (
    <Card style={{ minHeight: "500px", overflow: "visible" }}>
      <GenericPopup
        open={this.props.cancelOrderItemPopup}
        handleConfirm={this.handleCancel}
        handleClose={this.handleCancelClose}
        message={this.props.translate(
          "resources.orderList.shooterCancelOrderItemPopup.warning"
        )}
        title={this.props.translate(
          "resources.orderList.shooterCancelOrderItemPopup.title"
        )}
        confirmText={this.props.translate(
          "resources.orderList.shooterCancelOrderItemPopup.confirm"
        )}
        cancelText={this.props.translate(
          "resources.orderList.shooterCancelOrderItemPopup.cancel"
        )}
      />
      {this.props.orderItem !== null ? (
        [
          <PublicOrderItemEdit
            translate={this.props.translate}
            record={this.props.orderItem}
            cancelled={this.state.cancelled}
            disabled={
              this.props.orderItem.cancelled ||
              this.state.cancelled ||
              this.props.orderItem.archive ||
              this.props.orderItem.registrationEnded
            }
          />,
          <Toolbar style={{ justifyContent: "space-between" }}>
            {!this.props.orderItem.cancelled &&
            !this.state.cancelled &&
            !this.props.orderItem.archive &&
            !this.props.orderItem.registrationEnded ? (
              <Button
                onClick={() => this.handleSubmit(this.props.myForm)}
                variant="contained"
                color="primary"
              >
                Speichern
              </Button>
            ) : null}

            {!this.props.orderItem.cancelled &&
            !this.state.cancelled &&
            !this.props.orderItem.archive &&
            !this.props.orderItem.registrationEnded ? (
              <Button
                onClick={() =>
                  this.props.dispatch(togglePopup("cancelOrderItemPopup"))
                }
                variant="contained"
                style={{ color: "#4F6982", margin: "10px" }}
              >
                Abmelden
              </Button>
            ) : null}
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.props.history.push("/")}
            >
              Abbrechen
            </Button>
          </Toolbar>
        ]
      ) : this.state.error ? null : (
        <Grid
          style={{ height: "500px" }}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item>
            <Loading />
          </Grid>
        </Grid>
      )}
      {this.state.error ? (
        <Grid
          style={{ height: "500px" }}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Grid item>
            <Typography variant="title" color="textSecondary">
              {this.props.translate("resources.orderEdit.errorMessage")}
            </Typography>
          </Grid>
        </Grid>
      ) : null}

      <SuccessMessage
        successMessage={this.props.successMessage}
        open={this.props.successMessage ? true : false}
        onClose={this.hideSuccessMessage}
        translate={this.props.translate}
      />
      <ErrorMessage
        errorMessage={this.props.httpErrorMsg}
        open={this.props.httpErrorMsg ? true : false}
        onClose={this.hideErrorMessage}
        translate={this.props.translate}
      />
    </Card>
  );
}
const mapStateToProps = state => {
  return {
    httpErrorMsg: state.error.httpError.message,
    successMessage: state.success.successMessage.message,
    orderItem:
      state.orderItem && state.orderItem.data
        ? state.orderItem.data.orderItem
        : null,
    myForm:
      state.form && state.form["orderItemEdit"]
        ? state.form["orderItemEdit"].values
        : null,
    cancelOrderItemPopup: state.flags.popupOpen.cancelOrderItemPopup
  };
};
function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators({ changeLocale: changeLocaleAction }, dispatch)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(translate(PublicOrderItemEditPage));
