import { put, takeEvery, takeLatest } from "redux-saga/effects";
import {
  AUTH_ERROR,
  CRUD_UPDATE_SUCCESS,
  FETCH_ERROR,
  REGISTER_RESOURCE,
  USER_CHECK,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT
} from "react-admin";
import { SET_CART } from "../reducer/cartActions";
import { SET_IDENTITY, UPDATE_USERDATA } from "../reducer/userActions";
import { SET_ERROR } from "../reducer/errorActions";

function* checkAuth(action) {
  if (typeof action.payload !== "undefined") {
    yield put({ type: SET_IDENTITY, payload: action.payload });
  }
}

function* clearAuth(action) {
  if (action.meta.auth !== true) {
    yield put({
      type: SET_IDENTITY,
      payload: {
        userData: {
          id: null,
          clubId: null,
          factorId: null,
          divisionId: null,
          clientId: null,
          clientName: null,
          username: null,
          userRole: null,
          userEmail: null,
          userFullName: null,
          userFirstName: null,
          userLastName: null,
          competitionViewType: null,
          shooterCompetitionViewType: null,
          paymentUntil: null,
          paymentValid: null,
          permissions: null
        }
      }
    });
  }
}

function* checkAuthFromStorage(action) {
  yield put({
    type: SET_IDENTITY,
    payload: {
      userData: {
        id: localStorage.getItem("userId"),
        clubId: localStorage.getItem("clubId"),
        competitionClassId: localStorage.getItem("competitionClassId"),
        factorId: localStorage.getItem("factorId"),
        divisionId: localStorage.getItem("divisionId"),
        clientId: localStorage.getItem("clientId"),
        clientName: localStorage.getItem("clientName"),
        username: localStorage.getItem("username"),
        userRole: JSON.parse(localStorage.getItem("userRoles")),
        userEmail: localStorage.getItem("userEmail"),
        userFullName: localStorage.getItem("userFullName"),
        userFirstName: localStorage.getItem("userFirstName"),
        userLastName: localStorage.getItem("userLastName"),
        userCountry: localStorage.getItem("userCountry"),
        userState: localStorage.getItem("userState"),
        competitionViewType: JSON.parse(
          localStorage.getItem("competitionViewType")
        ),
        shooterCompetitionViewType: JSON.parse(
          localStorage.getItem("shooterCompetitionViewType")
        ),
        paymentUntil: localStorage.getItem("paymentUntil"),
        paymentValid: localStorage.getItem("paymentValid"),
        permissions: JSON.parse(localStorage.getItem("permissions"))
      }
    }
  });
}

function* checkCartFromStorage(action) {
  var amount = 0;
  var cartList = 0;

  try {
    amount =
      localStorage.getItem("cartAmount") == null
        ? 0
        : localStorage.getItem("cartAmount");

    cartList =
      localStorage.getItem("cartList") == null
        ? JSON.parse([])
        : JSON.parse(localStorage.getItem("cartList"));
  } catch (e) {
    /* nothing */
  }

  yield put({
    type: SET_CART,
    cart: {
      amount: amount,
      list: cartList
    }
  });
}

function* updateAuth(action) {
  if (action.meta.resource === "profile") {
    let user =
      action.payload && action.payload.data ? action.payload.data : null;
    if (user == null) {
      return;
    }
    localStorage.setItem("userEmail", user.email);
    localStorage.setItem("userFirstName", user.firstName);
    localStorage.setItem("userLastName", user.lastName);
    localStorage.setItem("userFullName", user.firstName + " " + user.lastName);
    localStorage.setItem("competitionViewType", user.competitionViewType);
    localStorage.setItem(
      "shooterCompetitionViewType",
      user.shooterCompetitionViewType
    );
    yield put({
      type: UPDATE_USERDATA,
      payload: {
        userFullName: user.firstName + " " + user.lastName,
        userFirstName: user.firstName,
        userLastName: user.lastName,
        userEmail: user.email,
        competitionViewType: user.competitionViewType,
        shooterCompetitionViewType: user.shooterCompetitionViewType
      }
    });
  }
}

function* restoreResources() {
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "users",
      options: {},
      hasList: true,
      hasEdit: true,
      hasShow: true,
      hasCreate: true
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "profile",
      options: {},
      hasList: false,
      hasEdit: true,
      hasShow: false,
      hasCreate: false
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "clients",
      options: {},
      hasList: true,
      hasEdit: true,
      hasShow: false,
      hasCreate: true
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "userRoles",
      options: {},
      hasList: true,
      hasEdit: true,
      hasShow: true,
      hasCreate: true
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "clubs",
      options: {},
      hasList: true,
      hasEdit: true,
      hasShow: true,
      hasCreate: true
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "competitionspub",
      options: {},
      hasList: true,
      hasEdit: false,
      hasShow: true,
      hasCreate: false
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "divisions",
      options: {},
      hasList: false,
      hasEdit: false,
      hasShow: false,
      hasCreate: false
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "competitions",
      options: {},
      hasList: false,
      hasEdit: false,
      hasShow: false,
      hasCreate: true
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "events",
      options: {},
      hasList: false,
      hasEdit: false,
      hasShow: false,
      hasCreate: true
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "factors",
      options: {},
      hasList: false,
      hasEdit: false,
      hasShow: false,
      hasCreate: false
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "competitionClasses",
      options: {},
      hasList: false,
      hasEdit: false,
      hasShow: false,
      hasCreate: false
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "competitionTypes",
      options: {},
      hasList: false,
      hasEdit: false,
      hasShow: false,
      hasCreate: false
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "squads",
      options: {},
      hasList: false,
      hasEdit: false,
      hasShow: false,
      hasCreate: false
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "cart",
      options: {},
      hasList: true,
      hasEdit: true,
      hasShow: true,
      hasCreate: true
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "levels",
      options: {},
      hasList: true,
      hasEdit: true,
      hasShow: true,
      hasCreate: true
    }
  });
  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "reservations",
      options: {},
      hasList: true,
      hasEdit: true,
      hasShow: true,
      hasCreate: true
    }
  });

  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "clubProfile",
      options: {},
      hasEdit: true,
      hasShow: true
    }
  });

  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "orderItems",
      options: {},
      hasEdit: true,
      hasShow: false,
      hasList: false,
      hasCreate: false
    }
  });

  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "shooterOrderItems",
      options: {},
      hasEdit: true,
      hasShow: false,
      hasList: false,
      hasCreate: false
    }
  });

  yield put({
    type: REGISTER_RESOURCE,
    payload: {
      name: "advertisings",
      options: {},
      hasEdit: true,
      hasShow: false,
      hasList: false,
      hasCreate: false
    }
  });
}

export default function* authSaga() {
  yield takeEvery(USER_LOGIN_SUCCESS, checkAuth);
  yield takeEvery(USER_CHECK, checkAuthFromStorage);
  yield takeEvery(SET_ERROR, checkAuthFromStorage);
  yield takeEvery(FETCH_ERROR, checkAuthFromStorage);
  yield takeEvery(AUTH_ERROR, clearAuth);
  yield takeEvery(SET_IDENTITY, checkCartFromStorage);
  yield takeEvery(CRUD_UPDATE_SUCCESS, updateAuth);
  yield takeLatest(REGISTER_RESOURCE, checkAuthFromStorage);
  yield takeEvery(USER_LOGOUT, restoreResources);
}
