// Material imports
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DownloadIcon from "@material-ui/icons/GetApp";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import download from "downloadjs";
import moment from "moment";
import React, { Component } from "react";
import { translate } from "react-admin";
import { connect } from "react-redux";
import { setPopup, togglePopup } from "../../reducer/flagActions";
import { setError } from "../../reducer/errorActions";
import restClient from "../../providers/dataProvider/rest";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from "@material-ui/core";
import {
  cancelOrderItem,
  exportArchivedCompetitions as exportArchivedCompetitionsCall,
  exportCompetitorList as exportCompetitorListCall,
  getUserBookedCompetitions
} from "../../utils/restUtils";
// Other imports
import PropTypes from "prop-types";
import ButtonMenu from "../../components/ButtonMenu";
import CancelButton from "../../components/CancelButton";
import { CompetitionFlagChips } from "../../components/MiscChips";
import ShooterCompetitionList from "../../components/pages/ShooterCompetitionList";
import ErrorMessage from "../../components/ErrorMessage";
import GenericPopup from "../../components/GenericPopup";
import { SimpleCardWithoutHeader } from "../../components/Cards";
import SimpleList from "../../components/SimpleList";
import { setSuccess } from "../../reducer/successActions";

// Icon imports
import CompetitionInformationIcons from "../../components/CompetitionInformationIcons";
import CancelIcon from "@material-ui/icons/Cancel";
import SuccessMessage from "../../components/SuccessMessage";
import ShooterCompetitionViewSwitch from "../../components/ShooterCompetitionViewSwitch";
import {
  defaultRowsPerPageOption,
  rowsPerPageOptions
} from "../../utils/paging";
import { FILE_API_PATH } from "../../utils/config";

const styles = {
  simpleTitle: {
    marginBottom: "20px",
    display: "flow-root"
  },
  listSimpleTitle: {
    marginTop: "20px",
    marginBottom: "20px",
    whiteSpace: "nowrap"
  },
  listViewHeader: {
    fontWeight: "bold",
    fontSize: 15
  },
  downloadButton: {
    float: "right"
  }
};

class ShooterCompetitionListPage extends Component {
  state = {
    currentPageBooked: 0,
    currentPageParticipated: 0,
    rowsPerPageBooked: defaultRowsPerPageOption,
    rowsPerPageParticipated: defaultRowsPerPageOption,
    userBookedCompetitions: [],
    userParticipatedCompetitions: [],
    totalItemsCountBooked: 0,
    totalItemsCountParticipated: 0,
    clickedButton: null,
    competitionGroups: []
  };

  getDataBooked = (page, rowsPerPage, property, order) => {
    getUserBookedCompetitions(
      page,
      rowsPerPage,
      (property = "competition.competitionDate"),
      (order = "asc"),
      {
        archive: false,
        multipleWaitingList: true
      }
    ).then(
      data => {
        this.setState({
          userBookedCompetitions: this.decorateDataBooked(data),
          totalItemsCountBooked: data.total
        });
        this.groupAndDecorateData(data);
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  exportCompetitorList = item => {
    exportCompetitorListCall(item.competitionId)
      .then(response => {
        return response.blob();
      })
      .then(
        data => {
          let filename = "teilnehmerliste.xlsx";

          download(
            data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
  };

  exportArchivedCompetitions = () => {
    exportArchivedCompetitionsCall()
      .then(response => {
        return response.blob();
      })
      .then(
        data => {
          let filename = "absolvierte-bewerbe.xlsx";
          download(
            data,
            filename,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
        },
        e => {
          this.props.dispatch(setError(e));
        }
      );
  };

  getDataParticipated = (
    page,
    rowsPerPage,
    property = "competition.competitionDate",
    order = "desc"
  ) => {
    getUserBookedCompetitions(page, rowsPerPage, property, order, {
      archive: true,
      multipleWaitingList: false
    }).then(
      data => {
        this.setState({
          userParticipatedCompetitions: this.decorateDataParticipated(data),
          totalItemsCountParticipated: data.total
        });
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  handleCancel = () => {
    cancelOrderItem(this.state.clickedButton.id).then(
      data => {
        this.getDataBooked(
          this.state.currentPageBooked,
          this.state.rowsPerPageBooked
        );
        this.handleCancelClose();
        this.props.dispatch(setSuccess({ message: "SUCCESS_SHOOTER_CANCEL" }));
      },
      e => {
        this.props.dispatch(setError(e));
      }
    );
  };

  fetchFile = id => {
    restClient("DOWNLOAD", "download", {
      id: id
    }).then(response => {
      try {
        const filename = response.headers
          .get("Content-Disposition")
          .split("filename=")[1];
        response.blob().then(blob => {
          if (typeof callback !== "undefined") {
            return;
          }
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement("a");
          a.href = url;
          a.download = filename;
          a.click();
        });
      } catch (e) {
        console.log(e);
      }
    });
  };

  fetchFileFromBucket = filePath => {
    window.open(FILE_API_PATH + "/" + filePath);
  };

  groupAndDecorateData = data => {
    let groups = [];

    for (let i = 0; i < data.data.length; i++) {
      let orderItem = data.data[i];
      let date = moment(orderItem.competitionDate).format("YYYY-MM");
      let actions = [];
      actions.push({
        callBack: () =>
          this.props.history.push(
            "/competitionDetails/" + orderItem.publicLink
          ),
        text: "Details",
        icon: <SearchIcon />
      });
      if (orderItem.paid) {
        actions.push({
          callBack: () => this.exportCompetitorList(orderItem),
          text: "Teilnehmerliste",
          icon: <DownloadIcon />
        });
      }

      if (orderItem.competitionUploads.length > 0) {
        orderItem.competitionUploads.map((competitionUpload, key) =>
          actions.push({
            callBack: () =>
              this.fetchFileFromBucket(competitionUpload.document.path),
            text: competitionUpload.type.showName
              ? competitionUpload.document.fileName
              : competitionUpload.type.name,
            icon: <DownloadIcon />
          })
        );
      }

      if (!orderItem.cancelled) {
        actions.push({
          callBack: () => {
            this.props.history.push("shooterOrderItems/" + orderItem.id);
          },
          text: "Bearbeiten",
          icon: <EditIcon />
        });
        actions.push({
          callBack: () => {
            this.props.history.push(
              "shooterOrderItems/" + orderItem.id + "?mode=view"
            );
          },
          text: "Anmelde-Details",
          icon: <SearchIcon />
        });
        actions.push({
          callBack: () => {
            this.props.dispatch(togglePopup("cancelOrderItemPopup"));
            this.setState({
              clickedButton: { id: orderItem.id, active: orderItem.cancelled }
            });
          },
          text: "Abmelden",
          icon: <CancelIcon />
        });
      }

      if (orderItem.multipleWaitingList) {
        orderItem.squadName =
          orderItem.squadName +
          " (" +
          this.props.translate("shooterCompetitionList.waitingList") +
          ": " +
          orderItem.squadName +
          ")";
      }
      orderItem.menu = (
        <ButtonMenu buttonIcon={<MoreVertIcon />} options={actions} />
      );

      orderItem.startDateTime = moment(orderItem.squadTime).format("LT");
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(orderItem);
    }

    this.setState({ competitionGroups: groups });
  };

  handleCancelClose = () =>
    this.props.dispatch(togglePopup("cancelOrderItemPopup"));

  decorateDataBooked = data => {
    let items = [];
    for (let i = 0; i < data.data.length; i++) {
      let item = data.data[i];
      let reducedItem = {
        id: i,
        mainText: item.competitionName,
        secondaryText: <CompetitionInformationIcons record={item} />,
        link: ""
      };

      if (item.cancelled) {
        reducedItem.cancelled = "Abgemeldet";

        reducedItem = {
          id: i,
          mainText: item.competitionName + " (Abgemeldet)",
          secondaryText: <CompetitionInformationIcons record={item} />,
          link: item.publicLink
        };
      } else {
        reducedItem.cancelled = (
          <CancelButton
            itemId={item.id}
            isCancelled={item.cancelled}
            setLabel={"resources.competitions.cancelButton.cancel"}
            unsetLabel={"resources.competitions.cancelButton.unCancel"}
            cancelHandler={e => {
              this.props.dispatch(togglePopup("cancelOrderItemPopup"));
              this.setState({
                clickedButton: { id: item.id, active: item.cancelled }
              });
            }}
          />
        );
      }

      let actions = [];
      actions.push({
        callBack: () =>
          this.props.history.push("/competitionDetails/" + item.publicLink),
        text: "Details",
        icon: <SearchIcon />
      });
      if (item.paid) {
        actions.push({
          callBack: () => this.exportCompetitorList(item),
          text: "Teilnehmerliste",
          icon: <DownloadIcon />
        });
      }

      if (item.competitionUploads.length > 0) {
        item.competitionUploads.map((competitionUpload, key) =>
          actions.push({
            callBack: () =>
              this.fetchFileFromBucket(competitionUpload.document.path),
            text: competitionUpload.type.showName
              ? competitionUpload.document.fileName
              : competitionUpload.type.name,
            icon: <DownloadIcon />
          })
        );
      }

      if (!item.cancelled) {
        actions.push({
          callBack: () => {
            this.props.history.push("shooterOrderItems/" + item.id);
          },
          text: "Bearbeiten",
          icon: <EditIcon />
        });
        actions.push({
          callBack: () => {
            this.props.history.push(
              "shooterOrderItems/" + item.id + "?mode=view"
            );
          },
          text: "Anmelde-Details",
          icon: <SearchIcon />
        });
        if (!item.registrationEnded) {
          actions.push({
            callBack: () => {
              this.props.dispatch(togglePopup("cancelOrderItemPopup"));
              this.setState({
                clickedButton: { id: item.id, active: item.cancelled }
              });
            },
            text: "Abmelden",
            icon: <CancelIcon />
          });
        }
      }

      if (item.multipleWaitingList) {
        reducedItem.mainText =
          reducedItem.mainText +
          " (" +
          this.props.translate("shooterCompetitionList.waitingList") +
          ": " +
          item.squadName +
          ")";
      }

      reducedItem.menu = (
        <ButtonMenu buttonIcon={<MoreVertIcon />} options={actions} />
      );
      items.push(reducedItem);
    }
    return items;
  };

  decorateDataParticipated = data => {
    let items = [];
    for (let i = 0; i < data.data.length; i++) {
      let item = data.data[i];
      let reducedItem = {
        id: i,
        mainText: item.competitionName,
        secondaryText: <CompetitionInformationIcons record={item} />,
        link: item.publicLink
      };
      if (item.cancelled === true) {
        reducedItem.cancelled = (
          <CompetitionFlagChips
            cancelled={true}
            translate={this.props.translate}
          />
        );
        reducedItem.cancelled = "Abgemeldet";
        reducedItem = {
          id: i,
          mainText: item.competitionName + " (Abgemeldet)",
          secondaryText: <CompetitionInformationIcons record={item} />,
          link: item.publicLink
        };
      }
      let actions = [];
      actions.push({
        callBack: () => {
          this.props.dispatch(
            setPopup("popupCompetitionRegistrationForm", false)
          );
          this.props.history.push("/competitionDetails/" + item.publicLink);
        },
        text: "Details",
        icon: <SearchIcon />
      });
      actions.push({
        callBack: () => {
          this.props.history.push("shooterOrderItems/" + item.id);
        },
        text: "Anmelde-Details",
        icon: <SearchIcon />
      });
      if (item.paid) {
        actions.push({
          callBack: () => this.exportCompetitorList(item),
          text: "Teilnehmerliste",
          icon: <DownloadIcon />
        });
      }
      if (item.competitionUploads.length > 0) {
        item.competitionUploads.map((competitionUpload, key) =>
          actions.push({
            callBack: () =>
              this.fetchFileFromBucket(competitionUpload.document.path),
            text: competitionUpload.type.showName
              ? competitionUpload.document.fileName
              : competitionUpload.type.name,
            icon: <DownloadIcon />
          })
        );
      }

      reducedItem.menu = (
        <ButtonMenu buttonIcon={<MoreVertIcon />} options={actions} />
      );
      items.push(reducedItem);
    }
    return items;
  };

  mapSortingProperty = property => {
    const mapping = {
      competitionDate: "competition.competitionDate",
      name: "competition.name",
      clubName: "competition.club.name",
      levelName: "competition.level.name",
      competitionTypeName: "competition.competitionType.name",
      country: "competition.country"
    };

    return mapping[property] ? mapping[property] : property;
  };

  handlePageChangeBooked = (page, rowsPerPage) => {
    this.getDataBooked(page, rowsPerPage);
    this.setState({ currentPageBooked: page, rowsPerPageBooked: rowsPerPage });
  };

  handlePageChangeParticipated = (page, rowsPerPage) => {
    this.getDataParticipated(page, rowsPerPage);
    this.setState({
      currentPageParticipated: page,
      rowsPerPageParticipated: rowsPerPage
    });
  };

  handleSortBooked = (property, order) => {
    this.getDataBooked(
      this.state.currentPageBooked,
      this.state.rowsPerPageBooked,
      this.mapSortingProperty(property),
      order
    );
  };

  handleSortParticipated = (property, order) => {
    this.getDataParticipated(
      this.state.currentPageParticipated,
      this.state.rowsPerPageParticipated,
      this.mapSortingProperty(property),
      order
    );
  };

  componentDidMount = () => {
    this.getDataBooked(
      this.state.currentPageBooked,
      this.state.rowsPerPageBooked
    );
    this.getDataParticipated(
      this.state.currentPageParticipated,
      this.state.rowsPerPageParticipated
    );
  };

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  hideSuccessMessage = () => {
    this.props.dispatch(setSuccess(null));
  };

  render = () => (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <GenericPopup
          open={this.props.cancelOrderItemPopup}
          handleConfirm={this.handleCancel}
          handleClose={this.handleCancelClose}
          message={this.props.translate(
            "resources.orderList.shooterCancelOrderItemPopup.warning"
          )}
          title={this.props.translate(
            "resources.orderList.shooterCancelOrderItemPopup.title"
          )}
          confirmText={this.props.translate(
            "resources.orderList.shooterCancelOrderItemPopup.confirm"
          )}
          cancelText={this.props.translate(
            "resources.orderList.shooterCancelOrderItemPopup.cancel"
          )}
        />
        <Typography variant="display1" style={styles.simpleTitle}>
          {this.props.translate(
            "shooterCompetitionList.headerBookedCompetitions"
          )}
        </Typography>
        <ShooterCompetitionViewSwitch
          listType={
            this.props.shooterCompetitionViewType && this.props.paymentValid
          }
        />
        {this.props.shooterCompetitionViewType && this.props.paymentValid ? (
          <Grid>
            <div
              style={{
                width: "100%",
                overflowX: "scroll",
                margin: "8px",
                overflowY: "hidden"
              }}
            >
              <div
                style={{
                  display: "table",
                  tableLayout: "fixed",
                  width: "100%"
                }}
              >
                <Table className={"competitions"}>
                  <TableHead component={Paper}>
                    <TableRow>
                      <TableCell style={styles.listViewHeader} padding="dense">
                        {this.props.translate(
                          "resources.competitions.listPage.squadDate"
                        )}
                      </TableCell>
                      <TableCell style={styles.listViewHeader} padding="none">
                        {this.props.translate(
                          "resources.competitions.listPage.region"
                        )}
                      </TableCell>
                      <TableCell style={styles.listViewHeader} padding="dense">
                        {this.props.translate(
                          "resources.competitions.listPage.metchPlace"
                        )}
                      </TableCell>
                      <TableCell style={styles.listViewHeader} padding="none">
                        {this.props.translate(
                          "resources.competitions.listPage.club"
                        )}
                      </TableCell>
                      <TableCell style={styles.listViewHeader} padding="dense">
                        {this.props.translate(
                          "resources.competitions.listPage.matchName"
                        )}
                      </TableCell>
                      <TableCell style={styles.listViewHeader} padding="none">
                        {this.props.translate(
                          "resources.competitions.listPage.level"
                        )}
                      </TableCell>
                      <TableCell style={styles.listViewHeader} padding="dense">
                        {this.props.translate(
                          "resources.competitions.listPage.matchType"
                        )}
                      </TableCell>
                      <TableCell style={styles.listViewHeader} padding="dense">
                        {this.props.translate(
                          "resources.competitions.listPage.division"
                        )}
                      </TableCell>
                      <TableCell
                        style={styles.listViewHeader}
                        padding="none"
                      ></TableCell>
                    </TableRow>
                  </TableHead>
                  {Object.keys(this.state.competitionGroups).map(
                    (key, index) => [
                      <>
                        <Typography
                          variant="headline"
                          style={styles.listSimpleTitle}
                        >
                          {moment(key + "-01").format("MMMM YYYY")}
                        </Typography>
                        <TableBody component={Paper}>
                          {this.state.competitionGroups[key].map(
                            (record, oKey) => (
                              <TableRow key={record.id}>
                                <TableCell padding="dense">
                                  <Typography noWrap>
                                    {!record.event
                                      ? moment(record.squadDate)
                                          .format("DD.MM.YYYY")
                                          .toString() +
                                        " " +
                                        moment(record.squadTime)
                                          .format("HH:mm")
                                          .toString() +
                                        " | " +
                                        record.squadName
                                      : moment(record.competitionDate)
                                          .format("DD.MM.YYYY HH:mm")
                                          .toString() +
                                        " | " +
                                        this.props.translate(
                                          "resources.competitions.listPage.event"
                                        )}
                                  </Typography>
                                </TableCell>
                                <TableCell padding="none">
                                  <Typography noWrap>
                                    {record.country
                                      ? record.country.toUpperCase()
                                      : null}
                                  </Typography>
                                </TableCell>
                                <TableCell padding="dense">
                                  <Typography noWrap>{record.city}</Typography>
                                </TableCell>
                                <TableCell padding="none">
                                  <Typography noWrap>
                                    {record.clubShortName}{" "}
                                  </Typography>
                                </TableCell>
                                <TableCell padding="dense">
                                  <Typography noWrap>
                                    {record.competitionName}
                                  </Typography>
                                </TableCell>
                                <TableCell padding="none">
                                  <Typography noWrap>
                                    {record.levelName}{" "}
                                  </Typography>
                                </TableCell>
                                <TableCell padding="dense">
                                  <Typography noWrap>
                                    {record.event
                                      ? record.eventType
                                      : record.competitionTypeName}
                                  </Typography>
                                </TableCell>
                                <TableCell padding="dense">
                                  <Typography noWrap>
                                    {record.divisionName}
                                  </Typography>
                                </TableCell>
                                <TableCell padding="none">
                                  <Typography noWrap>{record.menu}</Typography>
                                </TableCell>
                              </TableRow>
                            )
                          )}
                        </TableBody>
                      </>
                    ]
                  )}
                </Table>
              </div>
            </div>
            <TablePagination
              component="div"
              count={this.state.totalItemsCountBooked}
              page={this.state.currentPageBooked}
              rowsPerPage={this.state.rowsPerPageBooked}
              rowsPerPageOptions={rowsPerPageOptions}
              backIconButtonProps={{
                "aria-label": "Previous Page"
              }}
              nextIconButtonProps={{
                "aria-label": "Next Page"
              }}
              onChangePage={(event, page) =>
                this.handlePageChangeBooked(page, this.state.rowsPerPageBooked)
              }
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${this.props.translate(
                  "resources.competitions.listPage.of"
                )} ${count}`
              }
              labelRowsPerPage={this.props.translate(
                "resources.competitions.listPage.rowsPerPage"
              )}
              onChangeRowsPerPage={event =>
                this.handlePageChangeBooked(
                  this.state.currentPageBooked,
                  event.target.value
                )
              }
            />
          </Grid>
        ) : (
          <SimpleCardWithoutHeader
            content={[
              <SimpleList
                data={this.state.userBookedCompetitions}
                momentFormat={"L"}
                locale={"de-at"}
                className={"competitions"}
              />,
              <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={this.state.totalItemsCountBooked}
                page={this.state.currentPageBooked}
                rowsPerPage={this.state.rowsPerPageBooked}
                backIconButtonProps={{
                  "aria-label": "Previous Page"
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page"
                }}
                onChangePage={(event, page) =>
                  this.handlePageChangeBooked(
                    page,
                    this.state.rowsPerPageBooked
                  )
                }
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} ${this.props.translate(
                    "resources.competitions.listPage.of"
                  )} ${count}`
                }
                labelRowsPerPage={this.props.translate(
                  "resources.competitions.listPage.rowsPerPage"
                )}
                onChangeRowsPerPage={event =>
                  this.handlePageChangeBooked(
                    this.state.currentPageBooked,
                    event.target.value
                  )
                }
              />
            ]}
            buttonLabel=""
            btnClickHandler=""
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Typography variant="display1" style={styles.simpleTitle}>
          {this.props.translate(
            "shooterCompetitionList.headerParticipatedCompetitions"
          )}
          <Button
            className={"button-right"}
            variant="outlined"
            color="primary"
            style={styles.downloadButton}
            onClick={this.exportArchivedCompetitions}
          >
            <DownloadIcon />
            {this.props.translate(
              "shooterCompetitionList.downloadCompetitions"
            )}
          </Button>
        </Typography>
        <SimpleCardWithoutHeader
          content={[
            <SimpleList
              data={this.state.userParticipatedCompetitions}
              momentFormat={"L"}
              locale={"de-at"}
              className={"competitions"}
            />,
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={this.state.totalItemsCountParticipated}
              page={this.state.currentPageParticipated}
              rowsPerPage={this.state.rowsPerPageParticipated}
              backIconButtonProps={{
                "aria-label": "Previous Page"
              }}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} ${this.props.translate(
                  "resources.competitions.listPage.of"
                )} ${count}`
              }
              labelRowsPerPage={this.props.translate(
                "resources.competitions.listPage.rowsPerPage"
              )}
              nextIconButtonProps={{
                "aria-label": "Next Page"
              }}
              onChangePage={(event, page) =>
                this.handlePageChangeBooked(
                  page,
                  this.state.rowsPerPageParticipated
                )
              }
              onChangeRowsPerPage={event =>
                this.handlePageChangeBooked(
                  this.state.currentPageParticipated,
                  event.target.value
                )
              }
            />
          ]}
          buttonLabel=""
          btnClickHandler=""
        />
      </Grid>
      <SuccessMessage
        successMessage={this.props.successMessage}
        open={this.props.successMessage ? true : false}
        onClose={this.hideSuccessMessage}
        translate={this.props.translate}
      />
      <ErrorMessage
        errorMessage={this.props.errorMessage}
        open={this.props.errorMessage ? true : false}
        onClose={this.props.hideError}
        translate={this.props.translate}
      />
    </Grid>
  );
}

ShooterCompetitionList.propTypes = {
  competitions: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  sortCallback: PropTypes.func.isRequired,
  translate: PropTypes.func.isRequired,
  errorMessage: PropTypes.object,
  successMessage: PropTypes.object,
  hideError: PropTypes.func.isRequired
};
const mapStateToProps = state => {
  return {
    httpErrorMsg: state.error.httpError.message,
    payOrderItemPopup: state.flags.popupOpen.payOrderItemPopup,
    cancelOrderItemPopup: state.flags.popupOpen.cancelOrderItemPopup,
    successMessage: state.success.successMessage.message,
    shooterCompetitionViewType:
      state.identity && state.identity.userData
        ? state.identity.userData.shooterCompetitionViewType
        : false,
    paymentValid:
      state.identity && state.identity.userData
        ? state.identity.userData.paymentValid == "true"
        : false
  };
};

export default connect(mapStateToProps)(translate(ShooterCompetitionListPage));
