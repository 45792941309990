// React imports
import React from "react";

// Material imports
import Grid from "@material-ui/core/Grid";

import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
// Other imports
import PropTypes from "prop-types";
import DynamicList from "../DynamicList";
import PageTitle from "../PageTitle";

const styles = {
  spacer: {
    display: "block",
    height: "20px"
  },
  marginBottom: {
    marginBottom: "20px"
  },
  cardTitleBgGreen: {
    backgroundColor: "#7E896E",
    padding: "16px"
  },
  cardTitleBgPurple: {
    backgroundColor: "#7C757B",
    padding: "16px"
  },
  cardTitleBgBlue: {
    backgroundColor: "#4F6982",
    padding: "16px"
  },
  cardTitle: {
    color: "#ffffff",
    display: "flex",
    minHeight: "40px",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  signUpBtn: {
    color: "#ffffff",
    backgroundColor: "#7E896E"
  },
  signUpSquadBtn: {
    color: "#ffffff",
    backgroundColor: "#4F6982"
  },
  tableRow: {
    height: "auto",
    verticalAlign: "top"
  },
  tableCell: {
    borderBottomWidth: 0,
    padding: "4px 12px 4px 0"
  },
  simpleBtn: {
    color: "#7E896E",
    height: "36px",
    margin: 0,
    padding: "0 12px 0 0",
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 19,
    backgroundColor: "transparent",
    overflow: "hidden"
  },
  list: {
    padding: 0
  },
  listItem: {
    padding: "3px 0 8px 0"
  },
  squadTableCell: {
    paddingLeft: 0
  },
  modalWrapper: {
    position: "absolute",
    width: "400px",
    padding: 0,
    backgroundColor: "#ffffff",
    overflow: "auto"
  },
  closeBtn: {
    textAlign: "end"
  },
  modalContent: {
    padding: "8px 20px"
  },
  dialogWrapper: {
    width: "400px"
  }
};

const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: "#4F6982",
    color: "#ffffff",
    boxShadow: theme.shadows[1],
    fontSize: 11
  }
}))(Tooltip);

const OrderShow = props => {
  const { order } = props;

  return (
    <div>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <PageTitle
            title={props.translate("resources.orderShow.orderDetails")}
          />
          <Card>
            <CardContent>
              <Typography variant="body1">
                {props.translate("resources.orderShow.orderId") +
                  ": " +
                  order.id}
              </Typography>
              <Typography variant="body1">
                {order.firstName} {order.lastName} <br />
                {order.email}
              </Typography>
            </CardContent>
            <CardContent>
              <Typography variant="title">
                {props.translate("resources.orderShow.orderItems")}
              </Typography>
            </CardContent>
            <CardContent>
              <DynamicList
                disable_sorting={[
                  "competitionName",
                  "competitionDate",
                  "firstName",
                  "email",
                  "factorName",
                  "id",
                  "lastName",
                  "squadName",
                  "divisionName",
                  "className"
                ]}
                breakpoint={"xl"}
                data={props.orderItems}
                page={null}
                rowsPerPage={null}
                total={order.orderItems.length}
                handleChangePage={() => {}}
                handleChangeRowsPerPage={() => {}}
                translate={props.translate}
                sortCallback={() => {}}
                transpose={false}
                disable_keys={[
                  "id",
                  "division",
                  "factor",
                  "competitionClass",
                  "deleted"
                ]}
                translationPrefix={props.translationPrefix}
                dateParseString={null}
                momentFormat={props.momentFormat}
                locale={"de-at"}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

OrderShow.propTypes = {
  order: PropTypes.exact({
    id: PropTypes.number,
    name: PropTypes.string,
    date: PropTypes.objectOf(Date),
    information: PropTypes.string,
    type: PropTypes.string,
    level: PropTypes.string,
    stages: PropTypes.number,
    nominationFee: PropTypes.number,
    announcementLink: PropTypes.string,
    clubWebsiteLink: PropTypes.string,
    contactFirstName: PropTypes.string,
    contactLastName: PropTypes.string,
    contactEmail: PropTypes.string,
    contactTelephone: PropTypes.string,
    address: PropTypes.string,
    zip: PropTypes.number,
    city: PropTypes.string,
    country: PropTypes.string,
    googleMapsLink: PropTypes.string,
    registrationStart: PropTypes.objectOf(Date),
    registrationEnd: PropTypes.objectOf(Date),
    division: PropTypes.string,
    class: PropTypes.string,
    factor: PropTypes.string,
    minimumShots: PropTypes.number,
    squadInformationDescription: PropTypes.string,
    feeStatus: PropTypes.string
  }).isRequired
};

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(withStyles(styles)(OrderShow));
