import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import BlockPopup from "./BlockPopup";
import { translate } from "react-admin";

const styles = {
  errorMsg: {
    color: "#CB5D58",
    marginTop: "10px"
  },
  msg: {
    marginTop: "10px"
  }
};

let BlockUserPopup = props => {
  const { handleBlock, handleClose, open, errorMessage } = props;
  const checkAndSubmit = () => {
    handleBlock();
  };

  return <BlockPopup {...props} resource="users" />;
};

BlockUserPopup.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleBlock: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string,
  isBlocked: PropTypes.bool.isRequired
};

export default translate(connect()(BlockUserPopup));
