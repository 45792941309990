import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

const styles = {
  alignCenter: {
    justifyContent: "center"
  },
  spacer: {
    display: "block",
    height: "20px"
  },
  notification: {
    backgroundColor: "rgba(67, 160, 71, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#43A047",
    padding: "8px 10px"
  },
  error: {
    backgroundColor: "rgba(211, 46, 47, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#D32E2F",
    padding: "8px 10px"
  },
  info: {
    backgroundColor: "rgba(26, 118, 210, 0.7)",
    borderWidth: "1px",
    borderRadius: "2px",
    borderColor: "#43A047",
    padding: "8px 10px"
  },
  notificationText: {
    color: "#ffffff"
  },
  loginBtn: {
    display: "flex",
    justifyContent: "flex-end"
  }
};

const ConfirmPasswordReset = props => {
  const { translate, verify, showLoginLink, showError, goToLogin } = props;
  return (
    <Grid container spacing={16} style={styles.alignCenter}>
      <Grid item xs={6}>
        <Card>
          <CardContent>
            <Typography variant="title">
              {translate("resources.users.confirmPasswortReset.headline")}
            </Typography>
          </CardContent>
          <CardContent>
            {showLoginLink && [
              <div style={styles.notification}>
                <Typography variant="body1" style={styles.notificationText}>
                  {translate(
                    "resources.users.confirmPasswortReset.resetSuccess"
                  )}
                </Typography>
              </div>,
              <div style={styles.spacer}></div>,
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#4F6982",
                  color: "#ffffff"
                }}
                onClick={() => goToLogin()}
              >
                {translate("resources.users.confirmPasswortReset.goToLogin")}
              </Button>
            ]}
            {showError && (
              <div style={styles.error}>
                <Typography variant="body1" style={styles.notificationText}>
                  {translate("resources.users.confirmPasswortReset.resetError")}
                </Typography>
              </div>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};
ConfirmPasswordReset.propTypes = {
  translate: PropTypes.func.isRequired,
  verify: PropTypes.bool.isRequired,
  showLoginLink: PropTypes.bool.isRequired,
  showError: PropTypes.bool.isRequired,
  goToLogin: PropTypes.func.isRequired
};
export default ConfirmPasswordReset;
