import React, { Component } from "react";
import { connect } from "react-redux";
import { SaveButton, translate } from "react-admin";
import { updateShooterOrderItem } from "../../utils/restUtils";
import { setSuccess } from "../../reducer/successActions";
import { setError } from "../../reducer/errorActions";
import { withRouter } from "react-router-dom";
import ErrorMessage from "../../components/ErrorMessage";

class SaveShooterOrderItem extends Component {
  state = { disabledSave: false };

  hideErrorMessage = () => {
    this.props.dispatch(setError(null));
  };

  handleClick = () => {
    this.setState({ disabledSave: true });
    return this.props.handleSubmit(values => {
      updateShooterOrderItem(this.props.myForm.values).then(
        success => {
          this.props.history.push("/myCompetitions");
          this.props.dispatch(
            setSuccess({
              message: "SUCCESS_ORDER_ITEM_EDIT"
            })
          );
        },
        e => {
          this.setState({ disabledSave: false });
          this.props.dispatch(setError(e));
        }
      );
    });
  };

  render() {
    const { handleSubmitWithRedirect, saveWithNote, ...props } = this.props;

    return (
      <>
        <SaveButton
          handleSubmitWithRedirect={this.handleClick}
          {...props}
          disabled={this.state.disabledSave}
        />
        <ErrorMessage
          errorMessage={props.httpErrorMsg}
          open={props.httpErrorMsg ? true : false}
          onClose={this.hideErrorMessage}
          translate={this.props.translate}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    httpErrorMsg: state.error.httpError.message,
    myForm: state.form["record-form"]
  };
};

export const SaveShooterOrderItemButton = connect(mapStateToProps)(
  withRouter(translate(SaveShooterOrderItem))
);
